import * as t from 'io-ts';
import { JourneyStatusEnum } from '../../../src/modules/journey/model';
const Service = t.type({
    title: t.string,
    fare: t.type({
        currency_code: t.string,
    }),
    timezone: t.string,
});
const Vehicle = t.type({
    id: t.string,
    make: t.string,
    model: t.string,
    license_plate: t.string,
});
const ServiceProvider = t.type({
    id: t.string,
    first_name: t.string,
    last_name: t.string,
});
const BreakdownItem = t.type({
    name: t.string,
    value: t.number,
});
const Event = t.type({
    name: t.string,
    ts: t.string,
});
const Journey = t.intersection([
    t.type({
        id: t.string,
        number: t.number,
        status: t.keyof(JourneyStatusEnum),
        completed_at: t.union([t.string, t.undefined, t.null]),
        service: Service,
        serving_time: t.union([t.number, t.undefined]),
        service_provider: t.union([ServiceProvider, t.undefined]),
        vehicle: t.union([Vehicle, t.undefined]),
        price: t.union([t.number, t.undefined]),
        breakdown: t.union([t.array(BreakdownItem), t.undefined]),
        events: t.union([t.array(Event), t.undefined]),
    }),
    t.partial({
        pickup_at: t.union([t.string, t.undefined, t.null]),
    }),
]);
export { Journey };
