window.abrakadabra = () => {
    const SIGNIFICANT_TAGS = [
        'button',
        'a',
        'ul',
        'li',
        'h1',
        'h2',
        'h3',
        'h4',
        'label',
        'form',
        'input',
        'section',
        'body',
    ].map(s => s.toUpperCase());
    const SIGNIFICANT_TAGS_SELECTOR = SIGNIFICANT_TAGS.join(',') + ',[data-test-id]';
    const style = document.createElement('style');
    style.innerText = `
  [data-test-id] {
    outline: 0.5px dashed orange !important;
  }
  [data-test-id]:hover {
    box-shadow: 0px 0px 16px 3px red;
  }
`;
    document.head.append(style);
    const getAnotherSelector = (element) => {
        const significantParent = element.closest(SIGNIFICANT_TAGS_SELECTOR);
        const result = [];
        let current = element;
        while (current && current !== significantParent) {
            result.unshift(current.tagName);
            current = current.parentElement;
        }
        significantParent && result.unshift(significantParent.tagName);
        return result.filter(Boolean).join('>');
    };
    const collectPath = (element, path = []) => {
        const mySelector = element?.dataset?.testId
            ? `[data-test-id="${element?.dataset?.testId}"]`
            : getAnotherSelector(element);
        const selector = [mySelector, ...path];
        const parent = element.parentNode?.closest('[data-test-id]');
        if (parent) {
            return collectPath(parent, selector);
        }
        return selector;
    };
    // const refineSelector = (path: string[], takeFirst = path.length): string[] => {
    // };
    let lastTarget = null;
    let lastSelector = '';
    document.body.addEventListener('pointermove', event => {
        if (event.target === lastTarget) {
            return;
        }
        lastTarget = event.target;
        // eslint-disable-next-line no-console
        console.log('Press shift to copy selector');
        // eslint-disable-next-line no-console
        console.log(event.target);
        lastSelector = collectPath(event.target).join(' ');
        // eslint-disable-next-line no-console
        console.log(lastSelector, '\n------\n');
    }, { capture: true });
    document.addEventListener('keydown', event => {
        if (event.shiftKey) {
            navigator.clipboard.writeText(lastSelector);
        }
    }, { capture: true });
};
export {};
