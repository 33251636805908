import { createBrowserHistory } from 'history';
const ROLLBACK_PARAM_NAME = 'back_url';
const createHistory = (params) => {
    const prototype = createBrowserHistory();
    const initialLength = history.length;
    return Object.assign(prototype, {
        goBackSmart(defaultBackPath = params.defaultBackPath) {
            if (history.length === initialLength) {
                this.replace(defaultBackPath);
                return;
            }
            this.goBack();
        },
        replaceWithRollback(path) {
            if (path === this.location.pathname) {
                return;
            }
            const [pathname, search = ''] = path.split('?');
            const searchParams = new URLSearchParams(search);
            searchParams.append(ROLLBACK_PARAM_NAME, this.location.pathname);
            this.replace([pathname, searchParams.toString()].join('?'));
        },
        rollback() {
            const rollbackPath = new URLSearchParams(this.location.search).get(ROLLBACK_PARAM_NAME);
            if (!rollbackPath) {
                return false;
            }
            this.replace(rollbackPath);
            return true;
        },
    });
};
export { createHistory };
