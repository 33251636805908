import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useRef } from 'react';
import { Button, H3, IconArrowLeft } from '@wheely/ui-kit';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ProgressLine } from '../../../../src-common/view/components/ProgressLine';
import { BottomButtons } from '../BottomButtons';
import { CenteringContainer } from '../CenteringContainer';
import { SwapTransition } from '../SwapTransition';
import s from './styles.css';
import { DelayedDisappearance } from './DelayedDisappearance';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Dummy = React.forwardRef(props => props.children);
const Header = ({ onClick, isBackDisabled, step, totalSteps, isProgressHidden }) => (_jsxs("div", { className: s.top, children: [_jsx("button", { "data-test-id": "wizard__back-button", value: "BACK", className: s.back, onClick: onClick, disabled: isBackDisabled, children: _jsx(IconArrowLeft, { width: 20, height: 20 }) }), !isProgressHidden && (_jsx("span", { className: s.steps, "data-test-id": "wizard__progress-text", children: _jsx(FormattedMessage, { id: 'cE3DJO', defaultMessage: '{step} of {totalSteps}', values: { step: step, totalSteps: totalSteps } }) })), !isProgressHidden && _jsx(ProgressLine, { className: s.progress, progress: step / totalSteps })] }));
const StepLayout = ({ onClickYes, onClickBack, onClickNo, totalSteps = 1, step = 0, children, title, subTitle, textNoButton, textYesButton, transitionKey, hideProgress = false, hideHeader = false, }) => {
    const swapperRef = useRef(null);
    const handlePrevClickButton = useCallback(() => {
        swapperRef.current?.reverseNextTransition();
        onClickBack?.();
    }, [onClickBack]);
    const scrollAreaRef = useRef(null);
    const Swap = transitionKey ? SwapTransition : Dummy;
    const innerHeaderRef = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx(CenteringContainer, { innerClassName: s.fixedArea, innerRef: innerHeaderRef, outerClassName: cn(s.fixedAreaWrapper, { [s.fixedAreaWrapperHidden]: hideHeader }), children: _jsx(DelayedDisappearance, { Component: Header, isHidden: hideHeader, props: {
                        onClick: handlePrevClickButton,
                        isBackDisabled: !onClickBack,
                        isProgressHidden: hideProgress,
                        step,
                        totalSteps,
                    }, triggerRef: innerHeaderRef }) }), _jsx(Swap, { ref: swapperRef, transitionKey: transitionKey ?? '', targetElementRef: scrollAreaRef, children: _jsxs(CenteringContainer, { innerRef: scrollAreaRef, innerClassName: s.scrollArea, outerClassName: s.scrollAreaWrapper, children: [(title || subTitle) && (_jsxs("header", { className: s.header, "data-test-id": "wizard__step-title", children: [_jsx(H3, { className: s.title, children: title }), Boolean(subTitle) && (_jsx("p", { className: s.subTitle, role: "doc-subtitle", "data-test-id": "wizard__step-sub-title", children: subTitle }))] })), children, (onClickYes || onClickNo) && (_jsxs(BottomButtons, { children: [onClickYes && (_jsx(Button, { value: "YES", onClick: onClickYes, children: textYesButton || (_jsx(FormattedMessage, { id: 'LeRDjJ', defaultMessage: 'Yes' })) })), onClickNo && (_jsx(Button, { value: "NO", onClick: onClickNo, intent: "none", children: textNoButton || (_jsx(FormattedMessage, { id: 'xlcvRJ', defaultMessage: 'No' })) }))] }))] }) })] }));
};
export { StepLayout };
