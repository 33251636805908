import { ENV } from '../../env';
export const MAIN_HOST = ENV.BASE_DOMAIN;
const getSiteDomain = () => {
    switch (ENV.DEPLOY_ENV) {
        case 'dev':
            return 'https://site.dev.wheely-dev.com';
        case 'local':
        case 'staging':
            return 'https://site.stg.wheely-dev.com';
        default:
            return `https://${MAIN_HOST}`;
    }
};
export const siteDomain = getSiteDomain();
