import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H3, Tooltip } from '@wheely/ui-kit';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { Icon } from '../../../../../src-common/view/components/Icon';
import s from './styles.css';
const UpcomingBill = ({ amount, currencyCode, periodStart, periodEnd, timezone }) => (_jsxs("div", { className: s.upcomingBill, children: [_jsxs("div", { className: s.titleWrapper, children: [_jsx(H3, { className: s.upcomingBillTitle, children: _jsx(FormattedNumber, { value: amount, style: "currency", currency: currencyCode }) }), _jsx(Tooltip, { content: _jsx(FormattedMessage, { id: 'vl+HQ6', defaultMessage: 'Next Payment' }), children: _jsx("div", { className: s.infoIconWrapper, children: _jsx(Icon, { icon: "info", size: "18px" }) }) })] }), _jsx("div", { className: s.upcomingBillSubtitle, children: _jsx(FormattedMessage, { id: 'M+0Th6', defaultMessage: 'Period: {start} \u2013 {end}', values: {
                    start: _jsx(FormattedDate, { value: periodStart, timeZone: timezone }),
                    end: _jsx(FormattedDate, { value: periodEnd, timeZone: timezone }),
                } }) })] }));
export { UpcomingBill };
