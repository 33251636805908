import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../namespace';
import { onboardingAPI } from '../api';
import { updatePartner } from '../../partner/actions/actions';
let lastRequestId;
const submitPaymentInfoStep = createAsyncThunk(`${namespace}/submit-payment-info-step`, async (formValues, { dispatch, requestId }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const result = await onboardingAPI.submitPaymentInfoStep(formValues);
        if (!result.validationErrors) {
            await dispatch(updatePartner({
                paymentAccount: formValues.accountNumber,
                paymentSortCode: formValues.sortCode,
                paymentBik: formValues.bik,
                paymentIban: formValues.iban,
                paymentSwift: formValues.swift,
                paymentRasAccount: formValues.rasAccount,
                onboarding: result.onboarding,
                paymentBankName: formValues.bankName,
                paymentKorAccount: formValues.corAccount,
            }));
        }
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { submitPaymentInfoStep };
