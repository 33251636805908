import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setDocDefinitions, setMyDriverDocDefinitions } from '../actions';
import { driverAPI } from '../../api';
let lastRequestId;
const loadDriverDocDefinitions = createAsyncThunk(`${namespace}/fetch-doc-definitions`, async (_, { dispatch, requestId }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const docs = await driverAPI.fetchDocDefinitions();
        const withoutPhoto = docs.filter(doc => doc.docType !== 'photo');
        dispatch(setDocDefinitions(withoutPhoto));
        dispatch(setMyDriverDocDefinitions(withoutPhoto));
        return { isSucceed: true };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { loadDriverDocDefinitions };
