import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import cn from 'classnames';
import { SelectField } from '../../../../../src-common/view/adapters/SelectField';
import { TextField } from '../../../../../src-common/view/adapters/TextField';
import { DateField } from '../../../../../src-common/view/adapters/DateField';
import s from './FilterItem.css';
const FilterItem = ({ value, onChange, title, name, placeholder, options, type, icon, hasOneLineLabel, isCompact, fixedValue, isClearable, }) => {
    const titleElement = useMemo(() => isCompact ? ('') : (_jsx("span", { className: cn(s.title, { [s.oneLine]: hasOneLineLabel }), children: title })), [isCompact, hasOneLineLabel, title]);
    if (type === 'select' && options) {
        return (_jsx(SelectField, { isDisabled: fixedValue !== undefined, title: titleElement, name: name, value: value[name] ?? '', placeholder: isCompact ? title : placeholder, onChange: onChange, items: options, isClearable: isClearable }));
    }
    if (type === 'date') {
        return (_jsx(DateField, { isDisabled: fixedValue !== undefined, title: titleElement, placeholder: isCompact ? title : placeholder, name: name, onChange: onChange, value: value[name] ?? null, startElem: icon }));
    }
    return (_jsx(TextField, { isDisabled: fixedValue !== undefined, startElem: icon, title: titleElement, placeholder: isCompact ? title : placeholder, name: name, value: value[name] ?? '', onChange: onChange }));
};
export { FilterItem };
