import * as t from 'io-ts';
import { AccessStatusEnum } from '../../../src/shared/models/access-status';
import { formData } from '../io-types/form-data';
import { licensingAuthorityTypesEnum } from '../../../src/shared/models/licensingAuthority';
import { transferRequestStatusesEnum } from '../../../src/modules/driver/model/driver';
import { CarStatusHash } from './w4d-cars-types';
import { UploadDefinition, UploadedFile, UploadResult } from './w4d-upload-types';
const DriverTestStatus = t.type({
    id: t.string,
    test_id: t.string,
    status: t.keyof({ passed: null, failed: null, pending: null }),
});
const DriverDocType = t.string;
const DriverUploadRequest = formData(t.type({
    type: DriverDocType,
    upload_file: t.unknown,
    expires_at: t.union([t.undefined, t.string]),
}));
const DriverUploadedFile = t.type({
    ...UploadedFile.props,
    type: DriverDocType,
});
const TransferRequest = t.type({
    status: t.keyof(transferRequestStatusesEnum),
    left_at: t.string,
});
const DriverBrief = t.type({
    // TODO improve BE serializer and remove undefined
    access_status: t.union([t.undefined, t.keyof(AccessStatusEnum)]),
    birthday: t.union([t.null, t.string]),
    email: t.union([t.undefined, t.string]),
    first_name: t.string,
    id: t.string,
    last_name: t.string,
    phone: t.string,
    rating: t.number,
    score: t.number,
    home_address: t.union([t.undefined, t.string]),
    license_number: t.union([t.undefined, t.string]),
    uk_national_insurance: t.union([t.undefined, t.string]),
    tags: t.union([t.undefined, t.array(t.string)]),
    uploads: t.union([t.undefined, t.array(DriverUploadedFile)]),
    driver_transfer_request: t.union([t.undefined, t.null, TransferRequest]),
    // TODO improve BE serializer and remove undefined
    tests: t.union([t.undefined, t.array(DriverTestStatus)]),
    license_authority: t.union([t.undefined, t.string, t.null]),
    last_activity_at: t.union([t.undefined, t.string, t.null]),
    photo: t.union([t.undefined, t.string, t.null]),
    photo_url: t.union([t.undefined, t.string, t.null]),
    passed_accreditation: t.union([t.undefined, t.string, t.boolean]),
    car: t.union([
        t.undefined,
        t.null,
        t.type({
            id: t.string,
            current_driver_id: t.string,
            status_hash: CarStatusHash,
            license_plate: t.string,
            make: t.string,
            model: t.string,
        }),
    ]),
});
// TODO let back-end returns DriverBrief or Driver
const NewDriverResponse = t.partial(DriverBrief.props);
const DriverExtra = t.type({
    current_order: t.unknown,
    acceptance: t.union([t.undefined, t.null, t.number]),
    tags: t.union([t.undefined, t.array(t.string)]),
    city_id: t.string,
    partner_driver_contract: t.union([t.undefined, t.null, t.string]),
    partner: t.union([t.undefined, t.null, t.type({ id: t.string })]),
    block_info: t.union([
        t.undefined,
        t.type({
            blocked_at: t.union([t.undefined, t.string]),
            blocked_by: t.union([t.undefined, t.string]),
            reason: t.union([t.undefined, t.string]),
            notes: t.union([t.undefined, t.string]),
        }),
    ]),
});
const DriverDetails = t.intersection([DriverBrief, DriverExtra]);
const NewDriverRequest = t.type({
    last_name: t.string,
    first_name: t.string,
    middle_name: t.union([t.string, t.null]),
    phone: t.string,
    email: t.string,
    license_number: t.union([t.string, t.null]),
    uk_national_insurance: t.union([t.string, t.null]),
    home_address: t.union([t.string, t.null]),
    photo: t.null,
    birthday: t.string,
    rating: t.null,
    created_at: t.string,
    score: t.null,
    acceptance: t.null,
    access_status: t.string,
    last_activity_at: t.null,
    car_id: t.union([t.string, t.null]),
    block_info: t.null,
    license_authority: t.union([t.undefined, t.keyof(licensingAuthorityTypesEnum), t.null]),
    partner_driver_contract: t.union([t.undefined, t.null, t.string]),
    passed_tests: t.array(t.unknown),
});
const DriverUploadDefinition = t.type({
    ...UploadDefinition.props,
    type: DriverDocType,
});
const DriverUploadResult = t.type({
    ...UploadResult.props,
    driver_id: t.string,
    type: DriverDocType,
});
const WorkloadInterval = t.type({
    online_minutes: t.number,
    ts: t.string,
});
const WorkloadSummary = t.type({
    online_minutes: t.number,
    price: t.number,
});
const RawDriverWorkload = t.type({
    intervals: t.array(WorkloadInterval),
    summary: WorkloadSummary,
});
export { DriverBrief, DriverDetails, NewDriverRequest, NewDriverResponse, DriverUploadDefinition, DriverDocType, UploadedFile, DriverUploadRequest, DriverUploadResult, DriverUploadedFile, TransferRequest, WorkloadInterval, WorkloadSummary, };
