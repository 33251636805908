import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@wheely/ui-kit';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { BottomButtons } from '../../shared/view/BottomButtons';
import { StepLayout } from '../../shared/view/StepLayout';
import { CountryCode } from '../../shared/models/country-code';
import { PhoneField } from '../../../src-common/view/adapters/reactHookForm/PhoneField';
import { usePhoneCodes } from '../../shared/hooks/usePhoneCodes';
import { getCountryCode } from '../../modules/common/store/selectors';
const EditContactView = ({ onSubmit, control, isSubmitting, onGoBack }) => {
    const intl = useIntl();
    const phoneCodes = usePhoneCodes();
    const countryCode = useSelector(getCountryCode);
    return (_jsx(StepLayout, { hideProgress: true, title: intl.formatMessage({ id: "N5g41j", defaultMessage: "Contact information" }), onClickBack: onGoBack, children: _jsxs("form", { onSubmit: onSubmit, children: [_jsx(PhoneField, { name: "phoneNumber", namePhoneCode: "phoneCode", title: intl.formatMessage({ id: "sWCsmt", defaultMessage: "Phone" }), control: control, isRequired: true, items: phoneCodes, description: countryCode === CountryCode.France &&
                        intl.formatMessage({ id: "V8we6R", defaultMessage: "Enter phone number without 0" }) }), _jsx(BottomButtons, { children: _jsx(Button, { type: "submit", disabled: isSubmitting, children: _jsx(FormattedMessage, { id: 'RT8KNi', defaultMessage: 'Save' }) }) })] }) }));
};
export { EditContactView };
