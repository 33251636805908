import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useExternalLinks } from '../../../app/hooks/useExternalLinks';
import { eventTracking } from '../../../services/event-tracking';
const useMobileAppCard = () => {
    const intl = useIntl();
    const links = useExternalLinks();
    return useMemo(() => [
        {
            id: 'download',
            cardStatus: 'todo',
            title: intl.formatMessage({ id: "193cX3", defaultMessage: "Your account is now active" }),
            desc: intl.formatMessage({ id: "iTHR1Y", defaultMessage: "Download the Wheely app for Chauffeurs to start your first journey." }),
            actionTitle: intl.formatMessage({ id: "U9FVkJ", defaultMessage: "OPEN THE APP" }),
            actionTracker: () => {
                eventTracking.trackSnowplow({
                    event: 'Onboarding - App - Open',
                });
            },
            actionExternalLink: links.downloadDriverApp,
            order: -1000,
            isExcludedFromProgress: true,
        },
    ], [intl, links.downloadDriverApp]);
};
export { useMobileAppCard };
