import { createSlice } from '@reduxjs/toolkit';
import { namespace } from '../namespace';
const initialState = {
    earnings: [],
    interval: { from: undefined, to: undefined },
};
const earningsSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        setEarnings: (state, action) => {
            state.earnings = action.payload.earnings;
            state.interval = action.payload.interval;
        },
    },
});
export { earningsSlice, namespace as earningsNamespace };
