import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setDriver } from '../actions';
import { driverAPI } from '../../api';
const loadDriverById = createAsyncThunk(`${namespace}/load-driver-by-id`, async (id, { dispatch }) => {
    if (id === undefined) {
        return undefined;
    }
    const driver = await driverAPI.fetchDriverById(id);
    dispatch(setDriver(driver));
    return driver;
});
export { loadDriverById };
