import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import { useMemo } from 'react';
import { getHardcodedCarModels, } from '../../../../../modules/car/api/hardcoded-make-models';
import { getLocalizedColorName } from '../../../../../i18n/getLocalizedColorName';
const createListOfMakesByVehicles = (vehicles) => {
    const listOfMakes = {};
    vehicles.forEach(vehicle => {
        const currentMake = {};
        vehicle.modifications.forEach(modification => {
            const currentClass = modification.serviceClass;
            if (!currentMake.hasOwnProperty(currentClass)) {
                currentMake[currentClass] = {
                    tag: modification.tag,
                    models: [modification.model],
                    maxAge: modification.maxAge,
                    colors: modification.color,
                };
            }
            else {
                currentMake[currentClass].models.push(modification.model);
            }
        });
        listOfMakes[vehicle.make] = currentMake;
    });
    return listOfMakes;
};
export const useVehicleByClassList = (cityName) => {
    const vehicles = getHardcodedCarModels(cityName);
    const intl = useIntl();
    const listOfMakes = useMemo(() => createListOfMakesByVehicles(vehicles), [vehicles]);
    return useMemo(() => {
        const result = [];
        for (const [make, serviceClasses] of Object.entries(listOfMakes)) {
            Object.values(serviceClasses).forEach(serviceClass => {
                result.push({
                    title: `${make} ${intl.formatList(serviceClass.models, {
                        type: 'disjunction',
                    })}`,
                    description: (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(FormattedMessage, { id: 'uT53ZW', defaultMessage: 'No older than {count} years', values: { count: serviceClass.maxAge } }), _jsx("span", { children: "*" })] }), _jsx("div", { children: _jsx(FormattedList, { value: serviceClass.colors.map(color => getLocalizedColorName(color.value, intl)), type: "disjunction" }) })] })),
                    value: `${make.toLowerCase()}_${serviceClass.tag}`,
                });
            });
        }
        result.push({
            title: intl.formatMessage({ id: "bMMVvM", defaultMessage: "I don't have any of these vehicles yet" }),
            value: 'None',
        });
        return result;
    }, [intl, listOfMakes]);
};
