import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCountryCode, getCityName } from '../../modules/common/store/selectors';
import { CountryCode } from '../../shared/models/country-code';
import { IntlContext } from '../../i18n';
import { MAIN_HOST, siteDomain } from '../../shared/utils/getSiteDomain';
const checkIOS = () => ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
const getCountryName = (countryCode) => {
    switch (countryCode) {
        case CountryCode.France:
            return 'france';
        case CountryCode.Russia:
            return 'russia';
        default:
            return 'uk';
    }
};
const getBookOneDayAppointment = (cityName) => {
    switch (cityName) {
        case 'dubai':
            return 'https://calendly.com/dubaiappointments';
        case 'moscow':
            return 'https://wheely.reservio.com/wheelymoscow';
        case 'spb':
            return 'https://wheely.reservio.com/st-petersburg';
        case 'sochi':
            return 'https://wheely.reservio.com/sochi';
        default:
            return 'https://wheely.reservio.com/';
    }
};
const getBookTwoDayAppointment = (cityName) => {
    switch (cityName) {
        case 'paris':
            return {
                day1: 'https://calendly.com/wheelyparis/session-accreditation',
                day2: 'https://calendly.com/wheelyparis/reactivation',
            };
        case 'london':
            return {
                day1: 'https://calendly.com/wheelylondon/wheely-luxury-chauffeur-skills-training-day-1',
                day2: 'https://calendly.com/wheelylondon/wheely-luxury-chauffeur-skills-training-day-2',
            };
    }
};
const getOperationsTeamEmailByCity = (cityName) => {
    switch (cityName) {
        case 'london':
            return 'londonpartners@wheely.com';
        case 'paris':
            return 'parispartners@wheely.com';
        case 'dubai':
            return 'dubaipartners@wheely.com';
        case 'moscow':
            return 'moscowpartners@wheely.com';
        case 'spb':
            return 'spbpartners@wheely.com';
        case 'sochi':
            return 'sochipartners@wheely.com';
        default:
            return '';
    }
};
const getOperationsTeamEmailByLanguage = (language) => {
    switch (language) {
        case 'fr':
            return 'parispartners@wheely.com';
        case 'ru':
            return 'moscowpartners@wheely.com';
        default:
            return 'londonpartners@wheely.com';
    }
};
const getOperationsTeamEmail = ({ cityName, language, }) => {
    const email = getOperationsTeamEmailByCity(cityName);
    if (!email) {
        return getOperationsTeamEmailByLanguage(language);
    }
    return email;
};
const getBookInspectionLink = (cityName) => {
    if (cityName === 'paris') {
        return 'https://wheely.com/fr/faq-for-drivers/france/2424649/4197710';
    }
    return getBookOneDayAppointment(cityName);
};
const useExternalLinks = () => {
    const region = useSelector(getCountryCode);
    const cityName = useSelector(getCityName);
    const { language } = useContext(IntlContext);
    const isIOS = checkIOS();
    const countryName = getCountryName(region);
    return useMemo(() => ({
        faq: `https://${MAIN_HOST}/${language}/faq-for-drivers/${countryName}`,
        faqAcquiringANewVehicle: `https://${MAIN_HOST}/${language}/faq-for-drivers/uk/2424649/8148439`,
        downloadDriverApp: isIOS
            ? 'https://apps.apple.com/ru/app/wheely-for-chauffeurs/id1473863611'
            : 'https://play.google.com/store/apps/details?id=com.wheely.groundio',
        termsOfUse: `https://${MAIN_HOST}/${language}/legal/${countryName}/for-drivers/terms`,
        cookiePolicy: `https://${MAIN_HOST}/${language}/legal/${countryName}/for-users/cookie`,
        privacyPolicy: `https://${MAIN_HOST}/${language}/legal/${countryName}/for-drivers/privacy`,
        contactOperationTeam: getOperationsTeamEmail({ cityName, language }),
        bookCarInspection: getBookInspectionLink(cityName),
        requirementsForCar: `https://${MAIN_HOST}/${language}/faq-for-drivers/${countryName}/2424649/4191354`,
        bookOneDayAppointment: getBookOneDayAppointment(cityName),
        bookTwoDayAppointment: getBookTwoDayAppointment(cityName),
        chauffeurLanding: `${siteDomain}/${language}/chauffeur`,
        findInn: 'https://service.nalog.ru/inn.do',
        registerIPorOOO: 'https://www.gosuslugi.ru/situation/kak_otkryt_svoe_delo',
        licensingAuthorities: `https://${MAIN_HOST}/${language}/faq-for-drivers/uk/7104480/6502421`,
        licensingAuthorityTFL: 'https://tfl.gov.uk/info-for/taxis-and-private-hire/licensing',
        licensingAuthorityCastlepoint: 'https://www.castlepoint.gov.uk/hackney-carriage-and-private-hire-licensing/',
        licensingAuthorityRBWM: 'https://wheely.com/en/faq-for-drivers/uk/7104480/8607446',
        shareFeedback: (() => {
            switch (region) {
                case 'RU':
                    return 'https://docs.google.com/forms/d/15TmYETdkLD8q5htiIQ1_ATu5Bzp1T-xFNi8TuDBuOaQ';
                case 'FR':
                    return 'https://docs.google.com/forms/d/1RuyVzwnKTLAI863eGaKMCL11yLeuXXB5VUvyvtBvcaE';
                default:
                    return 'https://docs.google.com/forms/d/1Y17ugTLSLcfhgJ74HIKAbenZmOt3nLx2_Bl2e080Zzg';
            }
        })(),
    }), [isIOS, language, countryName, region, cityName]);
};
export { useExternalLinks };
