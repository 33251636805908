import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { prepareUploadMyDriverForm } from '../../modules/driver/actions/async/prepare-upload-driver-form';
import { prepareUploadPartnerForm } from '../../modules/partner/actions/async/preparePartnerUploadForm';
import { myDriverUploadSelectors } from '../../modules/driver/store/upload-slice';
import { partnerUploadSelectors } from '../../modules/partner/store/uploadSlice';
import { UploadMyDriver, UploadPartner } from '../../shared/view/Upload';
import { reloadPersonData } from '../../modules/partner/actions/async/reload-person-data';
import { usePermissionsContext } from '../../app/contexts/permissions';
import { EditOwnDriverInfo } from './components/EditOwnDriverInfo';
const isDriverDoc = Symbol('isDriverDoc');
const mergeDocListItems = (forPartner, forDriver) => [
    ...forDriver.map(document => ({ ...document, [isDriverDoc]: true })),
    ...forPartner.map(document => ({ ...document, [isDriverDoc]: false })),
];
const ProfileChauffeur = ({ onNavigateBack }) => {
    const dispatch = useDispatch();
    const { user } = usePermissionsContext();
    const [isDriverUploading, setIsDriverUploading] = useState(false);
    const [isLegalUploading, setIsLegalUploading] = useState(false);
    const handleFinishEdit = useCallback(() => {
        onNavigateBack('/profile');
    }, [onNavigateBack]);
    const handleFinishUpload = useCallback(() => {
        dispatch(reloadPersonData());
        setIsDriverUploading(false);
        setIsLegalUploading(false);
    }, [dispatch]);
    const handleSkipUpload = useCallback(() => {
        setIsDriverUploading(false);
        setIsLegalUploading(false);
    }, []);
    const driverDocs = useSelector(myDriverUploadSelectors.getDocsList);
    const partnerDocs = useSelector(partnerUploadSelectors.getDocsList);
    const mergedDocs = useMemo(() => user.isFleet
        ? mergeDocListItems(partnerDocs, [])
        : mergeDocListItems(partnerDocs, driverDocs), [driverDocs, partnerDocs, user.isFleet]);
    const handleUploadDoc = useCallback((_, doc) => {
        if (doc[isDriverDoc]) {
            dispatch(prepareUploadMyDriverForm({
                docType: doc.docType,
                onSuccess: () => setIsDriverUploading(true),
            }));
        }
        else {
            dispatch(prepareUploadPartnerForm({
                docType: doc.docType,
                onSuccess: () => setIsLegalUploading(true),
            }));
        }
    }, [dispatch]);
    return (_jsxs(_Fragment, { children: [_jsx(EditOwnDriverInfo, { docs: mergedDocs, onUploadDoc: handleUploadDoc, onFinishSubmit: handleFinishEdit, onGoBack: handleFinishEdit }), isDriverUploading && (_jsx(UploadMyDriver, { onClickSkip: handleSkipUpload, onFinishUpload: handleFinishUpload })), isLegalUploading && (_jsx(UploadPartner, { onClickSkip: handleSkipUpload, onFinishUpload: handleFinishUpload }))] }));
};
export { ProfileChauffeur };
