import { setCookieValue, getCookieValue } from '../shared/utils/cookie';
const URL_PARAMS_COOKIE = 'URL_PARAMS';
let initialParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());
if (Object.keys(initialParams).length > 0) {
    setCookieValue(URL_PARAMS_COOKIE, JSON.stringify(initialParams));
}
else {
    initialParams = getCookieValue(URL_PARAMS_COOKIE) || {};
}
const queryParamsService = {
    getAllParams: () => initialParams,
    getParamsByNames(...names) {
        return Object.fromEntries(Object.entries(this.getAllParams()).filter(([name]) => names.includes(name)));
    },
    getSingleByName(name) {
        return this.getAllParams()[name];
    },
};
export { queryParamsService };
