import { useLicensingAuthorityTypes } from '../../../shared/hooks/useLicensingAuthorityTypes';
import { useColors, useInteriorColors } from './useColors';
import { useGenerations } from './useGenerations';
import { useMakes } from './useMakes';
import { useModels } from './useModels';
import { useWheelbases } from './useWheelbases';
import { useYears } from './useYears';
import { useEngines } from './useEngines';
const useFormOptions = (make, model) => {
    const makes = useMakes();
    const models = useModels(make);
    const generations = useGenerations(make, model);
    const years = useYears(make, model);
    const colors = useColors(make, model);
    const interiorColors = useInteriorColors(make, model);
    const wheelbases = useWheelbases(make, model);
    const licensingAuthorities = useLicensingAuthorityTypes();
    const engines = useEngines();
    return {
        makes,
        models,
        generations,
        years,
        colors,
        interiorColors,
        wheelbases,
        licensingAuthorities,
        engines,
    };
};
export { useFormOptions };
