import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fsmSelectors, fsmAsyncActions, fsmSlice } from '../../modules/onboarding/store/fsm-slice';
import { fsmCfg, fsmConfigPatchForFR, fsmConfigPatchForUK, } from '../../modules/onboarding/fsm-config';
import { WizardView } from './view';
const WizardManager = ({ initialValue, partnerFirstName, partnerCountryCode, }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        const onUpdateFsmValue = (newValue, context) => {
            const internalPath = generatePath(newValue, context);
            history.replace(internalPath + (context?.hash ? `#${context.hash}` : ''));
        };
        dispatch(fsmSlice.actions.setOnUpdate(onUpdateFsmValue));
    }, [dispatch, history]);
    useEffect(() => {
        dispatch(fsmAsyncActions.trySetValue({ newValue: initialValue }));
    }, [dispatch, initialValue]);
    useEffect(() => {
        if (partnerCountryCode === 'GB') {
            dispatch(fsmSlice.actions.patchTransitions(fsmConfigPatchForUK));
            return;
        }
        if (partnerCountryCode === 'FR') {
            dispatch(fsmSlice.actions.patchTransitions(fsmConfigPatchForFR));
            return;
        }
        dispatch(fsmSlice.actions.patchTransitions(fsmCfg));
    }, [partnerCountryCode, dispatch]);
    const state = useSelector(fsmSelectors.getValue);
    const handleSwitchScreen = useCallback((event, newContext) => dispatch(fsmAsyncActions.sendEvent({ event, newContext })).unwrap(), [dispatch]);
    return (_jsx(WizardView, { partnerFirstName: partnerFirstName, partnerCountryCode: partnerCountryCode, onSwitchScreen: handleSwitchScreen, internalPath: state }));
};
export { WizardManager };
