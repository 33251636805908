import { createAsyncThunk } from '@reduxjs/toolkit';
import { commonAPI } from '../api';
import { commonNamespace, setCities, setPressure } from './slice';
const TWO_MINUTES = 2 * 60 * 1000;
export const loadCities = createAsyncThunk(`${commonNamespace}/load-cities`, async (_, { dispatch }) => {
    const result = await commonAPI.readCites();
    dispatch(setCities(result));
    return result;
});
export const loadPressure = createAsyncThunk(`${commonNamespace}/load-pressure`, async (_, { dispatch }) => {
    let result = null;
    try {
        result = await commonAPI.readPressure();
    }
    finally {
        dispatch(setPressure(result));
    }
    return result;
});
let timer;
export const startPressurePolling = createAsyncThunk(`${commonNamespace}/start-pressure-polling`, (_, { dispatch }) => {
    dispatch(loadPressure());
    timer = window.setTimeout(() => {
        dispatch(startPressurePolling());
    }, TWO_MINUTES);
});
export const stopPressurePolling = createAsyncThunk(`${commonNamespace}/stop-pressure-polling`, (_, { dispatch }) => {
    clearTimeout(timer);
    dispatch(setPressure(null));
});
