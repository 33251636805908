import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LicensingAuthorityTypes } from '../models/licensingAuthority';
import { useExternalLinks } from '../../app/hooks/useExternalLinks';
export const useLicensingAuthorityTypes = () => {
    const intl = useIntl();
    const links = useExternalLinks();
    return useMemo(() => [
        {
            title: intl.formatMessage({ id: "VDbFyx", defaultMessage: "TfL" }),
            value: LicensingAuthorityTypes.TFL,
            link: links.licensingAuthorityTFL,
        },
        {
            title: intl.formatMessage({ id: "q+N9ip", defaultMessage: "Royal Borough of Windsor and Maidenhead" }),
            value: LicensingAuthorityTypes.RBWM,
            link: links.licensingAuthorityRBWM,
        },
        {
            title: intl.formatMessage({ id: "CTRo8p", defaultMessage: "Castlepoint" }),
            value: LicensingAuthorityTypes.Castlepoint,
            link: links.licensingAuthorityCastlepoint,
        },
    ], [intl, links]);
};
