import * as Sentry from '@sentry/react';
import { captureException, captureMessage } from '@sentry/react';
import { omitDeep } from '../shared/utils/omitDeep';
import { ENV } from '../env';
import { history } from './history';
const sensitiveFields = [
    'token',
    'access_token',
    'refresh_token',
    'accessToken',
    'refreshToken',
    'first_name',
    'last_name',
    'firstName',
    'lastName',
    'email',
    'phone',
];
class ErrorHandling {
    constructor() {
        if (ENV.DEPLOY_ENV !== 'local') {
            Sentry.init({
                dsn: ENV.SENTRY_DSN,
                environment: ENV.DEPLOY_ENV,
                integrations: [
                    new Sentry.BrowserTracing({
                        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                    }),
                ],
                tracesSampleRate: 0,
                release: `chauffeur-onboarding@${ENV.NPM_PACKAGE_VERSION}`,
                beforeSend: event => omitDeep(event, sensitiveFields),
            });
        }
    }
    captureException(maybeException) {
        try {
            const exception = maybeException instanceof Error
                ? maybeException
                : Object.assign(new Error(), maybeException instanceof Object
                    ? maybeException
                    : { message: String(maybeException) });
            captureException(exception);
            // eslint-disable-next-line no-console
            console.error?.(exception);
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error?.(error);
        }
    }
    identify({ id, email }) {
        Sentry.setUser({ id, email });
    }
    clearIdentity() {
        Sentry.configureScope(scope => scope.setUser(null));
    }
    captureMessage(message) {
        try {
            captureMessage(message);
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error?.(error);
        }
    }
}
const errorHandling = new ErrorHandling();
export { ErrorHandling, errorHandling };
