import { jsx as _jsx } from "react/jsx-runtime";
import { PhoneField as BasePhoneField } from '@wheely/ui-kit';
import { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
const FORBIDDEN_PHONE_NUMBER_CHARACTERS_REGEXP = /[^\d]/g;
const PhoneField = ({ description, name, namePhoneCode, rules, control, defaultValue, shouldUnregister, isRequired, items, ...inputProps }) => {
    const intl = useIntl();
    const { field: phoneCodeField } = useController({
        name: namePhoneCode,
        control,
        shouldUnregister,
    });
    const validationRules = useMemo(() => items.find(item => item.value === phoneCodeField.value)?.validationRules || {}, [phoneCodeField.value, items]);
    const { field: { ref, ...field }, fieldState: { error }, } = useController({
        name,
        rules: {
            required: isRequired
                ? {
                    value: true,
                    message: intl.formatMessage({ id: "Q4ipsb", defaultMessage: "Field is required." }),
                }
                : undefined,
            ...validationRules,
            ...rules,
        },
        control,
        defaultValue,
        shouldUnregister,
    });
    const handleNumberChange = useCallback(newValue => {
        const filteredValue = newValue.replace(FORBIDDEN_PHONE_NUMBER_CHARACTERS_REGEXP, '');
        field.onChange(filteredValue);
    }, [field]);
    const handleCodeChange = useCallback((countryCodeItem) => {
        phoneCodeField.onChange(countryCodeItem.value);
    }, [phoneCodeField]);
    return (_jsx(BasePhoneField, { ...field, ...inputProps, inputRef: ref, value: field.value, error: error?.message, description: !error?.message && description ? description : undefined, items: items, countryCode: phoneCodeField.value, onCodeChange: handleCodeChange, onChange: handleNumberChange }));
};
export { PhoneField };
