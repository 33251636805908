import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import { Icon } from '../../../../src-common/view/components/Icon';
import { CarCell } from '../../../shared/view/CarCell';
import s from './PersonalInfo.css';
const CurrentVehicle = ({ driver }) => {
    const intl = useIntl();
    const titles = useMemo(() => [
        [
            'car',
            driver.carId ? (_jsx(CarCell, { carId: driver.carId }, driver.carId)) : (intl.formatMessage({ id: "y2at3e", defaultMessage: "Select vehicle" })),
            ({ children }) => (_jsx(Link, { to: driver.carId ? `/vehicles/${driver.carId}` : `/chauffeurs/${driver.id}/edit-vehicle`, className: s.link, children: children })),
        ],
    ], [driver.carId, driver.id, intl]);
    const values = {
        car: () => _jsx(Icon, { icon: 'chevron-to-end', className: s.linkIcon }),
    };
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "XAa3Zy", defaultMessage: "Current vehicle" }), values: values, titles: titles, action: driver.carId ? (_jsx(Link, { to: `/chauffeurs/${driver.id}/edit-vehicle`, className: s.edit, children: _jsx(FormattedMessage, { id: 'gRATpQ', defaultMessage: 'Change' }) })) : null }));
};
export { CurrentVehicle };
