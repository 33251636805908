import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AnchorButton, UL } from '@wheely/ui-kit';
import { useSelector } from 'react-redux';
import { IntlContext } from '../../../../i18n';
import { useExternalLinks } from '../../../../app/hooks/useExternalLinks';
import { CountryCode } from '../../../models/country-code';
import { getCalendlyLink } from '../../../utils/bookingLinksUtils';
import { getMyDriver } from '../../../../modules/driver/store/selectors';
import s from './AccountBlockedError.css';
const AccountBlockedError = () => {
    const { region } = useContext(IntlContext);
    const links = useExternalLinks();
    const myDriver = useSelector(getMyDriver);
    const url1 = getCalendlyLink(links.bookTwoDayAppointment?.day1 || '', myDriver);
    const url2 = getCalendlyLink(links.bookTwoDayAppointment?.day2 || '', myDriver);
    return (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, { id: 'XILJnT', defaultMessage: 'Please get in touch with <a>the operations team</a> ({link})', values: {
                    a: (text) => (_jsx("a", { href: `mailto:${links.contactOperationTeam}`, target: "_blank", rel: "noreferrer", children: text })),
                    link: links.contactOperationTeam,
                } }), region === CountryCode.GreatBritain && (_jsxs("div", { className: s.accreditationDetails, children: [_jsx("p", { children: _jsx(FormattedMessage, { id: 'uoUvWI', defaultMessage: 'If you have been contacted to book a re-accreditation session, please use the links below to book both Day 1 and Day 2.' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: '1pueQw', defaultMessage: 'In order to be fully active on the platform you need to complete two days of training. They are booked separately. Please, ensure that:' }) }), _jsx("p", { children: _jsxs(UL, { children: [_jsx(FormattedMessage, { id: 'KTYRiX', defaultMessage: 'You book both days. The \u00A335,00 deposit will be refunded to you after you finish your second day.', tagName: "li" }), _jsx(FormattedMessage, { id: '0jX/Lm', defaultMessage: 'Book Day 1 of training <stress>before</stress> Day 2.', tagName: "li", values: {
                                        stress: (text) => _jsx("span", { children: text }),
                                    } })] }) }), _jsxs("div", { className: s.buttons, children: [_jsx(AnchorButton, { href: url1, fill: true, intent: "none", target: "_blank", rel: "noreferrer", children: _jsx(FormattedMessage, { id: 'ycUk0h', defaultMessage: 'Book Day 1' }) }), _jsx(AnchorButton, { href: url2, fill: true, intent: "none", target: "_blank", rel: "noreferrer", children: _jsx(FormattedMessage, { id: 'aJs6z1', defaultMessage: 'Book Day 2' }) })] })] }))] }));
};
export { AccountBlockedError };
