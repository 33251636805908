import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Button } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MAIN_HOST } from '../../../../shared/utils/getSiteDomain';
import { IntlContext } from '../../../../i18n';
import { CountryCode } from '../../../../shared/models/country-code';
import { getCurrentCarId } from '../../../../modules/car/store/selectors';
import { CheckPoint } from './CheckPoint';
import s from './styles.css';
const createDocsLink = (link) => (children) => (_jsx(Link, { to: link, target: "_blank", rel: "noopener noreferrer", className: s.link, children: children }));
const GBDescription = () => {
    const carId = useSelector(getCurrentCarId);
    return (_jsxs(_Fragment, { children: [_jsx(CheckPoint, { children: _jsx(FormattedMessage, { id: 'OpmoQQ', defaultMessage: 'Upload your <link1>personal</link1> and <link2>vehicle</link2> documents', tagName: "div", values: {
                        link1: createDocsLink('/profile#docs'),
                        link2: createDocsLink(carId ? `/vehicles/${carId}#docs` : '/add-vehicle'),
                    } }) }), _jsx(CheckPoint, { children: _jsx(FormattedMessage, { id: 'kOBx32', defaultMessage: 'Complete your basic learning test' }) }), _jsx(CheckPoint, { children: _jsx(FormattedMessage, { id: 'B6ZBJh', defaultMessage: 'Book accreditation to start taking journeys', tagName: "div" }) }), _jsx(CheckPoint, { children: _jsx(FormattedMessage, { id: 'WZcJrW', defaultMessage: 'Complete a set number of journeys to receive up to \u00A32000 sign-up incentive' }) })] }));
};
const CongratsView = ({ onContinueClick }) => {
    const { region } = useContext(IntlContext);
    return (_jsxs("div", { children: [_jsx("div", { children: _jsx(FormattedMessage, { id: 'w4sgoo', defaultMessage: 'Your registration is complete.' }) }), _jsx("div", { className: s.image }), _jsxs("div", { children: [_jsx(CheckPoint, { isChecked: true, children: _jsx(FormattedMessage, { id: '5Z6s8z', defaultMessage: 'Complete registration' }) }), region === CountryCode.GreatBritain ? (_jsx(GBDescription, {})) : (_jsx(CheckPoint, { children: _jsx(FormattedMessage, { id: 'QL1B2u', defaultMessage: 'Pass accreditation and get access to the Chauffeur app. It usually takes 3-5 days.' }) }))] }), _jsx(Button, { onClick: onContinueClick, className: s.continue, children: _jsx(FormattedMessage, { id: 'sgXEFE', defaultMessage: 'Continue' }) }), _jsx("div", { className: s.footnote, children: _jsx(FormattedMessage, { id: 'TZTHMp', defaultMessage: 'You can return to process anytime by entering your phone number on <link></link>', values: {
                        link: () => (_jsxs("a", { href: "/", target: "_blank", children: ["chauffeur.", MAIN_HOST] })),
                    } }) })] }));
};
export { CongratsView };
