import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useImperativeHandle, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import s from './styles.css';
const classes = {
    appear: s.appear,
    appearActive: s.appearActive,
    appearDone: s.appearDone,
    enter: s.enter,
    enterActive: s.enterActive,
    enterDone: s.enterDone,
    exit: s.exit,
    exitActive: s.exitActive,
    exitDone: s.exitDone,
};
const reversedClasses = {
    appear: s.appear,
    appearActive: s.appearActive,
    appearDone: s.appearDone,
    enter: s.reversedEnter,
    enterActive: s.enterActive,
    enterDone: s.enterDone,
    exit: s.exit,
    exitActive: s.reversedExitActive,
    exitDone: s.exitDone,
};
const SwapTransition = React.forwardRef(({ targetElementRef, children, transitionKey }, ref) => {
    const remainingReverses = useRef(0);
    useImperativeHandle(ref, () => ({
        reverseNextTransition: () => {
            remainingReverses.current = 2;
        },
    }));
    const classNames = useMemo(() => Object.defineProperties({}, Object.fromEntries(Object.keys(classes).map(classKey => [
        classKey,
        {
            get: () => remainingReverses.current > 0 ? reversedClasses[classKey] : classes[classKey],
            enumerable: true,
        },
    ]))), []);
    return (_jsx(SwitchTransition, { mode: "out-in", children: _jsx(CSSTransition, { addEndListener: (done) => {
                targetElementRef.current?.addEventListener?.('transitionend', () => {
                    done();
                    if (remainingReverses.current > 0) {
                        remainingReverses.current--;
                    }
                }, { once: true });
            }, classNames: classNames, nodeRef: targetElementRef, children: children }, transitionKey) }));
});
SwapTransition.displayName = 'SwapTransition';
export { SwapTransition };
