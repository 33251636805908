import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CountryCode } from '../../../../shared/models/country-code';
import { IntlContext } from '../../../../i18n';
import { useExternalLinks } from '../../../../app/hooks/useExternalLinks';
import { ru } from './ru';
const useRegionSpecificCards = (context) => {
    const { region } = useContext(IntlContext);
    const intl = useIntl();
    const links = useExternalLinks();
    return useMemo(() => {
        switch (region) {
            case CountryCode.Russia: {
                return ru(context, intl, links);
            }
            case CountryCode.GreatBritain:
            default:
                return [];
        }
    }, [region, context, intl, links]);
};
export { useRegionSpecificCards };
