import { getFunctionName } from 'io-ts';
import { fold } from 'fp-ts/lib/Either';
import { isPlainObject } from '../../../src/shared/utils/isPlainObject';
const stringify = (value) => {
    if (typeof value === 'function') {
        return getFunctionName(value);
    }
    if (typeof value === 'number' && !isFinite(value)) {
        if (isNaN(value)) {
            return 'NaN';
        }
        return value > 0 ? 'Infinity' : '-Infinity';
    }
    if (isPlainObject(value)) {
        return '{...}';
    }
    try {
        return JSON.stringify(value);
    }
    catch (error) {
        return String(value);
    }
};
const getContextPath = (context) => context.map(({ key }) => key).join('.');
const getMessage = (error) => error.message !== undefined
    ? error.message
    : `Invalid value ${stringify(error.value)} supplied to ${getContextPath(error.context)}`;
const failure = (errors) => errors.map(getMessage);
const success = () => ['No errors!'];
const SimpleReporter = {
    report: fold(failure, success),
};
export { SimpleReporter };
