import { transformObjectValuesToObject } from '../../../shared/utils/transformObjectValuesToObject';
const SELF_EMPLOYED = 'Self Employed';
const GBPartnerType = {
    selfEmployed: SELF_EMPLOYED,
    company: 'LLP',
};
const RUPartnerType = {
    selfEmployed: SELF_EMPLOYED,
    ip: 'ИП',
    ooo: 'Юридическое лицо',
};
const FRPartnerType = {
    selfEmployed: SELF_EMPLOYED,
    company: 'SAS',
};
const UAEPartnerType = {
    company: 'LLC',
};
const allPartnerTypesEnum = [GBPartnerType, RUPartnerType, FRPartnerType, UAEPartnerType].reduce((acc, types) => ({ ...acc, ...transformObjectValuesToObject(types) }), {});
export { GBPartnerType, RUPartnerType, FRPartnerType, UAEPartnerType, SELF_EMPLOYED, allPartnerTypesEnum, };
