import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useCallback, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from '../../../../shared/view/Form';
import { getPartner } from '../../../../modules/partner/store/selectors';
import { eventTracking } from '../../../../services/event-tracking';
import { getCitiesForOnboarding } from '../../../../modules/common/store/selectors';
import { DEFAULT_LANGUAGE } from '../../../../services/userLanguage';
import { IntlContext } from '../../../../i18n';
import { submitWelcomeStep } from '../../../../modules/onboarding/actions/submitWelcomeStep';
import { CountryCode } from '../../../../shared/models/country-code';
import { queryParamsService } from '../../../../services/queryParams';
import { REFERRAL_CODE_KEY } from '../../../Todo/hooks/useReferralCard';
import { createPartner } from '../../../../modules/partner/actions/async/createPartner';
import { addRole, loadUser } from '../../../../modules/user/actions';
import { loadDriver } from '../../../../modules/driver/actions/async/load-driver';
import { loadPartner } from '../../../../modules/partner/actions/async/loadPartner';
import { fsmSlice } from '../../../../modules/onboarding/store/fsm-slice';
import { fsmCfg, fsmConfigPatchForFR, fsmConfigPatchForUK, } from '../../../../modules/onboarding/fsm-config';
import { getRoles, ROLES } from '../../../../modules/user/store/selectors';
import { WelcomeView } from './view';
const mapPartnerToForm = ({ firstName, lastName, middleName, cityId, email, referralCode, source }, externalReferralCode) => ({
    firstName,
    lastName,
    middleName,
    cityId,
    email,
    referralCode: externalReferralCode ?? referralCode,
    source,
});
const getCountryName = (intl, code) => {
    switch (code) {
        case CountryCode.GreatBritain:
            return intl.formatMessage({ id: "UoCfu6", defaultMessage: "United Kingdom" });
        case CountryCode.Russia:
            return intl.formatMessage({ id: "c59cvw", defaultMessage: "Russia" });
        case CountryCode.France:
            return intl.formatMessage({ id: "y2Zozj", defaultMessage: "France" });
        case CountryCode.ArabEmirates:
            return intl.formatMessage({ id: "Ntc6Zg", defaultMessage: "United Arab Emirates" });
        default:
            return intl.formatMessage({ id: "ZagDol", defaultMessage: "Other" });
    }
};
const Welcome = ({ onSwitchScreen }) => {
    const { language } = useContext(IntlContext);
    const intl = useIntl();
    const dispatch = useDispatch();
    const partner = useSelector(getPartner);
    const userRoles = useSelector(getRoles);
    const cities = useSelector(getCitiesForOnboarding);
    const postForm = useCallback(async (formValues) => {
        if (!userRoles.includes(ROLES.PARTNER)) {
            const { errors } = await dispatch(createPartner()).unwrap();
            if (errors && !errors.partner_exists) {
                return { baseErrors: Object.values(errors)[0] };
            }
            dispatch(addRole(ROLES.PARTNER));
        }
        const selectedCity = cities.find(city => city.id === formValues.cityId);
        const selectedCountryCode = selectedCity?.countryCode ?? null;
        const resultAction = await dispatch(submitWelcomeStep(formValues));
        if (submitWelcomeStep.fulfilled.match(resultAction)) {
            const { isSucceed, result = {} } = resultAction.payload;
            if (isSucceed && !result.validationErrors) {
                await dispatch(loadUser()).unwrap();
                dispatch(loadDriver());
                dispatch(loadPartner());
                eventTracking.track({
                    event: 'Onboarding - Contacts - Success',
                    city: selectedCity?.name,
                    country: selectedCountryCode,
                });
                switch (selectedCountryCode) {
                    case 'GB':
                        dispatch(fsmSlice.actions.patchTransitions(fsmConfigPatchForUK));
                        break;
                    case 'FR':
                        dispatch(fsmSlice.actions.patchTransitions(fsmConfigPatchForFR));
                        break;
                    default:
                        dispatch(fsmSlice.actions.patchTransitions(fsmCfg));
                        break;
                }
                onSwitchScreen('NEXT');
            }
            return result;
        }
        return {};
    }, [dispatch, onSwitchScreen, userRoles, cities]);
    const { handleSubmit, form: { control, formState: { isSubmitting }, }, } = useForm({
        defaultValues: mapPartnerToForm(partner, queryParamsService.getSingleByName(REFERRAL_CODE_KEY)),
        onSubmit: postForm,
    });
    useEffect(() => {
        eventTracking.track({
            event: 'Onboarding - Start',
        });
    }, []);
    const cityOptions = useMemo(() => {
        const regions = new Map();
        cities.forEach(city => {
            const countryName = getCountryName(intl, city.countryCode);
            let region = regions.get(countryName);
            if (!region) {
                region = {
                    title: countryName,
                    items: [],
                };
                regions.set(countryName, region);
            }
            region.items.push({
                title: city.localizedName[language] || city.localizedName[DEFAULT_LANGUAGE],
                value: city.id,
            });
        });
        return [...regions.values()];
    }, [cities, intl, language]);
    return (_jsx(WelcomeView, { control: control, onSubmit: handleSubmit, cities: cityOptions, isSubmitting: isSubmitting }));
};
export { Welcome };
