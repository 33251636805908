import { createClient } from '../../../../src-common/api/src/create-client';
import { w4dBillsRemoteAPI } from '../../../../src-common/api/definitions/w4d-bills-endpoints';
import { get } from '../../../shared/api/fetch-based-http-methods';
import { ENV } from '../../../env';
const client = createClient(w4dBillsRemoteAPI({ get, apiHost: ENV.API_URL }));
const prepareBill = (bill) => ({
    id: bill.id,
    number: bill.number,
    date: new Date(bill.date),
    isPaid: bill.paid,
    paidAtDate: bill.paid_at ? new Date(bill.paid_at) : null,
    amount: Number(bill.amount),
    currencyCode: bill.currency_code,
    pdfUrl: bill.pdf,
    excelUrl: bill.excel,
    periodStart: new Date(bill.period_start),
    periodEnd: new Date(bill.period_end),
});
const billAPI = {
    fetchBills: async ({ limit = 10, offset = 0, } = {}) => {
        const response = await client.readBills({
            pathParams: { limit: String(limit), offset: String(offset) },
        });
        const items = response.results.map(prepareBill);
        return {
            items,
            total: response.total_entries,
            limit: response.limit,
            offset: response.skip,
        };
    },
    fetchUpcomingBill: async () => {
        const response = await client.readUpcomingBill();
        return prepareBill(response.bill);
    },
};
export { billAPI };
