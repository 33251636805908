import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNotification } from '@wheely/ui-kit';
import { useIntl } from 'react-intl';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import { loadDriverById } from '../../modules/driver/actions/async/load-driver-by-id';
import { driverUploadSelectors } from '../../modules/driver/store/upload-slice';
import { getCurrentDriver, getHourlyWorkloads, getWorkloadSummary, } from '../../modules/driver/store/selectors';
import { prepareUploadDriverForm } from '../../modules/driver/actions/async/prepare-upload-driver-form';
import { UploadDriver } from '../../shared/view/Upload';
import { blockDriver } from '../../modules/driver/actions/async/block-driver';
import { IntlContext } from '../../i18n';
import { changeContractStatus } from '../../modules/driver/actions/async/change-contract-status';
import { dismissDriver } from '../../modules/driver/actions/async/dismissDriver';
import { loadWorkload } from '../../modules/driver/actions/async/loadWorkload';
import { resetDriver } from '../../modules/driver/actions/actions';
import { usePermissions } from '../../app/hooks/usePermissions';
import { View } from './view';
import { useOpenBlockingModal } from './components/Blocking';
import { useOpenDismissDriverDialog } from './components/DismissDriverDialog';
const ROUTE = '/chauffeurs/:driverId';
const DriverDetails = ({ onNavigateBack }) => {
    usePermissions('viewDrivers');
    const driverId = useRouteMatch(ROUTE)?.params?.driverId;
    const dispatch = useDispatch();
    const intl = useIntl();
    const [workingHoursWeek, setWorkingHoursWeek] = useState({
        from: startOfWeek(new Date(), { weekStartsOn: 1 }),
        to: endOfWeek(new Date(), { weekStartsOn: 1 }),
    });
    useEffect(() => {
        if (!driverId) {
            return;
        }
        dispatch(loadDriverById(driverId));
        return () => {
            dispatch(resetDriver());
        };
    }, [dispatch, driverId]);
    useEffect(() => {
        if (!driverId) {
            return;
        }
        dispatch(loadWorkload({ driverId, from: workingHoursWeek?.from, to: workingHoursWeek?.to }));
    }, [dispatch, workingHoursWeek, driverId]);
    const driver = useSelector(getCurrentDriver);
    const docs = useSelector(driverUploadSelectors.getDocsList);
    const hourlyWorkloads = useSelector(getHourlyWorkloads);
    const workloadSummary = useSelector(getWorkloadSummary);
    const handleClickBack = useCallback(() => onNavigateBack('/todo'), [onNavigateBack]);
    const { openDismissDriverDialog, closeDismissDriverDialog } = useOpenDismissDriverDialog();
    const { showNotification } = useNotification();
    const [isPending, setIsPending] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const handleUploadDoc = useCallback((_, doc) => {
        dispatch(prepareUploadDriverForm({
            docType: doc.docType,
            onSuccess: () => setIsUploading(true),
        }));
    }, [dispatch]);
    const handleSkipUpload = useCallback(() => {
        setIsUploading(false);
    }, []);
    const handleFinishUpload = useCallback(() => {
        dispatch(loadDriverById(driverId));
        setIsUploading(false);
    }, [dispatch, driverId]);
    const { openBlocking, closeDialogs } = useOpenBlockingModal();
    const handleBlock = useCallback(async (reason, message) => {
        if (!driverId) {
            return;
        }
        try {
            setIsPending(true);
            await dispatch(blockDriver({ driverId, reason, message })).unwrap();
        }
        finally {
            setIsPending(false);
            closeDialogs();
        }
    }, [closeDialogs, dispatch, driverId]);
    const handleChangeContractStatus = useCallback(isActive => {
        dispatch(changeContractStatus({ driverId, isActive }));
    }, [dispatch, driverId]);
    const handleStartBlocking = useCallback(() => {
        openBlocking({ onBlock: handleBlock });
    }, [handleBlock, openBlocking]);
    const handleDismissDriver = useCallback(async () => {
        if (!driverId) {
            return;
        }
        const { isAlreadyExecuted } = await dispatch(dismissDriver({ driverId })).unwrap();
        if (isAlreadyExecuted) {
            return;
        }
        closeDismissDriverDialog();
        showNotification({
            message: intl.formatMessage({ id: "eE4wa5", defaultMessage: "Chauffeur released" }),
            showCloseButton: false,
            dark: true,
        });
    }, [dispatch, driverId, closeDismissDriverDialog, intl, showNotification]);
    const handleStartDismissDriver = useCallback(() => {
        openDismissDriverDialog({ onDismiss: handleDismissDriver });
    }, [handleDismissDriver, openDismissDriverDialog]);
    const { region } = useContext(IntlContext);
    if (!driver) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(View, { onChangeContract: handleChangeContractStatus, region: region, docs: docs, onUploadDoc: handleUploadDoc, driver: driver, onClickBlock: handleStartBlocking, onClickBack: handleClickBack, onClickDismiss: handleStartDismissDriver, isPending: isPending, workloads: hourlyWorkloads, workloadSummary: workloadSummary, workingHoursWeek: workingHoursWeek, onWorkingHoursWeekChange: setWorkingHoursWeek }), isUploading && (_jsx(UploadDriver, { onClickSkip: handleSkipUpload, onFinishUpload: handleFinishUpload }))] }));
};
const withRoute = Object.assign(DriverDetails, { ROUTE });
export { withRoute as DriverDetails };
