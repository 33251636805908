import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import isValid from 'date-fns/isValid';
import { usePermissions } from '../../app/hooks/usePermissions';
import { loadJourneys } from '../../modules/journey/actions/async/load-journeys';
import { requestJourneyReport } from '../../modules/journey/actions/async/request-journey-report';
import { getJourneys } from '../../modules/journey/store/selectors';
import { getAllDrivers } from '../../modules/driver/store/selectors';
import { loadAllDrivers } from '../../modules/driver/actions/async/load-all-drivers';
import { getPartner } from '../../modules/partner/store/selectors';
import { useQueryParams } from '../../shared/hooks/useQueryParams';
import { formatDate } from '../../shared/utils/formatDate';
import { useReportSentDialog } from './components/ReportSentDialog/useReportSentDialog';
import { View } from './view';
const List = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const queryParams = useQueryParams();
    usePermissions('viewJourneys');
    const journeys = useSelector(getJourneys);
    const chauffeurs = useSelector(getAllDrivers);
    const chauffeurId = queryParams.get('for') ?? undefined;
    const fromParam = queryParams.get('from') ?? undefined;
    const toParam = queryParams.get('to') ?? undefined;
    const fromDate = fromParam && isValid(new Date(fromParam)) ? new Date(fromParam) : undefined;
    const toDate = toParam && isValid(new Date(toParam)) ? new Date(toParam) : undefined;
    const partner = useSelector(getPartner);
    useEffect(() => {
        dispatch(loadAllDrivers());
    }, [dispatch]);
    const fetchJourneys = useMemo(() => debounce(async ({ driverId, from, to, number, }, { limit, offset }) => {
        setIsLoading(true);
        try {
            await dispatch(loadJourneys({
                limit,
                offset,
                driverId,
                from: from ? formatDate(from) : undefined,
                to: to ? formatDate(to) : undefined,
                number,
            })).unwrap();
        }
        finally {
            setIsLoading(false);
        }
    }, 200, {
        leading: true,
        trailing: false,
    }), [dispatch, setIsLoading]);
    const { openModal } = useReportSentDialog();
    const requestJourneysReport = useCallback(async ({ driverId, from, to }) => {
        await dispatch(requestJourneyReport({
            driverId,
            from: from ? formatDate(from) : undefined,
            to: to ? formatDate(to) : undefined,
        })).unwrap();
        openModal({ email: partner.email });
    }, [dispatch, partner.email, openModal]);
    return (_jsx(View, { forChauffeur: chauffeurId, fromDate: fromDate, toDate: toDate, journeys: journeys, chauffeurs: chauffeurs, fetchJourneys: fetchJourneys, requestJourneysReport: requestJourneysReport, isLoading: isLoading }));
};
export { List };
