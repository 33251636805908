import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../shared/view/Form';
import { loadDriverById } from '../../modules/driver/actions/async/load-driver-by-id';
import { getCurrentDriver } from '../../modules/driver/store/selectors';
import { updateDriverInfo } from '../../modules/driver/actions/async/updateDriver';
import { getAllCars } from '../../modules/car/store/selectors';
import { EditCurrentVehicleView } from './view';
const ROUTE = '/chauffeurs/:driverId/edit-vehicle';
const EditCurrentVehicle = ({ onNavigateBack }) => {
    const driverId = useRouteMatch(ROUTE)?.params?.driverId;
    const dispatch = useDispatch();
    const driver = useSelector(getCurrentDriver);
    const vehicles = useSelector(getAllCars);
    const navigateBack = useCallback(() => onNavigateBack(`/chauffeurs/${driverId}`), [driverId, onNavigateBack]);
    const postForm = useCallback(async (formValues) => {
        const { isSucceed, result = {} } = await dispatch(updateDriverInfo(formValues)).unwrap();
        if (isSucceed && !result.validationErrors && !result.baseErrors) {
            navigateBack();
        }
        return result;
    }, [dispatch, navigateBack]);
    const { handleSubmit, form: { control, formState: { isSubmitting }, resetField, }, } = useForm({
        onSubmit: postForm,
        defaultValues: {
            carId: driver?.carId,
        },
    });
    useEffect(() => {
        if (!driverId) {
            return;
        }
        dispatch(loadDriverById(driverId));
    }, [dispatch, driverId, resetField]);
    useEffect(() => {
        resetField('carId', { defaultValue: driver?.carId || '' });
    }, [resetField, driver?.carId]);
    return (_jsx(EditCurrentVehicleView, { control: control, isSubmitting: isSubmitting, onSubmit: handleSubmit, onNavigateBack: navigateBack, vehicles: vehicles }));
};
export { EditCurrentVehicle, ROUTE };
