import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionList } from '../../shared/view/DescriptionList';
import { renderStatus } from './render-status';
import s from './styles.css';
const AccessStatusesSortedByPriority = [
    'end_of_life',
    'partner_blocked',
    'failed_tests',
    'blocked',
    'rejected',
    'expired_docs',
    'pending',
    'new_partner',
    'active',
];
const getStatusPriority = (toCheck) => AccessStatusesSortedByPriority.indexOf(toCheck);
const getTopPriorityStatus = (...statuses) => statuses.sort((a, b) => getStatusPriority(a) - getStatusPriority(b))[0];
const StatusInfo = ({ partner, driver }) => {
    const intl = useIntl();
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "ysGO2T", defaultMessage: "Status" }), className: s.values, values: useMemo(() => ({
            accessStatus: renderStatus(intl, getTopPriorityStatus(partner.accessStatus, driver.accessStatus)),
        }), [intl, partner.accessStatus, driver.accessStatus]), titles: useMemo(() => [
            [
                'accessStatus',
                intl.formatMessage({ id: "ncXy/y", defaultMessage: "Access" }),
            ],
        ], [intl]) }));
};
export { StatusInfo, renderStatus };
