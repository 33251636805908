import { FsmState } from '../../modules/onboarding/fsm-types';
const totalSteps = 5;
const mapFsmStateToCtProps = ({ stateValue, intl, partnerFirstName, }) => {
    switch (stateValue) {
        case FsmState.driverUpload:
        case FsmState.driver:
            return {
                step: 1,
                totalSteps,
                title: intl.formatMessage({ id: "m/FybZ", defaultMessage: "Hello, {name}" }, { name: partnerFirstName }),
                subTitle: intl.formatMessage({ id: "VB7Mvv", defaultMessage: "Add your personal information." }),
            };
        case FsmState.doYouHaveCar:
            return {
                step: 4,
                totalSteps,
                title: intl.formatMessage({ id: "hafaT4", defaultMessage: "Does your vehicle qualify?" }),
                textNoButton: intl.formatMessage({ id: "q4g4zW", defaultMessage: "SKIP" }),
            };
        case FsmState.partnerPayments:
            return {
                step: 3,
                totalSteps,
                title: intl.formatMessage({ id: "NQlgHT", defaultMessage: "Payment details" }),
            };
        case FsmState.uploadNewCarDocs:
        case FsmState.newCar:
            return {
                step: 5,
                totalSteps,
                title: intl.formatMessage({ id: "c0G8wG", defaultMessage: "Vehicle info" }),
            };
        case FsmState.payTo:
            return {
                step: 2,
                totalSteps,
                title: intl.formatMessage({ id: "n3sp5y", defaultMessage: "Payouts" }),
            };
        case FsmState.toDo:
            return {
                hideHeader: true,
                hideProgress: true,
            };
        case FsmState.welcome:
            return {
                hideHeader: true,
                hideProgress: true,
            };
        default:
            return {};
    }
};
const mapFsmStateToCtPropsUK = ({ stateValue, intl, }) => {
    switch (stateValue) {
        case FsmState.licensingAuthority:
            return {
                step: 1,
                totalSteps,
                title: intl.formatMessage({ id: "5mMUid", defaultMessage: "What authority issued your Private hire driver license?" }),
            };
        case FsmState.vehicle:
            return {
                step: 2,
                totalSteps,
                title: intl.formatMessage({ id: "8ptTVr", defaultMessage: "What vehicle do you have?" }),
            };
        case FsmState.driverUpload:
        case FsmState.driver:
            return {
                step: 3,
                totalSteps,
                title: intl.formatMessage({ id: "v3duLT", defaultMessage: "Personal Information" }),
            };
        case FsmState.partnerPayments:
            return {
                step: 5,
                totalSteps,
                title: intl.formatMessage({ id: "NQlgHT", defaultMessage: "Payment details" }),
            };
        case FsmState.payTo:
            return {
                step: 4,
                totalSteps,
                title: intl.formatMessage({ id: "n3sp5y", defaultMessage: "Payouts" }),
            };
        case FsmState.toDo:
            return {
                hideHeader: true,
                hideProgress: true,
            };
        case FsmState.welcome:
            return {
                hideHeader: true,
                hideProgress: true,
            };
        case FsmState.licensingAuthorityMissing:
            return {
                hideHeader: false,
                hideProgress: true,
                title: intl.formatMessage({ id: "nPyDZe", defaultMessage: "To proceed you need to have driver's license" }),
            };
        default:
            return {};
    }
};
const mapFsmStateToCtPropsFR = ({ stateValue, intl, }) => {
    const totalStepsFr = 4;
    switch (stateValue) {
        case FsmState.vehicle:
            return {
                step: 1,
                totalSteps: totalStepsFr,
                title: intl.formatMessage({ id: "8ptTVr", defaultMessage: "What vehicle do you have?" }),
            };
        case FsmState.driverUpload:
        case FsmState.driver:
            return {
                step: 2,
                totalSteps: totalStepsFr,
                title: intl.formatMessage({ id: "v3duLT", defaultMessage: "Personal Information" }),
            };
        case FsmState.payTo:
            return {
                step: 3,
                totalSteps: totalStepsFr,
                title: intl.formatMessage({ id: "n3sp5y", defaultMessage: "Payouts" }),
            };
        case FsmState.partnerPayments:
            return {
                step: 4,
                totalSteps: totalStepsFr,
                title: intl.formatMessage({ id: "NQlgHT", defaultMessage: "Payment details" }),
            };
        case FsmState.toDo:
            return {
                hideHeader: true,
                hideProgress: true,
            };
        case FsmState.welcome:
            return {
                hideHeader: true,
                hideProgress: true,
            };
        default:
            return {};
    }
};
export { mapFsmStateToCtProps, mapFsmStateToCtPropsUK, mapFsmStateToCtPropsFR };
