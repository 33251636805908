import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Logo } from './logo';
import s from './styles.css';
const useOpenState = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);
    return { isOpen, handleToggle, handleClose };
};
const NavMenuItem = ({ title, subItems, internalLink, externalLink, onClick, badge, Component, keepVisibleOnLargeScreen, onClose, isDisabled = false, isUppercased = false, }) => {
    const handleClick = useCallback(() => {
        if (isDisabled) {
            return;
        }
        onClick?.();
        onClose?.();
    }, [isDisabled, onClick, onClose]);
    return (_jsxs("li", { className: cn(s.item, {
            [s.hasSubItems]: !!subItems,
            [s.hiddenOnLargeScreen]: keepVisibleOnLargeScreen,
        }), "data-test-id": "main-menu-item", children: [(() => {
                if (internalLink) {
                    return (_jsx(Link, { to: internalLink, className: cn(s.itemInner, {
                            [s.itemUppercased]: isUppercased,
                            [s.isDisabled]: isDisabled,
                        }), onClick: handleClick, "data-test-id": "main-menu-item__internal-link", children: title }));
                }
                if (externalLink) {
                    return (_jsx("a", { href: isDisabled ? undefined : externalLink, target: "_blank", rel: "noreferrer", className: cn(s.itemInner, {
                            [s.itemUppercased]: isUppercased,
                            [s.isDisabled]: isDisabled,
                        }), onClick: handleClick, "data-test-id": "main-menu-item__external-link", children: title }));
                }
                if (subItems) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    return _jsx(SubNavMenu, { title: title, subItems: subItems, onClose: onClose });
                }
                if (Component) {
                    return _jsx(Component, { className: s.itemInner });
                }
                return (_jsx("button", { className: cn(s.itemInner, {
                        [s.itemUppercased]: isUppercased,
                        [s.isDisabled]: isDisabled,
                    }), onClick: handleClick, "data-test-id": "main-menu-item__button", children: title }));
            })(), badge] }));
};
const SubNavMenu = ({ title, subItems, onClose: onParentClose }) => {
    const { isOpen, handleToggle, handleClose } = useOpenState();
    return (_jsxs("section", { className: s.subMenu, onMouseEnter: handleToggle, onMouseLeave: handleClose, "data-test-id": "main-menu-item__sub-menu", children: [_jsx("button", { className: cn(s.subHeader, { [s.isSubOpen]: isOpen }), "data-test-id": "main-menu-item__sub-menu__title", children: title }), _jsx("ul", { className: s.subMenuItems, onClick: handleClose, "data-test-id": "main-menu-item__sub-menu__content", children: subItems.map((subItem, idx) => (_jsx(NavMenuItem, { ...subItem, onClose: onParentClose }, idx))) })] }));
};
const NavMenu = ({ appName, className, menuItems }) => {
    const { isOpen, handleToggle, handleClose } = useOpenState();
    const visibleItemsOnLargeScreen = menuItems.filter(menuItem => menuItem.keepVisibleOnLargeScreen);
    return (_jsxs("header", { className: cn(s.root, className), "data-test-id": "header", children: [_jsxs("div", { className: cn(s.right, { [s.isOpen]: isOpen }), children: [visibleItemsOnLargeScreen.length > 0 && (_jsx("ul", { className: s.menuItemsOnLargeScreen, "data-test-id": "header__fixed-menu", children: visibleItemsOnLargeScreen.map((item, idx) => (_jsx(NavMenuItem, { ...item, onClose: handleClose, isUppercased: true }, idx))) })), _jsx("button", { "data-test-id": "header__toggler-mobile", className: cn(s.toggleButton, s.mobile), onClick: handleToggle, children: _jsx("div", { className: s.toggleIcon }) }), _jsx("button", { "data-test-id": "header__toggler-desktop", className: cn(s.toggleButton, s.desktop), onMouseEnter: isOpen ? undefined : handleToggle, onClick: handleToggle, children: _jsx("div", { className: s.toggleIcon }) })] }), _jsxs("div", { className: s.logo, "data-test-id": "header__logo", children: [appName && _jsx("span", { className: s.appName, children: appName }), _jsx(Logo, {})] }), _jsx("div", { className: s.navWrapper, children: _jsx("nav", { className: s.menu, onMouseLeave: handleClose, children: _jsx("ul", { className: s.menuItems, "data-test-id": "header__popup-menu", children: menuItems.map((item, idx) => (_jsx(NavMenuItem, { ...item, onClose: handleClose }, idx))) }) }) })] }));
};
export { NavMenu };
