import { get, post, put, patch, postForm } from '../../../shared/api/fetch-based-http-methods';
import { w4dUserRemoteAPI } from '../../../../src-common/api/definitions/w4d-user-endpoints';
import { createClient } from '../../../../src-common/api/src/create-client';
import { ENV } from '../../../env';
const client = createClient(w4dUserRemoteAPI({ apiHost: ENV.API_URL, get, post, put, patch, postForm }));
const prepareUserData = (raw) => ({
    userId: raw.id,
    firstName: raw.first_name,
    lastName: raw.last_name,
    middleName: raw.middle_name,
    cityId: raw.city_id,
    cityName: raw.city_name,
    partnerId: raw.partner_id,
    email: raw.email,
    source: raw.source || null,
    driverId: raw.driver_id ?? undefined,
    phone: raw.phone ?? 'hidden',
    roles: raw.roles ?? [],
});
const userAPI = {
    readUserData: async () => {
        const response = await client.readUser();
        return prepareUserData(response.user);
    },
};
export { userAPI };
