import * as t from 'io-ts';
const Earning = t.type({
    acceptance: t.union([t.null, t.number]),
    id: t.string,
    name: t.string,
    photo: t.union([t.null, t.string]),
    rating: t.number,
    total: t.type({
        count: t.number,
        driver_rate: t.number,
        gross: t.number,
        insurance: t.union([t.null, t.unknown]),
        others: t.number,
        parking: t.number,
        payout: t.number,
        price: t.number,
        tips: t.number,
        wheely_commission: t.number,
        errand: t.number,
        errand_commission: t.number,
    }),
});
export { Earning };
