import { createSelector } from '@reduxjs/toolkit';
const createSelectors = (getSliceState, getUploadedDocs) => {
    const getUploadDefinitions = (state) => getSliceState(state).docDefinitions;
    const getUploadDefinition = createSelector((state) => getSliceState(state).uploadingForm.docType, getUploadDefinitions, (docType, docDefinitions) => docDefinitions.find(docDef => docDef.docType === docType));
    const getUploadForm = (state) => getSliceState(state).uploadingForm;
    const isUploading = (state) => getSliceState(state).isPending;
    const getDocDefinitions = (state) => getSliceState(state).docDefinitions;
    const getCurrentDocType = (state) => getSliceState(state).uploadingForm.docType;
    const getValuesForUploadForm = createSelector(getCurrentDocType, getUploadedDocs, (docType, uploads) => {
        const upload = uploads?.[docType];
        if (upload === undefined) {
            return undefined;
        }
        return {
            docType,
            file: {
                type: upload.docFormat === 'pdf' ? 'pdf' : 'image',
                url: upload.previewUrl || upload.downloadUrl,
            },
            rejectReason: upload.rejectReason,
            status: upload.status,
            expiresAt: upload.expiresAt,
        };
    });
    const getDocsList = createSelector(getDocDefinitions, getUploadedDocs, (docDefinitions, uploads) => docDefinitions.map(docDefinition => ({
        ...docDefinition,
        status: (uploads[docDefinition.docType]?.status || 'missing'),
        rejectedReason: uploads[docDefinition.docType]?.rejectReason,
        expiresAt: uploads[docDefinition.docType]?.expiresAt ?? null,
        expiresSoon: uploads[docDefinition.docType]?.expiresSoon ?? false,
    })));
    return {
        getUploadDefinitions,
        getUploadDefinition,
        getUploadForm,
        isUploading,
        getDocDefinitions,
        getCurrentDocType,
        getValuesForUploadForm,
        getDocsList,
    };
};
export { createSelectors };
