import { errorHandling } from '../../../src/services/errorHandling';
import { validate } from './validate';
import { applyPathParams } from './apply-path-params';
const createMethod = (cfg) => async (args) => {
    const rawPath = String(cfg.path);
    const pathParams = 'pathParams' in cfg
        ? validate(cfg.pathParams, args.pathParams, `${rawPath} pathParams`)
        : undefined;
    const payload = 'payload' in cfg
        ? validate(cfg.payload, args.payload, `${rawPath} payload`)
        : undefined;
    const headers = 'headers' in cfg
        ? validate(cfg.headers, args.headers, `${rawPath} headers`)
        : undefined;
    const path = applyPathParams(cfg.path, pathParams ?? {});
    const response = await cfg.method(path, payload, headers);
    try {
        const result = validate(cfg.response, response, `${rawPath} response`);
        return result;
    }
    catch (error) {
        errorHandling.captureException(error);
        return response;
    }
};
const createClient = (config, override) => {
    let workingConfig = config;
    if (override) {
        workingConfig = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.entries(config).map(([key, value]) => [key, { ...value }]));
    }
    override?.(workingConfig);
    const result = {};
    Object.keys(workingConfig).forEach(key => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[key] = createMethod(workingConfig[key]);
    });
    return result;
};
export { createClient, createMethod };
