// because of redux toolkit copy Error as plain object
const SerializableError = (name) => class E extends Error {
    constructor() {
        super(...arguments);
        this.name = name;
    }
    static is(x) {
        return x?.name === name;
    }
};
export { SerializableError };
