import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@wheely/ui-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DocsList } from '../../../src-common/view/components/DocsList';
import { DescriptionList } from '../../shared/view/DescriptionList';
import { IntlContext } from '../../i18n';
import { CountryCode } from '../../shared/models/country-code';
import { ToDoView } from '../Todo/view';
import { getCarForm, getCurrentCar, getCurrentCarDriver } from '../../modules/car/store/selectors';
import { carUploadSelectors } from '../../modules/car/store/upload-slice';
import { deleteCar } from '../../modules/car/actions/async/deleteCar';
import { StepLayout } from '../../shared/view/StepLayout';
import { UploadCar } from '../../shared/view/Upload';
import { prepareUploadCarForm } from '../../modules/car/actions/async/prepare-upload-car-form';
import { prepareCarForm } from '../../modules/car/actions/async/prepare-car-form';
import { reloadFormForCurrentCar } from '../../modules/car/actions/async/reload-form-for-current-car';
import { getHasRequiredCarDocs } from '../../modules/car/store/commonSelectors';
import { createBookCarAppointmentCard } from '../Todo/hooks/createBookCarAppointmentCard';
import { createGBBookCarAppointmentCard } from '../Todo/hooks/createGBBookCarAppointmentCard';
import { useExternalLinks } from '../../app/hooks/useExternalLinks';
import { getPartner } from '../../modules/partner/store/selectors';
import { getMyDriver } from '../../modules/driver/store/selectors';
import { AccessStatusEnum } from '../../shared/models/access-status';
import { WHEELBASES_MESSAGES, isWheelbase, ENGINE_MESSAGES, } from '../../modules/car/api/hardcoded-make-models';
import { AccessStatus } from '../../shared/view/AccessStatus';
import { selectIsIndividualChauffeur } from '../../modules/user/store/selectors';
import { useFormOptions } from '../../modules/car/hooks/useFormOptions';
import { usePermissions } from '../../app/hooks/usePermissions';
import { CurrentChauffeur } from './components/CurrentChauffeur';
import s from './styles.css';
const mapToCarInfo = ({ wheelbase, color, interiorColor, year, registrationDate, licensePlate, licensingAuthority, PHV, generation, vin, engineType, }, intl) => ({
    wheelbase: isWheelbase(wheelbase)
        ? intl.formatMessage(WHEELBASES_MESSAGES[wheelbase])
        : wheelbase,
    engineType: engineType && ENGINE_MESSAGES[engineType]
        ? intl.formatMessage(ENGINE_MESSAGES[engineType])
        : engineType,
    color,
    interiorColor,
    year,
    registrationDate,
    licensePlate,
    licensingAuthority,
    PHV,
    generation,
    vin,
});
const skipEmpty = (item) => item !== false;
const CarDetails = ({ carId, onNavigateBack }) => {
    const dispatch = useDispatch();
    usePermissions('viewCars');
    useEffect(() => {
        dispatch(prepareCarForm({ carId }));
    }, [carId, dispatch]);
    const { region } = useContext(IntlContext);
    const intl = useIntl();
    const [isDeleting, setIsDeleting] = useState(false);
    const carHasRequiredDocs = useSelector(getHasRequiredCarDocs);
    const titles = useMemo(() => {
        const allTitles = [
            [
                'generation',
                intl.formatMessage({ id: "2jSkNX", defaultMessage: "Generation" }),
            ],
            [
                'color',
                intl.formatMessage({ id: "VLD5bd", defaultMessage: "Vehicle color" }),
            ],
            [
                'interiorColor',
                intl.formatMessage({ id: "GvoEXn", defaultMessage: "Interior Color" }),
            ],
            [
                'year',
                intl.formatMessage({ id: "4imqJB", defaultMessage: "Year" }),
            ],
            [
                'registrationDate',
                intl.formatMessage({ id: "hAkYqw", defaultMessage: "Date of first registration" }),
            ],
            [
                'licensePlate',
                intl.formatMessage({ id: "7qlE2U", defaultMessage: "Licence Plate" }),
            ],
            [
                'vin',
                intl.formatMessage({ id: "H/UHUa", defaultMessage: "VIN" }),
            ],
            [
                'engineType',
                intl.formatMessage({ id: "global_vehicle_info_engine", defaultMessage: "Engine" }),
            ],
            [
                'wheelbase',
                intl.formatMessage({ id: "F2299T", defaultMessage: "Wheelbase" }),
            ],
            region === CountryCode.GreatBritain && [
                'licensingAuthority',
                intl.formatMessage({ id: "fdwPip", defaultMessage: "Licensing authority" }),
            ],
            region === CountryCode.GreatBritain && [
                'PHV',
                intl.formatMessage({ id: "6Xhtjk", defaultMessage: "PHV" }),
            ],
        ];
        return allTitles.filter(skipEmpty);
    }, [intl, region]);
    const handleDeleteCar = useCallback(async () => {
        setIsDeleting(true);
        try {
            await dispatch(deleteCar({ carId, onSuccess: () => onNavigateBack('/todo') })).unwrap();
        }
        finally {
            setIsDeleting(false);
        }
    }, [carId, dispatch, onNavigateBack]);
    const [isUploading, setIsUploading] = useState(false);
    const formValues = useSelector(getCarForm);
    const { colors, interiorColors, wheelbases, engines } = useFormOptions(formValues.make, formValues.model);
    const values = mapToCarInfo(formValues, intl);
    const docs = useSelector(carUploadSelectors.getDocsList);
    const isIndividualChauffeur = useSelector(selectIsIndividualChauffeur);
    const partner = useSelector(getPartner);
    const myDriver = useSelector(getMyDriver);
    const car = useSelector(getCurrentCar);
    const carDriver = useSelector(getCurrentCarDriver);
    const handleClickDocListItem = useCallback((_, doc) => {
        dispatch(prepareUploadCarForm({
            docType: doc.docType,
            onSuccess: () => setIsUploading(true),
        }));
    }, [dispatch, setIsUploading]);
    const handleCancelUploading = useCallback(() => {
        setIsUploading(false);
    }, []);
    const handleFinishUploading = useCallback(async () => {
        dispatch(reloadFormForCurrentCar({}));
        setIsUploading(false);
    }, [dispatch]);
    const handleClickBack = useCallback(async () => {
        onNavigateBack('/todo');
    }, [onNavigateBack]);
    const links = useExternalLinks();
    return (_jsxs(_Fragment, { children: [_jsxs(StepLayout, { onClickBack: handleClickBack, title: _jsxs(_Fragment, { children: [_jsx("div", { children: `${formValues.licensePlate} ${formValues.make} ${formValues.model}` }), _jsx(AccessStatus, { status: formValues.accessStatus })] }), hideProgress: true, children: [_jsxs("div", { children: [_jsx(DescriptionList, { title: intl.formatMessage({ id: "G9nZ5f", defaultMessage: "Information" }), itemsForSelect: {
                                    color: colors,
                                    interiorColor: interiorColors,
                                    wheelbase: wheelbases,
                                    engineType: engines,
                                }, className: s.values, values: values, titles: titles }), carDriver && _jsx(CurrentChauffeur, { chauffeur: carDriver }), isIndividualChauffeur &&
                                partner.accessStatus === 'active' &&
                                partner.countryCode === CountryCode.GreatBritain &&
                                myDriver?.accessStatus !== 'pending' &&
                                car &&
                                car.accessStatus !== AccessStatusEnum.active && (_jsx("div", { className: s.todo, children: _jsx(ToDoView, { cards: [createGBBookCarAppointmentCard(intl)] }) })), partner.countryCode !== CountryCode.GreatBritain && carHasRequiredDocs && (_jsx("div", { className: s.todo, children: _jsx(ToDoView, { cards: [createBookCarAppointmentCard(intl, links)] }) })), _jsx(DocsList, { title: intl.formatMessage({ id: "wVBktP", defaultMessage: "DOCUMENTS" }), className: s.docs, onChange: handleClickDocListItem, docs: docs })] }), _jsx("div", { className: s.actions, children: _jsx(Button, { minimal: true, intent: "danger", className: s.deleteButton, onClick: handleDeleteCar, "data-test-id": "delete-vehicle", disabled: isDeleting, children: _jsx(FormattedMessage, { id: 'UJmA/e', defaultMessage: 'Delete vehicle' }) }) })] }), isUploading && (_jsx(UploadCar, { onFinishUpload: handleFinishUploading, onClickSkip: handleCancelUploading }))] }));
};
export { CarDetails };
