import { useState, useCallback } from 'react';
import { userLanguage } from '../services/userLanguage';
const useUserLanguage = () => {
    const [language, setLanguageState] = useState(userLanguage.getLanguage());
    const setLanguage = useCallback((nextLanguage) => {
        setLanguageState(userLanguage.setLanguage(nextLanguage));
    }, [setLanguageState]);
    return {
        language,
        setLanguage,
    };
};
export { useUserLanguage };
