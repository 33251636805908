import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@wheely/ui-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '../../../../../src-common/view/adapters/reactHookForm/TextField';
import { SelectField } from '../../../../../src-common/view/adapters/reactHookForm/SelectField';
import { BottomButtons } from '../../../../shared/view/BottomButtons';
import { useLicensingAuthorityTypes } from '../../../../shared/hooks/useLicensingAuthorityTypes';
import { useExternalLinks } from '../../../../app/hooks/useExternalLinks';
import { DocsList } from '../../../../../src-common/view/components/DocsList';
import { StepLayout } from '../../../../shared/view/StepLayout';
import s from './styles.css';
const EditOwnDriverInfoView = ({ onSubmit, control, isSubmitting, docs, onUploadDoc, onGoBack, }) => {
    const intl = useIntl();
    const licensingAuthorityTypes = useLicensingAuthorityTypes();
    const links = useExternalLinks();
    return (_jsx(StepLayout, { hideProgress: true, title: intl.formatMessage({ id: "AIUtGz", defaultMessage: "Chauffeur info" }), onClickBack: onGoBack, children: _jsxs("form", { onSubmit: onSubmit, children: [_jsx(SelectField, { name: "licensingAuthority", title: intl.formatMessage({ id: "/Q8aMx", defaultMessage: "Licensing authority" }), description: intl.formatMessage({ id: "1s5SCC", defaultMessage: "Learn more about license types <a>here</a>." }, {
                        a: (text) => (_jsx("a", { href: links.licensingAuthorities, target: "_blank", rel: "noreferrer", children: text })),
                    }), control: control, items: licensingAuthorityTypes }), _jsx(TextField, { name: "licenseNumber", title: intl.formatMessage({ id: "pFGlVW", defaultMessage: "Private Hire Driver License Number" }), placeholder: "123456", description: intl.formatMessage({ id: "NB/idy", defaultMessage: "Full PHD license number from your licensing authority." }), control: control, isRequired: true }), _jsx(DocsList, { title: intl.formatMessage({ id: "wVBktP", defaultMessage: "DOCUMENTS" }), className: s.docs, onChange: onUploadDoc, docs: docs }), _jsx(BottomButtons, { children: _jsx(Button, { type: "submit", disabled: isSubmitting, children: _jsx(FormattedMessage, { id: 'RT8KNi', defaultMessage: 'Save' }) }) })] }) }));
};
export { EditOwnDriverInfoView };
