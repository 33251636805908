import { createClient } from '../../../../src-common/api/src/create-client';
import { w4dOnboardingRemoteAPI } from '../../../../src-common/api/definitions/w4d-onboarding-endpoints';
import { put } from '../../../shared/api/fetch-based-http-methods';
import { prepareDateForRequest } from '../../../shared/api/prepare-date';
import { ENV } from '../../../env';
const client = createClient(w4dOnboardingRemoteAPI({ apiHost: ENV.API_URL, put }));
const prepareWelcomeErrors = (errors) => {
    if (!errors) {
        return undefined;
    }
    return {
        cityId: errors.city_id,
        firstName: errors.first_name || errors.user_exists,
        lastName: errors.last_name,
        middleName: errors.middle_name,
        email: errors.email,
        referralCode: errors.chauffeur_promo_code,
    };
};
const prepareHelloErrors = (errors) => {
    if (!errors) {
        return undefined;
    }
    return {
        birthday: errors.birthday,
        homeAddress: errors.home_address,
        licenseNumber: errors.license_number,
        ukNationalInsurance: errors.uk_national_insurance,
        personalInn: errors.personal_inn,
    };
};
const preparePayoutsErrors = (errors) => {
    if (!errors) {
        return undefined;
    }
    return {
        partnerType: errors.partner_type,
        companyName: errors.company_name,
        companyAddress: errors.company_address,
        companyRegNumber: errors.company_reg_number,
        vatRegNo: errors.vat_reg_no,
        inn: errors.inn,
        kpp: errors.kpp,
    };
};
const preparePaymentInfoErrors = (errors) => {
    if (!errors) {
        return undefined;
    }
    return {
        accountNumber: errors.account_number,
        sortCode: errors.sort_code,
        bik: errors.bik,
        swift: errors.swift,
        iban: errors.iban,
        rasAccount: errors.ras_account,
    };
};
const preparePartnerOnboarding = (onboarding) => ({
    isCompleted: onboarding?.completed || false,
    lastStepName: onboarding?.last_step_name || 'welcome',
    isLastStepCompleted: onboarding?.last_step_completed || false,
});
const onboardingAPI = {
    submitWelcomeStep: async (formValues) => {
        const response = await client.updateWelcomeStep({
            payload: {
                city_id: formValues.cityId,
                first_name: formValues.firstName,
                last_name: formValues.lastName,
                middle_name: formValues.middleName,
                email: formValues.email,
                chauffeur_promo_code: formValues.referralCode,
                source: formValues.source,
            },
        });
        return {
            validationErrors: prepareWelcomeErrors(response.errors),
            driverId: response.driver?.id ?? '',
            onboarding: preparePartnerOnboarding(response.partner?.onboarding),
        };
    },
    submitHelloStep: async (formValues) => {
        const response = await client.updateHelloStep({
            payload: {
                birthday: prepareDateForRequest(formValues.birthday),
                home_address: formValues.homeAddress,
                license_number: formValues.licenseNumber,
                uk_national_insurance: formValues.ukNationalInsurance,
                personal_inn: formValues.personalInn,
                license_authority: formValues.licensingAuthority,
            },
        });
        return {
            validationErrors: prepareHelloErrors(response.errors),
            onboarding: preparePartnerOnboarding(response.partner?.onboarding),
        };
    },
    submitPayoutsStep: async (formValues) => {
        const response = await client.updatePayoutsStep({
            payload: {
                partner_type: formValues.partnerType,
                company_name: formValues.companyName,
                company_address: formValues.companyAddress,
                company_reg_number: formValues.companyRegNumber,
                vat_reg_no: formValues.vatRegNo,
                inn: formValues.inn,
                kpp: formValues.kpp,
            },
        });
        return {
            validationErrors: preparePayoutsErrors(response.errors),
            onboarding: preparePartnerOnboarding(response.partner?.onboarding),
        };
    },
    submitPaymentInfoStep: async (formValues) => {
        const response = await client.updatePaymentInfoStep({
            payload: {
                account_number: formValues.accountNumber,
                sort_code: formValues.sortCode,
                bik: formValues.bik,
                swift: formValues.swift,
                iban: formValues.iban,
                ras_account: formValues.rasAccount,
                kor_account: formValues.corAccount,
                bank_name: formValues.bankName,
            },
        });
        return {
            validationErrors: preparePaymentInfoErrors(response.errors),
            onboarding: preparePartnerOnboarding(response.partner?.onboarding),
        };
    },
    completeOnboarding: async () => {
        const response = await client.completeOnboarding();
        if (!response.partner.onboarding) {
            throw new Error('Partner onboarding field is missing');
        }
        return preparePartnerOnboarding(response.partner.onboarding);
    },
    updatePartnerType: async (type) => client.updatePartnerType({ payload: { partner_type: type } }),
};
export { onboardingAPI };
