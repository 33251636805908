import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PhoneField } from '@wheely/ui-kit';
import { Button } from '../../../../../src-common/view/adapters/Button';
import { PHONE_CODES } from '../../../../shared/const/phone-codes';
import { Captcha } from '../Captcha/Captcha';
import s from './style.css';
import { getInitialPhoneCodeByLocale } from './get-initial-phone-code-by-locale';
const PhoneForm = ({ initialPhone, onSubmit }) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState(initialPhone?.number || '');
    const [countryCode, setCountryCode] = useState(initialPhone?.code || getInitialPhoneCodeByLocale(intl.locale));
    const [errors, setErrors] = useState([]);
    const captchaRef = useRef(null);
    const handleSubmit = useCallback(async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            // eslint-disable-next-line no-console
            console.debug('Get Captcha');
            const captcha = (await captchaRef.current?.getCaptcha()) || '';
            // eslint-disable-next-line no-console
            console.debug('Received Captcha', captcha);
            if (!captcha) {
                // eslint-disable-next-line no-console
                console.debug('No Captcha. Show notification with error');
                setIsLoading(false);
                return;
            }
            // eslint-disable-next-line no-console
            console.debug('Sending Captcha');
            const result = await onSubmit({ number: phone, code: countryCode }, captcha);
            captchaRef.current?.reset();
            if (result.error) {
                setErrors([result.error.statusText]);
                setIsLoading(false);
            }
        }
        catch {
            setIsLoading(false);
        }
    }, [phone, countryCode, onSubmit]);
    const handleChangeCode = useCallback((code) => {
        setCountryCode(code.value);
    }, []);
    return (_jsx("div", { className: s.phoneForm, children: _jsxs("form", { onSubmit: handleSubmit, "data-test-id": "phone-form", children: [_jsx(PhoneField, { autoFocus: true, value: phone, description: _jsx(FormattedMessage, { id: '94vvh6', defaultMessage: 'We will send you a code to this phone number.' }), countryCode: countryCode, onChange: setPhone, items: PHONE_CODES, placeholder: intl.formatMessage({ id: "pTNKg/", defaultMessage: "Phone number" }), disabled: isLoading, onCodeChange: handleChangeCode, error: errors.join('\n') }), _jsx(Captcha, { ref: captchaRef }), _jsx("div", { className: s.submitPhone, children: _jsx(Button, { isDisabled: isLoading, type: "submit", children: _jsx(FormattedMessage, { id: '3av8Qa', defaultMessage: 'Get Code' }) }) })] }) }));
};
export { PhoneForm };
