import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { partnerAPI } from '../../api';
import { setPartner } from '../actions';
import { getUserData } from '../../../user/store/selectors';
const loadPartner = createAsyncThunk(`${namespace}/load-partner`, async (_, { dispatch, getState }) => {
    const me = getUserData(getState());
    const partner = await partnerAPI.readPartner(me);
    dispatch(setPartner(partner));
    return partner;
});
export { loadPartner };
