import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { driverAPI } from '../../api';
import { updateDriver } from '../actions';
let lastRequestId;
const dismissDriver = createAsyncThunk(`${namespace}/dismiss-driver`, async ({ driverId }, { dispatch, requestId }) => {
    if (lastRequestId !== undefined) {
        return { isAlreadyExecuted: true };
    }
    lastRequestId = requestId;
    let transferRequest;
    try {
        transferRequest = await driverAPI.sendTransferRequest(driverId);
        dispatch(updateDriver({ id: driverId, update: { transferRequest } }));
    }
    finally {
        lastRequestId = undefined;
    }
    return transferRequest ? { transferRequest } : {};
});
export { dismissDriver };
