import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCity } from '../../modules/partner/store/selectors';
import { CountryCode } from '../../shared/models/country-code';
import { useLicensingAuthorityTypes } from '../../shared/hooks/useLicensingAuthorityTypes';
import { useForm } from '../../shared/view/Form';
import { LicensingAuthorityTypes } from '../../shared/models/licensingAuthority';
import { StepLayout } from '../../shared/view/StepLayout';
import { getDefaultPhoneCode } from '../../shared/utils/getDefaultPhoneCode';
import { createDriver } from '../../modules/driver/actions/async/create-driver';
import { useExternalLinks } from '../../app/hooks/useExternalLinks';
import { CreateDriverView } from './view';
const getNewEmptyDriver = ({ phoneCode }) => ({
    birthday: null,
    email: '',
    firstName: '',
    homeAddress: '',
    lastName: '',
    licenseNumber: '',
    licensingAuthority: LicensingAuthorityTypes.TFL,
    personalInn: '',
    phoneCode: phoneCode || '',
    phoneNumber: '',
    ukNationalInsurance: '',
});
const CreateDriver = ({ onNavigateBack, onNavigate }) => {
    const countryCode = useSelector(getCity)?.countryCode ?? CountryCode.Unknown;
    const defaultPhoneCode = getDefaultPhoneCode(countryCode);
    const dispatch = useDispatch();
    const licensingAuthorityTypes = useLicensingAuthorityTypes();
    const postForm = useCallback(async (formValues) => {
        const resultAction = await dispatch(createDriver({ formValues, countryCode }));
        if (createDriver.fulfilled.match(resultAction)) {
            const result = resultAction.payload;
            if (!result.validationErrors && result.driver) {
                onNavigate(`/chauffeurs/${result.driver.id}#docs`, true);
            }
            return result;
        }
        return {};
    }, [dispatch, onNavigate, countryCode]);
    const { handleSubmit, form: { control, formState: { isSubmitting }, }, } = useForm({
        onSubmit: postForm,
        defaultValues: getNewEmptyDriver({ phoneCode: defaultPhoneCode }),
    });
    const handleClickBack = useCallback(() => {
        onNavigateBack('/todo');
    }, [onNavigateBack]);
    const links = useExternalLinks();
    return (_jsx(StepLayout, { hideProgress: true, onClickBack: handleClickBack, children: _jsx("form", { onSubmit: handleSubmit, children: _jsx(CreateDriverView, { control: control, countryCode: countryCode, licensingAuthorityTypes: licensingAuthorityTypes, linkToLicensingAuthorities: links.licensingAuthorities, linkToPrivacyPolicy: links.privacyPolicy, isSubmitting: isSubmitting }) }) }));
};
export { CreateDriver };
