import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyDriver } from '../../../../modules/driver/store/selectors';
import { submitHelloStep } from '../../../../modules/onboarding/actions/submitHelloStep';
import { prepareUploadMyDriverForm } from '../../../../modules/driver/actions/async/prepare-upload-driver-form';
import { myDriverUploadSelectors } from '../../../../modules/driver/store/upload-slice';
import { getPartner } from '../../../../modules/partner/store/selectors';
import { useForm } from '../../../../shared/view/Form';
import { LicensingAuthorityTypes } from '../../../../shared/models/licensingAuthority';
import { loadDriverDocDefinitions } from '../../../../modules/driver/actions/async/load-driver-doc-definitions';
import { fsmSelectors } from '../../../../modules/onboarding/store/fsm-slice';
import { eventTracking } from '../../../../services/event-tracking';
import { DriverForm } from './Form';
const mapDriverToForm = ({ homeAddress, licenseNumber, ukNationalInsurance, birthday, licensingAuthority }, partner, draft) => ({
    birthday,
    licenseNumber,
    ukNationalInsurance,
    homeAddress,
    personalInn: partner.personalInn,
    licensingAuthority: draft.licensingAuthority || licensingAuthority || LicensingAuthorityTypes.TFL,
});
const Driver = ({ onSwitchScreen, View = DriverForm }) => {
    const dispatch = useDispatch();
    const driver = useSelector(getMyDriver);
    const partner = useSelector(getPartner);
    const draft = useSelector(fsmSelectors.getDraft);
    const docs = useSelector(myDriverUploadSelectors.getDocsList);
    useEffect(() => {
        dispatch(loadDriverDocDefinitions());
    }, [dispatch]);
    const postForm = useCallback(async (formValues) => {
        const resultAction = await dispatch(submitHelloStep(formValues));
        if (submitHelloStep.fulfilled.match(resultAction)) {
            const { isSucceed, result = {} } = resultAction.payload;
            if (isSucceed && !result.validationErrors) {
                eventTracking.trackSnowplow({
                    event: 'Onboarding - Personal - Success',
                    state: docs.every(doc => doc.status !== 'missing'),
                });
                onSwitchScreen('NEXT');
            }
            return result;
        }
        return {};
    }, [dispatch, onSwitchScreen, docs]);
    const { handleSubmit, form: { control, formState: { isSubmitting }, }, } = useForm({
        preSaveStore: {
            storeId: `helloStepForm-${partner.id}`,
            type: 'memory',
        },
        onSubmit: postForm,
        defaultValues: mapDriverToForm(driver, partner, draft),
    });
    const handleClickDocListItem = useCallback((event, doc) => dispatch(prepareUploadMyDriverForm({
        docType: doc.docType,
        onSuccess: () => onSwitchScreen('UPLOAD'),
    })), [dispatch, onSwitchScreen]);
    return (_jsx("form", { onSubmit: handleSubmit, children: _jsx(View, { docs: docs, onDocListItemClick: handleClickDocListItem, control: control, isSubmitting: isSubmitting }) }));
};
export { Driver };
