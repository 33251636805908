import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissions } from '../../app/hooks/usePermissions';
import { loadEarnings } from '../../modules/earning/actions/async/load-earnings';
import { getEarnings, getInterval } from '../../modules/earning/store/selectors';
import { PeriodNameEnum } from '../../modules/earning/model';
import { View } from './view';
const List = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    usePermissions('viewOverview');
    const earnings = useSelector(getEarnings);
    const interval = useSelector(getInterval);
    const fetchEarnings = useCallback(({ periodName }) => {
        setIsLoading(true);
        dispatch(loadEarnings({
            periodName: periodName ?? PeriodNameEnum.last_week,
        }))
            .unwrap()
            .finally(() => {
            setIsLoading(false);
        });
    }, [dispatch, setIsLoading]);
    return (_jsx(View, { earnings: earnings, interval: interval, fetchEarnings: fetchEarnings, isLoading: isLoading }));
};
export { List };
