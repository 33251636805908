import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { usePermissionsContext } from '../contexts/permissions';
const usePermissions = (...permissions) => {
    const history = useHistory();
    const acl = usePermissionsContext();
    const isAllowed = permissions.every(name => acl.can[name]);
    useEffect(() => {
        if (acl.isReady && !isAllowed) {
            history.replace('/');
        }
    }, [acl.isReady, history, isAllowed]);
};
export { usePermissions };
