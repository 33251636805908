import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { Tooltip } from '@wheely/ui-kit';
import { WEEKDAYS_SHORT } from '../../../shared/view/LocalizedWeekPicker/const';
import { Clock } from './Clock';
import s from './WorkingHoursCalendar.css';
const WorkingHoursCalendar = ({ workloads }) => (_jsxs("div", { className: s.calendar, children: [_jsx("div", { className: s.cell }), WEEKDAYS_SHORT.map(weekday => (_jsx("div", { className: cn(s.cell, s.heading), children: _jsx(FormattedMessage, { ...weekday }) }, weekday.id))), workloads &&
            Object.entries(workloads).map(([time, hourlyWorkloads]) => (_jsxs(Fragment, { children: [_jsx("div", { className: s.cell, children: _jsx("span", { className: s.timeLabel, children: time }) }, time), hourlyWorkloads.map(hourlyWorkload => (_jsx("div", { className: cn(s.cell, { [s.future]: hourlyWorkload.isFuture }), children: hourlyWorkload.minutesOnline > 0 && (_jsx(Tooltip, { content: _jsx(FormattedMessage, { id: 'hHr2Az', defaultMessage: 'Chauffeur was online {minutes} min', values: { minutes: hourlyWorkload.minutesOnline } }), children: _jsx(Clock, { className: s.cellContent, minutes: hourlyWorkload.minutesOnline }) })) }, hourlyWorkload.ts)))] }, time)))] }));
export { WorkingHoursCalendar };
