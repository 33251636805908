import { EnumCardItemStatus } from '../types';
const assignTipText = (intl) => (item) => ({
    ...item,
    tipText: (() => {
        switch (item.status) {
            case EnumCardItemStatus.addDocument:
                return intl.formatMessage({ id: "uolx/v", defaultMessage: "Add documents" });
            case EnumCardItemStatus.documentsError:
                return intl.formatMessage({ id: "QW4nux", defaultMessage: "Documents error" });
            case EnumCardItemStatus.documentsExpired:
                return intl.formatMessage({ id: "WLZt0z", defaultMessage: "Expired documents" });
            case EnumCardItemStatus.inReview:
                return intl.formatMessage({ id: "+I3iZ6", defaultMessage: "In review" });
            case EnumCardItemStatus.approved:
                return intl.formatMessage({ id: "ngu9gy", defaultMessage: "Approved" });
            case EnumCardItemStatus.waitingForApproval:
                return intl.formatMessage({ id: "P3eKlW", defaultMessage: "Pending approval" });
            default:
                return '';
        }
    })(),
});
const assignTipTextForCardItems = (intl, items) => items.map(assignTipText(intl));
export { assignTipTextForCardItems };
