import { createSelector } from '@reduxjs/toolkit';
import { namespace } from '../namespace';
const getPartner = (state) => state[namespace].data;
const getPartnerId = (state) => state[namespace].data.id;
const getTestDefinitions = createSelector((state) => state.common.cities, (state) => state[namespace].data.cityId, (cities, cityId) => cities.find(city => city.id === cityId)?.testDefinitions ?? []);
const getAllTestDefinitions = createSelector((state) => state.common.cities, (cities) => cities.map(city => city.testDefinitions ?? []).flat());
const isTestDefinitionsReady = createSelector((state) => state.common.cities, (state) => state[namespace].data.cityId, (cities, cityId) => cities.length > 0 && cityId !== '');
const getCity = createSelector((state) => state.common.cities, (state) => state[namespace].data.cityId, (cities, cityId) => cities.find(city => city.id === cityId));
const getCityTimezone = createSelector((state) => state.common.cities, (state) => state[namespace].data.cityId, (cities, cityId) => cities.find(city => city.id === cityId)?.timezone);
const checkIsOnBoardingCompleted = (state) => state[namespace].data.onboarding.isCompleted;
const getPartnerType = (state) => state[namespace].data.partnerType;
const getUploadedDocsForPartner = (state) => state.partner.data.docUploads;
const getPartnerCountryCode = (state) => state[namespace].data.countryCode;
export { getCityTimezone, getPartnerId, getPartner, getPartnerType, getTestDefinitions, getAllTestDefinitions, getUploadedDocsForPartner, getPartnerCountryCode, checkIsOnBoardingCompleted, isTestDefinitionsReady, getCity, };
