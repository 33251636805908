import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setAllCars } from '../actions';
import { carAPI } from '../../api';
let lastRequestId;
const loadAllCars = createAsyncThunk(`${namespace}/load-all-cars`, async (_, { dispatch, requestId }) => {
    lastRequestId = requestId;
    const carsPage = await carAPI.fetchCars();
    if (lastRequestId !== requestId) {
        return { isReplaced: true };
    }
    dispatch(setAllCars(carsPage.items));
    return { result: carsPage.items };
});
export { loadAllCars };
