import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionList } from '../../shared/view/DescriptionList';
import s from './styles.css';
const ContactInfo = ({ children, partner, onClickEdit, className }) => {
    const intl = useIntl();
    const titles = useMemo(() => [
        [
            'phone',
            intl.formatMessage({ id: "nRHsAq", defaultMessage: "Phone" }),
        ],
        [
            'email',
            intl.formatMessage({ id: "RNNpvw", defaultMessage: "Email" }),
        ],
    ], [intl]);
    return (_jsx(DescriptionList, { className: className, title: intl.formatMessage({ id: "L0ST1R", defaultMessage: "Contact information" }), values: partner, titles: titles, action: _jsx("span", { onClick: onClickEdit, className: s.editButton, children: intl.formatMessage({ id: "zavkZT", defaultMessage: "Edit" }) }), children: children }));
};
export { ContactInfo };
