import qs from 'qs';
const isTypeform = (params) => 'driverId' in params && 'cityName' in params;
const getUrlForTest = (test, params) => {
    if (test.linkType === 'typeform' && isTypeform(params)) {
        return (test.linkUrl +
            `#` +
            qs.stringify({
                driver_id: params.driverId,
                city: params.cityName,
            }));
    }
    return test.linkUrl;
};
export { getUrlForTest };
