const createSelectors = (getSliceState) => ({
    getValue: (state) => getSliceState(state).currentValue,
    getTransitions: (state) => {
        const sliceState = getSliceState(state);
        return sliceState.config[sliceState.currentValue].on;
    },
    getConfig: (state) => getSliceState(state).config,
    isBusy: (state) => getSliceState(state).isBusy,
    getOnUpdateHandler: (state) => getSliceState(state).onUpdate,
    getContext: (state) => getSliceState(state).context,
    isInitialized: (state) => getSliceState(state).isInitialized,
    getDraft: (state) => getSliceState(state).draft,
});
export { createSelectors };
