import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@wheely/ui-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { PhoneField } from '../../../src-common/view/adapters/reactHookForm/PhoneField';
import { BottomButtons } from '../../shared/view/BottomButtons';
import { StepLayout } from '../../shared/view/StepLayout';
import { usePhoneCodes } from '../../shared/hooks/usePhoneCodes';
import { getCountryCode } from '../../modules/common/store/selectors';
import { CountryCode } from '../../shared/models/country-code';
import s from './styles.css';
const EditPersonalInfoView = ({ onSubmit, control, isSubmitting, onNavigateBack, }) => {
    const intl = useIntl();
    const phoneCodes = usePhoneCodes();
    const countryCode = useSelector(getCountryCode);
    return (_jsx(StepLayout, { hideProgress: true, title: intl.formatMessage({ id: "sY078i", defaultMessage: "Personal info" }), onClickBack: onNavigateBack, children: _jsx("main", { className: s.wrapper, children: _jsxs("form", { onSubmit: onSubmit, className: s.form, children: [_jsx(PhoneField, { name: "phoneNumber", namePhoneCode: "phoneCode", title: intl.formatMessage({ id: "hFykp8", defaultMessage: "Phone number" }), control: control, isRequired: true, items: phoneCodes, description: countryCode === CountryCode.France &&
                            intl.formatMessage({ id: "V8we6R", defaultMessage: "Enter phone number without 0" }) }), _jsx(BottomButtons, { children: _jsx(Button, { type: "submit", disabled: isSubmitting, className: s.mainButton, children: _jsx(FormattedMessage, { id: 'RT8KNi', defaultMessage: 'Save' }) }) })] }) }) }));
};
export { EditPersonalInfoView };
