import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionList } from '../../shared/view/DescriptionList';
import { IntlContext } from '../../i18n';
import { getLocalizedPartnerType } from '../../i18n/getLocalizedPartnerType';
import { CountryCode } from '../../shared/models/country-code';
const mapToCompanyInfo = (partner, intl) => ({
    partnerType: partner.partnerType ? getLocalizedPartnerType(partner.partnerType, intl) : '',
    legalName: partner.legalName,
    vatRegNo: partner.vatRegNo,
    companyRegNum: partner.companyRegNumber,
    companyInn: partner.inn,
    companyKpp: partner.legalKpp,
    address: partner.legalAddress1,
    taxRegNo: partner.taxRegNo,
    tradeLicenseNumber: partner.tradeLicenseNumber,
});
const skipEmpty = (item) => item !== false;
const CompanyInfo = ({ className, partner }) => {
    const intl = useIntl();
    const { region } = useContext(IntlContext);
    const titles = useMemo(() => {
        const allTitles = [
            region === CountryCode.Russia && [
                'partnerType',
                intl.formatMessage({ id: "fWyFSI", defaultMessage: "Company type" }),
            ],
            [
                'legalName',
                intl.formatMessage({ id: "JJw82k", defaultMessage: "Company name" }),
            ],
            [CountryCode.GreatBritain, CountryCode.France].includes(region) && [
                'vatRegNo',
                intl.formatMessage({ id: "lYsQGl", defaultMessage: "VAT REG NUM" }),
            ],
            region === CountryCode.GreatBritain && [
                'companyRegNum',
                intl.formatMessage({ id: "V2spUZ", defaultMessage: "Company registration number" }),
            ],
            region === CountryCode.Russia && [
                'companyInn',
                intl.formatMessage({ id: "3n7aJS", defaultMessage: "Company INN" }),
            ],
            region === CountryCode.Russia && [
                'companyKpp',
                intl.formatMessage({ id: "zN+RTt", defaultMessage: "Company KPP" }),
            ],
            region === CountryCode.ArabEmirates && [
                'tradeLicenseNumber',
                intl.formatMessage({ id: "1xiSEM", defaultMessage: "Trade License Number" }),
            ],
            region === CountryCode.ArabEmirates && [
                'taxRegNo',
                intl.formatMessage({ id: "yuKAnJ", defaultMessage: "Tax Reg NO" }),
            ],
            [
                'address',
                intl.formatMessage({ id: "/zotmy", defaultMessage: "Company address" }),
            ],
        ];
        return allTitles.filter(skipEmpty);
    }, [intl, region]);
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "ZKvp6f", defaultMessage: "Company info" }), className: className, values: useMemo(() => mapToCompanyInfo(partner, intl), [partner, intl]), titles: titles }));
};
export { CompanyInfo };
