import * as t from 'io-ts';
import { formData } from '../io-types/form-data';
import { postForm } from '../../../src/shared/api/fetch-based-http-methods';
import { CarBrief, CarDetails, CarMakeAndModel, CarUploadResult, CarUploadDefinition, CarDocType, NewCarRequest, CarValidations, } from './w4d-cars-types';
const w4dCarsRemoteAPI = ({ apiHost, get, post, put, del }) => ({
    readAllModels: {
        path: `${apiHost}/v6/cars/allmodels`,
        pathParams: t.type({
            city_id: t.string,
        }),
        response: t.type({
            results: t.array(CarMakeAndModel),
        }),
        method: get,
    },
    readCars: {
        path: ({ limit, offset }) => `${apiHost}/v6/partners/self/cars?&limit=${limit}&skip=${offset}`,
        response: t.type({
            total_entries: t.Int,
            skip: t.Int,
            limit: t.Int,
            results: t.array(CarBrief),
        }),
        method: get,
        pathParams: t.type({
            limit: t.number,
            offset: t.number,
        }),
    },
    readCarDetails: {
        path: ({ id }) => `${apiHost}/v6/partners/self/cars/${id}`,
        pathParams: t.type({
            id: t.string,
        }),
        method: get,
        response: t.type({
            car: CarDetails,
        }),
    },
    createCar: {
        path: `${apiHost}/v6/partners/self/cars`,
        method: post,
        response: t.union([
            t.type({
                car: CarDetails,
            }),
            CarValidations,
        ]),
        payload: NewCarRequest,
    },
    updateCar: {
        path: ({ carId }) => `${apiHost}/v6/partners/self/cars/${carId}`,
        pathParams: t.type({
            carId: t.string,
        }),
        method: put,
        response: t.union([
            t.type({
                car: CarDetails,
            }),
            CarValidations,
        ]),
        payload: NewCarRequest,
    },
    deleteCar: {
        path: ({ carId }) => `${apiHost}/v6/partners/self/cars/${carId}`,
        pathParams: t.type({
            carId: t.string,
        }),
        method: del,
        response: t.union([
            t.type({
                deleted: t.literal('ok'),
            }),
            t.type({
                errors: t.record(t.string, t.array(t.string)),
            }),
        ]),
    },
    uploadCarDocument: {
        path: ({ carId }) => `${apiHost}/v6/uploads/cars/${carId}`,
        pathParams: t.type({
            carId: t.string,
        }),
        method: postForm,
        response: t.union([
            t.type({
                upload: CarUploadResult,
            }),
            t.type({
                errors: t.type({
                    file: t.array(t.string),
                }),
            }),
        ]),
        payload: formData(t.partial({
            type: CarDocType,
            expires_at: t.union([t.undefined, t.string]),
            upload_file: t.unknown, // binary
        })),
    },
    readCarDocumentDefinitions: {
        path: `${apiHost}/v6/uploads/car`,
        method: get,
        response: t.array(CarUploadDefinition),
    },
});
export { w4dCarsRemoteAPI };
