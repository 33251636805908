import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Button } from '@wheely/ui-kit';
import { TransferRequestStatuses } from '../../../modules/driver/model/driver';
import { PersonalInfo } from './PersonalInfo';
import { CurrentVehicle } from './CurrentVehicle';
import { Statistics } from './Statistics';
import { OrdersInfo } from './OrdersInfo';
import s from './TabOverview.css';
const Overview = ({ driver, onClickBlock, onClickDismiss, isPending }) => {
    const isBlockActionAllowed = driver.blockInfo === undefined;
    const isDismissActionAllowed = driver.transferRequest?.status === TransferRequestStatuses.pending;
    return (_jsxs(_Fragment, { children: [_jsx(CurrentVehicle, { driver: driver }), _jsx(PersonalInfo, { driver: driver }), _jsx(OrdersInfo, { driver: driver }), _jsx(Statistics, { driver: driver }), (isBlockActionAllowed || isDismissActionAllowed) && (_jsxs("div", { className: s.actions, children: [isBlockActionAllowed && (_jsx(Button, { minimal: true, intent: "danger", className: s.deleteButton, onClick: onClickBlock, "data-test-id": "block-driver", disabled: isPending, children: _jsx(FormattedMessage, { id: 'v+X3Zo', defaultMessage: 'Block chauffeur' }) })), isDismissActionAllowed && (_jsx(Button, { minimal: true, onClick: onClickDismiss, "data-test-id": "transfer-driver", disabled: isPending, children: _jsx(FormattedMessage, { id: 'mzkAJN', defaultMessage: 'Dismiss' }) }))] }))] }));
};
export { Overview };
