import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
const getStatusTitle = (status) => {
    if (status === 'online') {
        return (_jsx(FormattedMessage, { id: 'ym4jgK', defaultMessage: 'Online' }));
    }
    if (status === 'busy') {
        return (_jsx(FormattedMessage, { id: 'y6ZU1J', defaultMessage: 'Busy' }));
    }
    return (_jsx(FormattedMessage, { id: 'QdCKom', defaultMessage: 'Offline' }));
};
export { getStatusTitle };
