import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { H1, H3 } from '@wheely/ui-kit';
import { useExternalLinks } from '../../../../app/hooks/useExternalLinks';
import s from './style.css';
const Header = () => {
    const links = useExternalLinks();
    return (_jsxs("header", { className: s.header, "data-test-id": "quest-header", children: [_jsx(H3, { capitalize: true, className: s.subtitle, "data-test-id": "quest-header__title", children: _jsx(FormattedMessage, { id: 'tIx+rU', defaultMessage: 'Wheely for Chauffeurs' }) }), _jsx(H1, { capitalize: true, className: s.title, "data-test-id": "quest-header__sign-in-title", children: _jsx(FormattedMessage, { id: '3GH14G', defaultMessage: 'Sign In' }) }), _jsx("div", { "data-test-id": "quest-header__sign-up", children: _jsx(FormattedMessage, { id: 'lgP7bC', defaultMessage: 'Don\'t have an account? <link>Sign up</link>', values: {
                        link: (text) => _jsx("a", { href: links.chauffeurLanding, children: text }),
                    } }) })] }));
};
export { Header };
