import { useIntl } from 'react-intl';
import { getLocalizedColorName } from '../../../i18n/getLocalizedColorName';
import { useModelConfig } from './useModelConfig';
const getLocalizedColorOptions = (selectOptions, intl) => selectOptions.map(({ value }) => ({
    title: getLocalizedColorName(value, intl),
    value,
}));
const useColors = (make, model) => {
    const intl = useIntl();
    const modelConfig = useModelConfig(make, model);
    if (!modelConfig || !model) {
        return [];
    }
    return getLocalizedColorOptions(modelConfig.color, intl);
};
const useInteriorColors = (make, model) => {
    const intl = useIntl();
    const modelConfig = useModelConfig(make, model);
    if (!modelConfig || !model) {
        return [];
    }
    return getLocalizedColorOptions(modelConfig.interiorColor, intl);
};
export { useColors, useInteriorColors };
