import { JourneyStatusEnum } from '../../modules/journey/model';
const getStatusIntent = (status) => {
    switch (status) {
        case JourneyStatusEnum.serving:
            return 'success';
        case JourneyStatusEnum.done:
            return 'primary';
        case JourneyStatusEnum.failed:
        case JourneyStatusEnum.car_not_found:
            return 'danger';
        case JourneyStatusEnum.looking_for_drivers:
        case JourneyStatusEnum.on_the_way:
        case JourneyStatusEnum.arrived:
            return 'warning';
        default:
            return 'none';
    }
};
export { getStatusIntent };
