import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUserId } from '@snowplow/browser-tracker';
import { getCookieValue } from '../../../../shared/utils/cookie';
import { errorHandling } from '../../../../services/errorHandling';
import { namespace } from '../../namespace';
import { userSlice } from '../../store/slice';
import { AUTH_COOKIE_KEY } from '../../../../shared/utils/session';
import { userAPI } from '../../api';
const loadUser = createAsyncThunk(`${namespace}/load-user`, async (_, { dispatch }) => {
    const myUser = await userAPI.readUserData();
    errorHandling.identify({ id: myUser.userId, email: myUser.email });
    setUserId(myUser.userId);
    const cityIdFromCookie = getCookieValue(AUTH_COOKIE_KEY)?.city_id;
    if (cityIdFromCookie) {
        myUser.cityId = cityIdFromCookie;
    }
    dispatch(userSlice.actions.setUserData(myUser));
    return myUser;
});
export { loadUser };
