import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setDocDefinitions } from '../actions';
import { partnerAPI } from '../../api';
const loadPartnerDocDefinitions = createAsyncThunk(`${namespace}/fetch-doc-definitions`, async (partnerType, { dispatch }) => {
    if (!partnerType) {
        return { isSucceed: false };
    }
    dispatch(setDocDefinitions([]));
    const docs = await partnerAPI.fetchDocDefinitions(partnerType);
    dispatch(setDocDefinitions(docs));
    return { isSucceed: true };
});
export { loadPartnerDocDefinitions };
