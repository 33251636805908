import React from 'react';
const useMergedRef = (...refs) => React.useCallback((element) => {
    for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];
        if (typeof ref === 'function') {
            ref(element);
        }
        else if (ref && typeof ref === 'object') {
            ref.current = element;
        }
    }
}, 
// eslint-disable-next-line react-hooks/exhaustive-deps
refs);
export { useMergedRef };
