import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
const prepareDateForRequest = (date) => {
    if (!date) {
        return '';
    }
    return formatISO(date);
};
const prepareDate = (input) => {
    if (!input) {
        return null;
    }
    return parseISO(input);
};
const prepareDateWithoutTime = (input) => {
    const parsedDate = prepareDate(input);
    if (!parsedDate) {
        return null;
    }
    const [dateStringWithoutTime] = parsedDate.toISOString().split('T');
    return new Date(dateStringWithoutTime);
};
export { prepareDateForRequest, prepareDate, prepareDateWithoutTime };
