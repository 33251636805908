const ERROR = {
    NOT_FOUND: '404',
    INIT_LOAD_FAIL: 'loadFail',
    BLOCKED: 'blocked',
    API_ERROR: 'apiError',
    INTERNAL_SERVER_ERROR: '500',
    BAD_GATEWAY: '502',
    LOGIN_REQUIRED: 'Login Required',
};
export { ERROR };
