import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { driverAPI } from '../../api';
import { getCurrentDriver } from '../../store/selectors';
import { updateDriver } from '../actions';
import { getPhoneFromCodeAndNumber } from '../../../../shared/utils/phoneUtils';
let lastRequestId;
const updateDriverInfo = createAsyncThunk(`${namespace}/submit-update-driver-info`, async (formValues, { dispatch, requestId, getState }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const result = {};
        const driver = getCurrentDriver(getState());
        if (!driver) {
            return {
                isSucceed: false,
            };
        }
        const updatedFiels = {};
        if ('carId' in formValues) {
            updatedFiels.carId = formValues.carId;
        }
        if ('phoneCode' in formValues && 'phoneNumber' in formValues) {
            updatedFiels.phone = getPhoneFromCodeAndNumber({
                code: formValues.phoneCode,
                number: formValues.phoneNumber,
            });
        }
        const [, driverUpdateErrors] = await driverAPI.updateDriver(driver.id, {
            ...driver,
            ...updatedFiels,
        });
        if (driverUpdateErrors) {
            const { phone: phoneError, ...other } = driverUpdateErrors;
            if (phoneError) {
                result.validationErrors = {
                    phoneNumber: phoneError,
                };
            }
            result.baseErrors = Object.values(other).flat();
        }
        if (!driverUpdateErrors) {
            dispatch(updateDriver({ id: driver.id, update: updatedFiels }));
        }
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { updateDriverInfo };
