import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl, FormattedMessage } from 'react-intl';
import { Tag } from '../Tag';
const TransferStatus = ({ date, timezone }) => {
    const intl = useIntl();
    return (_jsx(Tag, { children: _jsx(FormattedMessage, { id: '45AszA', defaultMessage: 'Leaving {date}', values: {
                date: intl.formatDate(date, {
                    month: '2-digit',
                    day: 'numeric',
                    timeZone: timezone,
                }),
            } }) }));
};
export { TransferStatus };
