import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { resetCarForm, updateForm } from '../actions';
import { loadCarById } from './load-car-by-id';
let lastRequestId;
const prepareCarForm = createAsyncThunk(`${namespace}/prepare-car-form`, async (context, { dispatch, requestId }) => {
    lastRequestId = requestId;
    const car = await dispatch(loadCarById(context.carId)).unwrap();
    if (lastRequestId !== requestId) {
        return { isReplaced: true };
    }
    if (car) {
        dispatch(updateForm(car));
    }
    else {
        dispatch(resetCarForm());
    }
    return { isSucceed: true };
});
export { prepareCarForm };
