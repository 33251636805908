import * as t from 'io-ts';
import { AccessStatusEnum } from '../../../src/shared/models/access-status';
import { licensingAuthorityTypesEnum } from '../../../src/shared/models/licensingAuthority';
import { UploadDefinition, UploadedFile, UploadResult } from './w4d-upload-types';
const Make = t.keyof({
    'Mercedes-Benz': null,
    'Mercedes-Maybach': null,
    BMW: null,
    Hongqi: null,
});
const CarDocType = t.string;
const CarMakeAndModel = t.type({
    _id: t.string,
    icon_url: t.string,
    make: Make,
    modifications: t.array(t.string),
});
const CarStatusHash = t.type({
    online: t.boolean,
    connected: t.boolean,
    busy: t.boolean,
});
const NewCarRequest = t.type({
    make: Make,
    model: t.string,
    generation: t.union([t.null, t.string]),
    license_plate: t.string,
    license_number: t.union([t.null, t.string]),
    color: t.string,
    interior_color: t.string,
    year: t.number,
    first_sale_date: t.union([t.undefined, t.string]),
    speed: t.null,
    pin: t.null,
    access_status: t.null,
    tags: t.Array,
    current_driver_id: t.null,
    current_order_id: t.null,
    license_authority: t.union([t.undefined, t.keyof(licensingAuthorityTypesEnum), t.null]),
    vin: t.string,
});
const CarUploadedFile = t.type({
    ...UploadedFile.props,
    type: CarDocType,
});
const CarDriver = t.type({
    id: t.string,
    first_name: t.union([t.undefined, t.string]),
    last_name: t.union([t.undefined, t.string]),
});
const CarBrief = t.type({
    access_status: t.keyof(AccessStatusEnum),
    color: t.string,
    id: t.string,
    interior_color: t.union([t.undefined, t.string]),
    license_plate: t.string,
    make: Make,
    model: t.string,
    generation: t.union([t.null, t.undefined, t.string]),
    name: t.string,
    status_hash: CarStatusHash,
    tags: t.array(t.string),
    year: t.union([t.undefined, t.Int]),
    uploads: t.union([t.undefined, t.array(CarUploadedFile)]),
    license_authority: t.union([t.undefined, t.keyof(licensingAuthorityTypesEnum)]),
    wheelbase: t.union([t.undefined, t.string]),
    current_driver: t.union([t.undefined, CarDriver]),
    vin: t.union([t.undefined, t.string, t.null]),
    engine_type: t.union([t.undefined, t.string]),
});
const CarExtra = t.type({
    current_driver_id: t.union([t.undefined, t.string]),
    current_order_id: t.union([t.undefined, t.string]),
    first_sale_date: t.union([t.undefined, t.string]),
    license_number: t.union([t.undefined, t.string]),
    pin: t.union([t.undefined, t.string]),
    speed: t.union([t.undefined, t.string]),
});
const CarUploadResult = t.type({
    ...UploadResult.props,
    car_id: t.string,
    type: CarDocType,
});
const CarUploadDefinition = t.type({
    ...UploadDefinition.props,
    type: CarDocType,
});
const CarDetails = t.intersection([CarBrief, CarExtra]);
const CarValidations = t.type({
    errors: t.partial({
        license_plate: t.array(t.string),
        color: t.array(t.string),
        interior_color: t.array(t.string),
        make: t.array(t.string),
        model: t.array(t.string),
    }),
});
const CarFormResponse = t.union([
    t.type({
        car: CarDetails,
    }),
    CarValidations,
]);
export { CarDocType, CarUploadDefinition, CarMakeAndModel, CarDetails, CarBrief, CarUploadResult, CarExtra, NewCarRequest, CarValidations, CarFormResponse, CarStatusHash, };
