import { get, post, put, del } from '../../../shared/api/fetch-based-http-methods';
import { w4dCarsRemoteAPI } from '../../../../src-common/api/definitions/w4d-cars-endpoints';
import { createClient } from '../../../../src-common/api/src/create-client';
import { skipNullishDeep } from '../../../shared/utils/skip-nullish-deep';
import { prepareDate, prepareDateForRequest } from '../../../shared/api/prepare-date';
import { createFormData } from '../../../shared/api/create-form-data';
import { prepareUploadedFile } from '../../../shared/models/uploaded-file';
import { formatDate } from '../../../shared/utils/formatDate';
import { getOnlineStatus } from '../../../shared/utils/getOnlineStatus';
import { ENV } from '../../../env';
import { getHardcodedCarModels } from './hardcoded-make-models';
const client = createClient(w4dCarsRemoteAPI({ apiHost: ENV.API_URL, get, post, put, del }));
const prepareCarRequest = (car) => ({
    make: car.make,
    model: car.model,
    generation: car.generation ?? null,
    license_plate: car.licensePlate,
    license_number: car.PHV,
    license_authority: car.licensingAuthority,
    color: car.color,
    interior_color: car.interiorColor,
    year: Number(car.year),
    first_sale_date: prepareDateForRequest(car.registrationDate),
    speed: null,
    pin: null,
    access_status: null,
    tags: [],
    current_driver_id: null,
    current_order_id: null,
    wheelbase: car.wheelbase,
    vin: car.vin,
    engine_type: car.engineType,
});
const prepareCar = (raw) => ({
    accessStatus: raw.access_status,
    color: raw.color,
    id: raw.id,
    interiorColor: raw.interior_color ?? '',
    licensePlate: raw.license_plate,
    make: raw.make,
    model: raw.model,
    generation: raw.generation ?? '',
    year: String(raw.year),
    wheelbase: raw.wheelbase || '',
    docUploads: !raw.uploads
        ? {}
        : Object.fromEntries(raw.uploads.map(rawUpload => [rawUpload.type, prepareUploadedFile(rawUpload)])),
    tags: raw.tags,
    vin: raw.vin ?? '',
    onlineStatus: getOnlineStatus(raw.status_hash),
    engineType: raw.engine_type,
    currentChauffeur: raw.current_driver
        ? {
            id: raw.current_driver.id,
            firstName: raw.current_driver.first_name ?? '',
            lastName: raw.current_driver.last_name ?? '',
        }
        : null,
});
const prepareCarDetails = (raw) => ({
    PHV: raw.license_number || '',
    licensingAuthority: raw.license_authority,
    registrationDate: prepareDate(raw.first_sale_date),
    ...prepareCar(raw),
});
const prepareFormResponse = (resp) => {
    if ('errors' in resp) {
        return [
            undefined,
            {
                licensePlate: resp.errors?.license_plate,
                color: resp.errors?.color,
                interiorColor: resp.errors?.interior_color,
                make: resp.errors?.make,
                model: resp.errors?.model,
            },
        ];
    }
    return [prepareCarDetails(resp.car), undefined];
};
const carAPI = {
    createCar: async (car) => {
        const resp = await client.createCar({ payload: prepareCarRequest(car) });
        return prepareFormResponse(resp);
    },
    updateCar: async (car) => {
        const resp = await client.updateCar({
            payload: prepareCarRequest(car),
            pathParams: { carId: String(car.id) },
        });
        return prepareFormResponse(resp);
    },
    deleteCar: async (carId) => client.deleteCar({
        pathParams: { carId },
    }),
    fetchAllModels: async (cityId, cityName) => {
        //const response = await client.readAllModels({ pathParams: { city_id: cityId } });
        const response = { results: getHardcodedCarModels(cityName) };
        return response.results.map(raw => ({
            id: raw._id,
            make: raw.make,
            models: raw.modifications,
        }));
    },
    fetchCars: async ({ limit = 250, offset = 0, } = {}) => {
        const response = await client.readCars({ pathParams: { limit, offset } });
        const items = response.results.map(prepareCar);
        return {
            items,
            total: response.total_entries,
            limit: response.limit,
            offset: response.skip,
        };
    },
    fetchCarById: async (id) => {
        const response = await client.readCarDetails({ pathParams: { id } });
        const raw = response.car;
        return prepareCarDetails(raw);
    },
    fetchDocDefinitions: async () => {
        const response = await client.readCarDocumentDefinitions();
        return response.map(raw => ({
            docType: raw.type,
            title: raw.caption,
            comment: raw.comment || undefined,
            desc: raw.description || undefined,
            exampleUrl: raw.example_url || undefined,
            isOptional: raw.optional,
            isExpires: raw.expires || false,
        }));
    },
    uploadFile: async (form, carId) => {
        const response = await client.uploadCarDocument({
            pathParams: { carId },
            payload: createFormData(skipNullishDeep({
                type: form.docType,
                expires_at: form.expiresAt && formatDate(form.expiresAt),
                upload_file: form.file?.file,
            })),
        });
        if (!('upload' in response)) {
            if ('errors' in response) {
                if (response.errors.file) {
                    return {
                        error: response.errors.file[0],
                    };
                }
            }
        }
        if ('upload' in response) {
            return {
                id: response.upload.id,
                type: response.upload.type,
            };
        }
        return {
            error: '',
        };
    },
};
export { carAPI, getOnlineStatus };
