import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissions } from '../../app/hooks/usePermissions';
import { loadBills } from '../../modules/bill/actions/async/load-bills';
import { loadUpcomingBill } from '../../modules/bill/actions/async/load-upcoming-bill';
import { getBills, getUpcomingBill } from '../../modules/bill/store/selectors';
import { getCityTimezone } from '../../modules/partner/store/selectors';
import { View } from './view';
const List = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    usePermissions('viewBills');
    useEffect(() => {
        dispatch(loadUpcomingBill());
    }, [dispatch]);
    const bills = useSelector(getBills);
    const upcomingBill = useSelector(getUpcomingBill);
    const timezone = useSelector(getCityTimezone);
    const fetchBills = useCallback((_, { limit, offset }) => {
        setIsLoading(true);
        dispatch(loadBills({ limit, offset }))
            .unwrap()
            .finally(() => {
            setIsLoading(false);
        });
    }, [dispatch, setIsLoading]);
    return (_jsx(View, { upcomingBill: upcomingBill, bills: bills, fetchBills: fetchBills, timezone: timezone, isLoading: isLoading }));
};
export { List };
