import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer as createSentryReduxEnhancer } from '@sentry/react';
import { authSlice, authNamespace } from './modules/auth/store/slice';
import { carsSlice, carsNamespace } from './modules/car/store/slice';
import { carUploadSlice, carUploadNamespace } from './modules/car/store/upload-slice';
import { driversSlice, driverNamespace } from './modules/driver/store/slice';
import { driverUploadSlice, driverUploadNamespace, myDriverUploadSlice, myDriverUploadNamespace, } from './modules/driver/store/upload-slice';
import { billsSlice, billsNamespace } from './modules/bill/store/slice';
import { onboardingSlice, onboardingNamespace } from './modules/partner/store/slice';
import { partnerUploadSlice, partnerUploadNamespace } from './modules/partner/store/uploadSlice';
import { journeysSlice, journeyNamespace } from './modules/journey/store/slice';
import { appSlice, appNamespace } from './app/store/slice';
import { fsmSlice, fsmNamespace } from './modules/onboarding/store/fsm-slice';
import { commonSlice, commonNamespace } from './modules/common/store/slice';
import { earningsSlice, earningsNamespace } from './modules/earning/store/slice';
import { userNamespace, userSlice } from './modules/user/store/slice';
import { reset } from './rootActions';
const combinedReducer = combineReducers({
    [authNamespace]: authSlice.reducer,
    [carUploadNamespace]: carUploadSlice.reducer,
    [driverUploadNamespace]: driverUploadSlice.reducer,
    [myDriverUploadNamespace]: myDriverUploadSlice.reducer,
    [carsNamespace]: carsSlice.reducer,
    [driverNamespace]: driversSlice.reducer,
    [billsNamespace]: billsSlice.reducer,
    [onboardingNamespace]: onboardingSlice.reducer,
    [appNamespace]: appSlice.reducer,
    [fsmNamespace]: fsmSlice.reducer,
    [commonNamespace]: commonSlice.reducer,
    [partnerUploadNamespace]: partnerUploadSlice.reducer,
    [journeyNamespace]: journeysSlice.reducer,
    [earningsNamespace]: earningsSlice.reducer,
    [userNamespace]: userSlice.reducer,
});
const rootReducer = (state, action) => {
    if (action.type === reset.type) {
        return combinedReducer(undefined, action);
    }
    return combinedReducer(state, action);
};
const rootStore = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
    enhancers: [createSentryReduxEnhancer({})],
});
export { rootStore };
