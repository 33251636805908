import { defineMessage } from 'react-intl';
export const MONTHS = [
    defineMessage({ id: "January", defaultMessage: "January" }),
    defineMessage({ id: "February", defaultMessage: "February" }),
    defineMessage({ id: "March", defaultMessage: "March" }),
    defineMessage({ id: "April", defaultMessage: "April" }),
    defineMessage({ id: "May", defaultMessage: "May" }),
    defineMessage({ id: "June", defaultMessage: "June" }),
    defineMessage({ id: "July", defaultMessage: "July" }),
    defineMessage({ id: "August", defaultMessage: "August" }),
    defineMessage({ id: "September", defaultMessage: "September" }),
    defineMessage({ id: "October", defaultMessage: "October" }),
    defineMessage({ id: "November", defaultMessage: "November" }),
    defineMessage({ id: "December", defaultMessage: "December" }),
];
export const WEEKDAYS_LONG = [
    defineMessage({ id: "Sunday", defaultMessage: "Sunday" }),
    defineMessage({ id: "Monday", defaultMessage: "Monday" }),
    defineMessage({ id: "Tuesday", defaultMessage: "Tuesday" }),
    defineMessage({ id: "Wednesday", defaultMessage: "Wednesday" }),
    defineMessage({ id: "Thursday", defaultMessage: "Thursday" }),
    defineMessage({ id: "Friday", defaultMessage: "Friday" }),
    defineMessage({ id: "Saturday", defaultMessage: "Saturday" }),
];
export const WEEKDAYS_SHORT = [
    defineMessage({ id: "Mo", defaultMessage: "Mo" }),
    defineMessage({ id: "Tu", defaultMessage: "Tu" }),
    defineMessage({ id: "We", defaultMessage: "We" }),
    defineMessage({ id: "Th", defaultMessage: "Th" }),
    defineMessage({ id: "Fr", defaultMessage: "Fr" }),
    defineMessage({ id: "Sa", defaultMessage: "Sa" }),
    defineMessage({ id: "Su", defaultMessage: "Su" }),
];
