import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useContext } from 'react';
import { Button } from '@wheely/ui-kit';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import addWeeks from 'date-fns/addWeeks';
import subWeeks from 'date-fns/subWeeks';
import isThisWeek from 'date-fns/isThisWeek';
import { Icon } from '../../../../src-common/view/components/Icon';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import { getCity } from '../../../modules/partner/store/selectors';
import { IntlContext } from '../../../i18n';
import { WeekSelector } from './WeekSelector';
import { WorkingHoursCalendar } from './WorkingHoursCalendar';
import { WeekPicker } from './WeekPicker';
import s from './TabWorkingHours.css';
const getTotalTime = (minutes, locale) => {
    const duration = intervalToDuration({ start: 0, end: minutes * 60 * 1000 });
    return formatDuration(duration, {
        format: ['days', 'hours', 'minutes'],
        locale,
    });
};
const getButtonTitle = (from, to, intl) => `${intl.formatDate(from, {
    month: '2-digit',
    day: 'numeric',
})} — ${intl.formatDate(to, {
    month: '2-digit',
    day: 'numeric',
})}`;
const WorkingHours = ({ workloads, week, summary, onWeekChange }) => {
    const intl = useIntl();
    const currencyCode = useSelector(getCity)?.currencyCode;
    const { dateFnsLocale } = useContext(IntlContext);
    const paginateWeek = useCallback((mode) => () => {
        if (week.from && week.to) {
            const changeWeek = mode === 'next' ? addWeeks : subWeeks;
            onWeekChange({
                from: changeWeek(week.from, 1),
                to: changeWeek(week.to, 1),
            });
        }
    }, [onWeekChange, week]);
    const paginateToPrevWeek = paginateWeek('prev');
    const paginateToNextWeek = paginateWeek('next');
    const isNextButtonDisabled = week.from ? isThisWeek(week.from) : false;
    const weekSelectorTitle = useMemo(() => {
        if (week.from && week.to) {
            return getButtonTitle(week.from, week.to, intl);
        }
        return '';
    }, [week, intl]);
    const titles = useMemo(() => [
        [
            'totalTime',
            intl.formatMessage({ id: "kOQ0td", defaultMessage: "Total hours online" }),
        ],
        [
            'weekAmount',
            intl.formatMessage({ id: "93cxnK", defaultMessage: "Week amount" }),
        ],
    ], [intl]);
    const values = useMemo(() => ({
        totalTime: getTotalTime(summary?.totalMinutes || 0, dateFnsLocale),
        weekAmount: intl.formatNumber(summary?.amount || 0, {
            style: 'currency',
            currency: currencyCode,
        }),
    }), [intl, summary, dateFnsLocale, currencyCode]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.controls, children: [_jsx(Button, { icon: _jsx(Icon, { icon: "arrow-back" }), onClick: paginateToPrevWeek, minimal: true, children: _jsx("span", { className: s.paginationButtonLabel, children: _jsx(FormattedMessage, { id: '10Kel6', defaultMessage: 'Previous week' }) }) }), _jsx(WeekSelector, { buttonTitle: weekSelectorTitle, children: _jsx(WeekPicker, { week: week, onWeekChange: onWeekChange }) }), _jsx(Button, { rightIcon: _jsx(Icon, { icon: "arrow-next" }), onClick: paginateToNextWeek, minimal: true, disabled: isNextButtonDisabled, children: _jsx("span", { className: s.paginationButtonLabel, children: _jsx(FormattedMessage, { id: 'nlzx0B', defaultMessage: 'Next week' }) }) })] }), _jsx(WorkingHoursCalendar, { workloads: workloads }), _jsx(DescriptionList, { title: intl.formatMessage({ id: "ilje8/", defaultMessage: "Statistics" }), values: values, titles: titles })] }));
};
export { WorkingHours };
