import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import isFuture from 'date-fns/isFuture';
import isDate from 'date-fns/isDate';
import { SelectField } from '../../../src-common/view/adapters/reactHookForm/SelectField';
import { DateField } from '../../../src-common/view/adapters/reactHookForm/DateField';
import { TextField } from '../../../src-common/view/adapters/reactHookForm/TextField';
import { Button } from '../../../src-common/view/adapters/Button';
import { DocsList, } from '../../../src-common/view/components/DocsList';
import { Section } from '../../shared/view/Section';
import { BottomButtons } from '../../shared/view/BottomButtons';
import { CountryCode } from '../../shared/models/country-code';
import s from './styles.css';
const VehicleFormView = memo(({ isSubmitting, onDocListItemClick, docs, onSubmit, textSubmitButton, countryCode, control, options, }) => {
    const intl = useIntl();
    return (_jsxs("form", { onSubmit: onSubmit, children: [_jsxs(Section, { children: [_jsx(SelectField, { control: control, name: "make", title: intl.formatMessage({ id: "bXCiK6", defaultMessage: "MAKE" }), items: options.makes, isRequired: true }), _jsx(SelectField, { control: control, name: "model", title: intl.formatMessage({ id: "kgzpAd", defaultMessage: "MODEL" }), items: options.models, isRequired: true }), _jsx(SelectField, { control: control, name: "engineType", title: intl.formatMessage({ id: "global_vehicle_info_engine", defaultMessage: "Engine" }), items: options.engines, isRequired: options.engines.length > 0 }), _jsx(SelectField, { control: control, name: "generation", title: intl.formatMessage({ id: "Xnyjxj", defaultMessage: "Generation" }), items: options.generations, isRequired: options.generations.length > 0 }), _jsx(SelectField, { control: control, name: "year", title: intl.formatMessage({ id: "GJizmK", defaultMessage: "YEAR" }), items: options.years, isRequired: true }), _jsx(SelectField, { control: control, name: "color", title: intl.formatMessage({ id: "q0A0Yx", defaultMessage: "VEHICLE COLOUR" }), items: options.colors, isRequired: true }), _jsx(SelectField, { control: control, name: "interiorColor", title: intl.formatMessage({ id: "1Udahz", defaultMessage: "INTERIOR COLOUR" }), items: options.interiorColors, isRequired: true }), _jsx(SelectField, { control: control, name: "wheelbase", title: intl.formatMessage({ id: "58jrmh", defaultMessage: "Wheelbase" }), description: intl.formatMessage({ id: "t2mugL", defaultMessage: "You can find this information in your logbook under \"Model\". For example, S580 L is a Long." }), items: options.wheelbases, isRequired: options.wheelbases.length > 0 }), _jsx(DateField, { control: control, name: "registrationDate", title: intl.formatMessage({ id: "J7FGJZ", defaultMessage: "DATE OF FIRST REGISTRATION" }), isRequired: true, rules: {
                            validate: {
                                notInTheFuture: value => {
                                    if (isDate(value) && isFuture(value)) {
                                        return intl.formatMessage({ id: "pLBW9Q", defaultMessage: "Date cannot be in the future." });
                                    }
                                },
                            },
                        } }), countryCode === CountryCode.GreatBritain && (_jsx(SelectField, { control: control, name: "licensingAuthority", title: intl.formatMessage({ id: "KgwE9+", defaultMessage: "Licensing authority" }), description: intl.formatMessage({ id: "af06K1", defaultMessage: "Same as the chauffeur\u2019s licensing authority." }), items: options.licensingAuthorities, isRequired: true })), _jsx(TextField, { control: control, name: "licensePlate", title: intl.formatMessage({ id: "+fhe3u", defaultMessage: "LICENSE PLATE" }), placeholder: intl.formatMessage({ id: "SVUpUP", defaultMessage: "AA00AAA" }), isRequired: true, description: intl.formatMessage({ id: "onboarding_vehicle_info_licens_plate_description", defaultMessage: "Please enter the license plate without spaces and additional characters" }) }), _jsx(TextField, { control: control, name: "vin", title: intl.formatMessage({ id: "MRWmN/", defaultMessage: "VIN" }), isRequired: true }), countryCode === CountryCode.GreatBritain && (_jsx(TextField, { control: control, name: "PHV", title: intl.formatMessage({ id: "FYXoly", defaultMessage: "Private Hire Vehicle License Number" }), placeholder: "12345678", description: intl.formatMessage({ id: "b84gsv", defaultMessage: "Full PHV licence number including all digits." }), isRequired: true, rules: {
                            pattern: {
                                value: /^([a-z0-9]+)$/i,
                                message: intl.formatMessage({ id: "2LPalV", defaultMessage: "Invalid PHV." }),
                            },
                        } })), _jsx(DocsList, { title: intl.formatMessage({ id: "pytlFK", defaultMessage: "ADD DOCUMENTS" }), className: s.docs, docs: docs, onChange: onDocListItemClick, isStatusHidden: true })] }), _jsx(BottomButtons, { children: _jsx(Button, { type: "submit", isPending: isSubmitting, children: textSubmitButton || (_jsx(FormattedMessage, { id: '6jg2Fp', defaultMessage: 'NEXT' })) }) })] }));
});
VehicleFormView.displayName = 'VehicleFormView';
export { VehicleFormView };
