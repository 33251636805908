import { jsx as _jsx } from "react/jsx-runtime";
import { eventTracking } from '../../../services/event-tracking';
const getTestCard = ({ cardStatus, actionTitle, language, }) => (test) => {
    const title = test.localizedTitle[language] || test.title;
    const description = test.localizedDescription[language] || test.description;
    return {
        id: `test-${test.id}`,
        isBlocked: false,
        cardStatus,
        title,
        desc: _jsx("span", { dangerouslySetInnerHTML: { __html: description } }),
        actionTitle,
        actionTracker: () => {
            eventTracking.trackSnowplow({
                event: 'Onboarding - Test - Open',
                name: test.title,
                id: test.id,
            });
        },
        actionExternalLink: test.eLearningModuleLink,
    };
};
const createTestCards = ({ myDriver, allTestDefinitions, }, intl, language) => {
    if (!myDriver) {
        return [];
    }
    const actionTitle = intl.formatMessage({ id: "a/BSJt", defaultMessage: "Start the module" });
    if (!myDriver) {
        return [];
    }
    const passedTests = allTestDefinitions.filter(test => myDriver.testStatuses[test.id] === 'passed');
    const requiredTests = allTestDefinitions.filter(test => myDriver.testStatuses[test.id] === 'pending');
    const rejectedTests = allTestDefinitions.filter(test => myDriver.testStatuses[test.id] === 'failed');
    return [
        ...passedTests.map(getTestCard({ cardStatus: 'done', actionTitle, language })),
        ...rejectedTests.map(getTestCard({ cardStatus: 'rejected', actionTitle, language })),
        ...requiredTests.map(getTestCard({ cardStatus: 'todo', actionTitle, language })),
    ];
};
export { createTestCards };
