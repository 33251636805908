import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import { forwardInputRef } from '../types/input';
import { isSubList, ListInput } from '../ListInput';
import { InputBase } from '../InputBase';
import { SimpleDropdown } from '../SimpleDropdown';
import { useMergedRef } from '../utils/useMergedRef';
import { Icon } from '../Icon';
import s from './index.css';
const findTitle = (items, value) => {
    for (const item of items) {
        if (isSubList(item)) {
            const found = findTitle(item.items, value);
            if (found) {
                return found;
            }
        }
        else {
            if (item.value === value) {
                return item.title;
            }
        }
    }
    return undefined;
};
const RightIcon = ({ showClearButton, handleRemove, isExpanded, isDisabled, }) => {
    if (showClearButton) {
        if (isDisabled) {
            return null;
        }
        return (_jsx("button", { onClick: handleRemove, className: s.removeButton, children: _jsx(Icon, { size: "14px", icon: 'x-circle-filled', className: s.removeIcon }) }));
    }
    return (_jsx(Icon, { size: "14px", icon: 'chevron-to-end', className: cn(s.arrow, { [s.isRotated]: isExpanded }) }));
};
const SelectInput = forwardInputRef((props, ref) => {
    const { value: inputValue, items, name, onChange, hasError, isDisabled, placeholder, isExpandedInitially = false, Dropdown = SimpleDropdown, dataTestId = 'select', isClearable = false, } = props;
    const showClearButton = Boolean(isClearable && inputValue);
    const inputMutableRef = useRef(null);
    const mergedRef = useMergedRef(ref, inputMutableRef);
    const [isExpanded, setIsExpanded] = useState(isExpandedInitially);
    const setIsCollapsed = useCallback(() => {
        inputMutableRef.current?.focus();
        setIsExpanded(false);
    }, [inputMutableRef]);
    const handleClick = useCallback(() => {
        if (showClearButton || isDisabled) {
            return;
        }
        setIsExpanded(!isExpanded);
    }, [isDisabled, isExpanded, showClearButton]);
    const handleChange = useCallback((event, { value }) => {
        onChange(event, { name, value: value });
        setIsCollapsed();
    }, [name, onChange, setIsCollapsed]);
    const handleRemove = () => {
        onChange(undefined, { name, value: '' });
    };
    const listRef = useRef(null);
    const handleKeyDown = useCallback((event) => {
        if ((isExpanded && event.key === 'ArrowUp') ||
            event.key === 'ArrowDown' ||
            event.key === 'Tab') {
            requestAnimationFrame(() => listRef.current?.focus());
        }
    }, [isExpanded]);
    return (_jsx(Dropdown, { dataTestId: `${dataTestId}__dropdown`, onRequireClose: setIsCollapsed, target: _jsx(InputBase, { dataTestId: `${dataTestId}__input`, disabled: isDisabled, hasError: hasError, hasFocusHighlight: isExpanded, value: findTitle(items, inputValue) ?? '', type: "button", onClick: handleClick, ref: mergedRef, onKeyDown: handleKeyDown, className: cn({ [s.button]: !showClearButton }), placeholder: placeholder, endElement: _jsx(RightIcon, { handleRemove: handleRemove, showClearButton: showClearButton, isDisabled: isDisabled, isExpanded: isExpanded }) }), targetRef: inputMutableRef, isExpanded: isExpanded, children: isExpanded && (_jsx("div", { className: s.dropdownContent, "data-test-id": `${dataTestId}__expanded-items`, children: _jsx(ListInput, { items: items, onChange: handleChange, value: inputValue, ref: listRef, name: name }) })) }));
});
export { SelectInput };
