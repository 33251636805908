import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setCars } from '../actions';
import { carAPI } from '../../api';
const loadCars = createAsyncThunk(`${namespace}/load-cars`, async ({ limit, offset }, { dispatch }) => {
    const cars = await carAPI.fetchCars({ limit, offset });
    dispatch(setCars(cars));
    return cars;
});
export { loadCars };
