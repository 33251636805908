import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { ListLayoutView } from '../../shared/view/ListLayout/ListLayout';
import { MobileListItem } from '../../shared/view/ListLayout/MobileListItem';
import { Icon } from '../../../src-common/view/components/Icon';
import { Tag } from '../../shared/view/Tag';
import { StatusTag } from '../../shared/view/StatusTag';
import { WARNING_STATUSES } from '../../shared/models/access-status';
import { getStatusTitle } from '../../shared/view/StatusTag/getStatusTitle';
import { AccessStatus } from '../../shared/view/AccessStatus';
import s from './styles.css';
const getFullModel = (car) => [car.make, car.model].join(' ');
const columns = [
    {
        style: {
            width: '25%',
        },
        renderHeader: () => (_jsx(FormattedMessage, { id: 'MuMIc6', defaultMessage: 'Model' })),
        renderCell: getFullModel,
    },
    {
        style: {
            width: '15%',
        },
        renderHeader: () => (_jsx(FormattedMessage, { id: 'dnFIhm', defaultMessage: 'License plate' })),
        renderCell: car => car.licensePlate,
    },
    {
        renderHeader: () => (_jsx(FormattedMessage, { id: 'rTlW3o', defaultMessage: 'Tags' })),
        renderCell: car => (_jsx("div", { className: s.tags, children: car.tags?.map(tag => (_jsx(Tag, { children: tag }, tag))) })),
    },
    {
        style: {
            width: '80px',
        },
        renderHeader: () => (_jsx(FormattedMessage, { id: 'aRouek', defaultMessage: 'Status' })),
        renderCell: car => (_jsxs("div", { className: s.status, children: [_jsx(StatusTag, { status: car.onlineStatus }), _jsx(AccessStatus, { status: car.accessStatus })] })),
    },
];
const hasWarning = (car) => WARNING_STATUSES.includes(car.accessStatus);
const GROUP_ORDER = ['online', 'busy', 'offline'];
const View = ({ cars, fetchCars, onClickNew, isLoading = false }) => {
    const history = useHistory();
    const onClickRow = useCallback((car) => car && history.push(`/vehicles/${car.id}`), [history]);
    return (_jsx(ListLayoutView, { wrapperClassName: s.wrapper, title: _jsx(FormattedMessage, { id: 'MiPb62', defaultMessage: 'Vehicles' }), data: cars.items, total: cars.total, limit: cars.limit, columns: columns, getEntityId: car => String(car.id), onFetchData: fetchCars, shouldBeEmptyWithoutFilters: false, getEntityGroup: car => car.onlineStatus, groupOrder: GROUP_ORDER, translateGroupName: getStatusTitle, renderItem: car => (_jsx(Link, { to: `/vehicles/${car.id}`, children: _jsx(MobileListItem, { title: getFullModel(car), note: car.licensePlate, right: hasWarning(car) ? (_jsx(Icon, { icon: 'warning', className: s.red, size: '22px' })) : (_jsx(Icon, { icon: 'chevron-to-end' })) }) })), onClickRow: onClickRow, action: useMemo(() => ({
            onClick: onClickNew,
            title: (_jsx(FormattedMessage, { id: 'm9K3yy', defaultMessage: 'Add new' })),
        }), [onClickNew]), isLoading: isLoading }));
};
export { View };
