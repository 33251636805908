import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { useCallback } from 'react';
import { Icon } from '../../../../src-common/view/components/Icon';
import s from './DriverPartnerContract.css';
const DriverPartnerContract = ({ value, name, onChange }) => {
    const handleClickRevoke = useCallback(() => {
        onChange(false, { value: false, name });
    }, [name, onChange]);
    const handleClickApprove = useCallback(() => {
        onChange(true, { value: true, name });
    }, [name, onChange]);
    return (_jsxs("div", { className: s.root, children: [_jsxs("div", { className: s.terms, children: [_jsx(FormattedMessage, { id: 'djB/+k', defaultMessage: '\u042F \u0433\u0430\u0440\u0430\u043D\u0442\u0438\u0440\u0443\u044E \u043D\u0430\u043B\u0438\u0447\u0438\u0435 \u0422\u0440\u0443\u0434\u043E\u0432\u043E\u0433\u043E \u0434\u043E\u0433\u043E\u0432\u043E\u0440\u0430 \u0441 \u044D\u0442\u0438\u043C \u0432\u043E\u0434\u0438\u0442\u0435\u043B\u0435\u043C \u0438 \u0441\u0440\u0430\u0437\u0443 \u0436\u0435 \u0443\u0432\u0435\u0434\u043E\u043C\u043B\u044E Wheely \u043F\u0440\u0438 \u0435\u0433\u043E \u0440\u0430\u0441\u0442\u043E\u0440\u0436\u0435\u043D\u0438\u0438' }), value && (_jsx("button", { className: s.revoke, onClick: handleClickRevoke, children: _jsx(FormattedMessage, { id: 'ru3jPM', defaultMessage: '\u041E\u0442\u043E\u0437\u0432\u0430\u0442\u044C' }) }))] }), _jsx("div", { className: s.right, children: value ? (_jsx(Icon, { icon: 'checkmark-in-circle', color: '#4cd964', size: "24px" })) : (_jsx("button", { className: s.approve, onClick: handleClickApprove, children: _jsx(FormattedMessage, { id: 'DP6fES', defaultMessage: '\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C' }) })) })] }));
};
export { DriverPartnerContract };
