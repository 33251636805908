import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import endOfWeek from 'date-fns/endOfWeek';
import { LocalizedWeekPicker } from '../../../shared/view/LocalizedWeekPicker';
import { WeekSelectorContext } from './WeekSelector';
const WeekPicker = ({ week, onWeekChange }) => {
    const lastValidDay = endOfWeek(new Date(), { weekStartsOn: 1 });
    const { closePopover } = useContext(WeekSelectorContext);
    const handleWeekChange = useCallback((days) => {
        onWeekChange(days);
        closePopover();
    }, [onWeekChange, closePopover]);
    return (_jsx(LocalizedWeekPicker, { value: week, onWeekChange: handleWeekChange, toMonth: new Date(), disabledDays: {
            after: lastValidDay,
        } }));
};
export { WeekPicker };
