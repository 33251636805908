import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPartner } from '../../../../modules/partner/store/selectors';
import { submitPayoutsStep } from '../../../../modules/onboarding/actions/submitPayoutsStep';
import { updatePartnerType } from '../../../../modules/onboarding/actions/updatePartnerType';
import { RUPartnerType, SELF_EMPLOYED, } from '../../../../modules/partner/model/partnerType';
import { loadPartnerDocDefinitions } from '../../../../modules/partner/actions/async/loadDocDefinitions';
import { prepareUploadPartnerForm } from '../../../../modules/partner/actions/async/preparePartnerUploadForm';
import { partnerUploadSelectors } from '../../../../modules/partner/store/uploadSlice';
import { useUpdateEffect } from '../../../../shared/hooks/useUpdateEffect';
import { useForm } from '../../../../shared/view/Form';
import { CountryCode } from '../../../../shared/models/country-code';
import { eventTracking } from '../../../../services/event-tracking';
import { PaymentTypeView } from './view';
const getInitialPartnerName = (partnerType, partner) => {
    if (partnerType === RUPartnerType.ip) {
        return `ИП ${[partner.lastName, partner.firstName, partner.middleName]
            .filter(Boolean)
            .join(' ')}`;
    }
    return '';
};
const getInitialPartnerType = (partner) => {
    if (partner.partnerType) {
        return partner.partnerType;
    }
    if (partner.countryCode === CountryCode.Russia) {
        return RUPartnerType.ip;
    }
    return SELF_EMPLOYED;
};
const mapPartnerToForm = (partner) => {
    const { legalName, vatRegNo, companyRegNumber, legalAddress1, inn, legalKpp } = partner;
    const partnerType = getInitialPartnerType(partner);
    return {
        partnerType,
        companyName: legalName || getInitialPartnerName(partnerType, partner),
        companyAddress: legalAddress1,
        vatRegNo,
        companyRegNumber,
        inn,
        kpp: legalKpp,
    };
};
const PaymentType = ({ onSwitchScreen }) => {
    const dispatch = useDispatch();
    const handleClickDocListItem = useCallback((event, doc) => dispatch(prepareUploadPartnerForm({
        docType: doc.docType,
        onSuccess: () => onSwitchScreen('UPLOAD'),
    })), [dispatch, onSwitchScreen]);
    const partner = useSelector(getPartner);
    const postForm = useCallback(async (formValues) => {
        const resultAction = await dispatch(submitPayoutsStep(formValues));
        if (submitPayoutsStep.fulfilled.match(resultAction)) {
            const { isSucceed, result = {} } = resultAction.payload;
            if (isSucceed && !result.validationErrors) {
                eventTracking.trackSnowplow({
                    event: 'Onboarding - Payouts - Success',
                    type: formValues.partnerType,
                });
                onSwitchScreen('NEXT');
            }
            return result;
        }
        return {};
    }, [dispatch, onSwitchScreen]);
    const { handleSubmit, form: { setValue, control, watch, formState: { isSubmitting }, }, } = useForm({
        preSaveStore: {
            storeId: `payoutsStepForm-${partner.id}`,
            type: 'memory',
        },
        onSubmit: postForm,
        defaultValues: mapPartnerToForm(partner),
    });
    const partnerType = watch('partnerType');
    useEffect(() => {
        dispatch(loadPartnerDocDefinitions(partnerType));
        eventTracking.trackSnowplow({
            event: 'Onboarding - Payouts - Switch',
            type: partnerType,
        });
    }, [dispatch, partnerType]);
    useUpdateEffect(() => {
        setValue('companyName', getInitialPartnerName(partnerType, partner));
        dispatch(updatePartnerType(partnerType));
    }, [dispatch, partnerType]);
    return (_jsx("form", { onSubmit: handleSubmit, children: _jsx(PaymentTypeView, { isSubmitting: isSubmitting, docs: useSelector(partnerUploadSelectors.getDocsList), onDocListItemClick: handleClickDocListItem, control: control }) }));
};
export { PaymentType };
