import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionList } from '../../shared/view/DescriptionList';
import { IntlContext } from '../../i18n';
import { CountryCode } from '../../shared/models/country-code';
import s from './styles.css';
const mapToDriverListValues = (partner, driver) => ({
    name: [partner.firstName, partner.middleName, partner.lastName].join(' '),
    birthday: driver.birthday,
    phone: partner.phone,
    email: partner.email,
    licenseNumber: driver.licenseNumber,
    insurance: driver.ukNationalInsurance,
    address: driver.homeAddress,
    inn: partner.personalInn,
    licensingAuthority: driver.licensingAuthority,
});
const skipEmpty = (item) => item !== false;
const ChauffeurInfo = ({ className, driver, partner, children, onClickEdit }) => {
    const intl = useIntl();
    const { region } = useContext(IntlContext);
    const chauffeurTitles = useMemo(() => {
        const allTitles = [
            [
                'name',
                intl.formatMessage({ id: "qME1s+", defaultMessage: "Name" }),
            ],
            [
                'birthday',
                intl.formatMessage({ id: "BGzd2M", defaultMessage: "Date of birth" }),
            ],
            [
                'phone',
                intl.formatMessage({ id: "emlGDr", defaultMessage: "Phone" }),
            ],
            [
                'email',
                intl.formatMessage({ id: "+nCb+d", defaultMessage: "Email" }),
            ],
            region === CountryCode.France && [
                'licenseNumber',
                intl.formatMessage({ id: "MpXmtX", defaultMessage: "VTC Number" }),
            ],
            region === CountryCode.GreatBritain && [
                'licensingAuthority',
                intl.formatMessage({ id: "RR6X3y", defaultMessage: "Licensing authority" }),
            ],
            region === CountryCode.GreatBritain && [
                'licenseNumber',
                intl.formatMessage({ id: "uK4lBu", defaultMessage: "Licence number" }),
            ],
            region === CountryCode.GreatBritain && [
                'insurance',
                intl.formatMessage({ id: "XC07/C", defaultMessage: "Insurance number" }),
            ],
            region === CountryCode.Russia && [
                'inn',
                intl.formatMessage({ id: "K9H/GO", defaultMessage: "INN" }),
            ],
            [
                'address',
                intl.formatMessage({ id: "4TxXBI", defaultMessage: "Home address" }),
            ],
        ];
        return allTitles.filter(skipEmpty);
    }, [intl, region]);
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "90lmj0", defaultMessage: "Chauffeur info" }), className: className, values: useMemo(() => mapToDriverListValues(partner, driver), [partner, driver]), titles: chauffeurTitles, action: region === CountryCode.GreatBritain &&
            driver.accessStatus === 'active' && (_jsx("span", { onClick: onClickEdit, className: s.editButton, children: intl.formatMessage({ id: "zavkZT", defaultMessage: "Edit" }) })), children: children }));
};
export { ChauffeurInfo };
