import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { getAllCars, getCarForm } from '../../store/selectors';
import { prepareCarForm } from './prepare-car-form';
const reloadFormForCurrentCar = createAsyncThunk(`${namespace}/reload-form-for-current-car`, async ({ carId }, { dispatch, getState }) => {
    const formCarId = getCarForm(getState()).id;
    const firstCarId = getAllCars(getState())[0]?.id;
    await dispatch(prepareCarForm({ carId: carId || formCarId || firstCarId })).unwrap();
});
export { reloadFormForCurrentCar };
