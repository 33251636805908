import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SelectField as OriginalSelectField } from '../SelectField';
const SelectField = ({ description, name, rules, control, defaultValue, shouldUnregister, isRequired, ...selectProps }) => {
    const intl = useIntl();
    const { field: { ref, ...field }, fieldState: { error }, } = useController({
        name,
        rules: {
            required: isRequired
                ? {
                    value: true,
                    message: intl.formatMessage({ id: "Q4ipsb", defaultMessage: "Field is required." }),
                }
                : undefined,
            ...rules,
        },
        control,
        defaultValue,
        shouldUnregister,
    });
    const handleChange = useCallback((_, { value }) => {
        // event doesn't contain value (e.target.value = undefined)
        field.onChange(value);
    }, [field]);
    return (_jsx(OriginalSelectField, { ...field, ...selectProps, inputRef: ref, onChange: handleChange, value: field.value, error: error?.message, description: description }));
};
export { SelectField };
