import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setModels } from '../actions';
import { carAPI } from '../../api';
const loadAllModels = createAsyncThunk(`${namespace}/load-all-models`, async (params, { dispatch }) => {
    if (!params) {
        return [];
    }
    const { id, name } = params;
    const models = await carAPI.fetchAllModels(id, name);
    dispatch(setModels({ models }));
    return models;
});
export { loadAllModels };
