class MemoryStorage {
    constructor() {
        this.map = new Map();
    }
    get length() {
        return this.map.size;
    }
    key(idx) {
        return Array.from(this.map.keys())[idx];
    }
    getItem(key) {
        return this.map.get(key);
    }
    setItem(key, value) {
        return this.map.set(key, value);
    }
    removeItem(key) {
        return this.map.delete(key);
    }
    clear() {
        return this.map.clear();
    }
}
export { MemoryStorage };
