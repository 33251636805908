import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback } from 'react';
import cn from 'classnames';
import { Loader } from './Loader';
import s from './MobileList.css';
const MobileList = ({ action, data, getEntityId, onClickRow, renderItem, getEntityGroup, groupOrder, translateGroupName = (str) => str, isLoading = false, }) => {
    const handleClickRow = useCallback((event) => {
        if (!onClickRow) {
            return;
        }
        const id = event.currentTarget.dataset.entityId ?? '';
        const entity = data.find(item => getEntityId(item) === id);
        onClickRow(entity);
    }, [data, getEntityId, onClickRow]);
    if (!getEntityGroup || !groupOrder) {
        return (_jsxs("ul", { className: s.list, children: [isLoading && _jsx(Loader, {}), data.map((item, idx) => (_jsx("li", { className: cn(s.item, { [s.clickable]: onClickRow }), onClick: handleClickRow, "data-entity-id": getEntityId(item), children: renderItem(item, idx) }, idx)))] }));
    }
    const grouped = getEntityGroup &&
        data.reduce((result, entity) => {
            const group = getEntityGroup(entity);
            result[group] = result[group] ?? [];
            result[group].push(entity);
            return result;
        }, {});
    let isActionRendered = false;
    return (_jsxs("ul", { className: s.list, children: [isLoading && _jsx(Loader, {}), groupOrder.map(groupName => {
                if (!grouped[groupName]) {
                    return null;
                }
                return (_jsxs(Fragment, { children: [_jsxs("div", { className: s.groupRow, children: [_jsx("h6", { className: s.group, children: translateGroupName(groupName) }), (() => {
                                    if (!action || isActionRendered) {
                                        return null;
                                    }
                                    isActionRendered = true;
                                    return (_jsx("button", { className: s.action, onClick: action.onClick, children: action.title }));
                                })()] }), grouped[groupName]?.map((item, idx) => (_jsx("li", { className: cn(s.item, { [s.clickable]: onClickRow }), onClick: handleClickRow, "data-entity-id": getEntityId(item), children: renderItem(item, idx) }, `${groupName}-${idx}`)))] }, groupName));
            })] }));
};
export { MobileList };
