import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../shared/view/Form';
import { loadDriverById } from '../../modules/driver/actions/async/load-driver-by-id';
import { getCurrentDriver } from '../../modules/driver/store/selectors';
import { updateDriverInfo } from '../../modules/driver/actions/async/updateDriver';
import { getCodeAndNumberFromPhone } from '../../shared/utils/phoneUtils';
import { EditPersonalInfoView } from './view';
const ROUTE = '/chauffeurs/:driverId/edit-info';
const EditPersonalInfo = ({ onNavigateBack }) => {
    const driverId = useRouteMatch(ROUTE)?.params?.driverId;
    const dispatch = useDispatch();
    const driver = useSelector(getCurrentDriver);
    const navigateBack = useCallback(() => onNavigateBack(`/chauffeurs/${driverId}`), [driverId, onNavigateBack]);
    const postForm = useCallback(async (formValues) => {
        const { isSucceed, result = {} } = await dispatch(updateDriverInfo(formValues)).unwrap();
        if (isSucceed && !result.validationErrors && !result.baseErrors) {
            navigateBack();
        }
        return result;
    }, [dispatch, navigateBack]);
    const { number, code } = getCodeAndNumberFromPhone(driver?.phone || '');
    const { handleSubmit, form: { control, formState: { isSubmitting }, resetField, }, } = useForm({
        onSubmit: postForm,
        defaultValues: {
            phoneNumber: number,
            phoneCode: code,
        },
    });
    useEffect(() => {
        if (!driverId) {
            return;
        }
        dispatch(loadDriverById(driverId));
    }, [dispatch, driverId, resetField]);
    useEffect(() => {
        resetField('phoneCode', { defaultValue: code });
        resetField('phoneNumber', { defaultValue: number });
    }, [resetField, number, code]);
    return (_jsx(EditPersonalInfoView, { control: control, isSubmitting: isSubmitting, onSubmit: handleSubmit, onNavigateBack: navigateBack }));
};
export { EditPersonalInfo };
