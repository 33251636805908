import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from '@wheely/web-auth-lib';
import { showError } from '../../app/actions';
import { getPartner, getTestDefinitions, isTestDefinitionsReady, } from '../../modules/partner/store/selectors';
import { getUrlForTest } from '../Todo/hooks/getUrlForTest';
import { getMyDriver } from '../../modules/driver/store/selectors';
import { loadUser } from '../../modules/user/actions';
import { loadCities } from '../../modules/common/store/actions';
import { loadPartner } from '../../modules/partner/actions/async/loadPartner';
import { setEmptyPartner } from '../../modules/partner/actions/async/setEmptyPartner';
import { loadDriver } from '../../modules/driver/actions/async/load-driver';
import { selectIsChauffeur } from '../../modules/user/store/selectors';
const TypeFormRedirect = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth();
    const isChauffeur = useSelector(selectIsChauffeur);
    const driver = useSelector(getMyDriver);
    const partner = useSelector(getPartner);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [isLoadingComplete, setIsLoadingComplete] = useState(false);
    useEffect(() => {
        const loadUserAndCityData = async () => {
            await Promise.all([dispatch(loadUser()).unwrap(), dispatch(loadCities()).unwrap()]);
            setIsLoadingUser(false);
        };
        isAuthenticated && loadUserAndCityData();
    }, [dispatch, isAuthenticated]);
    useEffect(() => {
        const loadPartnerAndDriverData = async () => {
            setIsLoadingComplete(true);
            if (isChauffeur) {
                await dispatch(setEmptyPartner()).unwrap();
            }
            else {
                await dispatch(loadPartner()).unwrap();
            }
            await dispatch(loadDriver()).unwrap();
            setIsLoadingComplete(false);
        };
        if (!isLoadingUser) {
            loadPartnerAndDriverData();
        }
    }, [dispatch, isChauffeur, isLoadingUser]);
    const { testId } = useParams();
    const isTestsReady = useSelector(isTestDefinitionsReady);
    const tests = useSelector(getTestDefinitions);
    const test = tests.find(current => current.id === testId);
    const history = useHistory();
    useEffect(() => {
        !isAuthenticated && history.replaceWithRollback('/login');
    }, [isAuthenticated, history]);
    useEffect(() => {
        if (!isTestsReady || !partner.cityName || isLoadingComplete) {
            return;
        }
        // test not found
        if (!test || !driver.id) {
            // hard reload
            dispatch(showError(intl.formatMessage({ id: "VMylfX", defaultMessage: "You are not allowed to perform this action." })));
            history.push('/');
            return;
        }
        window.location.href = getUrlForTest(test, {
            driverId: driver.id,
            cityName: partner.cityName,
        });
    }, [intl, driver.id, partner.cityName, isTestsReady, test, dispatch, history, isLoadingComplete]);
    return _jsx(_Fragment, {});
};
export { TypeFormRedirect };
