import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { carAPI } from '../../api';
import { setCar, updateForm } from '../actions';
import { getCarForm } from '../../store/selectors';
let lastRequestId;
const createCar = createAsyncThunk(`${namespace}/create-car`, async ({ onSuccess, formValues }, { getState, dispatch, requestId }) => {
    lastRequestId = requestId;
    const carId = getCarForm(getState()).id;
    const [updatedCar, serverValidation] = await (carId
        ? carAPI.updateCar({
            id: carId,
            ...formValues,
        })
        : carAPI.createCar(formValues));
    if (lastRequestId !== requestId) {
        return { isReplaced: true };
    }
    if (serverValidation) {
        return {
            isSucceed: false,
            result: {
                validationErrors: serverValidation,
            },
        };
    }
    dispatch(updateForm(updatedCar));
    dispatch(setCar(updatedCar));
    onSuccess?.(updatedCar.id);
    return { isSucceed: true };
});
export { createCar };
