import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H3, useDialog } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../src-common/view/adapters/Button';
import s from './Blocking.css';
const DismissDriverDialog = ({ onDismiss }) => (_jsxs("div", { className: s.root, children: [_jsx(FormattedMessage, { id: '4NM/pj', defaultMessage: 'A chauffeur will be deleted from your account. Continue?' }), _jsx("div", { className: s.actions, children: _jsx(Button, { onClick: onDismiss, intent: 'danger', children: _jsx(FormattedMessage, { id: 'LeRDjJ', defaultMessage: 'Yes' }) }) })] }));
const useOpenDismissDriverDialog = () => {
    const { openDialog, closeDialogs } = useDialog();
    const openDismissDriverDialog = ({ onDismiss }) => {
        openDialog(() => _jsx(DismissDriverDialog, { onDismiss: onDismiss }), {
            title: (_jsx(H3, { children: _jsx(FormattedMessage, { id: 'xiyJOg', defaultMessage: 'Confirm' }) })),
            bodyClassName: s.dialog,
        });
    };
    return { closeDismissDriverDialog: closeDialogs, openDismissDriverDialog };
};
export { useOpenDismissDriverDialog, DismissDriverDialog };
