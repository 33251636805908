import { useContext, useMemo } from 'react';
import { CountryCode } from '../../../shared/models/country-code';
import { IntlContext } from '../../../i18n';
import { useBookingActions } from '../../../shared/utils/bookingLinksUtils';
import { permissions } from '../../../app/contexts/permissions';
import { eventTracking } from '../../../services/event-tracking';
import { TEST_NAME_ACADEMY } from './const';
const useAcademyCard = ({ myDriver }, intl) => {
    const { region } = useContext(IntlContext);
    const { openModalAction, goToExternalLinkAction } = useBookingActions({
        region,
        driver: myDriver ?? { id: '', firstName: '', lastName: '', email: '', phone: '' },
    });
    const { can } = useContext(permissions);
    return useMemo(() => {
        if (!myDriver) {
            return [];
        }
        return [
            {
                id: TEST_NAME_ACADEMY,
                cardStatus: myDriver.hasPassedAccreditation ? 'done' : 'todo',
                title: intl.formatMessage({ id: "g4cGRL", defaultMessage: "Enrol at the Wheely academy" }),
                desc: (() => {
                    switch (region) {
                        case CountryCode.ArabEmirates:
                            return intl.formatMessage({ id: "2FoIj9", defaultMessage: "All chauffeurs must complete a 2-day training course at the Wheely Academy." });
                        case CountryCode.France:
                            return intl.formatMessage({ id: "oilrte", defaultMessage: "All chauffeurs must complete a 3-day training course at the Wheely Academy." });
                        case CountryCode.Russia:
                            return intl.formatMessage({ id: "0inrt1", defaultMessage: "All chauffeurs must complete a 2-day training course at the Wheely Academy and provide original documents." });
                        default:
                            return intl.formatMessage({ id: "ezW61a", defaultMessage: "All chauffeurs must complete a 2-day training course at the Wheely Academy." });
                    }
                })(),
                actionTitle: intl.formatMessage({ id: "5f+rcd", defaultMessage: "CHOOSE TIME" }),
                actionTracker: () => {
                    eventTracking.trackSnowplow({
                        event: 'Onboarding - Academy - Open',
                    });
                },
                action: openModalAction,
                actionExternalLink: goToExternalLinkAction,
                isHiddenIfPartnerIsActive: true,
                isBlocked: !can.bookAnAccreditation,
            },
        ];
    }, [myDriver, intl, region, openModalAction, goToExternalLinkAction, can.bookAnAccreditation]);
};
export { useAcademyCard };
