import { jsx as _jsx } from "react/jsx-runtime";
import { eventTracking } from '../../../services/event-tracking';
const createBookCarAppointmentCard = (intl, links) => ({
    id: 'car-book-an-appointment',
    cardStatus: 'todo',
    desc: intl.formatMessage({ id: "FqgSlN", defaultMessage: "Confirm <a>vehicle requirements.</a> The car must be inspected at the office." }, {
        a: (text) => (_jsx("a", { href: links.requirementsForCar, target: "_blank", rel: "noreferrer", children: text })),
    }),
    title: intl.formatMessage({ id: "BNJ8GD", defaultMessage: "book an appointment" }),
    actionTracker: () => {
        eventTracking.track({
            event: 'Onboarding - Car - Inspection - Open',
        });
    },
    actionExternalLink: links.bookCarInspection,
    actionTitle: intl.formatMessage({ id: "1XYOF1", defaultMessage: "Choose time" }),
});
export { createBookCarAppointmentCard };
