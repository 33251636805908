import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setDrivers } from '../actions';
import { driverAPI } from '../../api';
const loadDrivers = createAsyncThunk(`${namespace}/load-drivers`, async ({ limit, offset, query }, { dispatch }) => {
    const drivers = await driverAPI.fetchBriefDrivers({ limit, offset, query });
    dispatch(setDrivers(drivers));
    return drivers;
});
export { loadDrivers };
