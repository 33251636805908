import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import differenceInYears from 'date-fns/differenceInYears';
import { TextField } from '../../../../../../src-common/view/adapters/reactHookForm/TextField';
import { DateField } from '../../../../../../src-common/view/adapters/reactHookForm/DateField';
import { SelectField } from '../../../../../../src-common/view/adapters/reactHookForm/SelectField';
import { DocsList, } from '../../../../../../src-common/view/components/DocsList';
import { getCountryCode } from '../../../../../modules/common/store/selectors';
import { Section } from '../../../../../shared/view/Section';
import { BottomButtons } from '../../../../../shared/view/BottomButtons';
import { Button } from '../../../../../../src-common/view/adapters/Button';
import { CountryCode } from '../../../../../shared/models/country-code';
import { useExternalLinks } from '../../../../../app/hooks/useExternalLinks';
import { useLicensingAuthorityTypes } from '../../../../../shared/hooks/useLicensingAuthorityTypes';
import s from './styles.css';
const DriverForm = ({ isSubmitting, control, docs, onDocListItemClick, }) => {
    const intl = useIntl();
    const links = useExternalLinks();
    const countryCode = useSelector(getCountryCode);
    const licensingAuthorityTypes = useLicensingAuthorityTypes();
    return (_jsxs(_Fragment, { children: [_jsxs(Section, { children: [_jsx(DateField, { name: "birthday", title: intl.formatMessage({ id: "cQB2EL", defaultMessage: "DATE OF BIRTH" }), control: control, isRequired: true, rules: {
                            validate: value => value && differenceInYears(new Date(), value) < 18
                                ? intl.formatMessage({ id: "bdjV3H", defaultMessage: "You need to be at least 18 years of age" })
                                : undefined,
                        } }), countryCode === CountryCode.GreatBritain && (_jsxs(_Fragment, { children: [_jsx(SelectField, { name: "licensingAuthority", title: intl.formatMessage({ id: "/Q8aMx", defaultMessage: "Licensing authority" }), description: intl.formatMessage({ id: "EUTZOe", defaultMessage: "If multiple authorities license you, choose only one. Learn more about license types <a>here</a>." }, {
                                    a: (text) => (_jsx("a", { href: links.licensingAuthorities, target: "_blank", rel: "noreferrer", children: text })),
                                }), control: control, items: licensingAuthorityTypes }), _jsx(TextField, { name: "licenseNumber", title: intl.formatMessage({ id: "pFGlVW", defaultMessage: "Private Hire Driver License Number" }), placeholder: "12345678", description: intl.formatMessage({ id: "NB/idy", defaultMessage: "Full PHD license number from your licensing authority." }), control: control, isRequired: true }), _jsx(TextField, { name: "ukNationalInsurance", title: intl.formatMessage({ id: "N/hPyL", defaultMessage: "NATIONAL INSURANCE NUMBER" }), placeholder: "AB123456C", isRequired: true, rules: {
                                    pattern: {
                                        value: /^\w{2}\d{6}\w$/i,
                                        message: intl.formatMessage({ id: "5rqrKE", defaultMessage: "Wrong national insurance number, ex. AB123456C." }),
                                    },
                                }, control: control })] })), countryCode === CountryCode.France && (_jsx(TextField, { name: "licenseNumber", title: intl.formatMessage({ id: "RdRdnV", defaultMessage: "VTC (PRIVATE HIRE) REGISTRATION NUMBER" }), placeholder: "AAAA00000000", description: intl.formatMessage({ id: "A7nLWH", defaultMessage: "Example: EVTC12345678." }), control: control, isRequired: true })), countryCode === CountryCode.Russia && (_jsx(TextField, { name: "personalInn", title: intl.formatMessage({ id: "JGFKJo", defaultMessage: "Your INN" }), isRequired: true, description: intl.formatMessage({ id: "C9rzp+", defaultMessage: "INN for individuals consists of 12 digits. <link>Where to find?</link>" }, {
                            link: (message) => (_jsx("a", { target: "_blank", rel: "noreferrer", href: links.findInn, children: message })),
                        }), control: control })), _jsx(TextField, { name: "homeAddress", isRequired: true, title: intl.formatMessage({ id: "rx3Bk0", defaultMessage: "HOME ADDRESS" }), control: control }), _jsx(DocsList, { title: intl.formatMessage({ id: "pytlFK", defaultMessage: "ADD DOCUMENTS" }), className: s.docs, onChange: onDocListItemClick, docs: docs, isStatusHidden: true })] }), _jsx(BottomButtons, { children: _jsx(Button, { type: "submit", isPending: isSubmitting, children: _jsx(FormattedMessage, { id: '6jg2Fp', defaultMessage: 'NEXT' }) }) })] }));
};
export { DriverForm };
