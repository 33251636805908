import { jsx as _jsx } from "react/jsx-runtime";
import { SELF_EMPLOYED } from '../../../../modules/partner/model/partnerType';
const ru = (context, intl, links) => {
    const result = [];
    if (context.partner.partnerType === SELF_EMPLOYED) {
        result.push({
            order: -1,
            id: 'get-a-legal-status',
            cardStatus: 'todo',
            title: intl.formatMessage({ id: "pbmyE5", defaultMessage: "Register as an IE or OOO" }),
            desc: intl.formatMessage({ id: "7c0dmo", defaultMessage: "It usually takes five business days to register as an IE (individual entrepreneur) and 3 business days to open an OOO (limited liability company) from the moment you send in the documents. If your status has already been updated, please contact your <a>manager.</a>" }, {
                a: (text) => (_jsx("a", { href: links.contactOperationTeam, target: "_blank", rel: "noreferrer", children: text })),
            }),
            actionTitle: intl.formatMessage({ id: "LuI/dl", defaultMessage: "Go to \u2018Gosuslugi\u2019" }),
            actionExternalLink: links.registerIPorOOO,
        });
    }
    return result;
};
export { ru };
