// https://www.iso.org/iso-3166-country-codes.html
// https://www.iso.org/obp/ui/#search
const CountryCode = {
    GreatBritain: 'GB',
    France: 'FR',
    Russia: 'RU',
    Unknown: '',
    ArabEmirates: 'AE',
};
export { CountryCode };
