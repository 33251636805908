import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setPartner } from '../actions';
import { getEmptyPartner } from '../../model/getEmptyPartner';
import { getUserData } from '../../../user/store/selectors';
const setEmptyPartner = createAsyncThunk(`${namespace}/set-empty-partner`, async (_, { dispatch, getState }) => {
    const me = getUserData(getState());
    const emptyPartner = getEmptyPartner(me);
    dispatch(setPartner(emptyPartner));
    return emptyPartner;
});
export { setEmptyPartner };
