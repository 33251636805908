import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { driverAPI } from '../../api';
import { updateDriver } from '../actions';
const changeContractStatus = createAsyncThunk(`${namespace}/change-contract-status`, async ({ driverId, isActive }, { dispatch }) => {
    if (!driverId) {
        return undefined;
    }
    const driver = await driverAPI.changeContractStatus(driverId, isActive);
    if (driver) {
        dispatch(updateDriver({
            id: driver.id,
            update: { hasContractWithPartner: driver.hasContractWithPartner },
        }));
    }
    return driver;
});
export { changeContractStatus };
