import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { IconCheckmarkCircled } from '@wheely/ui-kit';
import { useController } from 'react-hook-form';
import { CarCell } from '../../../shared/view/CarCell';
import { DataField } from '../../../shared/view/DataField';
import s from './VehicleSelector.css';
const VehicleSelector = ({ vehicles, name, rules, control, defaultValue, shouldUnregister, }) => {
    const { field } = useController({ name, rules, control, defaultValue, shouldUnregister });
    const { ref, value, onChange, name: fieldName } = field;
    const handleChange = useCallback((vehicle) => {
        onChange(vehicle.id);
    }, [onChange]);
    return (_jsx("div", { children: vehicles.map(vehicle => (_jsx("div", { className: s.radio, children: _jsxs("label", { htmlFor: vehicle.id, children: [_jsx("input", { type: "radio", ref: ref, id: vehicle.id, name: fieldName, value: value, onChange: () => handleChange(vehicle) }), _jsx(DataField, { title: _jsx(CarCell, { carId: vehicle.id }), children: vehicle.id === value && (_jsx("div", { className: s.iconWrapper, children: _jsx(IconCheckmarkCircled, { width: 24, height: 24, className: s.icon }) })) })] }) }, vehicle.id))) }));
};
export { VehicleSelector };
