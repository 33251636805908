import * as t from 'io-ts';
import { formData } from '../io-types/form-data';
import { Partner, PartnerInfoByINN, PaymentInfoByBIK, PartnerRequest, PartnerDocDefinition, PartnerDocType, PartnerUploadResult, } from './w4d-partner-types';
const w4dPartnerRemoteAPI = ({ get, apiHost, put, post, postForm }) => ({
    createPartner: {
        path: `${apiHost}/v6/partners`,
        payload: t.type({
            partner: t.type({ city_id: t.string }),
            utm_tags: t.unknown,
        }),
        response: t.union([
            t.type({ partner: Partner, errors: t.undefined }),
            t.type({
                partner: t.undefined,
                errors: t.partial({ partner_exists: t.array(t.string) }),
            }),
        ]),
        method: post,
    },
    readPartner: {
        path: `${apiHost}/v6/partners/self`,
        response: t.union([
            t.type({
                partner: Partner,
                errors: t.undefined,
            }),
            t.type({
                partner: t.undefined,
                errors: t.type({ invalid_grant: t.array(t.string) }),
            }),
        ]),
        method: get,
    },
    updatePartner: {
        path: `${apiHost}/v6/partners/self`,
        response: t.union([
            t.type({
                partner: Partner,
                errors: t.undefined,
            }),
            t.type({
                partner: t.undefined,
                errors: t.partial({
                    payment_info: t.array(t.string),
                    user_exists: t.array(t.string),
                }),
            }),
        ]),
        payload: PartnerRequest,
        method: put,
    },
    readInfoByINN: {
        path: ({ inn }) => `${apiHost}/v6/search/inn/${inn}`,
        pathParams: t.type({
            inn: t.string,
        }),
        method: get,
        response: PartnerInfoByINN,
    },
    readInfoByBIK: {
        path: ({ bik }) => `${apiHost}/v6/search/bik/${bik}`,
        pathParams: t.type({
            bik: t.string,
        }),
        method: get,
        response: t.union([PaymentInfoByBIK, t.type({ errors: t.type({ error: t.array(t.string) }) })]),
    },
    readPartnerDocumentDefinitions: {
        path: ({ partnerType }) => `${apiHost}/v6/onboarding/partner_document_list?partner_type=${partnerType}`,
        pathParams: t.type({
            partnerType: t.string,
        }),
        method: get,
        response: t.union([
            t.array(PartnerDocDefinition),
            t.type({ errors: t.type({ partner_type: t.array(t.string) }) }),
        ]),
    },
    uploadPartnerDocument: {
        path: ({ partnerId }) => `${apiHost}/v6/uploads/partners/${partnerId}`,
        pathParams: t.type({
            partnerId: t.string,
        }),
        method: postForm,
        response: t.union([
            t.type({
                upload: PartnerUploadResult,
            }),
            t.type({
                errors: t.partial({
                    error: t.array(t.string),
                    file: t.array(t.string),
                }),
            }),
        ]),
        payload: formData(t.type({
            type: PartnerDocType,
            expires_at: t.union([t.undefined, t.string]),
            upload_file: t.unknown, // binary
        })),
    },
});
export { w4dPartnerRemoteAPI };
