import { createSelector } from '@reduxjs/toolkit';
const getVehiclesList = (state) => state.cars.models;
const getAllCars = (state) => state.cars.all;
const getCars = (state) => state.cars.cars;
const getCurrentCar = createSelector(getAllCars, (state) => state.cars.form.id, (cars, carId) => cars.find(c => c.id === carId));
const getCurrentUploads = createSelector(getCurrentCar, current => current?.docUploads);
const getCurrentCarDriver = createSelector((state) => state.drivers.all, getCurrentCar, (drivers, car) => {
    const driverId = car?.currentChauffeur?.id;
    if (!driverId) {
        return undefined;
    }
    const fullDriver = drivers.find(driver => driver.id === driverId);
    return fullDriver ?? car.currentChauffeur;
});
const getModelsByBrand = createSelector((state) => state.cars.models, brands => {
    const modelsByBrand = new Map();
    brands.forEach(brand => {
        const make = brand.make;
        brand.models.forEach(model => {
            let models = modelsByBrand.get(make);
            if (!models) {
                models = new Set();
                modelsByBrand.set(make, models);
            }
            models.add(model);
        });
    });
    return modelsByBrand;
});
const getCarForm = (state) => state.cars.form;
const getCurrentCarId = (state) => state.cars.form.id;
const getUploadedDocsForCurrentCar = createSelector((state) => state.cars.form.id, (state) => state.cars.entities, (id, allCars) => {
    if (id !== undefined) {
        const car = allCars[id];
        if (car !== undefined) {
            return car.docUploads;
        }
    }
    return {};
});
export { getVehiclesList, getModelsByBrand, getCarForm, getAllCars, getCars, getCurrentCarId, getUploadedDocsForCurrentCar, getCurrentCar, getCurrentUploads, getCurrentCarDriver, };
