import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@wheely/web-auth-lib';
import { GuestLayout } from '../../../src-common/view/layouts/Guest';
import { signIn, confirmPhone } from '../../modules/auth/store/actions';
import { IntlContext } from '../../i18n';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { PhoneForm } from './components/PhoneForm';
import { PinForm } from './components/PinForm';
import s from './style.css';
const Login = () => {
    const { isAuthenticated } = useAuth();
    const { language } = useContext(IntlContext);
    const [phone, setPhone] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isPinFormShown, setIsPinFormShown] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const handleSubmitPhone = useCallback(async (newPhone, captchaKey) => {
        setPhone(newPhone);
        const result = await dispatch(signIn({ phone: newPhone.code + newPhone.number, captcha: captchaKey, locale: language })).unwrap();
        if (!result.error) {
            setIsPinFormShown(true);
        }
        return result;
    }, [dispatch, language]);
    const handleSubmitPin = useCallback(async (pin) => {
        setIsLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const result = await dispatch(confirmPhone({ pin, locale: language })).unwrap();
        if (result.error) {
            setIsLoading(false);
        }
        return result;
    }, [dispatch, language]);
    const handleReturnBackFromPin = useCallback(() => {
        setIsPinFormShown(false);
    }, []);
    useEffect(() => {
        if (isAuthenticated) {
            if (!history.rollback()) {
                history.replace('/');
            }
        }
    }, [isAuthenticated, history]);
    return (_jsx(GuestLayout, { children: _jsxs("div", { className: s.login, "data-test-id": "login", children: [_jsx(Header, {}), isPinFormShown && phone ? (_jsx(PinForm, { phone: phone.code + phone.number, disabled: isLoading, onSubmit: handleSubmitPin, onBack: handleReturnBackFromPin })) : (_jsx(PhoneForm, { initialPhone: phone, onSubmit: handleSubmitPhone })), _jsx(Footer, {})] }) }));
};
export { Login };
