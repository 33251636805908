import { createSlice } from '@reduxjs/toolkit';
import { namespace as driverNamespace } from '../namespace';
import { eventTracking } from '../../../services/event-tracking';
const initialState = {
    driverReferralCode: {
        code: '',
        description: '',
    },
    driver: null,
    myDriver: {
        hasContractWithPartner: true,
        id: '',
        partnerId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthday: null,
        licenseNumber: '',
        ukNationalInsurance: '',
        homeAddress: '',
        rating: 0,
        score: 0,
        docUploads: {},
        testStatuses: {},
        accessStatus: 'pending',
        licensingAuthority: null,
        cityId: '',
        onlineStatus: 'offline',
        hasPassedAccreditation: false,
    },
    all: [],
    drivers: {
        items: [],
        total: 0,
        limit: 20,
        offset: 0,
    },
    driverWorkload: null,
};
const driversSlice = createSlice({
    name: driverNamespace,
    initialState,
    reducers: {
        setReferralCode: (state, action) => {
            state.driverReferralCode = action.payload;
        },
        setDriverWorkload: (state, action) => {
            state.driverWorkload = action.payload;
        },
        setDriver: (state, action) => {
            state.driver = action.payload;
            const idx = state.all.findIndex(d => d.id === action.payload.id);
            state.all[idx] = action.payload;
        },
        resetDriver: state => {
            state.driver = null;
        },
        setAllDrivers: (state, action) => {
            state.all = action.payload;
        },
        setDrivers: (state, action) => {
            state.drivers = action.payload;
        },
        setMyDriver: (state, action) => {
            state.myDriver = action.payload;
            eventTracking.setDriverId(action.payload.id);
        },
        updateMyDriver: (state, action) => {
            state.myDriver = {
                ...state.myDriver,
                ...action.payload,
            };
        },
        updateDriver: (state, action) => {
            if (state.driver && state.driver.id === action.payload.id) {
                state.driver = {
                    ...state.driver,
                    ...action.payload.update,
                };
            }
            let driverInList = state.all.find(driver => driver.id === action.payload.id);
            if (driverInList) {
                driverInList = { ...driverInList, ...action.payload.update };
            }
        },
    },
});
export { driversSlice, driverNamespace };
