const AccessStatusEnum = {
    active: null,
    expired_docs: null,
    pending: null,
    rejected: null,
    blocked: null,
    partner_blocked: null,
    new_partner: null,
    end_of_life: null,
    failed_tests: null,
};
const WARNING_STATUSES = ['expired_docs', 'rejected', 'blocked', 'failed_tests'];
export { AccessStatusEnum, WARNING_STATUSES };
