import { jsx as _jsx } from "react/jsx-runtime";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNotification } from '@wheely/ui-kit';
import { errorHandling } from '../../../../services/errorHandling';
import { noop } from '../../../../shared/utils/noop';
import { ENV } from '../../../../env';
const Captcha = forwardRef((_, ref) => {
    const captchaRef = useRef(null);
    const intl = useIntl();
    const { showNotification } = useNotification();
    const getCaptcha = useCallback(async () => {
        if (!captchaRef.current) {
            errorHandling.captureMessage('Captcha reference is missing');
        }
        const result = await captchaRef.current?.execute({ async: true }).catch(error => {
            // eslint-disable-next-line no-console
            console.debug('Captcha error', error);
            errorHandling.captureMessage(`Captcha error: ${typeof error === 'string' ? error : 'unknown'}`);
        });
        // eslint-disable-next-line no-console
        console.debug('Captcha result', result);
        if (!result?.response) {
            showNotification({
                message: intl.formatMessage({ id: "aKVZ6L", defaultMessage: "CAPTCHA verification failed. Please try again." }),
                showCloseButton: false,
                dark: true,
            });
        }
        return result?.response;
    }, [intl, showNotification]);
    const reset = useCallback(() => {
        // eslint-disable-next-line no-console
        console.debug('Reset Captcha');
        captchaRef.current?.resetCaptcha();
    }, []);
    useImperativeHandle(ref, () => ({ getCaptcha, reset }), [reset, getCaptcha]);
    return (_jsx(HCaptcha, { "data-test-id": "captcha", ref: captchaRef, onVerify: noop, size: "invisible", sitekey: ENV.HCAPTCHA_SITEKEY }));
});
export { Captcha };
