import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { checkIsOnBoardingCompleted, getPartner } from '../../modules/partner/store/selectors';
import { getIsAppInitialized } from '../store/selectors';
import { getAllCars } from '../../modules/car/store/selectors';
import { getHasRequiredCarDocs } from '../../modules/car/store/commonSelectors';
import { CountryCode } from '../../shared/models/country-code';
import { getHasRequiredMyDriverDocs } from '../../modules/driver/store/commonSelectors';
import { getCountryCode } from '../../modules/common/store/selectors';
import { getIsUserWithPartnerRole, selectIsChauffeur, selectIsFleet, selectIsFleetAndChauffeur, selectIsIndividualChauffeur, selectIsIndividualChauffeurRU, selectIsNew, selectIsIndividualChauffeurUK, } from '../../modules/user/store/selectors';
const defaultValue = {
    isReady: false,
    user: {
        isFleet: false,
        isChauffeur: false,
        isFleetAndChauffeur: false,
        isIndividualChauffeur: false,
        isIndividualChauffeurRU: false,
        isBlocked: false,
        isNew: true,
    },
    can: {
        notDoAnything: false,
        viewWizard: false,
        viewDrivers: false,
        viewCars: false,
        createCars: false,
        viewProfile: false,
        viewDriverDetails: false,
        viewTodo: false,
        viewBills: false,
        viewJourney: false,
        viewJourneys: false,
        viewOverview: false,
        bookAnAccreditation: false,
        viewPressure: false,
    },
};
const getPermissions = createSelector(getPartner, getAllCars, getIsAppInitialized, checkIsOnBoardingCompleted, getHasRequiredCarDocs, getHasRequiredMyDriverDocs, selectIsFleet, selectIsChauffeur, selectIsFleetAndChauffeur, selectIsIndividualChauffeur, selectIsIndividualChauffeurRU, selectIsNew, getIsUserWithPartnerRole, getCountryCode, selectIsIndividualChauffeurUK, (partner, cars, isAppInitialized, isOnboardingCompleted, hasRequiredCarDocs, hasRequiredDriverDocs, isFleet, isChauffeur, isFleetAndChauffeur, isIndividualChauffeur, isIndividualChauffeurRU, isNew, isUserWithPartnerRole, region, isIndividualChauffeurUK) => {
    if (!isAppInitialized) {
        return defaultValue;
    }
    const isBlocked = partner.accessStatus === 'blocked';
    return {
        isReady: true,
        user: {
            isFleet,
            isChauffeur,
            isFleetAndChauffeur,
            isIndividualChauffeur,
            isIndividualChauffeurRU,
            isNew,
            isBlocked,
        },
        can: {
            notDoAnything: isBlocked,
            viewDrivers: isFleetAndChauffeur || isFleet || (isIndividualChauffeurRU && isOnboardingCompleted),
            viewBills: isFleetAndChauffeur ||
                isFleet ||
                (isOnboardingCompleted && (isIndividualChauffeur || isIndividualChauffeurRU)),
            viewJourney: isFleetAndChauffeur ||
                isFleet ||
                ((isIndividualChauffeurUK || isIndividualChauffeurRU) && isOnboardingCompleted),
            viewJourneys: isFleetAndChauffeur ||
                isFleet ||
                ((isIndividualChauffeurUK || isIndividualChauffeurRU) && isOnboardingCompleted),
            viewOverview: isFleetAndChauffeur || isFleet || (isIndividualChauffeurRU && isOnboardingCompleted),
            viewCars: isFleetAndChauffeur ||
                isFleet ||
                ((isIndividualChauffeur || isIndividualChauffeurRU) && isOnboardingCompleted),
            viewWizard: !isOnboardingCompleted && (isNew || isIndividualChauffeur || isIndividualChauffeurRU),
            viewTodo: isChauffeur ||
                isFleetAndChauffeur ||
                isFleet ||
                (isOnboardingCompleted && (isIndividualChauffeur || isIndividualChauffeurRU)),
            viewProfile: isFleetAndChauffeur ||
                isFleet ||
                (isOnboardingCompleted && (isIndividualChauffeur || isIndividualChauffeurRU)),
            viewDriverDetails: isFleetAndChauffeur || isFleet || isIndividualChauffeurRU,
            createCars: isFleetAndChauffeur ||
                isFleet ||
                ((isIndividualChauffeur || isIndividualChauffeurRU) && isOnboardingCompleted),
            bookAnAccreditation: (() => {
                if (isIndividualChauffeur || isIndividualChauffeurRU) {
                    switch (region) {
                        case CountryCode.GreatBritain:
                        case CountryCode.Russia:
                            return cars.length > 0 && hasRequiredCarDocs && hasRequiredDriverDocs;
                        default:
                            return true;
                    }
                }
                if (isChauffeur) {
                    return true;
                }
                if (isFleet) {
                    return true;
                }
                return false;
            })(),
            viewPressure: isUserWithPartnerRole && isOnboardingCompleted,
        },
    };
});
const permissions = React.createContext(defaultValue);
const { Provider, Consumer: PermissionsConsumer } = permissions;
const PermissionsProvider = ({ children }) => (_jsx(Provider, { value: useSelector(getPermissions), children: children }));
const usePermissionsContext = () => useContext(permissions);
export { PermissionsConsumer, PermissionsProvider, permissions, usePermissionsContext, getPermissions, };
