import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { useExternalLinks } from '../../../app/hooks/useExternalLinks';
import s from './styles.css';
const ChangeEmailTip = () => {
    const links = useExternalLinks();
    return (_jsx("span", { className: s.changeEmailTip, children: _jsx(FormattedMessage, { id: 'MiG9LY', defaultMessage: 'Contact <a>Operations Team</a> ({link}) to edit your email.', values: {
                a: (text) => (_jsx("a", { href: `mailto:${links.contactOperationTeam}`, target: "_blank", rel: "noreferrer", children: text })),
                link: links.contactOperationTeam,
            } }) }));
};
export { ChangeEmailTip };
