import * as t from 'io-ts';
import { AccessStatusEnum } from '../../../src/shared/models/access-status';
import { allPartnerTypesEnum } from '../../../src/modules/partner/model/partnerType';
import { UploadDefinition, UploadedFile, UploadResult } from './w4d-upload-types';
import { City } from './w4d-city-types';
import { Onboarding } from './w4d-onboarding-types';
const PartnerDocType = t.string;
const AddressSet = t.partial({
    postal_code: t.union([t.string, t.null]),
    city: t.union([t.string, t.null]),
    line1: t.union([t.string, t.null]),
    line2: t.union([t.string, t.null]),
    line3: t.union([t.string, t.null]),
    po_box: t.union([t.string, t.null]),
});
const LegalInfo = t.partial({
    inn: t.union([t.string, t.null]),
    kpp: t.union([t.string, t.null]),
    registered_address: t.union([AddressSet, t.null]),
    postal_address: t.union([AddressSet, t.null]),
    company_reg_number: t.union([t.string, t.null]),
    vat_reg_no: t.union([t.string, t.null]),
    name: t.union([t.string, t.null]),
    type: t.union([t.keyof(allPartnerTypesEnum), t.null]),
    tax_reg_no: t.union([t.string, t.null, t.undefined]),
    trade_license_number: t.union([t.string, t.null, t.undefined]),
    nds: t.union([t.boolean, t.null, t.undefined]),
    ogrn: t.union([t.string, t.null, t.undefined]),
});
const PaymentInfo = t.partial({
    account_number: t.union([t.string, t.null]),
    bank_name: t.union([t.string, t.null]),
    bik: t.union([t.string, t.null]),
    iban: t.union([t.string, t.null]),
    kor_account: t.union([t.string, t.null]),
    ras_account: t.union([t.string, t.null]),
    sort_code: t.union([t.string, t.null]),
    swift: t.union([t.string, t.null]),
});
const PartnerDocDefinition = t.type({
    ...UploadDefinition.props,
    type: PartnerDocType,
});
const PartnerUploadedFile = t.type({
    ...UploadedFile.props,
    type: PartnerDocType,
});
const PartnerUploadResult = t.type({
    ...UploadResult.props,
    partner_id: t.string,
    type: PartnerDocType,
});
const Partner = t.type({
    access_status: t.keyof(AccessStatusEnum),
    city: t.union([t.null, t.undefined, City]),
    city_id: t.string,
    city_name: t.string,
    email: t.union([t.null, t.undefined, t.string]),
    id: t.string,
    personal_inn: t.union([t.undefined, t.null, t.string]),
    legal_info: t.union([t.null, t.undefined, LegalInfo]),
    payment_info: t.union([t.null, t.undefined, PaymentInfo]),
    phone: t.string,
    uploads: t.union([t.undefined, t.array(PartnerUploadedFile)]),
    vehicles_hidden: t.boolean,
    onboarding: t.union([Onboarding, t.undefined, t.null]),
    registration_audit_step: t.union([t.string, t.undefined, t.null]),
});
const PartnerRequest = t.type({
    access_status: t.string,
    city_id: t.string,
    email: t.string,
    phone: t.string,
    promo: t.null,
    registration_audit_step: t.union([t.string, t.null]),
    vehicles_hidden: t.boolean,
    legal_info: LegalInfo,
    payment_info: PaymentInfo,
});
const PartnerInfoByINN = t.type({
    type: t.string,
    name: t.string,
    registered_address: AddressSet,
    inn: t.string,
    kpp: t.union([t.string, t.null]),
    ogrn: t.string,
    ceo_name: t.string,
    signer_title: t.string,
});
const PaymentInfoByBIK = t.type({
    bik: t.string,
    namemini: t.string,
    index: t.string,
    city: t.string,
    address: t.string,
    phone: t.string,
    okato: t.string,
    okpo: t.string,
    regnum: t.string,
    srok: t.string,
    dateadd: t.string,
    datechange: t.string,
    bank_name: t.string,
    kor_account: t.string,
});
export { Partner, PartnerInfoByINN, PaymentInfoByBIK, PartnerRequest, LegalInfo, PaymentInfo, AddressSet, PartnerDocDefinition, PartnerDocType, PartnerUploadResult, };
