import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPartner } from '../../modules/partner/store/selectors';
import { scrollUp } from '../../shared/actions/scrollUp';
import { usePermissions } from '../../app/hooks/usePermissions';
import { useCards } from './hooks/useCards';
import { ToDoView } from './view';
import { getToDoListData } from './selectors';
const ToDo = React.memo(({ onNavigate, selectToDoListData = getToDoListData }) => {
    usePermissions('viewTodo');
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(scrollUp());
    }, [dispatch]);
    const cards = useCards({ selectToDoListData, onNavigate });
    const partner = useSelector(getPartner);
    return (_jsx(ToDoView, { cards: cards, hasTitle: true, hasProgress: true, isPartnerActive: partner?.accessStatus === 'active' }));
});
ToDo.displayName = 'ToDo';
export { ToDo };
