import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import cn from 'classnames';
import s from './styles.css';
const Selection = ({ value, title, description, name, rules, control, defaultValue, shouldUnregister, }) => {
    const { field } = useController({
        name,
        rules,
        control,
        defaultValue,
        shouldUnregister,
    });
    return (_jsxs("label", { htmlFor: value, className: s.wrapper, children: [_jsx("input", { className: s.input, ...field, id: value, type: "radio", value: value, checked: value === field.value }), _jsxs("div", { className: cn(s.card, { [s.checked]: value === field.value }), children: [_jsxs("div", { className: s.labels, children: [_jsx("div", { className: s.title, children: title }), description && _jsx("div", { className: s.description, children: description })] }), _jsx("div", { className: s.checkRing })] }, value)] }));
};
export { Selection };
