import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useContext } from 'react';
import cn from 'classnames';
import { IntlContext } from '../../../../i18n';
import { Icon } from '../../../../../src-common/view/components/Icon';
import s from './LanguageMenu.css';
const getLanguageNameByCode = (languageCode) => {
    switch (languageCode) {
        case 'ru':
            return 'Русский';
        case 'fr':
            return 'Français';
        default:
            return 'English';
    }
};
const LanguageMenu = ({ className }) => {
    const { language: currentLanguage, setLanguage, availableLanguages } = useContext(IntlContext);
    const [isOpened, setIsOpened] = useState(false);
    const toggle = useCallback(() => {
        setIsOpened(prev => !prev);
    }, []);
    const handleLanguageClick = useCallback(language => () => {
        setLanguage(language);
        setIsOpened(false);
    }, [setLanguage]);
    return (_jsxs("div", { className: s.languageMenuItem, "data-test-id": "lang-menu", children: [_jsxs("button", { className: cn(className, s.selectedLanguageItem), onClick: toggle, "data-test-id": "lang-menu__selected", children: [getLanguageNameByCode(currentLanguage), _jsx(Icon, { icon: "chevron-to-end", className: cn(s.arrowDown, isOpened && s.arrowUp) })] }), isOpened &&
                availableLanguages
                    .filter(language => language !== currentLanguage)
                    .map(language => (_jsx("button", { onClick: handleLanguageClick(language), className: cn(className, s.languageOption), "data-test-id": "lang-menu__item", children: getLanguageNameByCode(language) }, language)))] }));
};
export { LanguageMenu };
