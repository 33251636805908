import { CountryCode } from '../models/country-code';
import { applyPathParams } from '../../../src-common/api/src/apply-path-params';
import { useExternalLinks } from '../../app/hooks/useExternalLinks';
import { useBookAnAppointmentModal } from '../hooks/useBookAnAppointmentModal';
const getReservioQueryParams = (params) => ({
    name: [params.firstName, params.lastName].join(' '),
    email: params.email,
    a1: params.phone,
    month: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0'),
});
const getCalendlyQueryParams = (params) => ({
    a1: params.id,
});
const getReservioLink = (baseURL, params) => applyPathParams(baseURL, getReservioQueryParams(params));
const getCalendlyLink = (baseURL, params) => params ? applyPathParams(baseURL, getCalendlyQueryParams(params)) : baseURL;
const useBookingActions = ({ region, driver, }) => {
    const links = useExternalLinks();
    const { openModal } = useBookAnAppointmentModal();
    if (region === CountryCode.Russia) {
        return {
            goToExternalLinkAction: getReservioLink(links.bookOneDayAppointment, driver),
        };
    }
    if (region === CountryCode.ArabEmirates) {
        return {
            goToExternalLinkAction: getCalendlyLink(links.bookOneDayAppointment, driver),
        };
    }
    return {
        openModalAction: () => {
            openModal({
                dayOneLink: getCalendlyLink(links.bookTwoDayAppointment?.day1 || '', driver),
                dayTwoLink: getCalendlyLink(links.bookTwoDayAppointment?.day2 || '', driver),
            });
        },
    };
};
export { useBookingActions, getCalendlyLink };
