import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { partnerAPI } from '../../api';
const getPaymentInfoByBIK = createAsyncThunk(`${namespace}/get-payment-info-by-bik`, async (bik) => {
    const paymentInfo = await partnerAPI.getPaymentInfoByBIK(bik);
    if ('errors' in paymentInfo) {
        return { isError: true };
    }
    return {
        paymentBankName: paymentInfo.bank_name || '',
        paymentKorAccount: paymentInfo.kor_account || '',
    };
});
export { getPaymentInfoByBIK };
