import { createAsyncThunk } from '@reduxjs/toolkit';
import { showError } from '../../../app/actions';
import { eventTracking } from '../../../services/event-tracking';
const createAsyncActions = ({ namespace, eventTrackingType, selectHolderId, selectUploadForm, actionSetBusy, uploadFile, selectIsBusy, }) => {
    const submitUploadForm = createAsyncThunk(`${namespace}/submit-upload-form`, async ({ textDefaultError }, { getState, dispatch }) => {
        if (selectIsBusy(getState())) {
            return { isAlreadyExecuted: true };
        }
        try {
            dispatch(actionSetBusy(true));
            const holderId = selectHolderId(getState());
            if (typeof holderId !== 'string' || holderId === '') {
                throw new Error("can't upload: missing holder id");
            }
            const form = selectUploadForm(getState());
            const result = await uploadFile(form, holderId);
            if ('error' in result) {
                const errorMessage = result.error || textDefaultError;
                dispatch(showError(errorMessage));
                return { isSucceed: false, result: errorMessage };
            }
            if (eventTrackingType) {
                eventTracking.trackSnowplow({
                    event: 'Onboarding - Documents - Document - Success',
                    type: eventTrackingType,
                });
            }
            return { isSucceed: true };
        }
        finally {
            dispatch(actionSetBusy(false));
        }
    });
    return {
        submitUploadForm,
    };
};
export { createAsyncActions };
