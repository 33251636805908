import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePermissions } from '../../app/hooks/usePermissions';
import { getCars } from '../../modules/car/store/selectors';
import { loadCars } from '../../modules/car/actions/async/loadCars';
import { View } from './view';
const CarList = ({ onNavigate }) => {
    const dispatch = useDispatch();
    usePermissions('viewCars');
    const cars = useSelector(getCars);
    const [isLoading, setIsLoading] = useState(false);
    const handleClickNew = useCallback(() => {
        onNavigate('/add-vehicle');
    }, [onNavigate]);
    const fetchCars = useCallback(async (_, { limit, offset }) => {
        setIsLoading(true);
        try {
            await dispatch(loadCars({ limit, offset })).unwrap();
        }
        finally {
            setIsLoading(false);
        }
    }, [dispatch, setIsLoading]);
    return (_jsx(View, { fetchCars: fetchCars, cars: cars, onClickNew: handleClickNew, isLoading: isLoading }));
};
export { CarList };
