import { nanoid } from '@reduxjs/toolkit';
import { defineMessages } from 'react-intl';
const COLORS = {
    BLACK: { title: 'Black', value: '#000000' },
    WHITE: { title: 'White / Cream', value: '#FFFFFF' },
    GRAY: { title: 'Gray', value: '#808080' },
    DARK_BLUE: { title: 'Dark Blue', value: '#00007F' },
    BROWN: { title: 'Brown', value: '#49311C' },
    SILVER: { title: 'Silver', value: '#C0C0C0' },
    BEIGE: { title: 'Beige', value: '#F5F5DC' },
    NAUTIC_BLUE: { title: 'Nautic Blue', value: '#1A5091' },
    BURGUNDY: { title: 'Burgundy', value: '#800020' },
};
const DEFAULT_MAX_AGE = 3;
const DEFAULT_COLORS = [COLORS.BLACK, COLORS.WHITE];
const DEFAULT_INTERIOR_COLORS = [
    COLORS.BLACK,
    COLORS.WHITE,
    COLORS.BEIGE,
    COLORS.BROWN,
    COLORS.GRAY,
];
const londonColors = [COLORS.BLACK, COLORS.GRAY, COLORS.SILVER];
const londonInteriorColors = [COLORS.BLACK, COLORS.WHITE, COLORS.BEIGE, COLORS.BROWN, COLORS.GRAY];
const moscowColors = [COLORS.BLACK, COLORS.WHITE, COLORS.GRAY, COLORS.DARK_BLUE, COLORS.BROWN];
const MAKES = {
    MERCEDES: 'Mercedes-Benz',
    MERCEDES_MAYBACH: 'Mercedes-Maybach',
    BMW: 'BMW',
    HONGQI: 'Hongqi',
};
const WHEELBASES = {
    COMPACT: 'compact',
    SHORT: 'short',
    LONG: 'long',
    EXTRA_LONG: 'extra_long',
};
const WHEELBASES_MESSAGES = defineMessages({ compact: { id: "ydxyP+", defaultMessage: "Compact" }, short: { id: "EFrUzu", defaultMessage: "Short" }, long: { id: "eZzfb+", defaultMessage: "Long (L)" }, extra_long: { id: "jaNv5d", defaultMessage: "Extra-Long (XL)" } });
const SERVICE_CLASSES = {
    BUSINESS: 'Business',
    FIRST: 'First',
    LUXE: 'Luxe',
    XL: 'XL',
};
const isWheelbase = (value) => Object.values(WHEELBASES).includes(value);
const MAYBACH = {
    _id: nanoid(),
    make: MAKES.MERCEDES_MAYBACH,
    modifications: [
        {
            model: 'S-Class',
            color: moscowColors,
            interiorColor: DEFAULT_INTERIOR_COLORS,
            maxAge: 5,
            generations: ['X222', 'X223', 'Z223'],
            wheelbases: [],
            serviceClass: SERVICE_CLASSES.LUXE,
            tag: 'luxe',
        },
    ],
};
const ENGINES = {
    PETROL: 'petrol',
    DIESEL: 'diesel',
    ELECTRIC: 'electric',
    HYBRID: 'hybrid',
};
const ENGINE_MESSAGES = defineMessages({ [ENGINES.PETROL]: { id: "global_vehicle_info_engine_petrol", defaultMessage: "Petrol" }, [ENGINES.DIESEL]: { id: "global_vehicle_info_engine_diesel", defaultMessage: "Diesel" }, [ENGINES.HYBRID]: { id: "global_vehicle_info_engine_hybrid", defaultMessage: "Hybrid" }, [ENGINES.ELECTRIC]: { id: "global_vehicle_info_engine_electric", defaultMessage: "Electric" } });
const cityToModels = {
    moscow: [
        {
            _id: nanoid(),
            make: MAKES.MERCEDES,
            modifications: [
                {
                    model: 'E-Class',
                    maxAge: 4,
                    generations: ['W213', 'W214'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.SHORT],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'S-Class',
                    maxAge: 5,
                    generations: ['W222', 'W223'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.SHORT, WHEELBASES.LONG],
                    serviceClass: SERVICE_CLASSES.FIRST,
                    tag: 's_class',
                },
                {
                    model: 'V-Class',
                    maxAge: 5,
                    generations: ['W447'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.COMPACT, WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
                {
                    model: 'EQE',
                    maxAge: DEFAULT_MAX_AGE,
                    generations: ['V295'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQS',
                    maxAge: 5,
                    generations: ['V297'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQV',
                    maxAge: 5,
                    generations: ['W447'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
            ],
        },
        MAYBACH,
        {
            _id: nanoid(),
            make: MAKES.BMW,
            modifications: [
                {
                    model: '5 Series',
                    maxAge: 4,
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    generations: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: '7 Series',
                    maxAge: 5,
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    generations: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
            ],
        },
    ],
    spb: [
        {
            _id: nanoid(),
            make: MAKES.MERCEDES,
            modifications: [
                {
                    model: 'E-Class',
                    maxAge: 4,
                    generations: ['W213', 'W214'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.SHORT],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'S-Class',
                    maxAge: 5,
                    generations: ['W222', 'W223'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.SHORT, WHEELBASES.LONG],
                    serviceClass: SERVICE_CLASSES.FIRST,
                    tag: 's_class',
                },
                {
                    model: 'V-Class',
                    maxAge: 5,
                    generations: ['W447'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.COMPACT, WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
                {
                    model: 'EQE',
                    generations: ['V295'],
                    maxAge: DEFAULT_MAX_AGE,
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQS',
                    maxAge: 5,
                    generations: ['V297'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQV',
                    maxAge: 5,
                    generations: ['W447'],
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
            ],
        },
        MAYBACH,
        {
            _id: nanoid(),
            make: MAKES.BMW,
            modifications: [
                {
                    model: '5 Series',
                    maxAge: 2,
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    generations: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: '7 Series',
                    maxAge: 2,
                    color: moscowColors,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    generations: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
            ],
        },
    ],
    london: [
        {
            _id: nanoid(),
            make: MAKES.MERCEDES,
            modifications: [
                {
                    model: 'E-Class',
                    maxAge: 3,
                    color: [...londonColors, COLORS.NAUTIC_BLUE],
                    interiorColor: londonInteriorColors,
                    generations: ['W213', 'W214'],
                    wheelbases: [WHEELBASES.SHORT],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'S-Class',
                    maxAge: 3,
                    color: londonColors,
                    interiorColor: londonInteriorColors,
                    generations: ['W222', 'W223'],
                    wheelbases: [WHEELBASES.SHORT, WHEELBASES.LONG],
                    serviceClass: SERVICE_CLASSES.FIRST,
                    tag: 's_class',
                },
                {
                    model: 'V-Class',
                    maxAge: 4,
                    color: londonColors,
                    interiorColor: londonInteriorColors,
                    generations: ['W447'],
                    wheelbases: [WHEELBASES.COMPACT, WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
                {
                    model: 'EQE',
                    maxAge: 3,
                    color: londonColors,
                    interiorColor: londonInteriorColors,
                    generations: ['V295'],
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                    note: true,
                },
                {
                    model: 'EQS',
                    maxAge: 3,
                    color: londonColors,
                    interiorColor: londonInteriorColors,
                    generations: ['V297'],
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQV',
                    maxAge: 4,
                    color: londonColors,
                    interiorColor: londonInteriorColors,
                    generations: ['W447'],
                    wheelbases: [WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
            ],
        },
        {
            _id: nanoid(),
            make: MAKES.BMW,
            modifications: [
                {
                    model: '5 Series',
                    maxAge: 3,
                    color: [COLORS.BLACK, COLORS.GRAY],
                    interiorColor: [COLORS.BLACK, COLORS.WHITE, COLORS.BROWN, COLORS.BURGUNDY],
                    wheelbases: [WHEELBASES.SHORT],
                    generations: ['G60'],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'i5',
                    maxAge: 3,
                    color: [COLORS.BLACK, COLORS.GRAY],
                    interiorColor: [COLORS.BLACK, COLORS.WHITE, COLORS.BROWN, COLORS.BURGUNDY],
                    wheelbases: [WHEELBASES.SHORT],
                    generations: ['G60'],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
            ],
        },
    ],
    paris: [
        {
            _id: nanoid(),
            make: MAKES.MERCEDES,
            modifications: [
                {
                    model: 'E-Class',
                    maxAge: 4,
                    color: [COLORS.BLACK, COLORS.GRAY, COLORS.NAUTIC_BLUE],
                    interiorColor: londonInteriorColors,
                    generations: ['W213', 'W214'],
                    wheelbases: [WHEELBASES.SHORT],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'S-Class',
                    maxAge: 5,
                    color: [COLORS.BLACK, COLORS.GRAY],
                    interiorColor: londonInteriorColors,
                    generations: ['W222', 'W223'],
                    wheelbases: [WHEELBASES.SHORT, WHEELBASES.LONG],
                    serviceClass: SERVICE_CLASSES.FIRST,
                    tag: 's_class',
                },
                {
                    model: 'V-Class',
                    maxAge: 5,
                    color: [COLORS.BLACK],
                    interiorColor: londonInteriorColors,
                    generations: ['W447'],
                    wheelbases: [WHEELBASES.COMPACT, WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
                {
                    model: 'EQE',
                    maxAge: 4,
                    color: [COLORS.BLACK],
                    interiorColor: londonInteriorColors,
                    generations: ['V295'],
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQS',
                    maxAge: 4,
                    color: [COLORS.BLACK],
                    interiorColor: londonInteriorColors,
                    generations: ['V297'],
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQV',
                    maxAge: 5,
                    color: [COLORS.BLACK],
                    interiorColor: londonInteriorColors,
                    generations: ['W447'],
                    wheelbases: [WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
            ],
        },
        {
            _id: nanoid(),
            make: MAKES.BMW,
            modifications: [
                {
                    model: '5 Series',
                    maxAge: 4,
                    color: [COLORS.BLACK, COLORS.GRAY],
                    interiorColor: [COLORS.BLACK, COLORS.WHITE, COLORS.BROWN, COLORS.BURGUNDY],
                    wheelbases: [WHEELBASES.SHORT],
                    generations: ['G60'],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'i5',
                    maxAge: 4,
                    color: [COLORS.BLACK, COLORS.GRAY],
                    interiorColor: [COLORS.BLACK, COLORS.WHITE, COLORS.BROWN, COLORS.BURGUNDY],
                    wheelbases: [WHEELBASES.SHORT],
                    generations: ['G60'],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
            ],
        },
    ],
    dubai: [
        {
            _id: nanoid(),
            make: MAKES.MERCEDES,
            modifications: [
                {
                    model: 'E-Class',
                    maxAge: 3,
                    color: [COLORS.BLACK, COLORS.GRAY, COLORS.SILVER],
                    interiorColor: [COLORS.BLACK, COLORS.WHITE, COLORS.BEIGE, COLORS.BROWN, COLORS.GRAY],
                    generations: ['W214'],
                    wheelbases: [WHEELBASES.SHORT],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'S-Class',
                    generations: ['W223'],
                    maxAge: DEFAULT_MAX_AGE,
                    color: [COLORS.BLACK],
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.SHORT, WHEELBASES.LONG],
                    serviceClass: SERVICE_CLASSES.FIRST,
                    tag: 's_class',
                },
                {
                    model: 'V-Class',
                    generations: ['W447'],
                    maxAge: DEFAULT_MAX_AGE,
                    color: [COLORS.BLACK],
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.COMPACT, WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
                {
                    model: 'EQE',
                    maxAge: 3,
                    color: [COLORS.BLACK, COLORS.GRAY],
                    interiorColor: [COLORS.BLACK, COLORS.WHITE, COLORS.BEIGE, COLORS.BROWN, COLORS.GRAY],
                    generations: ['V295'],
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQS',
                    maxAge: 3,
                    color: [COLORS.BLACK, COLORS.GRAY],
                    interiorColor: [COLORS.BLACK, COLORS.WHITE, COLORS.BEIGE, COLORS.BROWN, COLORS.GRAY],
                    generations: ['V297'],
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQV',
                    generations: ['W447'],
                    maxAge: DEFAULT_MAX_AGE,
                    color: [COLORS.BLACK],
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
            ],
        },
        {
            _id: nanoid(),
            make: MAKES.BMW,
            modifications: [
                {
                    model: '5 Series',
                    maxAge: 1,
                    color: [COLORS.BLACK, COLORS.GRAY],
                    interiorColor: [
                        COLORS.BLACK,
                        COLORS.WHITE,
                        COLORS.BEIGE,
                        COLORS.BROWN,
                        COLORS.BURGUNDY,
                        COLORS.GRAY,
                    ],
                    wheelbases: [],
                    generations: ['G60'],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
            ],
        },
    ],
    sochi: [
        {
            _id: nanoid(),
            make: MAKES.MERCEDES,
            modifications: [
                {
                    model: 'E-Class',
                    maxAge: 4,
                    color: DEFAULT_COLORS,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    generations: ['W213', 'W214'],
                    wheelbases: [WHEELBASES.SHORT],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'S-Class',
                    generations: ['W223'],
                    maxAge: DEFAULT_MAX_AGE,
                    color: DEFAULT_COLORS,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [WHEELBASES.SHORT, WHEELBASES.LONG],
                    serviceClass: SERVICE_CLASSES.FIRST,
                    tag: 's_class',
                },
                {
                    model: 'V-Class',
                    maxAge: DEFAULT_MAX_AGE,
                    color: [COLORS.BLACK],
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    generations: ['W447'],
                    wheelbases: [WHEELBASES.COMPACT, WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
                {
                    model: 'EQE',
                    maxAge: DEFAULT_MAX_AGE,
                    generations: ['V295'],
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    color: DEFAULT_COLORS,
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQS',
                    maxAge: DEFAULT_MAX_AGE,
                    generations: ['V297'],
                    color: DEFAULT_COLORS,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: 'EQV',
                    maxAge: DEFAULT_MAX_AGE,
                    color: [COLORS.BLACK],
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    generations: ['W447'],
                    wheelbases: [WHEELBASES.LONG, WHEELBASES.EXTRA_LONG],
                    serviceClass: SERVICE_CLASSES.XL,
                    tag: 'v_class',
                },
            ],
        },
        {
            _id: nanoid(),
            make: MAKES.BMW,
            modifications: [
                {
                    model: '5 Series',
                    maxAge: 2,
                    color: DEFAULT_COLORS,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    generations: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
                {
                    model: '7 Series',
                    maxAge: 2,
                    color: DEFAULT_COLORS,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    generations: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
            ],
        },
        {
            _id: nanoid(),
            make: MAKES.HONGQI,
            modifications: [
                {
                    model: 'H9',
                    maxAge: 1,
                    color: DEFAULT_COLORS,
                    interiorColor: DEFAULT_INTERIOR_COLORS,
                    wheelbases: [],
                    generations: [],
                    serviceClass: SERVICE_CLASSES.BUSINESS,
                    tag: 'e_class',
                },
            ],
        },
    ],
};
const getHardcodedCarModels = (city) => {
    switch (city) {
        case 'moscow':
            return cityToModels.moscow;
        case 'spb':
            return cityToModels.spb;
        case 'london':
            return cityToModels.london;
        case 'paris':
            return cityToModels.paris;
        case 'dubai':
            return cityToModels.dubai;
        case 'sochi':
            return cityToModels.sochi;
        default:
            return cityToModels.london;
    }
};
export { getHardcodedCarModels, WHEELBASES_MESSAGES, isWheelbase, DEFAULT_COLORS, DEFAULT_MAX_AGE, DEFAULT_INTERIOR_COLORS, MAKES, ENGINES, ENGINE_MESSAGES, };
