import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { DocsList } from '../../../src-common/view/components/DocsList';
import { SELF_EMPLOYED } from '../../modules/partner/model/partnerType';
import { ToDoView } from '../Todo/view';
import { useAcademyCard } from '../Todo/hooks/useAcademyCards';
import { StepLayout } from '../../shared/view/StepLayout';
import { CountryCode } from '../../shared/models/country-code';
import { ChangeEmailTip } from '../../shared/view/ChangeEmailTip';
import { ChauffeurInfo } from './ChauffeurInfo';
import { CompanyInfo } from './CompanyInfo';
import { PaymentInfo } from './PaymentInfo';
import { ContactInfo } from './ContactInfo';
import { StatusInfo } from './StatusInfo';
import { Settings } from './Settings';
import { AgencyAgreementConfirmation } from './AgencyAgreementConfirmation';
import s from './styles.css';
const isCompany = (partner) => partner.partnerType !== SELF_EMPLOYED;
const ProfileView = ({ region, user, partner, driver, docs, onUploadDoc, onClickEditPayment, onClickEditDriver, onClickEditContactInformation, onClickBack, onClickAgencyAgreementLink, }) => {
    const intl = useIntl();
    const todoCards = useAcademyCard({
        myDriver: driver,
    }, intl);
    const userHasPartnerRole = user.isFleet || user.isFleetAndChauffeur || user.isIndividualChauffeurRU;
    return (_jsxs(StepLayout, { title: intl.formatMessage({ id: "HCCkSJ", defaultMessage: "Profile" }), onClickBack: onClickBack, hideProgress: true, children: [user.isFleet ? (_jsx(ContactInfo, { partner: partner, onClickEdit: onClickEditContactInformation, className: s.values, children: _jsx(ChangeEmailTip, {}) })) : (_jsx(ChauffeurInfo, { partner: partner, driver: driver, className: s.values, onClickEdit: onClickEditDriver, children: _jsx(ChangeEmailTip, {}) })), !user.isFleet && _jsx(StatusInfo, { partner: partner, driver: driver }), !user.isChauffeur && isCompany(partner) && (_jsx(CompanyInfo, { className: s.values, partner: partner })), !user.isChauffeur && (_jsx(PaymentInfo, { className: s.values, partner: partner, onClickEdit: onClickEditPayment })), !user.isFleet && (_jsx("div", { className: s.todo, children: _jsx(ToDoView, { cards: todoCards }) })), !user.isChauffeur && docs.length > 0 && (_jsx(DocsList, { title: intl.formatMessage({ id: "wVBktP", defaultMessage: "DOCUMENTS" }), className: s.docs, onChange: onUploadDoc, docs: docs })), user.isFleet && region === CountryCode.Russia && (_jsx(AgencyAgreementConfirmation, { onClick: onClickAgencyAgreementLink, className: s.settings })), userHasPartnerRole && _jsx(Settings, { partner: partner })] }));
};
export { ProfileView };
