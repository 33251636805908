import { createClient } from '../../../../src-common/api/src/create-client';
import { w4dEarningsRemoteAPI } from '../../../../src-common/api/definitions/w4d-earnings-endpoints';
import { get } from '../../../shared/api/fetch-based-http-methods';
import { PeriodNameEnum } from '../model';
import { ENV } from '../../../env';
const client = createClient(w4dEarningsRemoteAPI({ get, apiHost: ENV.API_URL }));
const prepareEarning = (earning) => ({
    acceptance: earning.acceptance,
    commission: earning.total.wheely_commission,
    count: earning.total.count,
    driverName: earning.name,
    errands: earning.total.errand,
    gross: earning.total.gross,
    id: earning.id,
    other: earning.total.others,
    parking: earning.total.parking,
    photo: earning.photo ?? '',
    rating: earning.rating,
    tips: earning.total.tips,
    payout: earning.total.payout,
    transferCost: 0,
    transferPrice: 0,
});
const prepareInterval = ({ from, to }, periodName) => {
    switch (periodName) {
        case PeriodNameEnum.yesterday:
        case PeriodNameEnum.last_week:
        case PeriodNameEnum.last_month:
            return {
                from: new Date(from),
                to: new Date(new Date(to).getTime() - 1),
            };
        default:
            return {
                from: new Date(from),
                to: new Date(to),
            };
    }
};
const earningsAPI = {
    fetchEarnings: async ({ periodName, }) => {
        const response = await client.readEarnings({
            pathParams: { periodName },
        });
        return {
            earnings: response.earnings.map(prepareEarning),
            interval: prepareInterval({ from: response.from, to: response.to }, periodName),
        };
    },
};
export { earningsAPI };
