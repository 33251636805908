import { jsx as _jsx } from "react/jsx-runtime";
import { useDialog } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';
import { ReportSentDialog } from './ReportSentDialog';
import s from './styles.css';
const useReportSentDialog = () => {
    const { openDialog } = useDialog();
    const openModal = ({ email }) => openDialog(({ onClose }) => _jsx(ReportSentDialog, { email: email, onClose: onClose }), {
        title: (_jsx(FormattedMessage, { id: '9bY7Ax', defaultMessage: 'You will receive report by your email' })),
        className: s.dialog,
        titleClassName: s.title,
        bodyClassName: s.bodyWrapper,
    });
    return { openModal };
};
export { useReportSentDialog };
