import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { DocsList } from '../../../../src-common/view/components/DocsList';
import { CountryCode } from '../../../shared/models/country-code';
import { DriverPartnerContract } from './DriverPartnerContract';
const Docs = ({ driver, docs, onUploadDoc, region, onChangeContract, }) => {
    const intl = useIntl();
    return (_jsxs(_Fragment, { children: [_jsx(DocsList, { title: intl.formatMessage({ id: "wVBktP", defaultMessage: "DOCUMENTS" }), onChange: onUploadDoc, docs: docs, scrollToView: false }), region === CountryCode.Russia && (_jsx(DriverPartnerContract, { value: driver.hasContractWithPartner, name: 'hasContractWithPartner', onChange: onChangeContract }))] }));
};
export { Docs };
