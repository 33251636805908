import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { partnerAPI } from '../../api';
import { updatePartner } from '../actions';
import { getPartner } from '../../store/selectors';
let lastRequestId;
const updatePaymentInfo = createAsyncThunk(`${namespace}/submit-payment-info-step`, async (formValues, { dispatch, requestId, getState }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const partner = getPartner(getState());
        const result = await partnerAPI.updatePaymentInfo({
            ...partner,
            paymentAccount: formValues.accountNumber,
            paymentSortCode: formValues.sortCode,
            paymentBik: formValues.bik,
            paymentIban: formValues.iban,
            paymentSwift: formValues.swift,
            paymentRasAccount: formValues.rasAccount,
            paymentBankName: formValues.bankName,
            paymentKorAccount: formValues.corAccount,
        });
        if (result.baseErrors) {
            return {
                isSucceed: false,
                result,
            };
        }
        dispatch(updatePartner({
            paymentAccount: formValues.accountNumber,
            paymentSortCode: formValues.sortCode,
            paymentBik: formValues.bik,
            paymentIban: formValues.iban,
            paymentSwift: formValues.swift,
            paymentRasAccount: formValues.rasAccount,
            paymentBankName: formValues.bankName,
            paymentKorAccount: formValues.corAccount,
        }));
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { updatePaymentInfo };
