import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Divider, AnchorButton, ButtonGroup } from '@wheely/ui-kit';
import { LanguageSwitcher } from '../../../../../src-common/view/components/LanguageSwitcher';
import { useExternalLinks } from '../../../../app/hooks/useExternalLinks';
import s from './style.css';
const Footer = () => {
    const links = useExternalLinks();
    return (_jsxs("div", { className: cn(s.footer), "data-test-id": "quest-footer", children: [_jsx(LanguageSwitcher, { className: s.languageSwitcher }), _jsx(Divider, {}), _jsxs(ButtonGroup, { minimal: true, square: true, className: s.linksGroup, "data-test-id": "quest-footer__links", children: [_jsx(AnchorButton, { href: links.termsOfUse, small: true, className: s.link, children: _jsx(FormattedMessage, { id: 'l3pPMG', defaultMessage: 'Terms & Conditions' }) }), _jsx(AnchorButton, { href: links.privacyPolicy, small: true, className: s.link, children: _jsx(FormattedMessage, { id: 'C3ZF7Z', defaultMessage: 'Privacy Policy' }) }), _jsx(AnchorButton, { href: links.cookiePolicy, small: true, className: s.link, children: _jsx(FormattedMessage, { id: 'euhaNY', defaultMessage: 'Cookie Policy' }) })] })] }));
};
export { Footer };
