import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { loadDriver } from '../../../driver/actions/async/load-driver';
import { loadCities } from '../../../common/store/actions';
import { loadPartner } from './loadPartner';
const reloadPersonData = createAsyncThunk(`${namespace}/reload-data`, async (_, { dispatch }) => {
    await Promise.all([dispatch(loadCities()).unwrap(), dispatch(loadPartner()).unwrap()]);
    await dispatch(loadDriver()).unwrap();
});
export { reloadPersonData };
