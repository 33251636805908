import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setIsAppInitialized } from '../../../../app/actions';
import { loadDriver } from '../../../driver/actions/async/load-driver';
import { loadAllModels } from '../../../car/actions/async/load-all-models';
import { loadCarDocDefinitions } from '../../../car/actions/async/load-car-doc-definitions';
import { loadDriverDocDefinitions } from '../../../driver/actions/async/load-driver-doc-definitions';
import { loadAllCars } from '../../../car/actions/async/loadAllCars';
import { reloadFormForCurrentCar } from '../../../car/actions/async/reload-form-for-current-car';
import { loadCities } from '../../../common/store/actions';
import { getIsAppInitialized } from '../../../../app/store/selectors';
import { LoginRequiredError } from '../../../../shared/api/fetch-based-http-methods';
import { errorHandling } from '../../../../services/errorHandling';
import { PartnerBlockedError } from '../../api';
import { getCity, getPartnerType } from '../../store/selectors';
import { getRoles, ROLES, selectIsChauffeur, selectIsNew } from '../../../user/store/selectors';
import { loadUser } from '../../../user/actions';
import { loadPartner } from './loadPartner';
import { loadPartnerDocDefinitions } from './loadDocDefinitions';
import { InitialLoadError } from './errors';
import { setEmptyPartner } from './setEmptyPartner';
import { loadReferralCode } from './loadReferralCode';
const thunkName = `${namespace}/load-initial-data`;
const loadInitialData = createAsyncThunk(thunkName, async (_, { dispatch, getState }) => {
    try {
        const isAppInitialized = getIsAppInitialized(getState());
        if (isAppInitialized) {
            return;
        }
        await Promise.all([dispatch(loadUser()).unwrap(), dispatch(loadCities()).unwrap()]);
        const isNewUser = selectIsNew(getState());
        const isChauffeur = selectIsChauffeur(getState());
        if (isNewUser || isChauffeur) {
            await dispatch(setEmptyPartner()).unwrap();
        }
        else {
            await dispatch(loadPartner()).unwrap();
        }
        if (!isNewUser) {
            const partnerType = getPartnerType(getState());
            const userHasDriverRole = getRoles(getState()).includes(ROLES.DRIVER);
            await Promise.all([
                userHasDriverRole ? dispatch(loadReferralCode()).unwrap() : [],
                dispatch(loadDriver()).unwrap(),
                isChauffeur
                    ? []
                    : [
                        dispatch(loadPartnerDocDefinitions(partnerType)).unwrap(),
                        dispatch(loadCarDocDefinitions()).unwrap(),
                        dispatch(loadDriverDocDefinitions()).unwrap(),
                        dispatch(loadAllCars()).unwrap(),
                    ],
            ]);
        }
        const city = getCity(getState());
        await Promise.all([
            dispatch(loadAllModels(city)).unwrap(),
            dispatch(reloadFormForCurrentCar({})).unwrap(),
        ]);
        dispatch(setIsAppInitialized());
    }
    catch (e) {
        if (LoginRequiredError.is(e) || PartnerBlockedError.is(e)) {
            throw e;
        }
        errorHandling.captureException(e);
        throw new InitialLoadError(e?.message);
    }
});
export { loadInitialData };
