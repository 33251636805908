import { CountryCode } from '../../../shared/models/country-code';
import { eventTracking } from '../../../services/event-tracking';
import { getItem } from './getItem';
import { assignTipTextForCardItems } from './assignTipTextForCardItems';
const createDriversCards = (context, onNavigate, intl, user) => {
    const { items, cardStatus } = getItem({
        entities: context.drivers ? context.drivers : [],
        docDefinitions: context.driverUploadDefinitions,
        getTitle: driver => `${driver.firstName} ${driver.lastName}`,
        getItemAction: driver => () => {
            eventTracking.trackSnowplow({
                event: 'Onboarding - Documents - Open',
                type: 'chauffeur',
            });
            if (user.isFleet || user.isIndividualChauffeurRU || user.isFleetAndChauffeur) {
                onNavigate(`/chauffeurs/${driver.id}#docs`);
            }
            else {
                onNavigate(`/profile#docs`);
            }
        },
    });
    const itemsWithTips = assignTipTextForCardItems(intl, items);
    return [
        {
            id: 'driver',
            isBlocked: false,
            action: () => {
                onNavigate(`/add-chauffeur`);
            },
            actionTitle: user.isFleet || user.isIndividualChauffeurRU || user.isFleetAndChauffeur
                ? intl.formatMessage({ id: "HCoCiN", defaultMessage: "Add chauffeur" })
                : undefined,
            title: (() => {
                if (cardStatus === 'done') {
                    return intl.formatMessage({ id: "MO7l4F", defaultMessage: "Chauffeur documents approved" });
                }
                if (cardStatus === 'review') {
                    return intl.formatMessage({ id: "FexXr1", defaultMessage: "Chauffeur documents" });
                }
                return intl.formatMessage({ id: "xbgMpw", defaultMessage: "Add chauffeur documents" });
            })(),
            desc: (() => {
                switch (context.partner.countryCode) {
                    case CountryCode.GreatBritain:
                        return intl.formatMessage({ id: "Dcf6yY", defaultMessage: "DVLA Plastic Driving Licence and Private Hire Driver Licence (PCO) are required." });
                    case CountryCode.Russia:
                        return intl.formatMessage({ id: "iSFJDa", defaultMessage: "\u0412\u043E\u0434\u0438\u0442\u0435\u043B\u044C\u0441\u043A\u043E\u0435 \u0443\u0434\u043E\u0441\u0442\u043E\u0432\u0435\u0440\u0435\u043D\u0438\u0435, \u043F\u0430\u0441\u043F\u043E\u0440\u0442 \u0438 \u0441\u043F\u0440\u0430\u0432\u043A\u0430 \u043E \u043D\u0435\u0441\u0443\u0434\u0438\u043C\u043E\u0441\u0442\u0438." });
                    default:
                        return '';
                }
            })(),
            items: itemsWithTips,
            cardStatus,
        },
    ];
};
export { createDriversCards };
