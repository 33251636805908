import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { partnerAPI } from '../../api';
import { updatePartner } from '../actions';
import { getPartner } from '../../store/selectors';
import { getPhoneFromCodeAndNumber } from '../../../../shared/utils/phoneUtils';
let lastRequestId;
const updateContactInfo = createAsyncThunk(`${namespace}/submit-contact-info`, async (formValues, { dispatch, requestId, getState }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const partner = getPartner(getState());
        const phone = getPhoneFromCodeAndNumber({
            code: formValues.phoneCode,
            number: formValues.phoneNumber,
        });
        const result = await partnerAPI.updateContactInfo({ ...partner, phone });
        await dispatch(updatePartner({
            phone,
        }));
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { updateContactInfo };
