import format from 'date-fns/format';
import isFuture from 'date-fns/isFuture';
import { createClient } from '../../../../src-common/api/src/create-client';
import { w4dDriversRemoteAPI } from '../../../../src-common/api/definitions/w4d-drivers-endpoints';
import { get, post, put } from '../../../shared/api/fetch-based-http-methods';
import { prepareDate, prepareDateForRequest } from '../../../shared/api/prepare-date';
import { skipNullishDeep } from '../../../shared/utils/skip-nullish-deep';
import { createFormData } from '../../../shared/api/create-form-data';
import { prepareUploadedFile } from '../../../shared/models/uploaded-file';
import { licensingAuthorityTypesEnum, } from '../../../shared/models/licensingAuthority';
import { CountryCode } from '../../../shared/models/country-code';
import { getOnlineStatus } from '../../car/api';
import { getPhoneFromCodeAndNumber } from '../../../shared/utils/phoneUtils';
import { formatDate } from '../../../shared/utils/formatDate';
import { ENV } from '../../../env';
const client = createClient(w4dDriversRemoteAPI({ get, post, put, apiHost: ENV.API_URL }));
const prepareTransferRequest = (rawTransferRequest) => rawTransferRequest
    ? {
        status: rawTransferRequest.status,
        leavingAtDate: new Date(rawTransferRequest.left_at),
    }
    : undefined;
const prepareLicenseNumber = (rawLicenseNumber) => {
    if (!rawLicenseNumber || rawLicenseNumber === 'XXXXXX') {
        return '';
    }
    return rawLicenseNumber;
};
const prepareDriver = (driver) => ({
    homeAddress: driver.home_address || '',
    birthday: prepareDate(driver.birthday),
    ukNationalInsurance: driver.uk_national_insurance || '',
    licenseNumber: prepareLicenseNumber(driver.license_number),
    email: driver.email || '',
    firstName: driver.first_name,
    id: driver.id,
    lastName: driver.last_name,
    phone: driver.phone,
    rating: driver.rating,
    score: driver.score,
    testStatuses: driver.tests
        ? Object.fromEntries(driver.tests.map(({ test_id, status }) => [test_id, status]))
        : {},
    // TODO remove ?? 'active' when BE will support this field
    accessStatus: driver.access_status ?? 'active',
    docUploads: Object.fromEntries(driver.uploads?.map(rawUpload => [
        rawUpload.type,
        prepareUploadedFile(rawUpload),
    ]) ?? []),
    photoURL: (driver.photo || driver.photo_url) ?? undefined,
    licensingAuthority: driver.license_authority && driver.license_authority in licensingAuthorityTypesEnum
        ? driver.license_authority
        : null,
    lastActivity: driver.last_activity_at ? new Date(driver.last_activity_at) : undefined,
    carId: driver.car?.id,
    onlineStatus: driver.car?.current_driver_id === driver.id
        ? getOnlineStatus(driver.car?.status_hash || {})
        : 'offline',
    hasPassedAccreditation: !!driver.passed_accreditation,
});
const prepareDriverDetails = (raw) => ({
    ...prepareDriver(raw),
    partnerId: raw.partner?.id,
    transferRequest: prepareTransferRequest(raw.driver_transfer_request),
    hasContractWithPartner: raw.partner_driver_contract === 'approved',
    tags: raw.tags,
    acceptance: raw.acceptance,
    cityId: raw.city_id,
    blockInfo: raw.block_info
        ? {
            blockedBy: raw.block_info.blocked_by,
            blockedAt: raw.block_info.blocked_at ? new Date(raw.block_info.blocked_at) : null,
            notes: raw.block_info.notes,
            reason: raw.block_info.reason,
        }
        : undefined,
});
const prepareDriverUpdate = (update) => ({
    phone: update.phone,
    id: update.id,
});
const prepareWorkload = (rawWorkload) => {
    const timeMap = rawWorkload.intervals.reduce((hash, interval) => {
        const date = new Date(interval.ts);
        const time = format(date, 'HH:mm');
        const minutesOnline = Math.ceil(interval.online_minutes);
        const workloadData = {
            ts: interval.ts,
            minutesOnline,
            isFuture: isFuture(date),
        };
        if (time in hash) {
            hash[time].push(workloadData);
        }
        else {
            hash[time] = [workloadData];
        }
        return hash;
    }, {});
    return {
        hourlyWorkloads: timeMap,
        summary: {
            totalMinutes: rawWorkload.summary.online_minutes,
            amount: rawWorkload.summary.price,
        },
    };
};
const driverAPI = {
    sendTransferRequest: async (driverId) => {
        const response = await client.sendTransferRequest({
            pathParams: { driverId },
        });
        return prepareTransferRequest(response.driver_transfer_request);
    },
    fetchReferralCode: async () => {
        const response = await client.readReferral();
        return response.referral_info;
    },
    changeContractStatus: async (driverId, isActive) => {
        const response = await client.changeContractStatus({
            pathParams: { driverId },
            payload: { status: isActive ? 'approved' : 'rejected' },
        });
        if (response.driver) {
            return prepareDriverDetails(response.driver);
        }
        return undefined;
    },
    blockDriver: async (driverId, reason, message) => {
        const response = await client.blockDriver({
            pathParams: { id: driverId },
            payload: { blocked_reason: reason, blocked_reason_notes: message },
        });
        if (response.driver) {
            return prepareDriverDetails(response.driver);
        }
    },
    fetchMyDriver: async () => {
        const response = await client.readMyDriver();
        return prepareDriverDetails(response.driver);
    },
    fetchBriefDrivers: async ({ limit = 100, offset = 0, query = '', } = {}) => {
        const response = await client.readDrivers({ pathParams: { limit, skip: offset, query } });
        const items = response.results.map(prepareDriverDetails);
        return {
            items,
            total: response.total_entries,
            limit: response.limit,
            offset: response.skip,
        };
    },
    fetchDriverById: async (id) => {
        const response = await client.readDriverDetails({ pathParams: { id } });
        return prepareDriverDetails(response.driver);
    },
    createDriver: async (driverForm, countryCode) => {
        const response = await client.createDriver({
            payload: {
                email: driverForm.email,
                first_name: driverForm.firstName,
                home_address: driverForm.homeAddress,
                last_name: driverForm.lastName,
                license_number: driverForm.licenseNumber,
                phone: getPhoneFromCodeAndNumber({
                    code: driverForm.phoneCode,
                    number: driverForm.phoneNumber,
                }),
                uk_national_insurance: driverForm.ukNationalInsurance,
                license_authority: driverForm.licensingAuthority,
                middle_name: null,
                photo: null,
                birthday: prepareDateForRequest(driverForm.birthday),
                rating: null,
                created_at: '',
                score: null,
                acceptance: null,
                access_status: 'pending',
                last_activity_at: null,
                car_id: null,
                block_info: null,
                partner_driver_contract: countryCode === CountryCode.Russia ? 'approved' : null,
                passed_tests: [],
            },
        });
        if ('driver' in response) {
            return [prepareDriverUpdate(response.driver), undefined];
        }
        if ('errors' in response) {
            const validation = skipNullishDeep({
                phoneNumber: response.errors.phone,
                email: response.errors.email,
            }, false);
            const userExistsErrorMessage = response.errors.user_exists;
            if (userExistsErrorMessage) {
                if (userExistsErrorMessage.includes('email') && !response.errors.email) {
                    validation.email = userExistsErrorMessage;
                }
                else if (!response.errors.phone) {
                    validation.phoneNumber = userExistsErrorMessage;
                }
            }
            return [undefined, validation];
        }
        return [];
    },
    updateDriver: async (driverId, update) => {
        const response = await client.updateDriver({
            payload: {
                email: update.email,
                first_name: update.firstName,
                home_address: update.homeAddress,
                last_name: update.lastName,
                license_number: update.licenseNumber,
                phone: update.phone,
                uk_national_insurance: update.ukNationalInsurance,
                license_authority: update.licensingAuthority,
                car_id: update.carId,
            },
            pathParams: { id: driverId },
        });
        if (response.driver) {
            return [true];
        }
        const errors = { ...response.errors };
        const { license_authority, license_number, user_exists } = response.errors;
        if (license_authority) {
            errors.licensingAuthority = license_authority;
            delete errors.license_authority;
        }
        if (license_number) {
            errors.licenseNumber = license_number;
            delete errors.license_number;
        }
        if (user_exists) {
            errors['phone'] = user_exists;
            delete errors.user_exists;
        }
        return [false, errors];
    },
    fetchDocDefinitions: async () => {
        const response = await client.readDriverDocumentDefinitions();
        return response.map(raw => ({
            docType: raw.type,
            title: raw.caption,
            comment: raw.comment || undefined,
            desc: raw.description || undefined,
            exampleUrl: raw.example_url || undefined,
            isOptional: raw.optional,
            isExpires: raw.expires || false,
        }));
    },
    uploadFile: async (form, holderId) => {
        const response = await client.uploadDriverDocument({
            pathParams: { driverId: holderId },
            payload: createFormData(skipNullishDeep({
                type: form.docType,
                expires_at: form.expiresAt && formatDate(form.expiresAt),
                upload_file: form.file?.file,
            }, false)),
        });
        if (!('upload' in response)) {
            if ('errors' in response) {
                if (response.errors.file) {
                    return {
                        error: response.errors.file[0],
                    };
                }
            }
        }
        if ('upload' in response) {
            return {
                id: response.upload.id,
                type: response.upload.type,
            };
        }
        return {
            error: '',
        };
    },
    loadWorkload: async ({ driverId, from, to }) => {
        const response = await client.loadWorkload({
            pathParams: { driverId, from: from.toISOString(), to: to.toISOString() },
        });
        return prepareWorkload(response);
    },
};
export { driverAPI, prepareDriver, prepareDriverDetails };
