import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import React from 'react';
import { Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { AuthProvider } from '@wheely/web-auth-lib';
import { NotificationProvider, DialogsProvider } from '@wheely/ui-kit';
import { CurrentPageSpanStoreManager } from '@wheely/web-tracing';
import { history } from './services/history';
import { App } from './app/view/App';
import { rootStore } from './root-store';
import { eventTracking } from './services/event-tracking';
import './services/errorHandling';
import { IntlProvider } from './i18n';
import { authAPI } from './modules/auth/api';
import { AUTH_COOKIE_KEY, getSessionFromCookie, getSessionFromIDB, removeIDBSession, } from './shared/utils/session';
import { PermissionsProvider } from './app/contexts/permissions';
import { deleteCookie } from './shared/utils/cookie';
import { Tracer } from './app/view/Tracer';
import { ENV } from './env';
eventTracking.init();
// Remove old service worker
if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
            registration.unregister();
        }
    });
}
CurrentPageSpanStoreManager.configure({
    tracesEndpoint: '/api/traces',
    tracesResourceName: 'front-chauffeur-onboarding',
    tracesCorsUrls: [ENV.API_URL],
    tracesConsoleEnabled: false,
    tracesResourceVersion: '0.0.3-alpha',
    tracesInstumentationUse: {
        fetch: true,
        documentLoad: true,
        xhr: true,
    },
});
const renderApp = async () => {
    const node = document.querySelector('.react-app-root');
    if (node === null) {
        throw new Error(`onboarding wizard fail: can't find element .react-app-root`);
    }
    // initialize session from cookie (can be set by wheely.com site)
    const savedCookieSession = getSessionFromCookie();
    // old session from indexedDB
    let session;
    try {
        const savedIDBSession = await getSessionFromIDB();
        if (savedIDBSession) {
            await authAPI.setCurrentSession(savedIDBSession);
            await removeIDBSession();
            session = savedIDBSession;
        }
        else if (savedCookieSession) {
            deleteCookie(AUTH_COOKIE_KEY);
            session = await authAPI.setCurrentSession(savedCookieSession);
        }
        else {
            session = await authAPI.restoreSession();
        }
    }
    catch (error) {
        session = undefined;
    }
    const initialIsAuthenticated = !!session?.activated;
    ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(AuthProvider, { initialIsAuthenticated: initialIsAuthenticated, authApi: authAPI, children: _jsx(ReduxProvider, { store: rootStore, children: _jsx(IntlProvider, { children: _jsxs(Router, { history: history, children: [_jsx(Tracer, {}), _jsx(DialogsProvider, { children: _jsx(NotificationProvider, { children: _jsx(PermissionsProvider, { children: _jsx(App, {}) }) }) })] }) }) }) }) }), node);
};
export { renderApp };
