import { createUploadSlice, createUploadSliceUtils, } from '../../../shared/store/doc-uploader/create-upload-slice';
import { driverAPI } from '../api';
import { getCurrentDriverId, getMyDriverId, getUploadedDocsForCurrentDriver, getUploadedDocsForMyDriver, } from './selectors';
const driverUploadNamespace = 'driverDocUpload';
const myDriverUploadNamespace = 'myDriverDocUpload';
const driverUploadSlice = createUploadSlice('photo', driverUploadNamespace);
const myDriverUploadSlice = createUploadSlice('photo', myDriverUploadNamespace);
const { selectors: driverUploadSelectors, asyncActions: driverUploadAsyncActions } = createUploadSliceUtils({
    namespace: driverUploadNamespace,
    eventTrackingType: 'chauffeur',
    actionSetBusy: driverUploadSlice.actions.setPending,
    selectHolderId: getCurrentDriverId,
    selectUploadedDocs: getUploadedDocsForCurrentDriver,
    uploadFile: driverAPI.uploadFile,
    getSliceState: (state) => state[driverUploadNamespace],
});
const { selectors: myDriverUploadSelectors, asyncActions: myDriverUploadAsyncActions } = createUploadSliceUtils({
    namespace: myDriverUploadNamespace,
    eventTrackingType: 'chauffeur',
    actionSetBusy: myDriverUploadSlice.actions.setPending,
    selectHolderId: getMyDriverId,
    selectUploadedDocs: getUploadedDocsForMyDriver,
    uploadFile: driverAPI.uploadFile,
    getSliceState: (state) => state[myDriverUploadNamespace],
});
export { driverUploadSlice, driverUploadSelectors, driverUploadAsyncActions, driverUploadNamespace, myDriverUploadSlice, myDriverUploadSelectors, myDriverUploadAsyncActions, myDriverUploadNamespace, };
