import { DEFAULT_IDB_STORAGE_CONFIG, DEFAULT_STORAGE_KEY, IndexedDBStorage, transformStoredSession, } from '@wheely/web-auth-lib';
import { getCookieValue } from './cookie';
export const AUTH_COOKIE_KEY = 'wheely-driver-signup';
const iDBStorage = new IndexedDBStorage(DEFAULT_IDB_STORAGE_CONFIG);
const getSessionFromCookie = () => {
    const savedCookieSession = getCookieValue(AUTH_COOKIE_KEY);
    if (!savedCookieSession) {
        return undefined;
    }
    return {
        accessToken: savedCookieSession.access_token || savedCookieSession.token,
        refreshToken: savedCookieSession.refresh_token,
        activated: true,
        expiresAt: isFinite(savedCookieSession.expires_at)
            ? new Date(+savedCookieSession.expires_at)
            : new Date(),
    };
};
const getSessionFromIDB = async () => {
    const rawOldSession = await iDBStorage.getItem(DEFAULT_STORAGE_KEY);
    if (rawOldSession) {
        return transformStoredSession(rawOldSession);
    }
};
const removeIDBSession = async () => iDBStorage.removeItem(DEFAULT_STORAGE_KEY);
export { getSessionFromCookie, getSessionFromIDB, removeIDBSession };
