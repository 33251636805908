import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import { ChangeEmailTip } from '../../../shared/view/ChangeEmailTip';
import s from './PersonalInfo.css';
const mapToPersonalInfo = (driver) => ({
    birthday: driver.birthday,
    phone: driver.phone,
    email: driver.email,
});
const PersonalInfo = ({ className, driver }) => {
    const intl = useIntl();
    const titles = useMemo(() => [
        [
            'phone',
            intl.formatMessage({ id: "DT8Cy2", defaultMessage: "Phone" }),
        ],
        [
            'birthday',
            intl.formatMessage({ id: "lN7jvS", defaultMessage: "Date of birth" }),
        ],
        [
            'email',
            intl.formatMessage({ id: "x7vKKw", defaultMessage: "Email" }),
        ],
    ], [intl]);
    const values = useMemo(() => mapToPersonalInfo(driver), [driver]);
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "K02gga", defaultMessage: "Personal info" }), className: className, values: values, titles: titles, action: _jsx(Link, { to: `/chauffeurs/${driver.id}/edit-info`, className: s.edit, children: intl.formatMessage({ id: "zavkZT", defaultMessage: "Edit" }) }), children: _jsx(ChangeEmailTip, {}) }));
};
export { PersonalInfo };
