import { isLeft } from 'fp-ts/Either';
import { SimpleReporter } from './reporter';
class ValidationError extends Error {
    constructor(result) {
        super('Validation Error');
        this.result = result;
        this.name = 'ApiValidationError';
    }
    static is(x) {
        return this.name === 'ApiValidationError';
    }
}
const validate = (type, raw, errorSource) => {
    const result = type.decode(raw);
    if (isLeft(result)) {
        // eslint-disable-next-line no-console
        console.error(`Could not validate data for ${errorSource}:\n${SimpleReporter.report(result).join('\n')}`);
        throw new ValidationError(result);
    }
    return result.right;
};
export { validate, ValidationError };
