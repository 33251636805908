import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getPartner } from '../../../../modules/partner/store/selectors';
import { useForm } from '../../../../shared/view/Form';
import { eventTracking } from '../../../../services/event-tracking';
import { Form } from './Form';
import { useVehicleByClassList } from './hooks/useVehicleByClassList';
const VehicleSelection = ({ onSwitchScreen }) => {
    const partner = useSelector(getPartner);
    const options = useVehicleByClassList(partner.cityName);
    const postForm = useCallback(async (formValues) => {
        onSwitchScreen('NEXT');
        eventTracking.track({
            event: formValues.vehicle === 'None'
                ? 'Onboarding - Vehicle - Failed'
                : 'Onboarding - Vehicle - Success',
            type: formValues.vehicle,
        });
        return {};
    }, [onSwitchScreen]);
    const { handleSubmit, form: { control, formState }, } = useForm({
        preSaveStore: {
            storeId: `vehicleStepForm-${partner.id}`,
            type: 'memory',
        },
        onSubmit: postForm,
    });
    return (_jsx(Form, { items: options, control: control, onSubmit: handleSubmit, isSubmitting: formState.isSubmitting, isValid: formState.isValid }));
};
export { VehicleSelection };
