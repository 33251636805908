import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { H2, H5, AnchorButton } from '@wheely/ui-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '../../../src-common/view/components/Icon';
import { CenteringContainer } from '../../shared/view/CenteringContainer';
import { useExternalLinks } from '../../app/hooks/useExternalLinks';
import s from './styles.css';
import { CardView } from './components/Card';
const sortedStatuses = ['rejected', 'todo', 'review', 'done'];
const defaultSectionTitles = {
    done: (_jsx(FormattedMessage, { id: 'LyJtaR', defaultMessage: 'DONE' })),
    rejected: (_jsx(FormattedMessage, { id: 'dVPJv1', defaultMessage: 'REJECTED' })),
    review: (_jsx(FormattedMessage, { id: '7B8OP4', defaultMessage: 'IN REVIEW' })),
    todo: (_jsx(FormattedMessage, { id: 'rAV2s3', defaultMessage: 'TO DO' })),
};
const calculateProgress = (cards) => {
    const completedCount = cards.filter(card => !card.isExcludedFromProgress && card.cardStatus === 'done').length;
    const total = cards.filter(card => !card.isExcludedFromProgress).length;
    return Math.floor((completedCount / total) * 100);
};
const ToDoView = memo(({ cards, sectionTitles = defaultSectionTitles, hasTitle, hasProgress }) => {
    const intl = useIntl();
    const links = useExternalLinks();
    const statuses = new Set(cards.map(card => card.cardStatus));
    const withStatus = (cardStatus) => cards.filter(card => card.cardStatus === cardStatus);
    return (_jsxs(CenteringContainer, { children: [hasTitle && (_jsx(H2, { className: s.title, "data-test-id": "todo__header", children: intl.formatMessage({ id: "Ouf4Zl", defaultMessage: "TO-DO LIST" }) })), _jsxs("div", { className: s.faqSection, children: [_jsx(H5, { className: s.faqDescription, children: _jsx(FormattedMessage, { id: 'T7BLD2', defaultMessage: 'Find useful information about registration, adding the vehicle and more.' }) }), _jsx(AnchorButton, { href: links.faq, intent: "none", minimal: true, className: s.faqLink, rightIcon: _jsx(Icon, { icon: "arrow-to-end-in-circle", size: "24px", className: s.faqLinkIcon }), children: _jsx(H5, { tag: "span", children: _jsx(FormattedMessage, { id: 'cZqaMX', defaultMessage: 'Help & FAQ' }) }) })] }), sortedStatuses.map((cardStatus) => statuses.has(cardStatus) ? (_jsxs("section", { className: s.section, "data-test-id": `todo__section__${cardStatus}`, children: [_jsxs(H5, { className: s.sectionTitle, "data-test-id": "todo__section__title", children: [sectionTitles[cardStatus], hasProgress && cardStatus === 'todo' && (_jsx("span", { className: s.progress, "data-test-id": "todo__progress", children: intl.formatMessage({ id: "wPV2Fk", defaultMessage: "{progress}% done" }, {
                                    progress: calculateProgress(cards),
                                }) }))] }), withStatus(cardStatus).map(card => (_createElement(CardView, { ...card, key: card.id })))] }, cardStatus)) : null)] }));
});
ToDoView.displayName = 'ToDoView';
export { ToDoView };
