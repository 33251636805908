import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { forwardInputRef } from '../types/input';
import { InputBase } from '../InputBase';
import { Icon } from '../Icon';
import { setInputElementValue } from '../utils/setInputElementValue';
import { useMergedRef } from '../utils/useMergedRef';
import s from './index.css';
const TextInput = forwardInputRef((props, ref) => {
    const { value: inputValue, name, onChange, hasError, isDisabled, startElem, placeholder, dataTestId, onBlur, } = props;
    const inputMutableRef = useRef(null);
    const mergedRef = useMergedRef(ref, inputMutableRef);
    const handleChange = useCallback((event) => {
        onChange(event, {
            name,
            value: event.currentTarget.value,
        });
    }, [name, onChange]);
    const handleClear = useCallback(() => {
        if (inputMutableRef.current) {
            setInputElementValue(inputMutableRef.current, '');
            inputMutableRef.current.focus();
        }
    }, [inputMutableRef]);
    return (_jsx(InputBase, { dataTestId: dataTestId, placeholder: placeholder, onChange: handleChange, onBlur: onBlur, name: name, disabled: isDisabled, hasError: hasError, type: "text", ref: mergedRef, value: inputValue, startElement: startElem, endElement: inputValue !== '' &&
            !isDisabled && (_jsx("button", { tabIndex: -1, className: s.clearButton, onClick: handleClear, type: "button", "data-test-id": "clear-button", children: _jsx(Icon, { size: "16px", icon: 'x-circle-filled', className: s.clearIcon }) })) }));
});
export { TextInput };
