import { scrollToFirstError } from '../view/scroll';
const applyServerValidation = (setError, errors) => {
    if (!errors) {
        return;
    }
    Object.entries(errors).forEach(([key, value]) => {
        const fieldName = key;
        const fieldErrors = value;
        if (fieldErrors?.length) {
            setError(fieldName, {
                message: fieldErrors[0],
                type: 'server validations',
            });
        }
    });
    scrollToFirstError();
};
export { applyServerValidation };
