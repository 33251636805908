import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useImperativeHandle, useRef } from 'react';
import cn from 'classnames';
import s from './index.css';
const isSubList = (item) => 'items' in item;
const getCurrentItem = (rootNodeRef, value) => {
    const button = rootNodeRef.current?.querySelector(`.${s.itemButton}[name="${value}"]`);
    return (button ?? rootNodeRef.current?.querySelector(`.${s.itemButton}`) ?? null);
};
const renderListItem = (item, handleChange) => (_jsx("li", { className: s.item, children: _jsx("button", { className: s.itemButton, name: item.value, type: "button", onClick: handleChange, "data-test-id": "list-input__button", children: item.element ?? item.title }) }, item.value));
const renderSubList = ({ items, title }, handleChange) => (_jsxs("li", { className: s.subListWrapper, "data-test-id": "list-input__sub-group", children: [_jsx("div", { className: s.subTitle, "data-test-id": "list-input__sub-group-title", children: title }), _jsx("ul", { className: s.subList, children: items.map(subItem => renderListItem(subItem, handleChange)) })] }, `#sublist-${title}`));
const ListInput = React.forwardRef((props, ref) => {
    const { items, name, value: inputValue, onChange, className, maxVisibleItems = 10 } = props;
    const rootNode = useRef(null);
    const handleChange = useCallback((event) => {
        const value = event.currentTarget.name;
        onChange(event, { name, value });
    }, [onChange, name]);
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Tab') {
            const buttons = [
                ...(rootNode.current?.querySelectorAll(`.${s.itemButton}`) ?? []),
            ];
            const current = rootNode.current?.querySelector(`.${s.itemButton}:focus`);
            const currentIdx = current ? buttons.indexOf(current) : 0;
            const offset = event.key === 'ArrowUp' ? -1 : 1;
            const nextIdx = (buttons.length + currentIdx + offset) % buttons.length;
            requestAnimationFrame(() => buttons[nextIdx]?.focus());
        }
    }, []);
    useImperativeHandle(ref, () => ({
        blur: () => {
            rootNode.current?.blur();
        },
        focus: (options) => {
            getCurrentItem(rootNode, inputValue)?.focus(options);
        },
        scrollIntoView: () => {
            rootNode.current?.scrollIntoView();
        },
    }), [inputValue]);
    return (_jsx("div", { "data-test-id": "list-input", className: cn(s.root, className), onKeyDown: handleKeyDown, ref: rootNode, style: { '--max-visible-items': maxVisibleItems }, children: _jsx("ul", { className: s.list, children: items.map(item => {
                if (isSubList(item)) {
                    return renderSubList(item, handleChange);
                }
                else {
                    return renderListItem(item, handleChange);
                }
            }) }) }));
});
export { ListInput, isSubList };
