import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import { useDialog, H3, Button } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from '../utils';
import { FilterItem } from './FilterItem';
import s from './FiltersDialog.css';
const FiltersDialog = ({ filtersConfig, onApplyFilters, onRemoveFilters, initialValues, onClose, }) => {
    const [filterValues, setFilterValues] = useState(initialValues);
    const onChange = useCallback((_, { name, value }) => {
        setFilterValues(oldFilters => ({ ...oldFilters, [name]: value }));
    }, []);
    const handleApplyFilters = useCallback(() => {
        onApplyFilters(filterValues);
        onClose();
    }, [filterValues, onApplyFilters, onClose]);
    const handleClearFilters = useCallback(() => {
        onRemoveFilters();
        onClose();
    }, [onRemoveFilters, onClose]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.filtersList, children: filtersConfig.map(({ title, name, placeholder, options, type, icon, hasOneLineLabel }) => (_jsx("div", { className: s.item, children: _jsx(FilterItem, { value: filterValues, onChange: onChange, title: title, name: name, placeholder: placeholder, options: options, type: type, icon: icon, hasOneLineLabel: hasOneLineLabel }) }, name))) }), _jsxs("div", { className: s.footerButtons, children: [_jsx(Button, { onClick: handleApplyFilters, className: s.applyButton, children: _jsx(FormattedMessage, { id: 'vXHNL9', defaultMessage: 'Apply' }) }), !isEmpty(filterValues) && (_jsx(Button, { onClick: handleClearFilters, className: s.clearButton, minimal: true, children: _jsx(FormattedMessage, { id: 'Zl67C/', defaultMessage: 'Clear filters' }) }))] })] }));
};
const useFiltersDialog = ({ filtersConfig, onApplyFilters, onRemoveFilters, initialValues, }) => {
    const { openDialog } = useDialog();
    const openFiltersDialog = () => {
        openDialog(({ onClose }) => (_jsx(FiltersDialog, { filtersConfig: filtersConfig, initialValues: initialValues, onApplyFilters: onApplyFilters, onRemoveFilters: onRemoveFilters, onClose: onClose })), {
            title: (_jsx(H3, { children: _jsx(FormattedMessage, { id: 'hDO1G9', defaultMessage: 'Filter journeys' }) })),
            className: s.filtersDialog,
            titleClassName: s.filtersDialogTitle,
            bodyClassName: s.filtersDialogBody,
        });
    };
    return { openModal: openFiltersDialog };
};
export { FiltersDialog, useFiltersDialog };
