import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import cn from 'classnames';
import { H5 } from '@wheely/ui-kit';
import { Icon } from '../../../../../src-common/view/components/Icon';
import s from './styles.css';
const Item = ({ title, internalLink, action, status, tipIntent = 'info', tipText }) => (_jsxs("button", { "data-url": internalLink, onClick: action, className: s.item, "data-test-id": "todo-card-item", children: [_jsxs("div", { className: s.itemLeft, children: [_jsx("div", { className: s.itemTitle, children: title }), status && (_jsx("div", { "data-test-id": "todo-card-item__status", className: cn({
                        [s.itemDanger]: tipIntent === 'danger',
                        [s.itemPending]: tipIntent === 'info',
                        [s.itemActive]: tipIntent === 'success',
                    }), children: tipText }))] }), _jsx(Icon, { icon: "chevron-to-end", className: s.itemIcon })] }, title));
const CardView = React.memo(({ actionTracker, action, cardStatus, isBlocked, title, desc, items, actionTitle, actionExternalLink, }) => {
    const isDone = cardStatus === 'done';
    const handleClickAction = useCallback(() => {
        actionTracker?.();
        action?.();
    }, [action, actionTracker]);
    return (_jsxs("div", { className: cn(s.card, { [s.cardBlocked]: isBlocked }), "data-test-id": "todo-card", children: [_jsxs(H5, { capitalize: true, className: s.cardTitle, "data-test-id": "todo-card__title", children: [title, isBlocked && (_jsx(Icon, { icon: "lock", className: s.iconBlock, size: "24px", "data-test-id": "todo-card__blocked-icon" })), isDone && (_jsx(Icon, { icon: "checkmark-in-circle", className: s.iconDone, size: "24px", "data-test-id": "todo-card__done-icon" }))] }), !isDone && (_jsx("p", { className: s.desc, "data-test-id": "todo-card__description", children: desc })), items && !isBlocked && !isDone && items.map((item, idx) => _createElement(Item, { ...item, key: idx })), actionTitle &&
                !isBlocked &&
                !isDone &&
                (action ? (_jsxs("button", { className: s.action, onClick: handleClickAction, "data-test-id": "todo-card__action-button", children: [_jsx(H5, { tag: "span", capitalize: true, children: actionTitle }), _jsx(Icon, { icon: "arrow-to-end-in-circle", className: s.actionIcon, size: "24px" })] })) : (_jsxs("a", { href: actionExternalLink, className: s.action, target: '_blank', rel: "noreferrer", onClick: actionTracker, "data-test-id": "todo-card__action-link", children: [_jsx(H5, { tag: "span", capitalize: true, children: actionTitle }), _jsx(Icon, { icon: "arrow-to-end-in-circle", className: s.actionIcon, size: "24px" })] })))] }));
});
CardView.displayName = 'CardView';
export { CardView };
