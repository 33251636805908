import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from '../Tag';
import { getStatusTitle } from './getStatusTitle';
const getIntent = (onlineStatus) => {
    switch (onlineStatus) {
        case 'online':
            return 'success';
        case 'busy':
            return 'warning';
        case 'offline':
        default:
            return 'default';
    }
};
const StatusTag = ({ status }) => {
    const intent = getIntent(status);
    const title = getStatusTitle(status);
    return _jsx(Tag, { intent: intent, children: title });
};
export { StatusTag };
