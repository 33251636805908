import { createUploadSlice, createUploadSliceUtils, } from '../../../shared/store/doc-uploader/create-upload-slice';
import { partnerAPI } from '../api';
import { getPartnerId, getUploadedDocsForPartner } from './selectors';
const partnerUploadNamespace = 'partnerDocUpload';
const partnerUploadSlice = createUploadSlice('user_agreement', partnerUploadNamespace);
const { selectors: partnerUploadSelectors, asyncActions: partnerUploadAsyncActions } = createUploadSliceUtils({
    namespace: partnerUploadNamespace,
    eventTrackingType: 'company',
    actionSetBusy: partnerUploadSlice.actions.setPending,
    selectHolderId: getPartnerId,
    selectUploadedDocs: getUploadedDocsForPartner,
    uploadFile: partnerAPI.uploadFile,
    getSliceState: (state) => state[partnerUploadNamespace],
});
export { partnerUploadSlice, partnerUploadSelectors, partnerUploadAsyncActions, partnerUploadNamespace, };
