import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Switch, Route, useHistory, Redirect, useLocation, matchPath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth } from '@wheely/web-auth-lib';
import { trackPageView } from '@snowplow/browser-tracker';
import { ToDo, Overview, Bills, Profile, ProfilePayments, ProfileContactInformation, ProfileChauffeur, Journeys, Journey, Vehicles, Vehicle, Chauffeurs, Chauffeur, ChauffeurEditInfo, ChauffeurEditVehicle, AddVehicle, AddChauffeur, TypeFormRedirect, Login, Wizard, } from '../../../pages';
import { MainLayout } from '../../../shared/view/MainLayout';
import { SwapTransition } from '../../../shared/view/SwapTransition';
import { loadInitialData } from '../../../modules/partner/actions/async/loadInitialData';
import { usePermissionsContext } from '../../contexts/permissions';
import { ErrorBySlug, ErrorWrapper } from '../../../shared/view/Error';
import { startPressurePolling, stopPressurePolling } from '../../../modules/common/store/actions';
import s from './style.css';
const match = (config, notFoundRender, currentPath) => {
    for (const pattern in config) {
        const result = matchPath(currentPath, {
            path: pattern,
            exact: true,
        });
        if (result) {
            return config[pattern](result.params);
        }
    }
    return notFoundRender();
};
const MainRoutes = () => {
    const { isAuthenticated } = useAuth();
    const dispatch = useDispatch();
    const acl = usePermissionsContext();
    useEffect(() => {
        isAuthenticated && dispatch(loadInitialData());
    }, [dispatch, isAuthenticated]);
    useEffect(() => {
        if (isAuthenticated && acl.can.viewPressure) {
            dispatch(startPressurePolling());
        }
        return () => {
            dispatch(stopPressurePolling());
        };
    }, [acl.can.viewPressure, dispatch, isAuthenticated]);
    const transitionTargetElementRef = useRef(null);
    const controlTransitionRef = useRef(null);
    const location = useLocation();
    const history = useHistory();
    const onNavigate = useCallback(async (uri, replace = false) => {
        if (replace) {
            history.replace(uri);
        }
        else {
            history.push(uri);
        }
    }, [history]);
    const onNavigateBack = useCallback(async (uri) => {
        controlTransitionRef.current?.reverseNextTransition();
        history.goBackSmart(uri);
    }, [history]);
    const screens = useMemo(() => match({
        '/todo': () => _jsx(ToDo, { onNavigate: onNavigate }),
        '/bills': () => _jsx(Bills, {}),
        '/overview': () => _jsx(Overview, {}),
        '/profile': () => _jsx(Profile, { onNavigate: onNavigate, onNavigateBack: onNavigateBack }),
        '/profile/payments': () => (_jsx(ProfilePayments, { onGoBack: () => onNavigateBack('/profile') })),
        '/profile/contact-information': () => (_jsx(ProfileContactInformation, { onGoBack: () => onNavigateBack('/profile') })),
        '/profile/chauffeur': () => _jsx(ProfileChauffeur, { onNavigateBack: onNavigateBack }),
        '/journeys': () => _jsx(Journeys, {}),
        '/journeys/:journeyId': ({ journeyId }) => (_jsx(Journey, { journeyId: journeyId, onNavigateBack: onNavigateBack })),
        '/add-vehicle': () => (_jsx(AddVehicle, { onNavigate: onNavigate, onNavigateBack: onNavigateBack })),
        '/vehicles': () => _jsx(Vehicles, { onNavigate: onNavigate }),
        '/vehicles/:carId': ({ carId }) => (_jsx(Vehicle, { carId: carId, onNavigateBack: onNavigateBack })),
        '/add-chauffeur': () => (_jsx(AddChauffeur, { onNavigate: onNavigate, onNavigateBack: onNavigateBack })),
        '/chauffeurs': () => _jsx(Chauffeurs, { onNavigate: onNavigate }),
        '/chauffeurs/:driverId': () => _jsx(Chauffeur, { onNavigateBack: onNavigateBack }),
        '/chauffeurs/:driverId/edit-info': () => (_jsx(ChauffeurEditInfo, { onNavigateBack: onNavigateBack })),
        '/chauffeurs/:driverId/edit-vehicle': () => (_jsx(ChauffeurEditVehicle, { onNavigateBack: onNavigateBack })),
    }, () => _jsx(ErrorBySlug, { slug: '404' }), location.pathname), [location.pathname, onNavigate, onNavigateBack]);
    const { can, isReady } = usePermissionsContext();
    if (!isAuthenticated) {
        return _jsx(Redirect, { to: "/login" });
    }
    if (!isReady) {
        return null;
    }
    return (_jsxs(Switch, { children: [_jsx(Redirect, { path: "/", exact: true, to: can.viewWizard ? '/join' : '/todo' }), _jsx(Route, { path: '/join', component: Wizard }), _jsx(Route, { children: _jsx(SwapTransition, { targetElementRef: transitionTargetElementRef, transitionKey: location.pathname, ref: controlTransitionRef, children: _jsx(MainLayout, { onNavigate: onNavigate, children: _jsx("div", { ref: transitionTargetElementRef, className: s.screens, children: screens }) }) }) })] }));
};
const App = React.memo(() => {
    const { pathname, search } = useLocation();
    useEffect(() => {
        trackPageView();
    }, [pathname, search]);
    return (_jsx(ErrorWrapper, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/typeform-redirect/:testId", component: TypeFormRedirect }), _jsx(Route, { path: "/login", component: Login }), _jsx(Route, { component: MainRoutes })] }) }));
});
App.displayName = 'App';
export { App };
