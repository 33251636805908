import * as t from 'io-ts';
const w4dJourneyReportRemoteAPI = ({ apiHost, post }) => ({
    sendReport: {
        path: `${apiHost}/v6/partners/self/orders_report`,
        payload: t.type({
            driver_id: t.string,
            from: t.string,
            to: t.string,
        }),
        method: post,
        response: t.type({
            enqueued: t.string,
        }),
    },
});
export { w4dJourneyReportRemoteAPI };
