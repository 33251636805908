import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionList } from '../../shared/view/DescriptionList';
import { IntlContext } from '../../i18n';
import { CountryCode } from '../../shared/models/country-code';
import s from './styles.css';
const isEmpty = (x) => Object.values(x).filter(Boolean).length === 0;
const mapToPaymentInfo = (partner) => ({
    accountNum: partner.paymentAccount,
    rasAccount: partner.paymentRasAccount,
    swift: partner.paymentSwift,
    iban: partner.paymentIban,
    sortCode: partner.paymentSortCode,
    bik: partner.paymentBik,
    bankName: partner.paymentBankName,
    correspondentAccount: partner.paymentKorAccount,
});
const PaymentInfo = ({ className, partner, onClickEdit }) => {
    const intl = useIntl();
    const { region } = useContext(IntlContext);
    const titles = useMemo(() => {
        switch (region) {
            case CountryCode.Russia:
                return [
                    [
                        'rasAccount',
                        intl.formatMessage({ id: "m7vyaO", defaultMessage: "Account number" }),
                    ],
                    [
                        'bik',
                        intl.formatMessage({ id: "x3aYxn", defaultMessage: "BIC" }),
                    ],
                    [
                        'bankName',
                        intl.formatMessage({ id: "M5lDg5", defaultMessage: "Bank name" }),
                    ],
                    [
                        'correspondentAccount',
                        intl.formatMessage({ id: "Nbm+ly", defaultMessage: "Correspondent account" }),
                    ],
                ];
            case CountryCode.France:
                return [
                    [
                        'swift',
                        intl.formatMessage({ id: "GBRjyQ", defaultMessage: "Swift" }),
                    ],
                    [
                        'iban',
                        intl.formatMessage({ id: "u+7Hx2", defaultMessage: "IBAN" }),
                    ],
                ];
            case CountryCode.ArabEmirates:
                return [
                    [
                        'iban',
                        intl.formatMessage({ id: "u+7Hx2", defaultMessage: "IBAN" }),
                    ],
                    [
                        'bik',
                        intl.formatMessage({ id: "lk/sna", defaultMessage: "BIC" }),
                    ],
                ];
            default:
                return [
                    [
                        'accountNum',
                        intl.formatMessage({ id: "m7vyaO", defaultMessage: "Account number" }),
                    ],
                    [
                        'sortCode',
                        intl.formatMessage({ id: "j10CLn", defaultMessage: "Sort code" }),
                    ],
                ];
        }
    }, [intl, region]);
    const values = useMemo(() => mapToPaymentInfo(partner), [partner]);
    const paymentInfoIsEmpty = isEmpty(values);
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "fQSGt7", defaultMessage: "Payment info" }), className: className, values: values, titles: titles, action: _jsx("span", { onClick: onClickEdit, className: s.editButton, children: paymentInfoIsEmpty
                ? intl.formatMessage({ id: "H4zI7T", defaultMessage: "Add" })
                : intl.formatMessage({ id: "zavkZT", defaultMessage: "Edit" }) }) }));
};
export { PaymentInfo };
