import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryCode } from '../../modules/common/store/selectors';
import { eventTracking } from '../../services/event-tracking';
import { useForm } from '../../shared/view/Form';
import { carUploadSelectors } from '../../modules/car/store/upload-slice';
import { prepareUploadCarForm } from '../../modules/car/actions/async/prepare-upload-car-form';
import { createCar } from '../../modules/car/actions/async/createCar';
import { getCarForm } from '../../modules/car/store/selectors';
import { useFormOptions } from '../../modules/car/hooks/useFormOptions';
import { VehicleFormView } from './View';
const BaseVehicleForm = memo(({ pageNameForEvent, onOpenUpload, onFinishSubmit, onSkipSubmit, textSubmitButton, isSubmitting, }) => {
    useEffect(() => {
        eventTracking.trackSnowplow({
            event: 'Onboarding - Car - Open',
            source: pageNameForEvent,
        });
    }, [pageNameForEvent]);
    const dispatch = useDispatch();
    const countryCode = useSelector(getCountryCode);
    const carForm = useSelector(getCarForm);
    const docs = useSelector(carUploadSelectors.getDocsList);
    const handleFormSubmit = useCallback(async ({ formValues }) => {
        if (carForm.id) {
            onSkipSubmit();
            return {};
        }
        const thunkResult = await dispatch(createCar({
            formValues,
            onSuccess: (id) => {
                eventTracking.track({
                    event: 'Onboarding - Car - Success',
                    source: pageNameForEvent,
                    state: docs.filter(doc => doc.status === 'missing').length === 0,
                });
                onFinishSubmit(id);
            },
        })).unwrap();
        if (thunkResult.result?.validationErrors) {
            return { validationErrors: thunkResult.result.validationErrors };
        }
        return {};
    }, [carForm.id, dispatch, onSkipSubmit, pageNameForEvent, docs, onFinishSubmit]);
    const { handleSubmit, form } = useForm({
        mode: 'onBlur',
        onSubmit: formValues => handleFormSubmit({ formValues }),
        values: carForm,
    });
    const { control, watch, formState, trigger } = form;
    const currentFormValues = watch();
    const formOptions = useFormOptions(currentFormValues.make, currentFormValues.model);
    const handleClickDocListItem = useCallback(async (event, doc) => {
        const isFormValid = await trigger();
        if (!isFormValid) {
            return;
        }
        dispatch(prepareUploadCarForm({
            docType: doc.docType,
            onSuccess: onOpenUpload,
            vehicleFormValues: currentFormValues,
        }));
    }, [dispatch, onOpenUpload, trigger, currentFormValues]);
    return (_jsx(VehicleFormView, { isSubmitting: isSubmitting || formState.isSubmitting, docs: docs, onDocListItemClick: handleClickDocListItem, onSubmit: handleSubmit, control: control, countryCode: countryCode, textSubmitButton: textSubmitButton, options: formOptions }));
});
BaseVehicleForm.displayName = 'VehicleForm';
export { BaseVehicleForm };
