import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseVehicleForm } from '../../../VehicleCreate/BaseForm';
import { fsmSelectors } from '../../../../modules/onboarding/store/fsm-slice';
import { loadCarDocDefinitions } from '../../../../modules/car/actions/async/load-car-doc-definitions';
const Vehicle = ({ onSwitchScreen }) => {
    const dispatch = useDispatch();
    const isSubmitting = useSelector(fsmSelectors.isBusy);
    useEffect(() => {
        dispatch(loadCarDocDefinitions());
    }, [dispatch]);
    return (_jsx(BaseVehicleForm, { onOpenUpload: () => onSwitchScreen('UPLOAD'), onFinishSubmit: () => onSwitchScreen('NEXT'), onSkipSubmit: () => onSwitchScreen('NEXT'), pageNameForEvent: "onboarding", isSubmitting: isSubmitting }));
};
export { Vehicle };
