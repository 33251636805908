import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { partnerAPI } from '../../api';
import { setPartner } from '../actions';
import { getPartner } from '../../store/selectors';
import { getUserData } from '../../../user/store/selectors';
const reloadPartner = createAsyncThunk(`${namespace}/reload-partner`, async (_, { dispatch, getState }) => {
    const me = getUserData(getState());
    if (me.roles.includes('partner')) {
        const updated = await partnerAPI.readPartner(me);
        dispatch(setPartner(updated));
        return updated;
    }
    return getPartner(getState());
});
export { reloadPartner };
