import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@wheely/ui-kit';
import { getCallRequestedAt } from '../../../../modules/auth/store/selectors';
import { requestAuthAutoCall } from '../../../../modules/auth/store/actions';
import { IntlContext } from '../../../../i18n';
import { Captcha } from '../Captcha/Captcha';
const Description = ({ phone, onChangeNumberClick }) => {
    const dispatch = useDispatch();
    const captchaRef = useRef(null);
    const callRequestedAt = useSelector(getCallRequestedAt);
    const { language } = useContext(IntlContext);
    const handleCallMeClick = useCallback(async () => {
        const captcha = (await captchaRef.current?.getCaptcha()) || '';
        if (!captcha) {
            return;
        }
        dispatch(requestAuthAutoCall({ phone, captcha, locale: language }));
    }, [dispatch, phone, language]);
    return (_jsxs(_Fragment, { children: [_jsx(Captcha, { ref: captchaRef }), callRequestedAt ? (_jsx(FormattedMessage, { id: 'ttpsiV', defaultMessage: 'We will call you to tell the code on {br} {phone} (<change>change number</change>). The ability to re-call will be available in a minute.', values: {
                    phone,
                    br: _jsx("br", {}),
                    change: (chunks) => (_jsx(Button, { onClick: onChangeNumberClick, children: chunks })),
                } })) : (_jsx(FormattedMessage, { id: 'X0tp53', defaultMessage: 'We have sent a text message with the code to {br} {phone} (<change>change number</change>). Haven\u2019t received the code? <call>Call me</call>', values: {
                    phone,
                    br: _jsx("br", {}),
                    change: (chunks) => (_jsx(Button, { onClick: onChangeNumberClick, children: chunks })),
                    call: (chunks) => (_jsx(Button, { onClick: handleCallMeClick, children: chunks })),
                } }))] }));
};
export { Description };
