import { createFsmSlice } from '../../../shared/store/finite-state-machine/create-fsm-slice';
import { fsmCfg } from '../fsm-config';
import { FsmState } from '../fsm-types';
import { reloadFormForCurrentCar } from '../../car/actions/async/reload-form-for-current-car';
import { prepareCarForm } from '../../car/actions/async/prepare-car-form';
import { completeOnboarding } from '../actions/completeOnboarding';
import { trackCarSkippedEvent } from '../actions/trackCarSkippedEvent';
import { loadDriver } from '../../driver/actions/async/load-driver';
import { scrollUp } from '../../../shared/actions/scrollUp';
import { loadAllCars } from '../../car/actions/async/loadAllCars';
import { reloadPartner } from '../../partner/actions/async/reloadPartner';
const fsmNamespace = 'onboarding-wizard';
const { fsmSlice, fsmSelectors, fsmAsyncActions } = createFsmSlice({
    config: fsmCfg,
    currentValue: FsmState.welcome,
    effects: {
        PREPARE_DRIVER_FORM: loadDriver,
        PREPARE_CAR_FORM: reloadFormForCurrentCar,
        RESET_CAR_FORM: prepareCarForm,
        COMPLETE_ONBOARDING: completeOnboarding,
        TRACK_CAR_SKIPPED_EVENT: trackCarSkippedEvent,
        SCROLL_UP: scrollUp,
        LOAD_CAR: loadAllCars,
        RELOAD_PARTNER: reloadPartner,
    },
    namespace: fsmNamespace,
});
export { fsmSlice, fsmSelectors, fsmAsyncActions, fsmNamespace };
