const getRequiredDocsTypes = (definitions) => definitions.reduce((result, definition) => {
    if (!definition.isOptional) {
        result.add(definition.docType);
    }
    return result;
}, new Set());
const getUploadedRequiredDocTypes = (uploadedDocTypes, requiredDocTypes) => uploadedDocTypes.filter(docType => requiredDocTypes.has(docType));
const hasAllRequiredDocs = (uploads, definitions) => {
    if (!uploads || Object.values(uploads).length === 0) {
        return false;
    }
    const requiredDocTypes = getRequiredDocsTypes(definitions);
    const uploadedDocsTypes = Object.keys(uploads);
    return Array.from(requiredDocTypes).every(docType => uploadedDocsTypes.includes(docType));
};
export { hasAllRequiredDocs, getUploadedRequiredDocTypes, getRequiredDocsTypes };
