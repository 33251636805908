import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { updatePaymentInfo } from '../../modules/partner/actions/async/updatePaymentInfo';
import { StepLayout } from '../../shared/view/StepLayout';
import { PaymentsForm } from './form';
const EditPayments = ({ onGoBack }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const postForm = useCallback(async (formValues) => {
        const resultAction = await dispatch(updatePaymentInfo(formValues));
        if (updatePaymentInfo.fulfilled.match(resultAction)) {
            const { isSucceed, result = {} } = resultAction.payload;
            if (isSucceed && !result.validationErrors && !result.baseErrors) {
                onGoBack();
            }
            return result;
        }
        return {};
    }, [dispatch, onGoBack]);
    return (_jsx(StepLayout, { onClickBack: onGoBack, hideProgress: true, title: intl.formatMessage({ id: "kKfEj5", defaultMessage: "Payment information" }), children: _jsx(PaymentsForm, { usePreSaveStore: false, onSubmit: postForm, textSubmitButton: _jsx(FormattedMessage, { id: 'RT8KNi', defaultMessage: 'Save' }) }) }));
};
export { EditPayments };
