import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../shared/view/Form';
import { getCodeAndNumberFromPhone } from '../../shared/utils/phoneUtils';
import { updateContactInfo } from '../../modules/partner/actions/async/updateContactInfo';
import { getPartner } from '../../modules/partner/store/selectors';
import { EditContactView } from './view';
const EditContactInfo = ({ onGoBack }) => {
    const dispatch = useDispatch();
    const partner = useSelector(getPartner);
    const postForm = useCallback(async (formValues) => {
        const { isSucceed, result = {} } = await dispatch(updateContactInfo(formValues)).unwrap();
        if (isSucceed && !result.validationErrors && !result.baseErrors) {
            onGoBack();
        }
        return result;
    }, [dispatch, onGoBack]);
    const { number, code } = getCodeAndNumberFromPhone(partner.phone);
    const { handleSubmit, form: { control, formState: { isSubmitting }, }, } = useForm({
        onSubmit: postForm,
        defaultValues: {
            phoneNumber: number,
            phoneCode: code,
        },
    });
    return (_jsx(EditContactView, { control: control, isSubmitting: isSubmitting, onSubmit: handleSubmit, onGoBack: onGoBack }));
};
export { EditContactInfo };
