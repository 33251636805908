import { OnboardingStep } from './index';
const emptyAboutMe = {
    cityId: '',
    cityName: '',
    firstName: '',
    userId: '',
    lastName: '',
    middleName: '',
    phone: '',
    source: null,
    roles: [],
};
const getEmptyPartner = (user = emptyAboutMe) => ({
    accessStatus: 'active',
    cityId: user.cityId,
    cityName: user.cityName,
    companyRegNumber: '',
    countryCode: undefined,
    docUploads: {},
    email: user.email ?? '',
    firstName: user.firstName,
    hasIntegrationWithTaxService: false,
    id: '',
    inn: '',
    lastName: user.lastName,
    legalAddress1: '',
    legalKpp: '',
    legalName: '',
    legalNds: false,
    legalOgrn: '',
    legalRegisteredAddress: undefined,
    legalPostalAddress: undefined,
    middleName: user.middleName,
    onboarding: {
        isCompleted: false,
        lastStepName: OnboardingStep.welcome,
        isLastStepCompleted: false,
    },
    partnerType: undefined,
    paymentAccount: '',
    paymentBik: '',
    paymentIban: '',
    paymentRasAccount: '',
    paymentSortCode: '',
    paymentSwift: '',
    personalInn: '',
    paymentKorAccount: '',
    paymentBankName: '',
    phone: user.phone ?? emptyAboutMe.phone,
    referralCode: '',
    source: null,
    userId: user.userId,
    vatRegNo: '',
    isVehiclesHidden: false,
    taxRegNo: '',
    tradeLicenseNumber: '',
    registrationAuditStep: null,
});
export { getEmptyPartner, emptyAboutMe };
