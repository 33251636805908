import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { usePermissionsContext } from '../../../app/contexts/permissions';
import { useExternalLinks } from '../../../app/hooks/useExternalLinks';
import { IntlContext } from '../../../i18n';
import { createCarsCards } from './createCarsCards';
import { createDriversCards } from './useDriverCard';
import { createTestCards } from './useTestCard';
import { useAcademyCard } from './useAcademyCards';
import { useRegionSpecificCards } from './useRegionSpecificCards/useRegionSpecificCards';
import { createPartnerCards } from './createPartnerCards';
import { useMobileAppCard } from './useMobileAppCard';
import { useReferralCard } from './useReferralCard';
import { createDriverFailedTestsCard } from './createDriverFailedTestsCard';
import { createDriverBlockedCard } from './createDriverBlockedCard';
const compareByOrderValue = (card1, card2) => (card1.order || 0) - (card2.order || 0);
const useCards = ({ onNavigate, selectToDoListData }) => {
    const todoListData = useSelector(selectToDoListData);
    const intl = useIntl();
    const links = useExternalLinks();
    const { language } = useContext(IntlContext);
    const { user } = usePermissionsContext();
    const academyCards = useAcademyCard(todoListData, intl);
    const regionSpecificCards = useRegionSpecificCards(todoListData);
    const referralCard = useReferralCard();
    const downloadAppCard = useMobileAppCard();
    return useMemo(() => {
        let result = [
            ...(user.isChauffeur ? [] : createPartnerCards(todoListData, onNavigate, intl)),
            ...(user.isChauffeur ? [] : createCarsCards(todoListData, onNavigate, intl, user, links)),
            ...(user.isChauffeur ? [] : createDriversCards(todoListData, onNavigate, intl, user)),
            ...createTestCards(todoListData, intl, language),
            ...academyCards,
            {
                id: 'reg',
                cardStatus: 'done',
                title: intl.formatMessage({ id: "jFKnN2", defaultMessage: "Apply for registration" }),
                desc: '',
            },
            ...regionSpecificCards,
        ].sort(compareByOrderValue);
        if (user.isChauffeur) {
            if (todoListData.myDriver?.accessStatus === 'blocked') {
                result.unshift(createDriverBlockedCard(links, intl));
            }
        }
        else if (todoListData.myDriver?.accessStatus === 'failed_tests') {
            result.unshift(createDriverFailedTestsCard(intl));
        }
        else if (todoListData.partner.accessStatus === 'active') {
            result = result.filter(card => !card.isHiddenIfPartnerIsActive);
            result.unshift(...downloadAppCard);
            if (referralCard) {
                result.unshift(referralCard);
            }
        }
        return result;
    }, [
        academyCards,
        downloadAppCard,
        intl,
        language,
        links,
        onNavigate,
        regionSpecificCards,
        todoListData,
        user,
        referralCard,
    ]);
};
export { useCards };
