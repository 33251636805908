import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { StepLayout } from '../../shared/view/StepLayout';
import { scrollUp } from '../../shared/actions/scrollUp';
import { UploadCar } from '../../shared/view/Upload';
import { reloadFormForCurrentCar } from '../../modules/car/actions/async/reload-form-for-current-car';
import { prepareCarForm } from '../../modules/car/actions/async/prepare-car-form';
import { usePermissions } from '../../app/hooks/usePermissions';
import { BaseVehicleForm } from './BaseForm';
const CreateVehicle = ({ onNavigateBack, onNavigate }) => {
    usePermissions('createCars');
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(prepareCarForm({}));
        dispatch(scrollUp());
    }, [dispatch]);
    const intl = useIntl();
    const [isUploading, setIsUploading] = useState(false);
    const handleFinishSubmit = useCallback((id) => onNavigate(`/vehicles/${id}#docs`, true), [onNavigate]);
    const handleGoBack = useCallback(() => onNavigateBack('/todo'), [onNavigateBack]);
    const handleOpenUpload = useCallback(() => setIsUploading(true), [setIsUploading]);
    const handleFinishUpload = useCallback(() => {
        dispatch(reloadFormForCurrentCar({}));
        setIsUploading(false);
    }, [dispatch]);
    const handleSkipUpload = useCallback(() => {
        setIsUploading(false);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(StepLayout, { onClickBack: handleGoBack, hideProgress: true, title: intl.formatMessage({ id: "c0G8wG", defaultMessage: "Vehicle info" }), children: _jsx(BaseVehicleForm, { onSkipSubmit: handleGoBack, onFinishSubmit: handleFinishSubmit, onOpenUpload: handleOpenUpload, textSubmitButton: intl.formatMessage({ id: "RT8KNi", defaultMessage: "Save" }), pageNameForEvent: "to do screen" }) }), isUploading && (_jsx(UploadCar, { onClickSkip: handleSkipUpload, onFinishUpload: handleFinishUpload }))] }));
};
export { CreateVehicle };
