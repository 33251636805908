import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { partnerAPI } from '../../api';
import { authAPI } from '../../../auth/api';
import { getUserData } from '../../../user/store/selectors';
const createPartner = createAsyncThunk(`${namespace}/create-partner`, async (_, { getState }) => {
    const me = getUserData(getState());
    const { partner, errors } = await partnerAPI.createPartner(me);
    if (errors) {
        return { errors };
    }
    if (partner) {
        await authAPI.refreshToken();
        return { partner };
    }
    return {};
});
export { createPartner };
