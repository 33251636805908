import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useDialog, AnchorButton, H3, H5, UL } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';
import { CountryCode } from '../../models/country-code';
import { IntlContext } from '../../../i18n';
import s from './styles.css';
const BookAnAppointmentModal = ({ url1, url2 }) => {
    const { region } = useContext(IntlContext);
    return (_jsxs("div", { children: [region === CountryCode.GreatBritain && (_jsx("div", { className: s.bookAppointmentDialogBlock, children: _jsxs(H5, { className: s.bookAppointmentDialogText, children: [_jsx(FormattedMessage, { id: '1pueQw', defaultMessage: 'In order to be fully active on the platform you need to complete two days of training. They are booked separately. Please, ensure that:' }), _jsxs(UL, { children: [_jsx(FormattedMessage, { id: 'KTYRiX', defaultMessage: 'You book both days. The \u00A335,00 deposit will be refunded to you after you finish your second day.', tagName: "li" }), _jsx(FormattedMessage, { id: '0jX/Lm', defaultMessage: 'Book Day 1 of training <stress>before</stress> Day 2.', tagName: "li", values: {
                                        stress: (text) => _jsx("span", { className: s.underline, children: text }),
                                    } })] })] }) })), _jsxs("div", { className: s.bookAppointmentDialogBlock, children: [_jsxs("div", { className: s.bookAppointmentDialogParagraph, children: [_jsx(H5, { className: s.bookAppointmentDialogText, children: _jsx(FormattedMessage, { id: 'EFDqP6', defaultMessage: 'Day 1: Classroom-based learning session on everything you need to know to become a Wheely Chauffeur' }) }), _jsx("br", {}), _jsx(H5, { className: s.bookAppointmentDialogText, children: _jsx(FormattedMessage, { id: 'IxmVNx', defaultMessage: 'Requirements: Documents uploaded, Online learning completed & Full Dress Code' }) })] }), _jsx(AnchorButton, { href: url1, fill: true, intent: "none", target: "_blank", rel: "noreferrer", children: _jsx(FormattedMessage, { id: 'ycUk0h', defaultMessage: 'Book Day 1' }) })] }), _jsxs("div", { className: s.bookAppointmentDialogBlock, children: [_jsxs("div", { className: s.bookAppointmentDialogParagraph, children: [_jsx(H5, { className: s.bookAppointmentDialogText, children: _jsx(FormattedMessage, { id: '0YJewn', defaultMessage: 'Day 2: Interactive chauffeur, vehicle & journey assessments to qualify as a Wheely Chauffeur' }) }), _jsx("br", {}), _jsx(H5, { className: s.bookAppointmentDialogText, children: _jsx(FormattedMessage, { id: 'OAgYRP', defaultMessage: 'Requirements: Vehicle prepared to Wheely Standards & Full Dress Code' }) })] }), _jsx(AnchorButton, { href: url2, fill: true, intent: "none", target: "_blank", rel: "noreferrer", children: _jsx(FormattedMessage, { id: 'aJs6z1', defaultMessage: 'Book Day 2' }) })] })] }));
};
const useBookAnAppointmentModal = () => {
    const { openDialog } = useDialog();
    const openBookAnAppointmentModal = ({ dayOneLink, dayTwoLink, }) => {
        openDialog(() => _jsx(BookAnAppointmentModal, { url1: dayOneLink, url2: dayTwoLink }), {
            title: (_jsx(H3, { children: _jsx(FormattedMessage, { id: 'k4bnb0', defaultMessage: 'Select the day' }) })),
            className: s.bookAppointmentDialog,
            titleClassName: s.bookAppointmentDialogTitle,
            bodyClassName: s.bookAppointmentDialogBody,
        });
    };
    return { openModal: openBookAnAppointmentModal };
};
export { useBookAnAppointmentModal };
