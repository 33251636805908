import { storage } from './storage';
const AVAILABLE_LANGUAGES = ['en', 'ru', 'fr'];
const DEFAULT_LANGUAGE = 'en';
const SAVED_LANGUAGE_TAG_KEY_NAME = 'userLocale';
class UserLanguage {
    getBrowserLanguage() {
        const browserLanguage = navigator.language || navigator.languages[0];
        if (browserLanguage) {
            const [browserLanguageTag] = browserLanguage.split('-');
            if (AVAILABLE_LANGUAGES.includes(browserLanguageTag)) {
                return browserLanguageTag;
            }
        }
        return undefined;
    }
    getLanguage() {
        const savedLanguage = storage.getItem(SAVED_LANGUAGE_TAG_KEY_NAME);
        if (savedLanguage && AVAILABLE_LANGUAGES.includes(savedLanguage)) {
            return savedLanguage;
        }
        const browserLanguage = this.getBrowserLanguage();
        if (browserLanguage && AVAILABLE_LANGUAGES.includes(browserLanguage)) {
            return browserLanguage;
        }
        return DEFAULT_LANGUAGE;
    }
    setLanguage(language) {
        if (!AVAILABLE_LANGUAGES.includes(language)) {
            return this.getLanguage();
        }
        storage.setItem(SAVED_LANGUAGE_TAG_KEY_NAME, language);
        return language;
    }
}
const userLanguage = new UserLanguage();
export { userLanguage, AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE };
