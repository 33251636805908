import { ENV } from '../../env';
const getCookieRawValue = (name) => {
    const prefix = name + '=';
    return document.cookie
        .split(';')
        .map(keyValue => keyValue.trim())
        .find(keyValue => keyValue.startsWith(prefix))
        ?.slice(prefix.length);
};
const getCookieValue = (name) => {
    const raw = getCookieRawValue(name);
    return raw && JSON.parse(decodeURIComponent(raw));
};
const getRootDomainForCookie = () => (ENV.DEPLOY_ENV === 'local' ? '' : '.') + location.hostname.split('.').slice(-2).join('.');
const setCookieValue = (name, value, days) => {
    const encoded = encodeURIComponent(value).replace(/%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g, decodeURIComponent);
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = `${name}=${encoded}${expires};path=/;domain=${getRootDomainForCookie()};Secure;`;
};
const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${getRootDomainForCookie()}`;
};
export { getCookieRawValue, getCookieValue, setCookieValue, deleteCookie };
