import * as t from 'io-ts';
import { PeriodNameEnum } from '../../../src/modules/earning/model';
import { Earning } from './w4d-earnings-types';
const w4dEarningsRemoteAPI = ({ apiHost, get }) => ({
    readEarnings: {
        path: ({ periodName }) => `${apiHost}/v6/drivers/earnings?period_name=${periodName}`,
        pathParams: t.type({
            periodName: t.keyof(PeriodNameEnum),
        }),
        method: get,
        response: t.type({
            earnings: t.array(Earning),
            from: t.string,
            to: t.string,
        }),
    },
});
export { w4dEarningsRemoteAPI };
