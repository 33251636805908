import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormattedMessage, useIntl, FormattedList } from 'react-intl';
import { UL } from '@wheely/ui-kit';
import { useSelector } from 'react-redux';
import { Section } from '../../../../shared/view/Section';
import { getModelsByBrand } from '../../../../modules/car/store/selectors';
import { getLocalizedColorName } from '../../../../i18n/getLocalizedColorName';
import { getLocalizedCarMakeName } from '../../../../i18n/getLocalizedCarMakeName';
import { CountryCode } from '../../../../shared/models/country-code';
import { getCountryCode } from '../../../../modules/common/store/selectors';
import { useExternalLinks } from '../../../../app/hooks/useExternalLinks';
import s from './styles.css';
const DoYouHaveACar = () => {
    const intl = useIntl();
    const country = useSelector(getCountryCode);
    const modelsByBrand = useSelector(getModelsByBrand);
    const { contactOperationTeam, faqAcquiringANewVehicle } = useExternalLinks();
    const countryBasedRequirements = useMemo(() => {
        switch (country) {
            case CountryCode.Russia:
                return (_jsx(FormattedMessage, { id: 'AFNSHg', defaultMessage: '<li>Excellent external and technical condition of the vehicle.</li>', values: { li: (text) => _jsx("li", { children: text }) } }));
            case CountryCode.France:
                return (_jsx(FormattedMessage, { id: 'UoVWYR', defaultMessage: '<li>Must have a valid VTC license mentioning plate number.</li>', values: { li: (text) => _jsx("li", { children: text }) } }));
            default:
                return (_jsx(FormattedMessage, { id: '80lHib', defaultMessage: '<li>Fully licensed by TfL, Castlepoint or Royal Borough of Windsor and Maidenhead and covered by commercial insurance.</li>', values: { li: (text) => _jsx("li", { children: text }) } }));
        }
    }, [country]);
    return (_jsxs(Section, { children: [_jsxs(UL, { className: s.list, children: [Array.from(modelsByBrand.entries()).map(([make, models]) => Array.from(models).map(model => (_jsxs("li", { children: [getLocalizedCarMakeName(make, intl), " ", model.model, model.note ? '*' : null, model.generations.length > 0 ? (_jsxs(_Fragment, { children: [' ', "(", _jsx(FormattedList, { value: model.generations, style: "narrow", type: "conjunction" }), ")"] })) : null, ".", ' ', _jsx(FormattedMessage, { id: 'r61hCi', defaultMessage: 'Up to {maxAge, plural, one {# year} other {# years}} old.', values: { maxAge: model.maxAge } }), ' ', _jsx("span", { className: s.colors, children: _jsx(FormattedMessage, { id: '+zI1x6', defaultMessage: 'In {listOfColors} colours.', values: {
                                        listOfColors: intl.formatList(model.color.map(color => getLocalizedColorName(color.value, intl)), { type: 'disjunction' }),
                                    } }) })] }, `${make}_${model.model}`)))), countryBasedRequirements] }), country === CountryCode.GreatBritain ? (_jsxs("div", { className: s.notes, children: [_jsx("p", { className: s.note, children: _jsx(FormattedMessage, { id: 'kYEYox', defaultMessage: '*If you have an EQE, please get in touch with us at <link></link>.', values: {
                                link: () => (_jsx("a", { href: `mailto:${contactOperationTeam}`, target: "_blank", rel: "noreferrer", children: contactOperationTeam })),
                            } }) }), _jsx("p", { className: s.note, children: _jsx(FormattedMessage, { id: 'SbCpEF', defaultMessage: 'Don\'t have a vehicle yet? See <link>here</link> some options that might be interesting to you.', values: {
                                link: (text) => _jsx("a", { href: faqAcquiringANewVehicle, children: text }),
                            } }) })] })) : null] }));
};
export { DoYouHaveACar };
