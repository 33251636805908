import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../namespace';
import { onboardingAPI } from '../api';
import { updatePartner } from '../../partner/actions/actions';
let lastRequestId;
const submitPayoutsStep = createAsyncThunk(`${namespace}/submit-payment-info-step`, async (formValues, { dispatch, requestId }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const result = await onboardingAPI.submitPayoutsStep(formValues);
        if (!result.validationErrors) {
            await dispatch(updatePartner({
                partnerType: formValues.partnerType,
                legalName: formValues.companyName,
                legalAddress1: formValues.companyAddress,
                companyRegNumber: formValues.companyRegNumber,
                vatRegNo: formValues.vatRegNo,
                inn: formValues.inn,
                legalKpp: formValues.kpp,
                onboarding: result.onboarding,
            }));
        }
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { submitPayoutsStep };
