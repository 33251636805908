import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { H2 } from '@wheely/ui-kit';
import cn from 'classnames';
import { CenteringContainer } from '../CenteringContainer';
import { noop } from '../../utils/noop';
import { Button } from '../../../../src-common/view/adapters/Button';
import { Filters, MobileFilters } from './Filters';
import { List } from './List';
import { Pagination } from './Pagination';
import { NoResults } from './NoResults';
import { isEmpty } from './utils';
import s from './ListLayout.css';
import { MobileList } from './MobileList';
const MOBILE_VIEW_WIDTH_PX = 419;
const ListLayoutView = ({ title, action, before, after, filterConfig, onClickRow, shouldBeEmptyWithoutFilters, data, columns, getEntityId, limit, total, onFetchData, onFiltersChange = noop, renderItem, translateGroupName, getEntityGroup, groupOrder, isFiltersEmpty = isEmpty, isLoading = false, wrapperClassName, }) => {
    const filterDefaultValue = useMemo(() => !filterConfig
        ? {}
        : filterConfig.reduce((result, item) => {
            if (item.initialValue) {
                result[item.name] = item.initialValue;
            }
            if (item.fixedValue) {
                result[item.name] = item.fixedValue;
            }
            return result;
        }, {}), [filterConfig]);
    const hasPagination = total !== undefined && limit !== undefined;
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState(filterDefaultValue);
    const [isMobileView, setIsMobileView] = useState(false);
    const filtersRef = useRef(null);
    const changePage = useCallback((newOffset) => {
        setOffset(newOffset);
        setTimeout(() => {
            filtersRef.current?.scrollIntoView({
                behavior: 'smooth',
            });
        }, 100);
    }, []);
    const handleRemoveFilters = useCallback(() => {
        setFilters(filterDefaultValue);
    }, [filterDefaultValue]);
    const handleChangeFilter = useCallback((_, { name, value }) => {
        setFilters(params => {
            const newFilters = { ...params, [name]: value };
            onFiltersChange(newFilters);
            return newFilters;
        });
        setOffset(0);
    }, [onFiltersChange]);
    useEffect(() => {
        const handleChangeScreen = (event) => {
            setIsMobileView(event.matches ?? false);
        };
        const match = window.matchMedia(`screen and (max-width: ${MOBILE_VIEW_WIDTH_PX}px)`);
        setIsMobileView(match.matches);
        if (match.addEventListener) {
            match.addEventListener('change', handleChangeScreen);
        }
        else {
            // Safari 13 https://www.designcise.com/web/tutorial/how-to-fix-the-javascript-typeerror-matchmedia-addeventlistener-is-not-a-function
            match.addListener(handleChangeScreen);
        }
        return () => {
            if (match.removeEventListener) {
                match.removeEventListener('change', handleChangeScreen);
            }
            else {
                match.removeListener(handleChangeScreen);
            }
        };
    }, [setIsMobileView]);
    useEffect(() => {
        if (shouldBeEmptyWithoutFilters && isFiltersEmpty(filters)) {
            return;
        }
        onFetchData(filters, hasPagination ? { limit, offset } : { limit: 100, offset: 0 });
    }, [
        offset,
        filters,
        shouldBeEmptyWithoutFilters,
        limit,
        onFetchData,
        hasPagination,
        isFiltersEmpty,
    ]);
    return (_jsxs(CenteringContainer, { outerClassName: cn(s.wide, wrapperClassName), children: [_jsx("div", { className: s.headerRow, children: _jsx(H2, { capitalize: true, children: title }) }), before && _jsx("div", { className: s.beforeRow, children: before }), (action || filterConfig) && (_jsx("div", { className: s.actionRow, children: isMobileView ? (_jsx(MobileFilters, { value: filters, config: filterConfig ?? [], onChange: handleChangeFilter, onRemoveFilters: handleRemoveFilters, filtersRef: filtersRef })) : (_jsx(Filters, { value: filters, config: filterConfig ?? [], onChange: handleChangeFilter, actionWrapperClassName: action?.className, children: action && _jsx(Button, { onClick: action.onClick, children: action.title }) })) })), _jsxs("div", { className: s.listRow, children: [!isLoading && !isEmpty(filters) && data.length === 0 && (_jsx(NoResults, { onRemoveFilters: handleRemoveFilters })), (isLoading || data.length > 0) && isMobileView && (_jsx(MobileList, { getEntityId: getEntityId, onClickRow: onClickRow, data: shouldBeEmptyWithoutFilters && isFiltersEmpty(filters) ? [] : data, renderItem: renderItem, getEntityGroup: getEntityGroup, groupOrder: groupOrder, translateGroupName: translateGroupName, isLoading: isLoading, action: action }, "list")), (isLoading || data.length > 0) && !isMobileView && (_jsx(List, { data: shouldBeEmptyWithoutFilters && isFiltersEmpty(filters) ? [] : data, columns: columns, getEntityId: getEntityId, onClickRow: onClickRow, isLoading: isLoading }, "table"))] }), hasPagination && (_jsx("div", { className: s.paginationRow, children: _jsx(Pagination, { offset: offset, limit: limit, total: total, onChangeOffset: changePage, isDisabled: isLoading }) })), after && _jsx("div", { className: s.afterRow, children: after })] }));
};
export { ListLayoutView };
