import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { IconCalendar } from '@wheely/ui-kit';
import parse from 'date-fns/esm/parse';
import { TextField as OriginalField } from './TextField';
const leftIcon = _jsx(IconCalendar, {});
const REFERENCE_DATE = new Date();
const DATE_SEPARATORS = new Set(['.', ' ', '-', '\\', '/']);
const isValidDate = (date) => !Number.isNaN(date.valueOf());
const extractDateFormat = (intl) => intl
    .formatDate(new Date(2222, 10, 30))
    .replace('2222', 'yyyy')
    .replace('11', 'MM')
    .replace('30', 'dd');
const checkIsDigit = (symbol) => symbol[0] === Number(symbol[0]).toString();
const tryAutoComplete = (textValue, nextTextValue, pattern) => {
    if (nextTextValue.length - textValue.length === 1 && nextTextValue.startsWith(textValue)) {
        const lastSymbolIdx = nextTextValue.length - 1;
        const lastSymbol = nextTextValue[lastSymbolIdx];
        if (!checkIsDigit(lastSymbol) && !DATE_SEPARATORS.has(lastSymbol)) {
            return textValue;
        }
        const currentPatternSymbol = pattern[lastSymbolIdx];
        const nextPatternSymbol = pattern[lastSymbolIdx + 1];
        if (currentPatternSymbol !== lastSymbol && DATE_SEPARATORS.has(currentPatternSymbol)) {
            nextTextValue = textValue + currentPatternSymbol + lastSymbol;
        }
        else if (DATE_SEPARATORS.has(nextPatternSymbol)) {
            nextTextValue += nextPatternSymbol;
        }
        else if (nextTextValue.length > pattern.length) {
            nextTextValue = textValue;
        }
    }
    return nextTextValue;
};
const checkIsDate = (date) => date instanceof Date && isValidDate(date);
const EMPTY_VALUE = null;
const DateField = ({ value, name, onChange, onBlur, inputRef, error, description, ...restProps }) => {
    const intl = useIntl();
    if (value !== null && !checkIsDate(value)) {
        throw new Error(`Date input expect only Date or null value: ${typeof value}`);
    }
    const [textValue, setTextValue] = useState(checkIsDate(value) ? intl.formatDate(value) : '');
    const [isFormatSuggestVisible, setIsFormatSuggestVisible] = useState(false);
    useEffect(() => {
        setTextValue(checkIsDate(value) ? intl.formatDate(value) : '');
    }, [intl, value]);
    const pattern = useMemo(() => extractDateFormat(intl), [intl]);
    const handleChange = (event, { value: inputTextValue }) => {
        const nextTextValue = tryAutoComplete(textValue, inputTextValue, pattern);
        if (nextTextValue === textValue) {
            return;
        }
        setTextValue(nextTextValue);
        if (nextTextValue === '') {
            setIsFormatSuggestVisible(false);
            onChange(event, { value: EMPTY_VALUE, name });
            return;
        }
        const parsedDate = parse(nextTextValue, pattern, REFERENCE_DATE);
        if (isValidDate(parsedDate) && parsedDate.getFullYear() > 1000) {
            onChange(event, { value: parsedDate, name });
            setIsFormatSuggestVisible(false);
        }
        else {
            setIsFormatSuggestVisible(true);
        }
    };
    const handleBlur = (event) => {
        onBlur?.(event);
        setIsFormatSuggestVisible(false);
        setTextValue(checkIsDate(value) ? intl.formatDate(value) : '');
    };
    const placeholder = intl.formatMessage({ id: "LCqZRb", defaultMessage: "DD/MM/YYYY" });
    return (_jsx(OriginalField, { ...restProps, name: name, startElem: leftIcon, inputRef: inputRef ?? undefined, onChange: handleChange, onBlur: handleBlur, value: textValue, placeholder: placeholder, error: error, dataTestId: "form-date-field", inputDataTestId: "date-input", description: (() => {
            if (error) {
                return undefined;
            }
            if (isFormatSuggestVisible) {
                return intl.formatMessage({ id: "RE1xCE", defaultMessage: "Please use format {format}, ex. {example}" }, {
                    format: placeholder,
                    example: intl.formatDate(new Date()),
                });
            }
            return description;
        })() }));
};
export { DateField };
