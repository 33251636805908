import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { CountryCode } from '../models/country-code';
const PHONE_CODES = [
    {
        title: (_jsx(FormattedMessage, { id: 'dbw4Qp', defaultMessage: 'Russia (+7)' })),
        value: '+7',
        countryCode: CountryCode.Russia,
        length: 10,
    },
    {
        title: (_jsx(FormattedMessage, { id: 'ehEu7E', defaultMessage: 'France (+33)' })),
        value: '+33',
        countryCode: CountryCode.France,
        length: 9,
    },
    {
        title: (_jsx(FormattedMessage, { id: 'N6ggfV', defaultMessage: 'United Kingdom (+44)' })),
        value: '+44',
        countryCode: CountryCode.GreatBritain,
        length: 10,
    },
    {
        title: (_jsx(FormattedMessage, { id: '5GRTsq', defaultMessage: 'Other country' })),
        value: '+',
        countryCode: CountryCode.Unknown,
    },
];
export { PHONE_CODES };
