import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { H5 } from '@wheely/ui-kit';
import { useSelector } from 'react-redux';
import { Icon } from '../Icon';
import { getCityTimezone } from '../../../../src/modules/partner/store/selectors';
import s from './styles.css';
const isDocExpiring = (doc) => doc.status === 'approved' && doc.expiresSoon;
const statusesMap = {
    pending: (_jsx(FormattedMessage, { id: 'OLlShR', defaultMessage: 'Pending approval' })),
    approved: (_jsx(FormattedMessage, { id: '4suAsB', defaultMessage: 'Approved' })),
    expired: (_jsx(FormattedMessage, { id: 'vG+u3W', defaultMessage: 'Expired' })),
    missing: undefined,
    rejected: (_jsx(FormattedMessage, { id: 'atQGVL', defaultMessage: 'Rejected' })),
};
const DocSubtitle = ({ doc }) => {
    const timezone = useSelector(getCityTimezone);
    const intl = useIntl();
    if (doc.rejectedReason) {
        return _jsx(_Fragment, { children: doc.rejectedReason });
    }
    if (isDocExpiring(doc) && doc.expiresAt) {
        return (_jsx(FormattedMessage, { id: '7yQPeq', defaultMessage: 'Expires {date}', values: {
                date: intl.formatDate(doc.expiresAt, {
                    day: 'numeric',
                    month: 'short',
                    timeZone: timezone,
                }),
            } }));
    }
    return _jsx(_Fragment, { children: statusesMap[doc.status] });
};
const DocsList = ({ docs, className, onChange, title, isStatusHidden, scrollToView = true, }) => {
    const intl = useIntl();
    const location = useLocation();
    const nodeRef = useRef(null);
    useEffect(() => {
        if (location.hash === '#docs' && scrollToView) {
            setTimeout(() => nodeRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' }), 500);
        }
    }, [location, scrollToView]);
    const handleClick = (doc) => (event) => {
        event.preventDefault();
        onChange(event, doc);
    };
    const docsTotal = docs.length;
    const docsReady = docs.filter(doc => doc.status === 'approved' || doc.status === 'pending').length;
    return (_jsxs("div", { ref: nodeRef, className: cn(className, s.root), "data-test-id": "docs", id: "#docs", children: [title && (_jsxs(H5, { capitalize: true, className: s.title, "data-test-id": "docs__header", children: [title, _jsx("span", { className: s.count, "data-test-id": "docs__total", children: intl.formatMessage({ id: "OKr7TZ", defaultMessage: "{docsReady} of {docsTotal}" }, {
                            docsTotal,
                            docsReady,
                        }) })] })), _jsx("ul", { className: s.list, children: docs.map(doc => (_jsx("li", { className: s.item, children: _jsxs("button", { type: "button", className: s.itemButton, onClick: handleClick(doc), "data-test-id": "docs__item", children: [_jsxs("span", { className: cn(s.itemTitle, {
                                    [s.hasStatus]: !isStatusHidden,
                                    [s.isMissing]: doc.status === 'missing',
                                }), children: [doc.title, " ", doc.isOptional && '*', !isStatusHidden && doc.status !== 'missing' && (_jsx("span", { "data-test-id": "docs__item-status", className: cn(s.itemStatus, doc.rejectedReason ? s.rejected : s[doc.status], {
                                            [s.expiring]: isDocExpiring(doc),
                                        }), children: _jsx(DocSubtitle, { doc: doc }) }))] }), _jsx("span", { className: s.itemIcon, children: doc.status === 'missing' ? (_jsx(FormattedMessage, { id: 'AVEk6c', defaultMessage: 'ADD' })) : (_jsx(Icon, { icon: "edit-pencil-outline", size: "24px", "data-test-id": "docs__action-icon" })) })] }) }, doc.docType))) }), docs.some(doc => doc.isOptional) && (_jsx("span", { className: s.tipOptional, "data-test-id": "docs__note", children: _jsx(FormattedMessage, { id: 'vtUs2C', defaultMessage: '* Optional' }) }))] }));
};
export { DocsList };
