import { jsx as _jsx } from "react/jsx-runtime";
import { useNotification } from '@wheely/ui-kit';
import { useCallback } from 'react';
import { useForm as useFormOriginal, } from 'react-hook-form';
import { applyServerValidation } from '../../utils/applyServerValidation';
import { scrollToFirstError } from '../scroll';
import { useFormStorage } from './useFormStorage';
const DEFAULT_PRESAVE_STORE = {
    storeId: '',
    type: 'none',
};
const useForm = (props) => {
    const { preSaveStore = DEFAULT_PRESAVE_STORE, onSubmit, ...useFormProps } = props;
    const form = useFormOriginal(useFormProps);
    const { showNotification } = useNotification();
    const clearStorage = useFormStorage({ config: preSaveStore, form });
    const handleSubmitWrapped = useCallback(async (formValues) => {
        const result = await onSubmit(formValues);
        if (result.validationErrors) {
            applyServerValidation(form.setError, result.validationErrors);
        }
        else if (result.baseErrors) {
            showNotification({
                showCloseButton: false,
                dark: true,
                message: result.baseErrors.map(message => _jsx("div", { children: message }, message)),
            });
        }
        else {
            clearStorage();
        }
        return result;
    }, [onSubmit, clearStorage, form.setError, showNotification]);
    return {
        form,
        handleSubmit: form.handleSubmit(handleSubmitWrapped, scrollToFirstError),
    };
};
export { useForm };
