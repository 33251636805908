import { createSelector } from '@reduxjs/toolkit';
import { getAllDrivers, getMyDriver } from '../../../modules/driver/store/selectors';
import { myDriverUploadSelectors } from '../../../modules/driver/store/upload-slice';
import { getAllCars, getCurrentCar } from '../../../modules/car/store/selectors';
import { carUploadSelectors } from '../../../modules/car/store/upload-slice';
import { getAllTestDefinitions, getPartner } from '../../../modules/partner/store/selectors';
import { partnerUploadSelectors } from '../../../modules/partner/store/uploadSlice';
const getToDoListData = createSelector(myDriverUploadSelectors.getUploadDefinitions, getAllCars, carUploadSelectors.getUploadDefinitions, getPartner, partnerUploadSelectors.getUploadDefinitions, getAllDrivers, getMyDriver, getAllTestDefinitions, getCurrentCar, (driverUploadDefinitions, cars, carUploadDefinitions, partner, partnerUploadDefinitions, drivers, myDriver, allTestDefinitions, currentCar) => ({
    drivers: drivers,
    myDriver,
    driverUploadDefinitions,
    cars,
    carUploadDefinitions,
    partner,
    partnerUploadDefinitions,
    allTestDefinitions,
    currentCar,
}));
export { getToDoListData };
