import { createAsyncThunk } from '@reduxjs/toolkit';
import { showError } from '../../../../app/actions';
import { namespace } from '../../namespace';
import { carAPI } from '../../api';
import { deleteCarFromStore } from '../actions';
let lastRequestId;
const deleteCar = createAsyncThunk(`${namespace}/delete-car`, async ({ carId, onSuccess }, { dispatch, requestId }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        if (!carId) {
            return { isSucceed: false };
        }
        const result = await carAPI.deleteCar(carId);
        if ('deleted' in result) {
            dispatch(deleteCarFromStore({ carId }));
        }
        else {
            dispatch(showError(Object.values(result.errors).flat().join('\n')));
        }
        onSuccess?.();
        return { isSucceed: true };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { deleteCar };
