import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { H5, CheckboxField } from '@wheely/ui-kit';
import { updateIsVehiclesHidden } from '../../../modules/partner/actions/async/updateIsVehiclesHidden';
import s from './styles.css';
const Settings = ({ partner }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const handleCheckboxChange = useCallback(async (event) => {
        setError(null);
        const { result = {} } = await dispatch(updateIsVehiclesHidden({ isVehiclesHidden: !event.currentTarget.checked })).unwrap();
        if (result.baseErrors?.[0]) {
            setError(result.baseErrors?.[0]);
        }
    }, [dispatch]);
    return (_jsxs("div", { className: s.root, children: [_jsx(H5, { capitalize: true, className: s.title, children: _jsx(FormattedMessage, { id: 'D7Ew06', defaultMessage: 'Settings' }) }), _jsx(CheckboxField, { inline: true, name: "isAllowToChooseVehicles", onChange: handleCheckboxChange, checked: !partner.isVehiclesHidden, error: error, children: _jsx(FormattedMessage, { id: 'VjPKYY', defaultMessage: 'Allow chauffeurs to choose vehicle' }) })] }));
};
export { Settings };
