import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, defineMessages } from 'react-intl';
import { H3, H5 } from '@wheely/ui-kit';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { StepLayout } from '../../shared/view/StepLayout';
import { Avatar } from '../Chauffeurs/components/Avatar';
import { AccessStatus } from '../../shared/view/AccessStatus';
import { TransferStatus } from '../../shared/view/TransferStatus';
import { getCityTimezone } from '../../modules/partner/store/selectors';
import s from './view.css';
import { BlockInfo } from './components/BlockInfo';
import { WorkingHours } from './components/TabWorkingHours';
import { Docs } from './components/TabDocs';
import { Overview } from './components/TabOverview';
const DEFAULT_TAB = '#overview';
const tabs = defineMessages({ '#overview': { id: "SAseai", defaultMessage: "Overview" }, '#docs': { id: "49Lusj", defaultMessage: "Documents" }, '#working-hours': { id: "DO9fZh", defaultMessage: "Working hours" } });
const View = ({ driver, docs, onUploadDoc, onClickBack, onChangeContract, onClickBlock, onClickDismiss, isPending, region, workloads, workloadSummary, workingHoursWeek, onWorkingHoursWeekChange, }) => {
    const timezone = useSelector(getCityTimezone);
    const location = useLocation();
    const activeTab = location.hash in tabs ? location.hash : DEFAULT_TAB;
    return (_jsx(StepLayout, { onClickBack: onClickBack, hideProgress: true, children: _jsxs("div", { className: s.root, children: [_jsxs("div", { className: s.topRow, children: [_jsx(Avatar, { photoURL: driver.photoURL }), _jsxs(H3, { className: s.name, children: [driver.firstName, " ", driver.lastName] }), _jsxs("div", { className: s.tags, children: [_jsx(AccessStatus, { status: driver.accessStatus }), driver.transferRequest && (_jsx(TransferStatus, { date: driver.transferRequest.leavingAtDate, timezone: timezone }))] }), driver.blockInfo?.blockedBy === 'partner' ? (_jsx(BlockInfo, { blockInfo: driver.blockInfo })) : null] }), _jsx("div", { className: s.tabsFiller, children: _jsx("div", { className: s.tabsWrapper, children: _jsx("div", { className: s.tabs, children: Object.entries(tabs).map(tab => (_jsx("div", { className: s.tabWrapper, children: _jsx(Link, { to: tab[0], replace: true, className: s.link, children: _jsx(H5, { capitalize: true, className: cx(s.tab, { [s.active]: tab[0] === activeTab }), children: _jsx(FormattedMessage, { ...tab[1] }) }) }) }, tab[0]))) }) }) }), activeTab === '#overview' && (_jsx(Overview, { driver: driver, onClickBlock: onClickBlock, onClickDismiss: onClickDismiss, isPending: isPending })), activeTab === '#docs' && (_jsx(Docs, { driver: driver, docs: docs, onUploadDoc: onUploadDoc, region: region, onChangeContract: onChangeContract })), activeTab === '#working-hours' && (_jsx(WorkingHours, { workloads: workloads, week: workingHoursWeek, summary: workloadSummary, onWeekChange: onWorkingHoursWeekChange }))] }) }));
};
export { View };
