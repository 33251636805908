import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ListLayoutView } from '../../shared/view/ListLayout/ListLayout';
import { MobileListItem } from '../../shared/view/ListLayout/MobileListItem';
import { Icon } from '../../../src-common/view/components/Icon';
import { StatusTag } from '../../shared/view/StatusTag';
import { AccessStatus } from '../../shared/view/AccessStatus';
import { TransferStatus } from '../../shared/view/TransferStatus';
import { getCityTimezone } from '../../modules/partner/store/selectors';
import { CarCell } from '../../shared/view/CarCell';
import { Avatar } from './components/Avatar';
import { Phone } from './components/Phone';
import { LastActiveCell } from './components/LastActiveCell';
import s from './styles.css';
const GROUP_ORDER = ['online', 'busy', 'offline'];
const filterConfig = [
    {
        title: _jsx(FormattedMessage, { id: 'Fk2UcG', defaultMessage: 'Search' }),
        name: 'search',
        type: 'text',
        initialValue: '',
    },
];
const getFullName = (driver) => [driver.firstName, driver.lastName].join(' ');
const View = ({ drivers, fetchDrivers, onClickNew, isLoading = false }) => {
    const history = useHistory();
    const timezone = useSelector(getCityTimezone);
    const columns = useMemo(() => [
        {
            renderHeader: () => _jsx(_Fragment, {}),
            renderCell: driver => _jsx(Avatar, { photoURL: driver.photoURL }),
            style: { width: '64px' },
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'SfHYNA', defaultMessage: 'Name' })),
            renderCell: getFullName,
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: '6jQUMP', defaultMessage: 'Rating' })),
            renderCell: driver => (_jsx(FormattedNumber, { value: driver.rating, maximumFractionDigits: 2, minimumFractionDigits: 2 })),
            style: { width: '84px' },
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'ENwC6W', defaultMessage: 'Vehicle' })),
            renderCell: driver => _jsx(CarCell, { carId: driver.carId }),
            style: { width: '258px' },
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'ICsLCD', defaultMessage: 'Last activity' })),
            renderCell: driver => _jsx(LastActiveCell, { lastActivity: driver.lastActivity }),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: '9o7Uk1', defaultMessage: 'Phone number' })),
            renderCell: driver => _jsx(Phone, { phone: driver.phone }),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'ixac+i', defaultMessage: 'Status' })),
            renderCell: driver => (_jsxs("div", { className: s.status, children: [_jsx(StatusTag, { status: driver.onlineStatus }), _jsx(AccessStatus, { status: driver.accessStatus }), driver.transferRequest && (_jsx(TransferStatus, { date: driver.transferRequest.leavingAtDate, timezone: timezone }))] })),
        },
    ], [timezone]);
    const onClickRow = useCallback((driver) => driver && history.push(`/chauffeurs/${driver.id}`), [history]);
    return (_jsx(ListLayoutView, { wrapperClassName: s.wrapper, title: _jsx(FormattedMessage, { id: 'PdVFfx', defaultMessage: 'Chauffeurs' }), data: drivers.items, total: drivers.total, limit: drivers.limit, columns: columns, onFetchData: fetchDrivers, getEntityId: driver => String(driver.id), shouldBeEmptyWithoutFilters: false, groupOrder: GROUP_ORDER, getEntityGroup: driver => driver.onlineStatus, filterConfig: filterConfig, renderItem: driver => (_jsx(Link, { to: `/chauffeurs/${driver.id}`, children: _jsx(MobileListItem, { title: _jsxs(_Fragment, { children: [getFullName(driver), " ", _jsx(AccessStatus, { status: driver.accessStatus }), driver.transferRequest && (_jsx(TransferStatus, { date: driver.transferRequest.leavingAtDate, timezone: timezone }))] }), note: _jsx(FormattedNumber, { value: driver.rating, maximumFractionDigits: 2, minimumFractionDigits: 2 }), right: _jsx(Icon, { icon: 'chevron-to-end' }) }) })), onClickRow: onClickRow, action: useMemo(() => ({
            onClick: onClickNew,
            title: (_jsx(FormattedMessage, { id: 'IoXTmo', defaultMessage: 'Add new' })),
            className: s.action,
        }), [onClickNew]), isLoading: isLoading }));
};
export { View };
