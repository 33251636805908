import { createClient } from '../../../../src-common/api/src/create-client';
import { w4dCitiesRemoteAPI } from '../../../../src-common/api/definitions/w4d-cities-endpoints';
import { w4dPressureRemoteAPI } from '../../../../src-common/api/definitions/w4d-pressure-endpoints';
import { get } from '../../../shared/api/fetch-based-http-methods';
import { CountryCode } from '../../../shared/models/country-code';
import { ENV } from '../../../env';
const citiesClient = createClient(w4dCitiesRemoteAPI({ apiHost: ENV.API_URL, get }));
const pressureClient = createClient(w4dPressureRemoteAPI({ apiHost: ENV.API_URL, get }));
const prepareTest = (testRaw) => ({
    id: testRaw.id,
    description: testRaw.text_description ?? '',
    title: testRaw.text_title ?? '',
    localizedTitle: testRaw.title,
    localizedDescription: testRaw.body_driver,
    testType: testRaw.type,
    linkType: testRaw.link?.type ?? '',
    linkUrl: testRaw.link?.url ?? '',
    eLearningModuleLink: testRaw.e_learning_module_link ?? '',
});
const prepareCities = (cities) => {
    const countryCodes = Object.values(CountryCode);
    return cities
        .filter(city => city.country_code && countryCodes.includes(city.country_code))
        .map(city => ({
        id: city.id,
        localizedName: city.localized_name,
        name: city.name,
        countryCode: city.country_code,
        testDefinitions: city?.driver_tests?.map(prepareTest) ?? [],
        timezone: city.timezone,
        currencyCode: city.currency,
        isEnabledForOnboarding: city.onboarding_enabled,
    }));
};
const commonAPI = {
    readCites: async () => {
        const response = await citiesClient.readCities();
        return prepareCities(Object.values(response.cities));
    },
    readPressure: async () => {
        const response = await pressureClient.readPressure();
        return response.pressure;
    },
};
export { commonAPI };
