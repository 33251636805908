import * as t from 'io-ts';
import { Bill } from './w4d-bills-types';
const w4dBillsRemoteAPI = ({ apiHost, get }) => ({
    readBills: {
        path: ({ limit, offset }) => `${apiHost}/v6/partners/bills?limit=${limit}&skip=${offset}`,
        pathParams: t.type({
            limit: t.string,
            offset: t.string,
        }),
        method: get,
        response: t.type({
            total_entries: t.Int,
            skip: t.Int,
            limit: t.Int,
            results: t.array(Bill),
        }),
    },
    readUpcomingBill: {
        path: `${apiHost}/v6/partners/bills/upcoming`,
        method: get,
        response: t.type({
            bill: Bill,
        }),
    },
});
export { w4dBillsRemoteAPI };
