import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { TextField } from '../../../../../../src-common/view/adapters/reactHookForm/TextField';
const GbPaymentInfo = ({ control }) => {
    const intl = useIntl();
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { name: "accountNumber", title: intl.formatMessage({ id: "n8n0/+", defaultMessage: "ACCOUNT NUMBER" }), placeholder: intl.formatMessage({ id: "mPjzkt", defaultMessage: "00000000" }), rules: {
                    pattern: {
                        value: /^\d{6,8}$/,
                        message: intl.formatMessage({ id: "nJIDyD", defaultMessage: "This is between 6 and 8 digits." }),
                    },
                }, description: intl.formatMessage({ id: "ZmNueo", defaultMessage: "This is between 6 and 8 digits." }), control: control }), _jsx(TextField, { name: "sortCode", title: intl.formatMessage({ id: "2HtcXD", defaultMessage: "SORT CODE" }), placeholder: intl.formatMessage({ id: "6yRuXM", defaultMessage: "123456" }), rules: {
                    pattern: {
                        value: /^\d{6}$/,
                        message: intl.formatMessage({ id: "IQpLQz", defaultMessage: "This is 6 digits." }),
                    },
                }, control: control })] }));
};
export { GbPaymentInfo };
