import { defineMessages } from 'react-intl';
import { EventNameEnum } from '../../modules/journey/model';
const EVENTS = defineMessages({ [EventNameEnum.on_the_way]: { id: "Mi6G3e", defaultMessage: "On the way" }, [EventNameEnum.arrived]: { id: "KJ39fF", defaultMessage: "Arrived" }, [EventNameEnum.serving]: { id: "8zmoNb", defaultMessage: "Passenger on board" }, [EventNameEnum.done]: { id: "+kV9Ny", defaultMessage: "Done" }, [EventNameEnum.cancelled]: { id: "G7yHtb", defaultMessage: "Cancelled" }, [EventNameEnum.failed]: { id: "bZv1Wh", defaultMessage: "Failed" }, [EventNameEnum.looking_for_drivers]: { id: "/t/QLL", defaultMessage: "Looking for chauffeur" }, [EventNameEnum.extrastop]: { id: "3hwXxL", defaultMessage: "Extrastop" }, [EventNameEnum.car_not_found]: { id: "nwutDs", defaultMessage: "Vehicle not found" } });
const renderEvent = (event, intl) => {
    if (event in EVENTS) {
        return intl.formatMessage(EVENTS[event]);
    }
    return '–';
};
export { renderEvent };
