import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../../driver/namespace';
import { setReferralCode } from '../../../driver/actions/actions';
import { driverAPI } from '../../../driver/api';
const loadReferralCode = createAsyncThunk(`${namespace}/load-referral-code`, async (_, { dispatch }) => {
    try {
        const referralCode = await driverAPI.fetchReferralCode();
        dispatch(setReferralCode(referralCode));
        return referralCode;
    }
    catch (error) {
        return undefined;
    }
});
export { loadReferralCode };
