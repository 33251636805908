import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUserId } from '@snowplow/browser-tracker';
import { wait } from '../../../shared/utils/wait';
import { deleteCookie } from '../../../shared/utils/cookie';
import { errorHandling } from '../../../services/errorHandling';
import { AUTH_COOKIE_KEY } from '../../../shared/utils/session';
import { authAPI } from '../api';
import { history } from '../../../services/history';
import { reset } from '../../../rootActions';
import { authNamespace, setCallRequestedAt, resetCallRequestedAt } from './slice';
export const signIn = createAsyncThunk(`${authNamespace}/signIn`, async ({ phone, captcha, locale }) => authAPI.signInWithPhone(phone, captcha, locale));
export const signOut = createAsyncThunk(`${authNamespace}/signOut`, async (_, { dispatch }) => {
    deleteCookie(AUTH_COOKIE_KEY);
    await authAPI.signOut();
    dispatch(reset());
    errorHandling.clearIdentity();
    setUserId(null);
    history.replace('/login');
});
export const confirmPhone = createAsyncThunk(`${authNamespace}/confirmPhone`, async ({ pin, locale }) => {
    const result = await authAPI.activateToken(pin, locale);
    return result;
});
let currentCallRequestId = '';
export const requestAuthAutoCall = createAsyncThunk(`${authNamespace}/autoCall`, async ({ phone, captcha, locale }, { requestId, dispatch }) => {
    if (currentCallRequestId) {
        return;
    }
    currentCallRequestId = requestId;
    try {
        const result = await authAPI.requestPinCall(phone, captcha, locale);
        if (!result.error) {
            dispatch(setCallRequestedAt());
            await wait(60 * 1000);
            dispatch(resetCallRequestedAt());
        }
    }
    finally {
        currentCallRequestId = '';
    }
});
