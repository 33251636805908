import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useContext } from 'react';
import { useIntl } from 'react-intl';
import { WeekPicker as BaseWeekPicker } from '@wheely/ui-kit';
import { IntlContext } from '../../../i18n';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from './const';
const LocalizedWeekPicker = (props) => {
    const intl = useIntl();
    const { language } = useContext(IntlContext);
    const [localizedMonths, localizedWeekdaysLong, localizedWeekdaysShort] = useMemo(() => [
        MONTHS.map(message => intl.formatMessage(message)),
        WEEKDAYS_LONG.map(message => intl.formatMessage(message)),
        WEEKDAYS_SHORT.map(message => intl.formatMessage(message)),
    ], [intl]);
    return (_jsx(BaseWeekPicker, { locale: language, firstDayOfWeek: 1, months: localizedMonths, weekdaysLong: localizedWeekdaysLong, weekdaysShort: localizedWeekdaysShort, ...props }));
};
export { LocalizedWeekPicker };
