import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Section } from '../../../../shared/view/Section';
import { CountryCode } from '../../../../shared/models/country-code';
import { RuPaymentInfo } from './LocalizedForm/ru';
import { GbPaymentInfo } from './LocalizedForm/gb';
import { FrPaymentInfo } from './LocalizedForm/fr';
import { UaePaymentInfo } from './LocalizedForm/uae';
const PaymentsView = ({ control, partnerType, countryCode }) => {
    const form = useMemo(() => {
        switch (countryCode) {
            case CountryCode.Russia: {
                return _jsx(RuPaymentInfo, { partnerType: partnerType, control: control });
            }
            case CountryCode.France: {
                return _jsx(FrPaymentInfo, { control: control });
            }
            case CountryCode.ArabEmirates: {
                return _jsx(UaePaymentInfo, { control: control });
            }
            default:
                return _jsx(GbPaymentInfo, { control: control });
        }
    }, [countryCode, partnerType, control]);
    return _jsx(Section, { children: form });
};
export { PaymentsView };
