import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../../../../../src-common/view/components/Icon';
import { isEmpty, getNonEmptyFieldsCount } from '../utils';
import { FilterItem } from './FilterItem';
import { useFiltersDialog } from './FiltersDialog';
import s from './MobileFilters.css';
const MobileFilters = ({ value, config, onChange, onRemoveFilters, filtersRef, children, }) => {
    const handleApplyFilters = useCallback(filters => {
        Object.entries(filters).forEach(([key, filterValue]) => {
            onChange(undefined, { name: key, value: filterValue });
        });
    }, [onChange]);
    const { openModal } = useFiltersDialog({
        filtersConfig: config,
        onApplyFilters: handleApplyFilters,
        onRemoveFilters,
        initialValues: value,
    });
    if (config.length === 0) {
        return null;
    }
    const visibleFilter = config[config.length - 1];
    const appliedFiltersCount = isEmpty(value) ? 0 : getNonEmptyFieldsCount(value);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.filters, ref: filtersRef, children: _jsxs("div", { className: s.item, children: [_jsx("div", { className: s.itemMain, children: _jsx(FilterItem, { value: value, onChange: onChange, title: visibleFilter.title, name: visibleFilter.name, placeholder: visibleFilter.placeholder, options: visibleFilter.options, type: visibleFilter.type, icon: visibleFilter.icon, hasOneLineLabel: visibleFilter.hasOneLineLabel, isCompact: true }) }), config.length > 1 && (_jsx("button", { className: s.filterButton, onClick: openModal, children: _jsx(Icon, { icon: "filter", size: "24px" }) }))] }) }), appliedFiltersCount > 0 && (_jsx("div", { className: s.filtersCount, children: _jsx(FormattedMessage, { id: 'ZPlKXC', defaultMessage: 'Filters applied: {count}', values: { count: appliedFiltersCount } }) })), children && (_jsx("div", { className: s.child, children: children }, "#children"))] }));
};
export { MobileFilters };
