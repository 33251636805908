import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCityTimezone } from '../../../modules/partner/store/selectors';
import { TwoLineCell } from '../../../shared/view/TwoLineCell';
const LastActiveCell = React.memo(({ lastActivity }) => {
    const timeZone = useSelector(getCityTimezone) ?? 'utc';
    return lastActivity ? (_jsx(TwoLineCell, { line1: _jsx(FormattedDate, { value: lastActivity, timeZone: timeZone }), line2: _jsx(FormattedTime, { value: lastActivity, timeZone: timeZone }) })) : (_jsx(FormattedMessage, { id: '/adrJa', defaultMessage: 'No' }));
});
export { LastActiveCell };
