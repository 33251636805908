import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { NavMenu } from '../../../../../src-common/view/components/NavMenu';
import { useExternalLinks } from '../../../hooks/useExternalLinks';
import { signOut } from '../../../../modules/auth/store/actions';
import { getAllCars } from '../../../../modules/car/store/selectors';
import { permissions } from '../../../contexts/permissions';
import { useBookingActions } from '../../../../shared/utils/bookingLinksUtils';
import { IntlContext } from '../../../../i18n';
import { getMyDriver } from '../../../../modules/driver/store/selectors';
import { getCity } from '../../../../modules/partner/store/selectors';
import { getPressure } from '../../../../modules/common/store/slice';
import { LanguageMenu } from './LanguageMenu';
import { CityPressure } from './CityPressure';
const isMenuItem = (maybeItem) => Boolean(maybeItem);
const MainMenu = ({ onNavigate, className }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { region, language } = useContext(IntlContext);
    const links = useExternalLinks();
    const cars = useSelector(getAllCars);
    const { can } = useContext(permissions);
    const driver = useSelector(getMyDriver);
    const city = useSelector(getCity);
    const localizedCityName = city?.localizedName[language];
    const pressure = useSelector(getPressure);
    const { openModalAction, goToExternalLinkAction } = useBookingActions({ region, driver });
    const menuItems = useMemo(() => [
        can.viewTodo && {
            title: intl.formatMessage({ id: "aVUtxs", defaultMessage: "To-Do List" }),
            onClick: () => onNavigate?.('/todo'),
            keepVisibleOnLargeScreen: true,
        },
        can.viewOverview && {
            title: intl.formatMessage({ id: "rLIKeT", defaultMessage: "Overview" }),
            onClick: () => onNavigate?.(`/overview`),
            keepVisibleOnLargeScreen: true,
        },
        can.viewDrivers && {
            title: intl.formatMessage({ id: "8jZiZo", defaultMessage: "Chauffeurs" }),
            onClick: () => onNavigate?.(`/chauffeurs`),
            keepVisibleOnLargeScreen: true,
        },
        can.viewCars && {
            title: intl.formatMessage({ id: "4zRzj8", defaultMessage: "Vehicles" }),
            onClick: () => onNavigate?.(cars.length === 0 ? '/add-vehicle' : '/vehicles'),
            keepVisibleOnLargeScreen: true,
        },
        can.viewJourneys && {
            title: intl.formatMessage({ id: "R5qHEZ", defaultMessage: "Journeys" }),
            onClick: () => onNavigate?.(`/journeys`),
            keepVisibleOnLargeScreen: true,
        },
        can.viewBills && {
            title: intl.formatMessage({ id: "3lmlzx", defaultMessage: "Bills" }),
            onClick: () => onNavigate?.(`/bills`),
            keepVisibleOnLargeScreen: true,
        },
        can.viewProfile && {
            title: intl.formatMessage({ id: "qkBbAc", defaultMessage: "Profile" }),
            onClick: () => onNavigate?.('/profile'),
            keepVisibleOnLargeScreen: true,
        },
        {
            title: intl.formatMessage({ id: "Vsu9vZ", defaultMessage: "Questions" }),
            subItems: [
                {
                    title: intl.formatMessage({ id: "XfJ/7B", defaultMessage: "FAQ" }),
                    externalLink: links.faq,
                },
                {
                    title: intl.formatMessage({ id: "MM/sCI", defaultMessage: "App" }),
                    externalLink: links.downloadDriverApp,
                },
                {
                    title: intl.formatMessage({ id: "xhfLcg", defaultMessage: "Book an Appointment" }),
                    isDisabled: !can.bookAnAccreditation,
                    externalLink: goToExternalLinkAction,
                    onClick: openModalAction,
                },
            ],
        },
        can.viewPressure && {
            Component: () => _jsx(CityPressure, { value: pressure, cityName: localizedCityName }),
        },
        {
            title: intl.formatMessage({ id: "S6MKBJ", defaultMessage: "Settings" }),
            subItems: [
                {
                    title: 'Language',
                    Component: LanguageMenu,
                },
                {
                    title: intl.formatMessage({ id: "4CIyDw", defaultMessage: "Log out" }),
                    onClick: () => dispatch(signOut()),
                },
            ],
        },
    ].filter(isMenuItem), [
        can.viewTodo,
        can.viewProfile,
        can.viewOverview,
        can.viewCars,
        can.viewDrivers,
        can.viewBills,
        can.viewJourneys,
        can.bookAnAccreditation,
        intl,
        links.faq,
        links.downloadDriverApp,
        goToExternalLinkAction,
        openModalAction,
        onNavigate,
        cars.length,
        dispatch,
        can.viewPressure,
        pressure,
        localizedCityName,
    ]);
    return (_jsx(NavMenu, { appName: intl.formatMessage({ id: "nNw0W5", defaultMessage: "For chauffeurs" }), menuItems: menuItems, className: className }));
};
export { MainMenu };
