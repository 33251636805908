import * as t from 'io-ts';
import { City } from './w4d-city-types';
const w4dCitiesRemoteAPI = ({ apiHost, get }) => ({
    readCities: {
        path: `${apiHost}/v6/cities`,
        method: get,
        response: t.type({
            cities: t.record(t.string, City),
        }),
    },
});
export { w4dCitiesRemoteAPI };
