import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../../../../src-common/view/components/Icon';
import s from './Pagination.css';
const PaginationButton = ({ onClick, offset, current, total, isDisabled, }) => {
    const targetPage = current + offset;
    const isBack = offset < 0;
    const isReachedLimit = isBack ? targetPage < 1 : current === total;
    return (_jsxs("button", { disabled: isDisabled || isReachedLimit, className: cn(s.button, isBack ? s.back : s.next), onClick: isDisabled ? undefined : onClick, value: targetPage, children: [isBack && _jsx(Icon, { icon: "arrow-back" }), !isReachedLimit && (_jsx(FormattedMessage, { id: 'tvRjpW', defaultMessage: 'page' })), ' ', !isReachedLimit && targetPage, !isBack && _jsx(Icon, { icon: "arrow-next" })] }));
};
const Pagination = ({ offset, limit, total, onChangeOffset, isDisabled }) => {
    const page = Math.floor(offset / limit) + 1;
    const totalPage = Math.floor(total / limit) + 1;
    const handleChange = useCallback((event) => {
        onChangeOffset((Number(event.currentTarget.value) - 1) * limit);
    }, [limit, onChangeOffset]);
    return (_jsxs("div", { className: s.pagination, children: [_jsx(PaginationButton, { onClick: handleChange, current: page, total: totalPage, offset: -1, isDisabled: isDisabled }), _jsx("div", { className: s.center, children: _jsx(FormattedMessage, { id: '+TVp/2', defaultMessage: '{page} of {totalPage}', values: { page, totalPage } }) }), _jsx(PaginationButton, { onClick: handleChange, current: page, total: totalPage, offset: +1, isDisabled: isDisabled })] }));
};
export { Pagination };
