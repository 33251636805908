import * as t from 'io-ts';
const Bill = t.type({
    id: t.string,
    number: t.union([t.Int, t.null]),
    date: t.string,
    paid: t.boolean,
    paid_at: t.union([t.string, t.null]),
    amount: t.string,
    currency_code: t.string,
    pdf: t.string,
    excel: t.string,
    period_start: t.string,
    period_end: t.string,
    period_start_date: t.string,
    period_end_date: t.string,
});
export { Bill };
