import { createSlice } from '@reduxjs/toolkit';
import { namespace as commonNamespace } from '../namespace';
const getPressure = (state) => state[commonNamespace].pressure;
const initialState = {
    cities: [],
    pressure: null,
};
const commonSlice = createSlice({
    name: commonNamespace,
    initialState,
    reducers: {
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        setPressure: (state, action) => {
            state.pressure = action.payload;
        },
    },
});
export const { setCities, setPressure } = commonSlice.actions;
export { commonSlice, commonNamespace, getPressure };
