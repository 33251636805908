import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useCallback, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Tag } from '@wheely/ui-kit';
import { useHistory } from 'react-router-dom';
import { errorHandling } from '../../services/errorHandling';
import { ListLayoutView } from '../../shared/view/ListLayout/ListLayout';
import { MobileListItem } from '../../shared/view/ListLayout/MobileListItem';
import { renderStatus } from '../../shared/utils/renderStatus';
import { getStatusIntent } from '../../shared/utils/getStatusIntent';
import { Icon } from '../../../src-common/view/components/Icon';
import s from './styles.css';
const View = ({ journeys, chauffeurs, fetchJourneys, requestJourneysReport, forChauffeur, fromDate, toDate, isLoading = false, }) => {
    const intl = useIntl();
    const history = useHistory();
    const [isRequestingReport, setIsRequestingReport] = useState(false);
    const [filters, setFilters] = useState({
        driverId: forChauffeur,
        from: fromDate,
        to: toDate,
    });
    const handleRowClick = useCallback((journey) => {
        journey && history.push(`/journeys/${journey.id}`);
    }, [history]);
    const onExcelButtonClick = useCallback(async () => {
        if (isRequestingReport) {
            return;
        }
        try {
            setIsRequestingReport(true);
            await requestJourneysReport({
                driverId: filters.driverId,
                from: filters.from,
                to: filters.to,
            });
        }
        catch (error) {
            errorHandling.captureException(error);
        }
        finally {
            setIsRequestingReport(false);
        }
    }, [filters, requestJourneysReport, isRequestingReport]);
    const filterConfig = useMemo(() => [
        {
            name: 'from',
            type: 'date',
            hasOneLineLabel: true,
            fixedValue: fromDate,
            title: (_jsx(FormattedMessage, { id: 'Yd9/E1', defaultMessage: 'From' })),
        },
        {
            name: 'to',
            type: 'date',
            hasOneLineLabel: true,
            fixedValue: toDate,
            title: (_jsx(FormattedMessage, { id: 'Uxa2xM', defaultMessage: 'To' })),
        },
        {
            name: 'driverId',
            type: 'select',
            placeholder: '',
            hasOneLineLabel: true,
            title: (_jsx(FormattedMessage, { id: 'PFkoxL', defaultMessage: 'Chauffeur' })),
            options: chauffeurs.map(chauffeur => ({
                title: `${chauffeur.firstName} ${chauffeur.lastName}`,
                value: chauffeur.id,
            })),
            fixedValue: forChauffeur,
            isClearable: true,
        },
        {
            name: 'number',
            type: 'text',
            icon: _jsx(Icon, { icon: "search", size: "18px", className: s.searchIcon }),
            hasOneLineLabel: true,
            title: (_jsx(FormattedMessage, { id: '1k+oza', defaultMessage: 'Search by number' })),
        },
    ], [chauffeurs, forChauffeur, fromDate, toDate]);
    const columns = useMemo(() => [
        {
            renderHeader: () => '#',
            renderCell: (_, _1, index) => journeys.offset + index + 1,
            style: { width: '40px' },
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'TLkwfC', defaultMessage: 'Date' })),
            renderCell: journey => journey.completedAt ? (_jsx(FormattedDate, { value: journey.completedAt, timeZone: journey.timezone, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })) : ('–'),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'le/TJw', defaultMessage: 'Service' })),
            renderCell: journey => journey.service,
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'KC13My', defaultMessage: 'Status' })),
            renderCell: journey => (_jsx(Tag, { intent: getStatusIntent(journey.status), children: renderStatus(journey.status, intl) })),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'y4M8hm', defaultMessage: 'Cost' })),
            renderCell: journey => journey.price !== undefined ? (_jsx(FormattedNumber, { value: journey.price, style: "currency", currency: journey.currencyCode })) : ('–'),
            style: { width: '100px' },
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'xHSdWR', defaultMessage: 'Vehicle' })),
            renderCell: journey => (journey.vehicle ? journey.vehicle.licensePlate : '–'),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'PWyHXt', defaultMessage: 'Chauffeur' })),
            renderCell: journey => journey.chauffeur ? `${journey.chauffeur.firstName} ${journey.chauffeur.lastName}` : '–',
        },
    ], [intl, journeys.offset]);
    return (_jsx("div", { className: s.root, children: _jsx(ListLayoutView, { title: _jsx(FormattedMessage, { id: 'vtkn7V', defaultMessage: 'Journeys' }), data: journeys.items, total: journeys.total, limit: journeys.limit, columns: columns, getEntityId: journey => String(journey.id), onFetchData: fetchJourneys, onFiltersChange: setFilters, onClickRow: handleRowClick, shouldBeEmptyWithoutFilters: false, filterConfig: filterConfig, isLoading: isLoading, after: _jsxs("button", { disabled: isRequestingReport || journeys.items.length === 0, className: s.excelButton, onClick: onExcelButtonClick, children: [_jsx(FormattedMessage, { id: 'qY30KY', defaultMessage: 'Send Excel to email' }), ' ', _jsx(Icon, { icon: "arrow-to-end-in-circle", size: "24px" })] }), renderItem: journey => (_jsx(MobileListItem, { title: _jsxs("div", { className: s.title, children: [journey.vehicle ? journey.vehicle.licensePlate : '–', _jsx(Tag, { className: s.tag, intent: getStatusIntent(journey.status), children: renderStatus(journey.status, intl) })] }), note: journey.completedAt ? (_jsx(FormattedDate, { value: journey.completedAt, timeZone: journey.timezone, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })) : ('–'), right: journey.price ? (_jsx(FormattedNumber, { value: journey.price, style: "currency", currency: journey.currencyCode })) : ('–') })) }) }));
};
export { View };
