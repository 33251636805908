import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import s from './index.css';
const getWidth = (nodeRef) => {
    if (nodeRef?.current) {
        return nodeRef.current.clientWidth;
    }
    return 0;
};
const isEmpty = (value) => value === '' || value === null || value === undefined;
const InputBase = React.forwardRef((props, ref) => {
    const { startElement, endElement, ghostText, value, hasFocusHighlight, className, hasError, disabled, type, placeholder, dataTestId = 'input', ...inputProps } = props;
    const startRef = useRef(null);
    const endRef = useRef(null);
    const ghostTextRef = useRef(null);
    const [paddingStart, setPaddingStart] = useState(10);
    const [paddingEnd, setPaddingEnd] = useState(10);
    useLayoutEffect(() => {
        const startWidth = getWidth(startRef);
        if (startWidth && paddingStart !== startWidth) {
            setPaddingStart(startWidth);
        }
        const endWidth = getWidth(endRef);
        if (endWidth && paddingEnd !== endWidth) {
            setPaddingEnd(endWidth);
        }
    }, [paddingStart, paddingEnd, ghostText, ghostTextRef, startRef, endRef]);
    return (_jsxs("div", { "data-test-id": `${dataTestId}__wrapper`, className: s.wrapper, style: {
            '--input-start-padding': paddingStart + 'px',
            '--input-end-padding': paddingEnd + 'px',
        }, children: [_jsx("input", { ...inputProps, type: type, disabled: disabled, ref: ref, value: value, className: cn(className, s.input, {
                    [s.hasFocus]: hasFocusHighlight,
                    [s.isDisabled]: disabled,
                    [s.danger]: hasError,
                }), "data-test-id": dataTestId }), ghostText && (_jsx("div", { className: s.ghostText, ref: ghostTextRef, children: _jsxs("div", { className: s.ghostTextVisibleBox, "data-test-id": `${dataTestId}__ghost-text`, children: [_jsx("span", { className: s.ghostIndent, children: value }), ghostText] }) })), isEmpty(value) && (_jsx("div", { "data-test-id": `${dataTestId}__placeholder`, className: s.placeholder, children: placeholder })), startElement && (_jsx("div", { className: s.start, ref: startRef, "data-test-id": `${dataTestId}__before`, children: startElement })), endElement && (_jsx("div", { className: s.end, ref: endRef, "data-test-id": `${dataTestId}__after`, children: endElement }))] }));
});
export { InputBase };
