import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { partnerUploadSelectors } from '../../store/uploadSlice';
import { updateUploadFormValue } from '../actions';
const prepareUploadPartnerForm = createAsyncThunk(`${namespace}/prepare-upload-partner-form`, async ({ docType, onSuccess }, { getState, dispatch }) => {
    dispatch(updateUploadFormValue({ docType }));
    const values = partnerUploadSelectors.getValuesForUploadForm(getState());
    dispatch(updateUploadFormValue({
        expiresAt: values?.expiresAt,
        file: values?.file,
        status: values?.status,
        rejectReason: values?.rejectReason,
    }));
    onSuccess?.();
    return { isSucceed: true };
});
export { prepareUploadPartnerForm };
