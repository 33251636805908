import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { usePermissions } from '../../app/hooks/usePermissions';
import { getDrivers } from '../../modules/driver/store/selectors';
import { loadDrivers } from '../../modules/driver/actions/async/loadDrivers';
import { View } from './view';
const DriverList = ({ onNavigate }) => {
    const dispatch = useDispatch();
    usePermissions('viewDrivers');
    const drivers = useSelector(getDrivers);
    const [isLoading, setIsLoading] = useState(false);
    const handleClickNew = useCallback(() => {
        onNavigate('/add-chauffeur');
    }, [onNavigate]);
    const fetchDrivers = useMemo(() => debounce(async ({ search = '' }, { limit, offset }) => {
        setIsLoading(true);
        try {
            await dispatch(loadDrivers({ limit, offset, query: search.trim() })).unwrap();
        }
        finally {
            setIsLoading(false);
        }
    }, 200, {
        leading: false,
        trailing: true,
    }), [dispatch, setIsLoading]);
    return (_jsx(View, { fetchDrivers: fetchDrivers, drivers: drivers, onClickNew: handleClickNew, isLoading: isLoading }));
};
export { DriverList };
