const renderStatus = (intl, status) => {
    switch (status) {
        case 'expired_docs':
            return intl.formatMessage({ id: "CRUMf/", defaultMessage: "Expired documents" });
        case 'active':
            return intl.formatMessage({ id: "8EHCFU", defaultMessage: "Active" });
        case 'blocked':
            return intl.formatMessage({ id: "WhfbvD", defaultMessage: "Blocked" });
        case 'partner_blocked':
            return intl.formatMessage({ id: "9I+vB7", defaultMessage: "Partner blocked" });
        case 'failed_tests':
            return intl.formatMessage({ id: "/mwJYF", defaultMessage: "Failed tests" });
        case 'pending':
            return intl.formatMessage({ id: "yLqv3w", defaultMessage: "Pending approval" });
        case 'rejected':
            return intl.formatMessage({ id: "IvvbfX", defaultMessage: "Rejected" });
        case 'new_partner':
        case 'end_of_life':
            return '-';
    }
};
export { renderStatus };
