import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import s from './index.css';
const SimpleDropdown = (props) => {
    const { target, children, onRequireClose, isExpanded, dataTestId = 'dropdown' } = props;
    const popupRef = useRef(null);
    const rootRef = useRef(null);
    useEffect(() => {
        const handleClickBody = (event) => {
            if (isExpanded && !rootRef.current?.contains(event.target)) {
                onRequireClose?.();
            }
        };
        window?.document.body.addEventListener('click', handleClickBody);
        return () => {
            window?.document.body.removeEventListener('click', handleClickBody);
        };
    }, [isExpanded, onRequireClose]);
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape') {
            onRequireClose?.();
        }
    }, [onRequireClose]);
    return (_jsxs("div", { className: s.simpleDropdown, ref: rootRef, onKeyDown: handleKeyDown, "data-test-id": `${dataTestId}__wrapper`, children: [target, _jsx("div", { "data-test-id": `${dataTestId}__over`, className: cn(s.popup, { [s.isExpanded]: isExpanded }), ref: popupRef, children: children })] }));
};
export { SimpleDropdown };
