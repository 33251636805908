import qs from 'qs';
const merge = (path, queryString) => path + (path.includes('?') ? '&' : '?') + queryString;
const applyPathParams = (path, params) => {
    if (typeof path === 'string') {
        return merge(path, qs.stringify(params));
    }
    const paramsMap = new Map(Object.entries(params));
    const proxy = {};
    Object.defineProperties(proxy, Object.fromEntries(Object.entries(params).map(([key, value]) => [
        key,
        {
            get: () => {
                paramsMap.delete(key);
                return value;
            },
        },
    ])));
    const appliedPath = path(proxy);
    const withoutUtilized = Object.fromEntries(paramsMap.entries());
    return merge(appliedPath, qs.stringify(withoutUtilized));
};
export { applyPathParams };
