import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useMemo, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Loader } from '@wheely/ui-kit';
import debounce from 'lodash/debounce';
import { TextField } from '../../../../../../src-common/view/adapters/reactHookForm/TextField';
import { getPaymentInfoByBIK } from '../../../../../modules/partner/actions/async/getPaymentInfoByBIK';
import s from './styles.css';
const bikValidationRegex = /^\d{9}$/;
const RuPaymentInfo = ({ control, partnerType }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { watch, setError, clearErrors, setValue } = useFormContext();
    const [isLoading, setIsLoading] = useState(false);
    const [bik, corAccount, bankName] = watch(['bik', 'corAccount', 'bankName']);
    const fetchAdditionalPaymentInfo = useMemo(() => debounce(async (newBik) => {
        clearErrors('bik');
        setIsLoading(true);
        try {
            const result = await dispatch(getPaymentInfoByBIK(newBik)).unwrap();
            if (result.paymentBankName && result.paymentKorAccount) {
                setValue('bankName', result.paymentBankName);
                setValue('corAccount', result.paymentKorAccount);
            }
            if ('isError' in result) {
                setError('bik', {
                    message: intl.formatMessage({ id: "FFzE1r", defaultMessage: "Incorrect BIC" }),
                });
            }
        }
        finally {
            setIsLoading(false);
        }
    }, 200), [dispatch, setIsLoading, setError, clearErrors, intl, setValue]);
    useEffect(() => {
        const getBankNameAndCorAccount = async () => {
            const isBikValid = new RegExp(bikValidationRegex).test(bik);
            if (isBikValid) {
                fetchAdditionalPaymentInfo(bik);
            }
        };
        getBankNameAndCorAccount();
    }, [bik, dispatch, fetchAdditionalPaymentInfo]);
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { name: "rasAccount", title: intl.formatMessage({ id: "n8n0/+", defaultMessage: "ACCOUNT NUMBER" }), placeholder: intl.formatMessage({ id: "2p4yS8", defaultMessage: "4080181000000600001" }), description: intl.formatMessage({ id: "4qvmrq", defaultMessage: "{partnerType, select, Self_Employed {Account held in your name.} \u0418\u041F {Account opened in the name of the individual entrepreneur.} \u042E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u043E\u0435_\u043B\u0438\u0446\u043E {Account in the name of the company.} other {} }" }, { partnerType: (partnerType ?? 'other').replace(/\s/g, '_') }), control: control, rules: {
                    required: true,
                    pattern: {
                        value: /^\d{20}$/,
                        message: intl.formatMessage({ id: "0rf3pP", defaultMessage: "This is 20 digits." }),
                    },
                } }), _jsx(TextField, { name: "bik", title: intl.formatMessage({ id: "TcbaS8", defaultMessage: "BIC" }), placeholder: intl.formatMessage({ id: "kVUU8D", defaultMessage: "044525225" }), control: control, rules: {
                    required: true,
                    pattern: {
                        value: bikValidationRegex,
                        message: intl.formatMessage({ id: "NWDmx1", defaultMessage: "This is 9 digits." }),
                    },
                } }), corAccount && bankName ? (_jsxs(_Fragment, { children: [_jsx(TextField, { isDisabled: true, name: "bankName", title: _jsxs("div", { className: s.textFieldLabel, children: [_jsx(FormattedMessage, { id: 'NfDBdG', defaultMessage: 'Bank name' }), isLoading && _jsx(Loader, { className: s.textFieldLoader })] }), control: control }), _jsx(TextField, { isDisabled: true, name: "corAccount", title: _jsxs("div", { className: s.textFieldLabel, children: [_jsx(FormattedMessage, { id: 'nkBpTV', defaultMessage: 'Correspondent account' }), isLoading && _jsx(Loader, { className: s.textFieldLoader })] }), control: control })] })) : (isLoading && _jsx(Loader, { className: s.loader }))] }));
};
export { RuPaymentInfo };
