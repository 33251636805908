import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useController } from 'react-hook-form';
import { TextField as OriginalTextField } from '../TextField';
const TextField = ({ description, name, rules, control, defaultValue, shouldUnregister, isRequired, transformValue = value => value, ...inputProps }) => {
    const intl = useIntl();
    const { field: { ref, ...field }, fieldState: { error }, } = useController({
        name,
        rules: {
            required: isRequired
                ? {
                    value: true,
                    message: intl.formatMessage({ id: "Q4ipsb", defaultMessage: "Field is required." }),
                }
                : undefined,
            ...rules,
        },
        control,
        defaultValue,
        shouldUnregister,
    });
    const handleChange = useCallback((_e, { value }) => {
        field.onChange(transformValue(value));
    }, [field, transformValue]);
    return (_jsx(OriginalTextField, { ...field, ...inputProps, onChange: handleChange, inputRef: ref, value: field.value, error: error?.message, description: !error?.message && description ? description : undefined }));
};
export { TextField };
