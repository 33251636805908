import * as t from 'io-ts';
const UploadResult = t.type({
    // approved_at: t.union([t.null, t.string]),
    // city_id: t.string,
    // created_at: t.string,
    // expires_at: t.union([t.null, t.string]),
    id: t.string,
    // reject_reason: t.union([t.null, t.string]),
    // status: t.string,
    type: t.string,
    // updated_at: t.string,
    // url: t.string,
    // preview_url: t.string,
});
const UploadDefinition = t.type({
    caption: t.string,
    comment: t.union([t.null, t.string]),
    description: t.union([t.null, t.string]),
    example_url: t.union([t.null, t.string]),
    expires: t.boolean,
    optional: t.boolean,
    require_delay: t.union([t.null, t.number]),
    type: t.string,
});
const UploadedFile = t.type({
    approved_at: t.union([t.undefined, t.string]),
    city_id: t.string,
    created_at: t.string,
    expires_at: t.union([t.undefined, t.string, t.null]),
    id: t.string,
    reject_reason: t.union([t.undefined, t.string]),
    status: t.string,
    type: t.string,
    updated_at: t.string,
    url: t.string,
    preview_url: t.string,
    format: t.string,
});
export { UploadDefinition, UploadedFile, UploadResult };
