import { createSlice } from '@reduxjs/toolkit';
import { namespace as authNamespace } from '../namespace';
const initialState = {
    callRequestedAt: null,
};
const authSlice = createSlice({
    name: authNamespace,
    initialState,
    reducers: {
        setCallRequestedAt: state => {
            state.callRequestedAt = new Date();
        },
        resetCallRequestedAt: state => {
            state.callRequestedAt = null;
        },
    },
});
export const { setCallRequestedAt, resetCallRequestedAt } = authSlice.actions;
export { authSlice, authNamespace };
