const FsmState = {
    newCar: '/join/new-vehicle',
    uploadNewCarDocs: '/join/new-vehicle?upload=car',
    doYouHaveCar: '/join/do-you-have-vehicle',
    driver: '/join/chauffeur',
    driverUpload: '/join/chauffeur?upload=driver',
    partnerPayments: '/join/payments',
    payTo: '/join/pay-to',
    payToUpload: '/join/pay-to?upload=legal',
    toDo: '/todo',
    welcome: '/join/welcome',
    licensingAuthority: '/join/licensing-authority',
    licensingAuthorityMissing: '/join/licensing-authority-missing',
    vehicle: '/join/vehicle',
};
export { FsmState };
