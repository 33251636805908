import { jsx as _jsx } from "react/jsx-runtime";
import { defineMessages, useIntl } from 'react-intl';
import { Tag } from '../Tag';
const STATUSES = defineMessages({ active: { id: "bNWux8", defaultMessage: "active" }, expired_docs: { id: "e+ENqk", defaultMessage: "documents" }, pending: { id: "plvf9F", defaultMessage: "pending" }, rejected: { id: "RHIKJX", defaultMessage: "rejected" }, blocked: { id: "gocuNX", defaultMessage: "blocked" }, partner_blocked: { id: "R2Re3R", defaultMessage: "partner" }, new_partner: { id: "RoBz7U", defaultMessage: "new" }, end_of_life: { id: "bwurwl", defaultMessage: "end of life" }, failed_tests: { id: "s0Lykb", defaultMessage: "failed tests" } });
const AccessStatus = ({ status }) => {
    const intl = useIntl();
    return (_jsx(Tag, { intent: (() => {
            switch (status) {
                case 'blocked':
                case 'expired_docs':
                case 'partner_blocked':
                case 'failed_tests':
                    return 'danger';
                case 'active':
                    return 'success';
                default:
                    return 'default';
            }
        })(), children: intl.formatMessage(STATUSES[status]) }));
};
export { AccessStatus };
