import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Dialog, DocumentUpload, H3 } from '@wheely/ui-kit';
import { DateField } from '../../../../src-common/view/adapters/DateField';
import { CenteringContainer } from '../CenteringContainer';
import { Button } from '../../../../src-common/view/adapters/Button';
import { noop } from '../../utils/noop';
import s from './styles.css';
const UploadView = ({ onClickSkip, docDefinition, onChangeValue, onSubmit, form, isUploading, fileTypes = ['jpeg', 'png', 'pdf'], }) => {
    const intl = useIntl();
    const expiresAt = form.expiresAt ?? null;
    const [touched, setTouched] = useState(false);
    const isFile = form.file?.file instanceof File;
    const handleChangeDate = useCallback((event, params) => {
        setTouched(true);
        onChangeValue(event, params);
    }, [onChangeValue]);
    let dateValidation;
    if (docDefinition?.isExpires) {
        if (expiresAt === null) {
            dateValidation = intl.formatMessage({ id: "+YX9Pa", defaultMessage: "Required field" });
        }
        else if (expiresAt <= new Date()) {
            dateValidation = intl.formatMessage({ id: "3E0zuF", defaultMessage: "Document's expiry date must be later than today" });
        }
    }
    return (_jsx(Dialog, { className: s.root, isOpen: true, onClose: isUploading ? undefined : onClickSkip, children: _jsxs(CenteringContainer, { dataTestId: "dialog__content", children: [_jsx(H3, { capitalize: true, "data-test-id": "dialog__title", children: docDefinition?.title }), _jsx("div", { className: s.desc, dangerouslySetInnerHTML: { __html: docDefinition?.desc || '' }, "data-test-id": "dialog__description" }), _jsx("div", { "data-test-id": "dialog__upload-input-wrapper", children: _jsx(DocumentUpload, { className: s.file, name: 'file', value: form.file, onChange: isUploading ? noop : onChangeValue, error: (() => {
                            if (form.rejectReason) {
                                return form.rejectReason;
                            }
                            if (form.status === 'rejected') {
                                return intl.formatMessage({ id: "pibz1z", defaultMessage: "Rejected" });
                            }
                            if (form.status === 'expired') {
                                return intl.formatMessage({ id: "LJVq4Q", defaultMessage: "Expired" });
                            }
                            return undefined;
                        })() }) }), !isFile && (_jsx("div", { className: s.fileTypes, "data-test-id": "dialog__file-types", children: _jsx(FormattedMessage, { id: 'avn0lI', defaultMessage: 'Add {fileTypes}', values: { fileTypes: intl.formatList(fileTypes, { type: 'disjunction' }) } }) })), docDefinition?.isExpires && (_jsx("div", { className: s.expires, "data-test-id": "dialog__expires-at", children: _jsx(DateField, { title: intl.formatMessage({ id: "e8Rq20", defaultMessage: "EXPIRES AT" }), isDisabled: isUploading, name: 'expiresAt', value: expiresAt, onChange: handleChangeDate, error: touched ? dateValidation : undefined }) })), _jsx(Button, { "data-test-id": "dialog__upload-button", className: s.save, onClick: onSubmit, isDisabled: isUploading || !isFile || !!dateValidation, isPending: isUploading, children: intl.formatMessage({ id: "j5G7QV", defaultMessage: "SAVE" }) })] }) }));
};
export { UploadView };
