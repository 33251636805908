import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { getPartner } from '../../modules/partner/store/selectors';
import { OnboardingStep } from '../../modules/partner/model';
import { FsmState } from '../../modules/onboarding/fsm-types';
import { usePermissions } from '../../app/hooks/usePermissions';
import { MainLayout } from '../../shared/view/MainLayout';
import { useCongratsDialog } from './components/Congrats';
import { WizardManager } from './manager';
const onboardingStepToCurrentFsmState = {
    [OnboardingStep.welcome]: FsmState.welcome,
    [OnboardingStep.hello]: FsmState.driver,
    [OnboardingStep.payouts]: FsmState.payTo,
    [OnboardingStep.payment]: FsmState.partnerPayments,
};
const onboardingStepToNextFsmState = {
    [OnboardingStep.welcome]: FsmState.driver,
    [OnboardingStep.hello]: FsmState.payTo,
    [OnboardingStep.payouts]: FsmState.partnerPayments,
    [OnboardingStep.payment]: FsmState.doYouHaveCar,
};
const onboardingStepToNextFsmStateUK = {
    [OnboardingStep.welcome]: FsmState.licensingAuthority,
    [OnboardingStep.hello]: FsmState.payTo,
    [OnboardingStep.payouts]: FsmState.partnerPayments,
    [OnboardingStep.payment]: FsmState.toDo,
};
const onboardingStepToNextFsmStateFR = {
    [OnboardingStep.welcome]: FsmState.vehicle,
    [OnboardingStep.hello]: FsmState.payTo,
    [OnboardingStep.payouts]: FsmState.partnerPayments,
    [OnboardingStep.payment]: FsmState.toDo,
};
const getInitialFsmValue = (partner) => {
    if (partner.onboarding.isCompleted) {
        return FsmState.toDo;
    }
    const lastStepName = partner.onboarding.lastStepName;
    if (partner.onboarding.isLastStepCompleted) {
        if (partner.countryCode === 'GB') {
            return onboardingStepToNextFsmStateUK[lastStepName];
        }
        if (partner.countryCode === 'FR') {
            return onboardingStepToNextFsmStateFR[lastStepName];
        }
        return onboardingStepToNextFsmState[lastStepName];
    }
    return onboardingStepToCurrentFsmState[lastStepName];
};
const Wizard = React.memo(() => {
    usePermissions('viewWizard');
    const partner = useSelector(getPartner);
    useCongratsDialog();
    return (_jsx(MainLayout, { children: _jsx(WizardManager, { partnerFirstName: partner.firstName, partnerCountryCode: partner.countryCode, initialValue: getInitialFsmValue(partner) }) }));
});
Wizard.displayName = 'Wizard';
export { Wizard };
