import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { useCallback, useState } from 'react';
import { useExternalLinks } from '../../../app/hooks/useExternalLinks';
import { Icon } from '../../../../src-common/view/components/Icon';
import s from './Feedback.css';
const Feedback = () => {
    const links = useExternalLinks();
    const [isClosed, setIsClosed] = useState(JSON.parse(localStorage?.isFeedBackClosed ?? 'false'));
    const handleClose = useCallback(() => {
        if (localStorage) {
            localStorage.isFeedBackClosed = true;
        }
        setIsClosed(true);
    }, []);
    return isClosed ? null : (_jsxs("div", { className: s.feedback, children: [_jsx("button", { className: s.close, onClick: handleClose, children: _jsx(Icon, { icon: "cross" }) }), _jsx(FormattedMessage, { id: 'ofJcRS', defaultMessage: 'We have launched a new version of the dashboard. Please, share your feedback so we can keep improving it.' }), _jsxs("a", { className: s.button, target: "_blank", href: links.shareFeedback, rel: "noreferrer noopener", children: [_jsx(FormattedMessage, { id: '14TaGy', defaultMessage: 'share feedback' }), _jsx(Icon, { icon: "arrow-to-end-in-circle" })] })] }));
};
export { Feedback };
