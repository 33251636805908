import { createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { updateMyDriver } from '../../driver/actions/actions';
import { namespace } from '../namespace';
import { onboardingAPI } from '../api';
import { updatePartner } from '../../partner/actions/actions';
let lastRequestId;
const submitHelloStep = createAsyncThunk(`${namespace}/submit-hello-step`, async (formValues, { dispatch, requestId }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const result = await onboardingAPI.submitHelloStep(formValues);
        if (!result.validationErrors) {
            const { personalInn, ...driverFields } = formValues;
            batch(() => {
                dispatch(updateMyDriver({ ...driverFields }));
                dispatch(updatePartner({ personalInn, onboarding: result.onboarding }));
            });
        }
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { submitHelloStep };
