import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getReferralCode } from '../../../modules/driver/store/selectors';
import { siteDomain } from '../../../shared/utils/getSiteDomain';
const REFERRAL_CODE_KEY = 'referral-code';
const useReferralCard = () => {
    const intl = useIntl();
    const referralCode = useSelector(getReferralCode);
    if (!referralCode.code) {
        return undefined;
    }
    return {
        id: 'ref',
        cardStatus: 'todo',
        title: intl.formatMessage({ id: "4pEOfu", defaultMessage: "Invite colleagues" }),
        desc: referralCode.description,
        actionTitle: intl.formatMessage({ id: "zXHMwP", defaultMessage: "Copy invite link" }),
        action: () => {
            navigator.clipboard.writeText(siteDomain + `/chauffeur?${REFERRAL_CODE_KEY}=${referralCode.code}`);
        },
    };
};
export { useReferralCard, REFERRAL_CODE_KEY };
