import { isPlainObject } from './isPlainObject';
const omitDeep = (input, excludes) => Object.entries(input).reduce((nextInput, [key, value]) => {
    const shouldExclude = excludes.includes(key);
    if (shouldExclude) {
        nextInput[key] = '___HIDDEN___';
        return nextInput;
    }
    if (Array.isArray(value)) {
        const nextValue = value.map(arrItem => {
            if (isPlainObject(arrItem)) {
                return omitDeep(arrItem, excludes);
            }
            return arrItem;
        });
        nextInput[key] = nextValue;
    }
    else if (isPlainObject(value)) {
        nextInput[key] = omitDeep(value, excludes);
    }
    else {
        nextInput[key] = value;
    }
    return nextInput;
}, {});
export { omitDeep };
