import { MemoryStorage } from './MemoryStorage';
const TEST_KEY = '__test';
const isLocalStorageSupported = () => {
    try {
        window.localStorage.setItem(TEST_KEY, '1');
        window.localStorage.removeItem(TEST_KEY);
        return true;
    }
    catch (e) {
        return false;
    }
};
const storage = isLocalStorageSupported() ? window.localStorage : new MemoryStorage();
export { storage };
