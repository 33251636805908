import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotification } from '@wheely/ui-kit';
import { getFatalErrorSlug } from '../../../app/store/selectors';
import { setErrorHandler } from '../../../app/actions';
import { MainLayout } from '../MainLayout';
import { ErrorBySlug } from './ErrorBySlug';
const ErrorWrapper = ({ children }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setErrorHandler(message => showNotification({ showCloseButton: false, dark: true, message })));
    }, [dispatch, showNotification]);
    const fatalError = useSelector(getFatalErrorSlug);
    return (_jsx(ErrorBoundary, { children: fatalError ? (_jsx(MainLayout, { children: _jsx(ErrorBySlug, { slug: fatalError }) })) : (children) }));
};
export { ErrorWrapper };
