import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setJourney } from '../actions';
import { journeyAPI } from '../../api';
const loadJourney = createAsyncThunk(`${namespace}/load-journey`, async ({ id }, { dispatch }) => {
    const journey = await journeyAPI.fetchJourney(id);
    dispatch(setJourney(journey));
    return journey;
});
export { loadJourney };
