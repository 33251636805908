import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { driverAPI } from '../../api';
import { updateMyDriver } from '../actions';
const blockDriver = createAsyncThunk(`${namespace}/block-driver`, async ({ driverId, reason, message }, { dispatch }) => {
    const driver = await driverAPI.blockDriver(driverId, reason, message);
    if (driver) {
        dispatch(updateMyDriver(driver));
    }
    return driver;
});
export { blockDriver };
