import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setBills } from '../actions';
import { billAPI } from '../../api';
const loadBills = createAsyncThunk(`${namespace}/load-bills`, async ({ limit, offset }, { dispatch }) => {
    const bills = await billAPI.fetchBills({ limit, offset });
    dispatch(setBills(bills));
    return bills;
});
export { loadBills };
