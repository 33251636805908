import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
const createDriverBlockedCard = ({ contactOperationTeam, }, intl) => ({
    id: 'blocked_driver',
    cardStatus: 'rejected',
    desc: (_jsx(FormattedMessage, { id: 'brDWLJ', defaultMessage: 'Please get in touch with <a>customer service</a> ({link})', values: {
            a: (text) => (_jsx("a", { href: `mailto:${contactOperationTeam}`, target: "_blank", rel: "noreferrer", children: text })),
            link: contactOperationTeam,
        } })),
    isExcludedFromProgress: true,
    order: -10,
    title: intl.formatMessage({ id: "TDrhF6", defaultMessage: "Your account has been blocked" }),
});
export { createDriverBlockedCard };
