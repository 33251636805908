import { useSelector } from 'react-redux';
import { getVehiclesList } from '../store/selectors';
const useMakeConfig = (make) => {
    const vehiclesList = useSelector(getVehiclesList);
    if (!make) {
        return undefined;
    }
    return vehiclesList.find(makeConfig => makeConfig.make === make);
};
export { useMakeConfig };
