import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { carUploadSlice, carUploadAsyncActions, carUploadSelectors, } from '../../../modules/car/store/upload-slice';
import { driverUploadSlice, driverUploadAsyncActions, driverUploadSelectors, myDriverUploadSlice, myDriverUploadAsyncActions, myDriverUploadSelectors, } from '../../../modules/driver/store/upload-slice';
import { partnerUploadSlice, partnerUploadAsyncActions, partnerUploadSelectors, } from '../../../modules/partner/store/uploadSlice';
import { ConnectedUpload } from './connected-upload';
const UploadCar = React.memo((props) => (_jsx(ConnectedUpload, { onUpdateForm: carUploadSlice.actions.updateUploadFormValue, onSubmitForm: carUploadAsyncActions.submitUploadForm, selectUploadForm: carUploadSelectors.getUploadForm, selectUploadDefinition: carUploadSelectors.getUploadDefinition, onClickSkip: props.onClickSkip, onFinishUpload: props.onFinishUpload })));
UploadCar.displayName = 'UploadCar';
const UploadDriver = React.memo((props) => (_jsx(ConnectedUpload, { onUpdateForm: driverUploadSlice.actions.updateUploadFormValue, onSubmitForm: driverUploadAsyncActions.submitUploadForm, selectUploadForm: driverUploadSelectors.getUploadForm, selectUploadDefinition: driverUploadSelectors.getUploadDefinition, onClickSkip: props.onClickSkip, onFinishUpload: props.onFinishUpload })));
UploadDriver.displayName = 'UploadDriver';
const UploadMyDriver = React.memo((props) => (_jsx(ConnectedUpload, { onUpdateForm: myDriverUploadSlice.actions.updateUploadFormValue, onSubmitForm: myDriverUploadAsyncActions.submitUploadForm, selectUploadForm: myDriverUploadSelectors.getUploadForm, selectUploadDefinition: myDriverUploadSelectors.getUploadDefinition, onClickSkip: props.onClickSkip, onFinishUpload: props.onFinishUpload })));
UploadMyDriver.displayName = 'UploadMyDriver';
const UploadPartner = React.memo((props) => (_jsx(ConnectedUpload, { onUpdateForm: partnerUploadSlice.actions.updateUploadFormValue, onSubmitForm: partnerUploadAsyncActions.submitUploadForm, selectUploadForm: partnerUploadSelectors.getUploadForm, selectUploadDefinition: partnerUploadSelectors.getUploadDefinition, onClickSkip: props.onClickSkip, onFinishUpload: props.onFinishUpload })));
UploadPartner.displayName = 'UploadPartner';
export { UploadCar, UploadDriver, UploadMyDriver, UploadPartner };
