import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getPartner } from '../../../../modules/partner/store/selectors';
import { useForm } from '../../../../shared/view/Form';
import { useLicensingAuthorityTypes } from '../../../../shared/hooks/useLicensingAuthorityTypes';
import { eventTracking } from '../../../../services/event-tracking';
import { fsmSlice } from '../../../../modules/onboarding/store/fsm-slice';
import { Form } from './Form';
const useOptions = () => {
    const intl = useIntl();
    const licensingAuthorityTypes = useLicensingAuthorityTypes();
    const options = useMemo(() => {
        licensingAuthorityTypes.push({
            title: intl.formatMessage({ id: "22CJ/D", defaultMessage: "I don't have license yet" }),
            value: 'None',
        });
        return licensingAuthorityTypes;
    }, [intl, licensingAuthorityTypes]);
    return options;
};
const LicensingAuthority = ({ onSwitchScreen }) => {
    const options = useOptions();
    const partner = useSelector(getPartner);
    const dispatch = useDispatch();
    const postForm = useCallback(async (formValues) => {
        if (formValues.licensingAuthority !== 'None') {
            dispatch(fsmSlice.actions.setDraft({
                licensingAuthority: formValues.licensingAuthority,
            }));
        }
        onSwitchScreen('NEXT', {
            state: formValues.licensingAuthority === 'None' ? 'failed' : '',
        });
        eventTracking.track({
            event: formValues.licensingAuthority === 'None'
                ? 'Onboarding - License - Failed'
                : 'Onboarding - License - Success',
            type: formValues.licensingAuthority,
        });
        return {};
    }, [onSwitchScreen, dispatch]);
    const { handleSubmit, form: { control, formState }, } = useForm({
        preSaveStore: {
            storeId: `licensingAuthorityStepForm-${partner.id}`,
            type: 'memory',
        },
        onSubmit: postForm,
    });
    return (_jsx(Form, { items: options, control: control, onSubmit: handleSubmit, isSubmitting: formState.isSubmitting, isValid: formState.isValid }));
};
export { LicensingAuthority };
