const getCurrentDriver = (state) => state.drivers.driver;
const getMyDriver = (state) => state.drivers.myDriver;
const getAllDrivers = (state) => state.drivers.all;
const getDrivers = (state) => state.drivers.drivers;
const getCurrentDriverId = (state) => state.drivers.driver?.id;
const getMyDriverId = (state) => state.drivers.myDriver.id;
const getReferralCode = (state) => state.drivers.driverReferralCode;
const getUploadedDocsForCurrentDriver = (state) => state.drivers.driver?.docUploads || {};
const getUploadedDocsForMyDriver = (state) => state.drivers.myDriver.docUploads;
const getHourlyWorkloads = (state) => state.drivers.driverWorkload?.hourlyWorkloads || null;
const getWorkloadSummary = (state) => state.drivers.driverWorkload?.summary || null;
export { getMyDriver, getCurrentDriver, getAllDrivers, getDrivers, getCurrentDriverId, getMyDriverId, getUploadedDocsForCurrentDriver, getUploadedDocsForMyDriver, getReferralCode, getHourlyWorkloads, getWorkloadSummary, };
