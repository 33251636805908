import { createSlice } from '@reduxjs/toolkit';
import { namespace as userNamespace } from '../namespace';
const initialState = {
    data: {
        cityId: '',
        cityName: '',
        firstName: '',
        userId: '',
        lastName: '',
        middleName: '',
        phone: '',
        source: null,
        roles: [],
    },
};
const userSlice = createSlice({
    name: userNamespace,
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.data = action.payload;
        },
        addRole: (state, action) => {
            if (!state.data.roles.includes(action.payload)) {
                state.data.roles = [...state.data.roles, action.payload];
            }
        },
    },
});
export { userSlice, userNamespace };
