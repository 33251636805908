import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../namespace';
import { onboardingAPI } from '../api';
let lastRequestId;
const submitWelcomeStep = createAsyncThunk(`${namespace}/submit-setup-user`, async (form, { requestId }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const result = await onboardingAPI.submitWelcomeStep(form);
        // related driver will be created implicitly on the back-end
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { submitWelcomeStep };
