import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { submitPaymentInfoStep } from '../../../../modules/onboarding/actions/submitPaymentInfoStep';
import { PaymentsForm } from '../../../ProfileEditPayments/form';
import { eventTracking } from '../../../../services/event-tracking';
const StepPayments = ({ onSwitchScreen }) => {
    const dispatch = useDispatch();
    const postForm = useCallback(async (formValues) => {
        const resultAction = await dispatch(submitPaymentInfoStep(formValues));
        if (submitPaymentInfoStep.fulfilled.match(resultAction)) {
            const { isSucceed, result = {} } = resultAction.payload;
            if (isSucceed && !result.validationErrors) {
                eventTracking.trackSnowplow({
                    event: 'Onboarding - Payment - Success',
                });
                onSwitchScreen('NEXT');
            }
            return result;
        }
        return {};
    }, [dispatch, onSwitchScreen]);
    return _jsx(PaymentsForm, { onSubmit: postForm });
};
export { StepPayments };
