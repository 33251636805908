import { OnboardingStep } from '../model';
import { createClient } from '../../../../src-common/api/src/create-client';
import { w4dPartnerRemoteAPI } from '../../../../src-common/api/definitions/w4d-partner-endpoints';
import { get, post, put, patch, postForm } from '../../../shared/api/fetch-based-http-methods';
import { skipNullishDeep } from '../../../shared/utils/skip-nullish-deep';
import { prepareUploadedFile } from '../../../shared/models/uploaded-file';
import { createFormData } from '../../../shared/api/create-form-data';
import { SerializableError } from '../../../../src-common/utils/SerializableError';
import { authAPI } from '../../auth/api';
import { errorHandling } from '../../../services/errorHandling';
import { prepareErrors } from '../../../shared/utils/prepareErrors';
import { formatDate } from '../../../shared/utils/formatDate';
import { ENV } from '../../../env';
// This const is used when needs to update partner fields but the user still has not input a few required fields.
// This value will be used for the required field on sending to the server and replaced with an empty string on the client.
const SPECIAL_STRING = '👹🤡💩👻💀☠️👽';
const client = createClient(w4dPartnerRemoteAPI({ apiHost: ENV.API_URL, get, post, put, patch, postForm }));
const prepareLegalName = (raw) => ({
    legalName: raw.legal_info?.name === SPECIAL_STRING ? '' : raw.legal_info?.name ?? '',
    vatRegNo: raw.legal_info?.vat_reg_no ?? '',
});
const prepareOnboarding = (rawOnboarding) => {
    if (!rawOnboarding) {
        return {
            isCompleted: true,
            lastStepName: OnboardingStep['payment'],
            isLastStepCompleted: true,
        };
    }
    return {
        isCompleted: rawOnboarding?.completed || false,
        lastStepName: rawOnboarding?.last_step_name || 'welcome',
        isLastStepCompleted: rawOnboarding?.last_step_completed || false,
    };
};
const preparePartner = (raw) => ({
    id: raw.id,
    referralCode: '',
    email: raw.email || '',
    countryCode: raw.city?.country_code,
    phone: raw.phone,
    cityId: raw.city_id,
    cityName: raw.city_name,
    companyRegNumber: raw.legal_info?.company_reg_number || '',
    personalInn: raw.personal_inn || '',
    inn: raw.legal_info?.inn || '',
    legalAddress1: raw.legal_info?.registered_address?.line1 || '',
    ...prepareLegalName(raw),
    partnerType: raw.legal_info?.type || undefined,
    legalKpp: raw.legal_info?.kpp || '',
    legalNds: (raw.legal_info?.nds === true || raw.legal_info?.nds === false) ?? null,
    legalOgrn: raw.legal_info?.ogrn || '',
    legalRegisteredAddress: raw.legal_info?.registered_address || undefined,
    legalPostalAddress: raw.legal_info?.postal_address || undefined,
    paymentAccount: raw.payment_info?.account_number || '',
    paymentSortCode: raw.payment_info?.sort_code || '',
    paymentBik: raw.payment_info?.bik || '',
    paymentRasAccount: raw.payment_info?.ras_account || '',
    paymentKorAccount: raw.payment_info?.kor_account || '',
    paymentSwift: raw.payment_info?.swift || '',
    paymentIban: raw.payment_info?.iban || '',
    paymentBankName: raw.payment_info?.bank_name || '',
    accessStatus: raw.access_status,
    onboarding: prepareOnboarding(raw.onboarding),
    docUploads: Object.fromEntries(raw.uploads?.map(rawUpload => [
        rawUpload.type,
        prepareUploadedFile(rawUpload),
    ]) ?? []),
    isVehiclesHidden: raw.vehicles_hidden,
    taxRegNo: raw.legal_info?.tax_reg_no ?? '',
    tradeLicenseNumber: raw.legal_info?.trade_license_number ?? '',
    registrationAuditStep: raw.registration_audit_step || null,
});
const prepareUpdatePartner = (partner) => ({
    access_status: partner.accessStatus,
    city_id: partner.cityId,
    email: partner.email,
    phone: partner.phone,
    vehicles_hidden: partner.isVehiclesHidden,
    legal_info: {
        registered_address: partner.legalRegisteredAddress || null,
        postal_address: partner.legalPostalAddress || null,
        inn: partner.inn || null,
        kpp: partner.legalKpp || null,
        company_reg_number: partner.companyRegNumber || null,
        vat_reg_no: partner.vatRegNo || null,
        nds: partner.legalNds,
        ogrn: partner.legalOgrn || null,
        name: partner.legalName || null,
        type: partner.partnerType || null,
        trade_license_number: partner.tradeLicenseNumber || null,
    },
    payment_info: {
        account_number: partner.paymentAccount || null,
        bank_name: partner.paymentBankName || null,
        sort_code: partner.paymentSortCode || null,
        bik: partner.paymentBik || null,
        swift: partner.paymentSwift || null,
        iban: partner.paymentIban || null,
        kor_account: partner.paymentKorAccount || null,
        ras_account: partner.paymentRasAccount || null,
    },
    registration_audit_step: partner.registrationAuditStep,
    promo: null,
});
class PartnerBlockedError extends SerializableError('PartnerBlockedError') {
}
const partnerAPI = {
    createPartner: async (user) => {
        const response = await client.createPartner({
            payload: {
                partner: { city_id: user.cityId },
                utm_tags: window.UTM_TAGS ?? {},
            },
        });
        if (response.errors) {
            return { errors: response.errors };
        }
        return {
            partner: {
                firstName: user.firstName,
                lastName: user.lastName,
                middleName: user.middleName,
                userId: user.userId,
                source: user.source,
                ...preparePartner(response.partner),
            },
        };
    },
    readPartner: async (user) => {
        const response = await client.readPartner();
        if (response.errors) {
            throw new PartnerBlockedError();
        }
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            middleName: user.middleName,
            userId: user.userId,
            source: user.source,
            ...preparePartner(response.partner),
        };
    },
    updatePaymentInfo: async (partner) => {
        const response = await client.updatePartner({
            payload: prepareUpdatePartner(partner),
        });
        return {
            baseErrors: prepareErrors(response.errors),
        };
    },
    updateIsVehiclesHidden: async (partner) => {
        const response = await client.updatePartner({
            payload: prepareUpdatePartner(partner),
        });
        return {
            baseErrors: prepareErrors(response.errors),
        };
    },
    updateContactInfo: async (partner) => {
        const response = await client.updatePartner({
            payload: prepareUpdatePartner(partner),
        });
        const result = {};
        if (response.errors?.user_exists) {
            result.validationErrors = {
                phoneNumber: response.errors.user_exists,
            };
            delete response.errors.user_exists;
        }
        result.baseErrors = prepareErrors(response.errors);
        return result;
    },
    fetchDocDefinitions: async (partnerType) => {
        const response = await client.readPartnerDocumentDefinitions({ pathParams: { partnerType } });
        if ('errors' in response) {
            errorHandling.captureMessage('Failed to fetch doc definitions: ' + JSON.stringify(response.errors));
            return [];
        }
        return response.map(raw => ({
            docType: raw.type,
            title: raw.caption,
            comment: raw.comment || undefined,
            desc: raw.description || undefined,
            exampleUrl: raw.example_url || undefined,
            isOptional: raw.optional,
            isExpires: raw.expires || false,
        }));
    },
    uploadFile: async (form, holderId) => {
        const response = await client.uploadPartnerDocument({
            pathParams: { partnerId: holderId },
            payload: createFormData(skipNullishDeep({
                type: form.docType,
                expires_at: form.expiresAt && formatDate(form.expiresAt),
                upload_file: form.file?.file,
            }, false)),
        });
        if (!('upload' in response)) {
            if ('errors' in response) {
                if (response.errors.file) {
                    return {
                        error: response.errors.file[0],
                    };
                }
                if (response.errors.error) {
                    return {
                        error: response.errors.error[0],
                    };
                }
            }
        }
        if ('upload' in response) {
            return {
                id: response.upload.id,
                type: response.upload.type,
            };
        }
        return {
            error: '',
        };
    },
    getAgencyAgreementLink: async () => {
        const { data, error } = await authAPI.getShortToken();
        if (error) {
            throw new Error(error.statusText);
        }
        if (data?.shorttoken.id) {
            return `${ENV.API_URL}/v6/partners/self/agent_agreement_confirmation?short_token=${data.shorttoken.id}`;
        }
    },
    getPaymentInfoByBIK: async (bik) => client.readInfoByBIK({ pathParams: { bik } }),
};
export { partnerAPI, PartnerBlockedError, prepareUpdatePartner, preparePartner };
