import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useMemo, useState } from 'react';
import { IconCalendar, IconCornerBottom, Popover2 as Popover, Button, } from '@wheely/ui-kit';
import { noop } from '../../../shared/utils/noop';
const defaultContext = {
    isOpen: false,
    openPopover: noop,
    closePopover: noop,
};
const WeekSelectorContext = createContext(defaultContext);
const WeekSelector = ({ children, buttonTitle, disabled, usePortalForPopover = true, popoverTargetTagName, popoverClassName, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handlePopoverOpening = useCallback(() => {
        setIsOpen(true);
    }, []);
    const handlePopoverClosed = useCallback(() => {
        setIsOpen(false);
    }, []);
    const handlePopoverInteraction = useCallback(nextOpenState => {
        setIsOpen(nextOpenState);
    }, []);
    const contextValue = useMemo(() => ({
        isOpen,
        openPopover: handlePopoverOpening,
        closePopover: handlePopoverClosed,
    }), [isOpen, handlePopoverOpening, handlePopoverClosed]);
    return (_jsx(WeekSelectorContext.Provider, { value: contextValue, children: _jsx(Popover, { className: popoverClassName, minimal: true, placement: "bottom-start", onOpening: handlePopoverOpening, onClosed: handlePopoverClosed, onInteraction: handlePopoverInteraction, openOnTargetFocus: true, isOpen: isOpen, disabled: disabled, modifiers: {
                offset: {
                    enabled: true,
                    options: { offset: [0, 8] },
                },
            }, content: children, targetTagName: popoverTargetTagName, usePortal: usePortalForPopover, children: _jsx(Button, { icon: _jsx(IconCalendar, {}), rightIcon: _jsx(IconCornerBottom, {}), children: buttonTitle }) }) }));
};
export { WeekSelector, WeekSelectorContext };
