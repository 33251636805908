class FetchingStatusStore {
    constructor() {
        this._requestsInProgress = new Set();
        this._listeners = new Set();
        this.subscribe = (listener) => {
            this._listeners.add(listener);
            return () => {
                this._listeners.delete(listener);
            };
        };
        this.getSnapshot = () => Boolean(this._requestsInProgress.size);
        this._emitChange = () => {
            for (const listener of this._listeners) {
                listener();
            }
        };
        this.addRequest = (requestId) => {
            this._requestsInProgress.add(requestId);
            this._emitChange();
        };
        this.removeRequest = (requestId) => {
            this._requestsInProgress.delete(requestId);
            this._emitChange();
        };
    }
}
export const FetchingStatusStoreInstance = new FetchingStatusStore();
