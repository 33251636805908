import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setJourneys } from '../actions';
import { journeyAPI } from '../../api';
const loadJourneys = createAsyncThunk(`${namespace}/load-journeys`, async ({ limit, offset, driverId, from, to, number, }, { dispatch }) => {
    const journeys = await journeyAPI.fetchJourneys({ limit, offset, driverId, from, to, number });
    dispatch(setJourneys(journeys));
    return journeys;
});
export { loadJourneys };
