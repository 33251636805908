import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import { Tag } from '../../../shared/view/Tag';
import { formatTimeDuration } from '../../../shared/utils/formatTimeDuration';
import s from './styles.css';
const mapToDetails = (journey) => ({
    service: () => _jsx(Tag, { children: journey.service }),
    duration: journey.duration ? formatTimeDuration(journey.duration) : null,
    chauffeur: () => journey.chauffeur ? (_jsx(Link, { className: s.link, to: `/chauffeurs/${journey.chauffeur.id}`, children: `${journey.chauffeur.firstName} ${journey.chauffeur.lastName}` })) : null,
    vehicle: () => journey.vehicle ? (_jsxs(Link, { className: s.link, to: `/vehicles/${journey.vehicle.id}`, children: [_jsxs("div", { children: [journey.vehicle.make, " ", journey.vehicle.model, ","] }), _jsx("div", { children: journey.vehicle.licensePlate })] })) : null,
});
const JourneyDetails = ({ journey }) => {
    const intl = useIntl();
    const titles = useMemo(() => [
        [
            'service',
            intl.formatMessage({ id: "fRbGY0", defaultMessage: "Service" }),
        ],
        [
            'duration',
            intl.formatMessage({ id: "U07LlE", defaultMessage: "Duration" }),
        ],
        [
            'chauffeur',
            intl.formatMessage({ id: "zjwb06", defaultMessage: "Chauffeur" }),
        ],
        [
            'vehicle',
            intl.formatMessage({ id: "ZTbur4", defaultMessage: "Vehicle" }),
        ],
    ], [intl]);
    const values = useMemo(() => mapToDetails(journey), [journey]);
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "WNpCWs", defaultMessage: "Details" }), titles: titles, values: values, className: s.details }));
};
export { JourneyDetails };
