import { createSlice } from '@reduxjs/toolkit';
import { eventTracking } from '../../../services/event-tracking';
import { namespace as onboardingNamespace } from '../namespace';
import { getEmptyPartner } from '../model/getEmptyPartner';
const initialState = {
    data: getEmptyPartner(),
};
const onboardingSlice = createSlice({
    name: onboardingNamespace,
    initialState,
    reducers: {
        setPartner: (state, action) => {
            state.data = action.payload;
            if (state.data?.id) {
                eventTracking.setPartnerId(state.data.id);
            }
        },
        setPartnerCity: (state, action) => {
            state.data.cityId = action.payload;
        },
        updatePartner: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
    },
    extraReducers: builder => {
        builder.addMatcher((action) => Boolean(action.error), (state, action) => {
            // action contains copy of error so we can not use instanceOf
            if (action.error?.name === 'PartnerBlockedError') {
                state.data.accessStatus = 'partner_blocked';
            }
        });
    },
});
export { onboardingSlice, onboardingNamespace };
