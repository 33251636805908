import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setAllDrivers, setMyDriver } from '../actions';
import { driverAPI } from '../../api';
import { setPartnerCity } from '../../../partner/actions/actions';
import { PartnerBlockedError } from '../../../partner/api';
import { getUserData, ROLES } from '../../../user/store/selectors';
const loadDriver = createAsyncThunk(`${namespace}/load-driver`, async (_, { dispatch, getState }) => {
    const me = getUserData(getState());
    let driver = undefined;
    if (me.roles.includes(ROLES.PARTNER)) {
        const drivers = await driverAPI.fetchBriefDrivers();
        dispatch(setAllDrivers(drivers.items));
    }
    if (me.roles.includes(ROLES.DRIVER)) {
        driver = await driverAPI.fetchMyDriver();
        dispatch(setMyDriver(driver));
        if (!me.roles.includes(ROLES.PARTNER)) {
            dispatch(setAllDrivers([driver]));
            dispatch(setPartnerCity(driver.cityId));
        }
    }
    if (!me.roles.includes(ROLES.FLEET) &&
        !me.roles.includes(ROLES.PARTNER_ASSISTANT) &&
        me.roles.includes(ROLES.DRIVER) &&
        me.roles.includes(ROLES.PARTNER) &&
        // it means the problem is not in expired docs or smth similar
        driver?.accessStatus === 'blocked') {
        throw new PartnerBlockedError();
    }
    return driver;
});
export { loadDriver };
