import { DEFAULT_MAX_AGE } from '../api/hardcoded-make-models';
import { useModelConfig } from './useModelConfig';
const getYearsItems = (record) => {
    const maxAge = record.maxAge ?? DEFAULT_MAX_AGE;
    const nowYear = new Date().getFullYear();
    const result = [];
    for (let year = nowYear; year > nowYear - maxAge - 1; year--) {
        result.push({
            value: String(year),
            title: String(year),
        });
    }
    return result;
};
const useYears = (make, model) => {
    const modelConfig = useModelConfig(make, model);
    if (!modelConfig || !model) {
        return [];
    }
    return getYearsItems(modelConfig);
};
export { useYears };
