import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect, useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@wheely/ui-kit';
import { Button } from '../../../../../src-common/view/adapters/Button';
import { Description } from './Description';
import s from './style.css';
const useFocus = () => {
    const inputRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 0);
    }, []);
    return inputRef;
};
const PinForm = ({ phone, disabled, onSubmit, onBack }) => {
    const intl = useIntl();
    const [pin, setPin] = useState('');
    const [errors, setErrors] = useState([]);
    const inputRef = useFocus();
    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        const result = await onSubmit(pin);
        if (result.error) {
            setErrors([result.error.statusText]);
        }
    }, [pin, onSubmit]);
    const handleChange = useCallback((e) => {
        setPin(e.target.value);
    }, []);
    return (_jsx("div", { className: s.phoneForm, children: _jsxs("form", { onSubmit: handleSubmit, "data-test-id": "pin-form", children: [_jsx(TextField, { type: "number", inputRef: inputRef, className: s.pinField, value: pin, description: _jsx(Description, { phone: phone, onChangeNumberClick: onBack }), onChange: handleChange, placeholder: intl.formatMessage({ id: "A68Nq+", defaultMessage: "Code" }), disabled: disabled, error: errors.join('\n'), autoFocus: true }), _jsx(Button, { isDisabled: disabled, type: "submit", children: _jsx(FormattedMessage, { id: 'kvZ0Hv', defaultMessage: 'Sign In' }) })] }) }));
};
export { PinForm };
