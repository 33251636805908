import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { namespace as carsNamespace } from '../namespace';
import { LicensingAuthorityTypes } from '../../../shared/models/licensingAuthority';
const carEntityAdapter = createEntityAdapter({});
const defaultFormValues = {
    id: '',
    make: 'Mercedes-Benz',
    model: '',
    generation: '',
    color: '',
    interiorColor: '',
    registrationDate: null,
    year: '',
    licensePlate: '',
    PHV: '',
    accessStatus: 'expired_docs',
    licensingAuthority: LicensingAuthorityTypes.TFL,
    wheelbase: '',
    vin: '',
};
const mapCarToForm = (car) => ({
    make: car.make,
    model: car.model,
    generation: car.generation,
    color: car.color,
    interiorColor: car.interiorColor,
    year: car.year,
    licensePlate: car.licensePlate,
    PHV: car.PHV,
    registrationDate: car.registrationDate,
    id: car.id,
    accessStatus: car.accessStatus,
    licensingAuthority: car.licensingAuthority ?? LicensingAuthorityTypes.TFL,
    wheelbase: car.wheelbase,
    vin: car.vin,
    engineType: car.engineType,
});
const entityState = carEntityAdapter.getInitialState();
const initialState = {
    models: [],
    all: [],
    cars: {
        items: [],
        total: 0,
        limit: 20,
        offset: 0,
    },
    form: { ...defaultFormValues },
    ...entityState,
};
const carsSlice = createSlice({
    name: carsNamespace,
    initialState,
    reducers: {
        setAllCars: (state, action) => {
            state.all = action.payload;
        },
        setCars: (state, action) => {
            state.cars = action.payload;
        },
        setModels: (state, action) => {
            state.models = action.payload.models;
        },
        setCar: (state, action) => {
            state.entities[action.payload.id] = action.payload;
            const idx = state.all.findIndex(item => item.id === action.payload.id);
            if (idx === -1) {
                state.all.push(action.payload);
            }
            else {
                state.all[idx] = action.payload;
            }
        },
        deleteCarFromStore: (state, action) => {
            state.entities = {};
            state.all = state.all.filter(car => car.id !== action.payload.carId);
            state.form = { ...defaultFormValues };
        },
        updateForm: (state, action) => {
            state.form = action.payload ? mapCarToForm(action.payload) : defaultFormValues;
        },
        resetCarForm: state => {
            state.form = { ...defaultFormValues };
        },
    },
});
export { carsSlice, carsNamespace };
