import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormattedDate, FormattedNumber, FormattedTime, useIntl } from 'react-intl';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import { Tag } from '../../../shared/view/Tag';
import { Icon } from '../../../../src-common/view/components/Icon';
import s from './Statistics.css';
const skipEmpty = (item) => item !== false;
const Statistics = ({ className, driver }) => {
    const intl = useIntl();
    const titles = useMemo(() => {
        const allTitles = [
            [
                'acceptance',
                intl.formatMessage({ id: "1vqy76", defaultMessage: "Acceptance" }),
            ],
            [
                'rating',
                intl.formatMessage({ id: "WBIlW2", defaultMessage: "Rating" }),
            ],
            [
                'score',
                intl.formatMessage({ id: "/px/5i", defaultMessage: "Score" }),
            ],
            [
                'lastActivity',
                intl.formatMessage({ id: "CZ5uHm", defaultMessage: "Last activity" }),
            ],
            [
                'tags',
                intl.formatMessage({ id: "4Atvju", defaultMessage: "Tags" }),
            ],
        ];
        return allTitles.filter(skipEmpty);
    }, [intl]);
    const values = useMemo(() => ({
        acceptance: () => driver.acceptance && (_jsxs(_Fragment, { children: [_jsx(FormattedNumber, { value: driver.acceptance * 100, maximumFractionDigits: 0 }), "%"] })),
        rating: () => (_jsx(FormattedNumber, { value: driver.rating, maximumFractionDigits: 2, minimumFractionDigits: 2 })),
        score: () => (_jsxs("div", { className: s.score, children: [_jsx(Icon, { icon: 'diamond', className: s.diamond }), _jsx(FormattedNumber, { value: driver.score, maximumFractionDigits: 0 })] })),
        lastActivity: () => (_jsxs(_Fragment, { children: [_jsx(FormattedDate, { value: driver.lastActivity }), ' ', _jsx(FormattedTime, { value: driver.lastActivity })] })),
        tags: () => (_jsx("div", { className: s.tags, children: driver.tags?.map(x => (_jsx(Tag, { children: x }, x))) })),
    }), [driver]);
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "mNjCNJ", defaultMessage: "Statistics" }), className: className, values: values, titles: titles }));
};
export { Statistics };
