import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from 'react-intl';
import { Tag } from '@wheely/ui-kit';
import { StepLayout } from '../../shared/view/StepLayout';
import { getStatusIntent } from '../../shared/utils/getStatusIntent';
import { renderStatus } from '../../shared/utils/renderStatus';
import { HeaderDetails } from './components/HeaderDetails';
import { JourneyDetails } from './components/JourneyDetails';
import { Breakdown } from './components/Breakdown';
import { History } from './components/History';
const View = ({ journey, onClickBack }) => {
    const intl = useIntl();
    return (_jsx("div", { children: _jsxs(StepLayout, { onClickBack: onClickBack, title: _jsx(FormattedMessage, { id: 'mwjSP0', defaultMessage: 'Journey #{number}', values: { number: journey.number } }), subTitle: _jsx(Tag, { intent: getStatusIntent(journey.status), children: renderStatus(journey.status, intl) }), hideProgress: true, children: [_jsx(HeaderDetails, { journey: journey }), _jsx(JourneyDetails, { journey: journey }), (!!journey?.breakdown?.length || journey.isTotalDefined) && (_jsx(Breakdown, { journey: journey })), journey.events && _jsx(History, { journey: journey })] }) }));
};
export { View };
