import * as t from 'io-ts';
import { OnboardingStepsEnum } from '../../../src/modules/partner/model/onboarding';
const WelcomeStepErrors = t.partial({
    city_id: t.array(t.string),
    first_name: t.array(t.string),
    user_exists: t.array(t.string),
    last_name: t.array(t.string),
    middle_name: t.array(t.string),
    email: t.array(t.string),
    chauffeur_promo_code: t.array(t.string),
});
const HelloStepErrors = t.partial({
    birthday: t.array(t.string),
    home_address: t.array(t.string),
    license_number: t.array(t.string),
    uk_national_insurance: t.array(t.string),
    personal_inn: t.array(t.string),
});
const PayoutsStepErrors = t.partial({
    partner_type: t.array(t.string),
    company_name: t.array(t.string),
    company_address: t.array(t.string),
    company_reg_number: t.array(t.string),
    vat_reg_no: t.array(t.string),
    inn: t.array(t.string),
    kpp: t.array(t.string),
});
const PaymentInfoStepErrors = t.partial({
    account_number: t.array(t.string),
    sort_code: t.array(t.string),
    bik: t.array(t.string),
    swift: t.array(t.string),
    ras_account: t.array(t.string),
    iban: t.array(t.string),
});
const Onboarding = t.type({
    completed: t.boolean,
    last_step_name: t.keyof(OnboardingStepsEnum),
    last_step_completed: t.boolean,
});
export { WelcomeStepErrors, HelloStepErrors, PayoutsStepErrors, PaymentInfoStepErrors, Onboarding };
