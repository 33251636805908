import { useMakeConfig } from './useMakeConfig';
const useModels = (make) => {
    const makeConfig = useMakeConfig(make);
    if (!makeConfig || !make) {
        return [];
    }
    return makeConfig.models.map(modelOptions => ({
        title: modelOptions.model,
        value: modelOptions.model,
    }));
};
export { useModels };
