import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { carAPI } from '../../api';
import { setDocDefinitions } from '../actions';
let lastRequestId;
const loadCarDocDefinitions = createAsyncThunk(`${namespace}/load-doc-definitions`, async (_, { dispatch, requestId }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const docs = await carAPI.fetchDocDefinitions();
        dispatch(setDocDefinitions(docs));
        return { isSucceed: true };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { loadCarDocDefinitions };
