import { RUPartnerType } from '../modules/partner/model/partnerType';
// right now required only for RU
const getLocalizedPartnerType = (partnerType, intl) => {
    switch (partnerType) {
        case RUPartnerType.ip:
            return intl.formatMessage({ id: "gAtqKc", defaultMessage: "Individual Entrepreneur" });
        case RUPartnerType.ooo:
            return intl.formatMessage({ id: "rKrfYb", defaultMessage: "Legal entity" });
        case RUPartnerType.selfEmployed:
            return intl.formatMessage({ id: "nN5R1C", defaultMessage: "Self-Employed" });
        default:
            return partnerType;
    }
};
export { getLocalizedPartnerType };
