import { jsx as _jsx } from "react/jsx-runtime";
const UserInputError = ({ children, dataTestId = 'validation-error', }) => _jsx("span", { "data-test-id": dataTestId, children: children });
const scrollToFirstUserInputError = (arg) => {
    const target = window.document.querySelectorAll('[data-test=invalid-input]')?.[0];
    if (!target) {
        return;
    }
    target.scrollIntoView(arg);
};
export { UserInputError, scrollToFirstUserInputError };
