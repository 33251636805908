import { createSlice } from '@reduxjs/toolkit';
import { namespace as journeyNamespace } from '../namespace';
const initialState = {
    journey: null,
    journeys: {
        items: [],
        total: 0,
        limit: 10,
        offset: 0,
    },
};
const journeysSlice = createSlice({
    name: journeyNamespace,
    initialState,
    reducers: {
        setJourneys: (state, action) => {
            state.journeys = action.payload;
        },
        setJourney: (state, action) => {
            state.journey = action.payload;
        },
    },
});
export { journeysSlice, journeyNamespace };
