import * as t from 'io-ts';
const w4dPressureRemoteAPI = ({ apiHost, get }) => ({
    readPressure: {
        path: `${apiHost}/v6/pressure`,
        method: get,
        response: t.type({
            pressure: t.Int,
        }),
    },
});
export { w4dPressureRemoteAPI };
