export const ENV = (() => {
    const runtimeEnv = window.ENV;
    const buildTimeEnv = process.env;
    return {
        /**
         * These environment variables are set dynamically via JS script injection
         */
        APP_ID: runtimeEnv.APP_ID,
        BASE_DOMAIN: runtimeEnv.BASE_DOMAIN,
        SNOWPLOW_COLLECTOR_URL: runtimeEnv.SNOWPLOW_COLLECTOR_URL,
        API_URL: runtimeEnv.API_URL,
        /**
         * These environment variables are defined during project build time
         */
        DEPLOY_ENV: buildTimeEnv.DEPLOY_ENV,
        NODE_ENV: buildTimeEnv.NODE_ENV,
        NPM_PACKAGE_VERSION: buildTimeEnv.NPM_PACKAGE_VERSION,
        /**
         * Test key is used for non-production deploy environments.
         *
         * If HCAPTCHA_SITEKEY is absent for some reason, use `safe-end-user` test key.
         * This is a bad scenario, but still better than blocking chauffeurs from accessing the app.
         *
         * Refer to https://docs.hcaptcha.com/#integration-testing-test-keys.
         */
        HCAPTCHA_SITEKEY: buildTimeEnv.DEPLOY_ENV === 'production' && buildTimeEnv.HCAPTCHA_SITEKEY
            ? buildTimeEnv.HCAPTCHA_SITEKEY
            : '20000000-ffff-ffff-ffff-000000000002',
        /**
         * These are hardcoded environment variables
         */
        SENTRY_DSN: 'https://c9e009464d0a46dda47f79f8e4ba713d@o18635.ingest.sentry.io/6111528',
    };
})();
