import * as t from 'io-ts';
import { isLeft } from 'fp-ts/Either';
const formData = (type) => new t.Type('FormData', (value) => value instanceof FormData, (value, ctx) => {
    if (value instanceof FormData) {
        const plainObject = Object.fromEntries(value.entries());
        const validation = type.validate(plainObject, ctx);
        if (isLeft(validation)) {
            return validation;
        }
        return t.success(value);
    }
    return t.failure(value, ctx, 'value is not instance of FormData');
}, raw => raw);
export { formData };
