import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { UL } from '@wheely/ui-kit';
import { useExternalLinks } from '../../../../app/hooks/useExternalLinks';
import { useLicensingAuthorityTypes } from '../../../../shared/hooks/useLicensingAuthorityTypes';
import s from './styles.css';
const LicensingAuthorityMissing = () => {
    const links = useExternalLinks();
    const licensingAuthorities = useLicensingAuthorityTypes();
    return (_jsxs("div", { className: s.wrapper, children: [_jsx("p", { children: _jsx(FormattedMessage, { id: 'YMS1iI', defaultMessage: 'To work with Wheely you need to be licensed by one of the following authorities:' }) }), _jsx(UL, { children: licensingAuthorities.map(licensingAuthority => (_jsx("li", { children: _jsx("a", { href: licensingAuthority.link, target: "_blank", rel: "noreferrer", children: licensingAuthority.title }) }, licensingAuthority.value))) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'SFe0sn', defaultMessage: 'If you have a car, note that you MUST hold a PHV and PHD that are licensed with the same authority, otherwise, you would not be able to work as a Wheely chauffeur.' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'ELHs6c', defaultMessage: 'Detailed information about available licensing options can be found <link>here</link>.', values: {
                        link: (text) => (_jsx("a", { href: links.licensingAuthorities, target: "_blank", rel: "noreferrer", children: text })),
                    } }) })] }));
};
export { LicensingAuthorityMissing };
