import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
const DelayedDisappearance = ({ Component, props, triggerRef, isHidden, }) => {
    const ref = useRef(null);
    const [isReady, setIsReady] = useState(isHidden);
    useEffect(() => {
        triggerRef.current?.addEventListener('transitionend', () => setIsReady(true));
    }, [triggerRef]);
    useEffect(() => {
        if (isHidden) {
            setIsReady(false);
        }
    }, [isHidden]);
    if (isHidden) {
        return isReady ? null : ref.current;
    }
    return (ref.current = _jsx(Component, { ...props }));
};
export { DelayedDisappearance };
