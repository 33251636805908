import * as t from 'io-ts';
const User = t.type({
    first_name: t.string,
    last_name: t.string,
    middle_name: t.string,
    id: t.string,
    city_id: t.string,
    city_name: t.string,
    email: t.union([t.undefined, t.string]),
    partner_id: t.union([t.undefined, t.string]),
    source: t.union([t.undefined, t.null, t.string]),
    driver_id: t.union([t.undefined, t.null, t.string]),
    phone: t.union([t.undefined, t.null, t.string]),
    roles: t.union([t.undefined, t.null, t.array(t.string)]),
});
export { User };
