import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import s from './styles.css';
const Breakdown = ({ journey }) => {
    const intl = useIntl();
    const { titles, values } = useMemo(() => {
        const priceDetails = (journey.breakdown || []).map(breakdownItem => ({
            key: breakdownItem.name,
            label: breakdownItem.name,
            value: intl.formatNumber(breakdownItem.value, {
                style: 'currency',
                currency: journey.currencyCode,
            }),
        }));
        if (journey.isTotalDefined) {
            priceDetails.push({
                key: 'total',
                label: intl.formatMessage({ id: "6WajoZ", defaultMessage: "Total" }),
                value: intl.formatNumber(journey.price, {
                    style: 'currency',
                    currency: journey.currencyCode,
                }),
            });
        }
        return {
            titles: priceDetails.map(priceDetail => [priceDetail.key, priceDetail.label]),
            values: priceDetails.reduce((priceDetailsValues, priceDetail) => {
                priceDetailsValues[priceDetail.key] = priceDetail.value;
                return priceDetailsValues;
            }, {}),
        };
    }, [intl, journey.breakdown, journey.price, journey.currencyCode, journey.isTotalDefined]);
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "WjiNlv", defaultMessage: "Breakdown" }), titles: titles, values: values, className: s.details }));
};
export { Breakdown };
