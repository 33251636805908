import { jsx as _jsx } from "react/jsx-runtime";
import { useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import s from './Clock.css';
const Clock = ({ className, minutes }) => {
    const clockRef = useRef(null);
    useLayoutEffect(() => {
        if (clockRef.current) {
            clockRef.current.style.setProperty('--end', minutes * 6 + 'deg');
        }
    }, [minutes]);
    return _jsx("div", { ref: clockRef, className: cn(s.clock, className) });
};
export { Clock };
