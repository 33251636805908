import * as t from 'io-ts';
import { Journey } from './w4d-journeys-types';
const w4dJourneysRemoteAPI = ({ apiHost, get }) => ({
    readJourneys: {
        path: ({ driverId, from, to, limit, offset, number }) => `${apiHost}/v6/partners/self/orders?driver_id=${driverId}&from=${from}&limit=${limit}&skip=${offset}&to=${to}${number ? `&number=${number}` : ''}`,
        pathParams: t.type({
            driverId: t.string,
            from: t.string,
            limit: t.string,
            offset: t.string,
            to: t.string,
            number: t.string,
        }),
        method: get,
        response: t.type({
            total_entries: t.Int,
            skip: t.Int,
            limit: t.Int,
            results: t.array(Journey),
        }),
    },
    readJourney: {
        path: ({ id }) => `${apiHost}/v6/partners/self/orders/${id}`,
        pathParams: t.type({
            id: t.string,
        }),
        method: get,
        response: t.type({
            order: Journey,
        }),
    },
});
export { w4dJourneysRemoteAPI };
