import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Loader } from '@wheely/ui-kit';
import { FsmState } from '../../modules/onboarding/fsm-types';
import { StepLayout } from '../../shared/view/StepLayout';
import { UploadCar, UploadMyDriver, UploadPartner } from '../../shared/view/Upload';
import { fsmSelectors } from '../../modules/onboarding/store/fsm-slice';
import { mapFsmStateToCtProps, mapFsmStateToCtPropsFR, mapFsmStateToCtPropsUK, } from './view-config';
import { DoYouHaveACar } from './components/DoYouHaveACar';
import { Vehicle } from './components/Vehicle';
import { StepPayments } from './components/Payments';
import { Driver } from './components/Driver';
import { Welcome } from './components/Welcome';
import { PaymentType } from './components/PaymentType';
import { LicensingAuthority as StepLicensingAuthority } from './components/LicensingAuthority';
import { VehicleSelection as StepVehicleSelection } from './components/VehicleSelection';
import { LicensingAuthorityMissing as StepLicensingAuthorityMissing } from './components/LicensingAuthorityMissing';
import s from './styles.css';
const ignoreQueryParams = (url) => url.split('?')[0];
const getQueryParam = (url, key) => {
    const params = new URLSearchParams(url.split('?')[1]);
    return params.get(key) ?? undefined;
};
const createHandlers = (onSwitchScreen) => (transitions) => ({
    onClickYes: 'YES' in transitions ? () => onSwitchScreen('YES') : undefined,
    onClickNo: 'NO' in transitions ? () => onSwitchScreen('NO') : undefined,
    onClickBack: 'BACK' in transitions ? () => onSwitchScreen('BACK') : undefined,
});
const WizardView = React.memo(({ internalPath, partnerFirstName, partnerCountryCode, onSwitchScreen }) => {
    const intl = useIntl();
    const transitions = useSelector(fsmSelectors.getTransitions);
    const isInitialized = useSelector(fsmSelectors.isInitialized);
    const handlers = useMemo(() => createHandlers(onSwitchScreen)(transitions), [onSwitchScreen, transitions]);
    const handleClickBack = useCallback(() => onSwitchScreen('BACK'), [onSwitchScreen]);
    const containerProps = useMemo(() => {
        if (partnerCountryCode === 'GB') {
            return mapFsmStateToCtPropsUK({ stateValue: internalPath, intl });
        }
        if (partnerCountryCode === 'FR') {
            return mapFsmStateToCtPropsFR({ stateValue: internalPath, intl });
        }
        return mapFsmStateToCtProps({ partnerFirstName, stateValue: internalPath, intl });
    }, [internalPath, intl, partnerFirstName, partnerCountryCode]);
    const internalPathOnly = ignoreQueryParams(internalPath);
    if (!isInitialized) {
        return (_jsx("div", { className: s.loader, children: _jsx(Loader, {}) }));
    }
    return (_jsx(StepLayout, { ...containerProps, transitionKey: internalPathOnly, ...handlers, children: (() => {
            const nodes = [];
            switch (internalPathOnly) {
                case FsmState.welcome:
                    nodes.push(_jsx(Welcome, { onSwitchScreen: onSwitchScreen }, "welcome"));
                    break;
                case FsmState.driver:
                    nodes.push(_jsx(Driver, { onSwitchScreen: onSwitchScreen }, "driver"));
                    break;
                case FsmState.newCar:
                    nodes.push(_jsx(Vehicle, { onSwitchScreen: onSwitchScreen }, "new-car"));
                    break;
                case FsmState.doYouHaveCar:
                    nodes.push(_jsx(DoYouHaveACar, {}, "have-car"));
                    break;
                case FsmState.payTo:
                    nodes.push(_jsx(PaymentType, { onSwitchScreen: onSwitchScreen }, "pay-to"));
                    break;
                case FsmState.partnerPayments:
                    nodes.push(_jsx(StepPayments, { onSwitchScreen: onSwitchScreen }, "partner"));
                    break;
                case FsmState.licensingAuthority:
                    nodes.push(_jsx(StepLicensingAuthority, { onSwitchScreen: onSwitchScreen }, "licensingAuthority"));
                    break;
                case FsmState.licensingAuthorityMissing:
                    nodes.push(_jsx(StepLicensingAuthorityMissing, {}, "licensingAuthorityMissing"));
                    break;
                case FsmState.vehicle:
                    nodes.push(_jsx(StepVehicleSelection, { onSwitchScreen: onSwitchScreen }, "vehicle"));
                    break;
                default:
                    break;
            }
            const upload = getQueryParam(internalPath, 'upload');
            switch (upload) {
                case 'driver':
                    nodes.push(_jsx(UploadMyDriver, { onClickSkip: handleClickBack, onFinishUpload: handleClickBack }, "driver-upload"));
                    break;
                case 'car':
                    nodes.push(_jsx(UploadCar, { onClickSkip: handleClickBack, onFinishUpload: handleClickBack }, "car-upload"));
                    break;
                case 'legal':
                    nodes.push(_jsx(UploadPartner, { onClickSkip: handleClickBack, onFinishUpload: handleClickBack }, "legal-upload"));
                    break;
                default:
                    break;
            }
            return nodes;
        })() }, "swap-container"));
});
WizardView.displayName = 'WizardView';
export { WizardView };
