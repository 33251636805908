import { AuthApi, AuthAdapter, LocalStorage, AuthApiSessionManager } from '@wheely/web-auth-lib';
import { ENV } from '../../../env';
const storageKey = 'chauffeur-portal-session';
const syncronizerStorageKey = 'chauffeur-portal-session-sync';
const storage = new LocalStorage();
const adapter = new AuthAdapter({ apiHost: ENV.API_URL, appId: ENV.APP_ID || '' });
const sessionManager = new AuthApiSessionManager({
    storage,
    tabSyncronizerConfig: {
        storageKey: syncronizerStorageKey,
    },
    adapter,
    sessionStorageKey: storageKey,
});
const authAPI = new AuthApi({ sessionManager });
export { authAPI, sessionManager };
