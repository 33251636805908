import { createUploadSlice, createUploadSliceUtils, } from '../../../shared/store/doc-uploader/create-upload-slice';
import { carAPI } from '../api';
import { getCurrentCarId, getUploadedDocsForCurrentCar } from './selectors';
const carUploadNamespace = 'carDocUpload';
const carUploadSlice = createUploadSlice('sts', carUploadNamespace);
const { selectors: carUploadSelectors, asyncActions: carUploadAsyncActions } = createUploadSliceUtils({
    namespace: carUploadNamespace,
    eventTrackingType: 'car',
    actionSetBusy: carUploadSlice.actions.setPending,
    selectHolderId: getCurrentCarId,
    selectUploadedDocs: getUploadedDocsForCurrentCar,
    uploadFile: carAPI.uploadFile,
    getSliceState: (state) => state[carUploadNamespace],
});
export { carUploadSlice, carUploadSelectors, carUploadAsyncActions, carUploadNamespace };
