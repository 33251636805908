import { createAsyncThunk } from '@reduxjs/toolkit';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import { namespace } from '../../namespace';
import { driverAPI } from '../../api';
import { setDriverWorkload } from '../actions';
let lastRequestId;
const loadWorkload = createAsyncThunk(`${namespace}/load-workload`, async ({ driverId, from, to }, { dispatch, requestId }) => {
    if (lastRequestId !== undefined) {
        return { isAlreadyExecuted: true };
    }
    lastRequestId = requestId;
    let workload;
    try {
        workload = await driverAPI.loadWorkload({
            driverId,
            from: from ? from : startOfWeek(new Date(), { weekStartsOn: 1 }),
            to: to ? to : endOfWeek(new Date(), { weekStartsOn: 1 }),
        });
        dispatch(setDriverWorkload(workload));
    }
    finally {
        lastRequestId = undefined;
    }
    return workload ? { workload } : {};
});
export { loadWorkload };
