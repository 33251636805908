import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAllCars } from '../../../modules/car/store/selectors';
import { TwoLineCell } from '../TwoLineCell';
const CarCell = React.memo(({ carId }) => {
    const cars = useSelector(getAllCars);
    const car = cars.find(c => c.id === carId);
    return car ? (_jsx(TwoLineCell, { line1: [car.make, car.model].join(' '), line2: car.licensePlate })) : (_jsx(FormattedMessage, { id: '4ntpuE', defaultMessage: 'No' }));
});
export { CarCell };
