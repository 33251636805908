import { createSlice } from '@reduxjs/toolkit';
import { createSelectors } from './selectors';
import { createAsyncActions } from './async-actions';
const createUploadSlice = (initialDocType, name) => createSlice({
    name: name,
    initialState: {
        docDefinitions: [],
        uploadingForm: {
            docType: initialDocType,
            expiresAt: undefined,
            file: undefined,
        },
        isPending: false,
    },
    reducers: {
        updateUploadFormValue: (state, action) => {
            if (Object.keys(action.payload).length > 0) {
                Object.assign(state.uploadingForm, action.payload);
            }
        },
        setPending: (state, action) => {
            state.isPending = action.payload;
        },
        setDocDefinitions: (state, action) => {
            // Type 'TDocType' is not assignable to type 'Draft<TDocType>
            state.docDefinitions = action.payload;
        },
    },
});
const createUploadSliceUtils = ({ namespace, eventTrackingType, actionSetBusy, selectHolderId, selectUploadedDocs, uploadFile, getSliceState, }) => {
    const selectors = createSelectors(getSliceState, selectUploadedDocs);
    const asyncActions = createAsyncActions({
        namespace,
        eventTrackingType,
        selectHolderId,
        selectUploadForm: selectors.getUploadForm,
        selectIsBusy: selectors.isUploading,
        actionSetBusy,
        uploadFile,
    });
    return { selectors, asyncActions };
};
export { createUploadSlice, createUploadSliceUtils };
