import { useIntl } from 'react-intl';
import { WHEELBASES_MESSAGES } from '../api/hardcoded-make-models';
import { useModelConfig } from './useModelConfig';
const useWheelbases = (make, model) => {
    const intl = useIntl();
    const modelConfig = useModelConfig(make, model);
    if (!modelConfig || !model) {
        return [];
    }
    return modelConfig.wheelbases.map(wheelbaseOption => ({
        title: intl.formatMessage(WHEELBASES_MESSAGES[wheelbaseOption]),
        value: wheelbaseOption,
    }));
};
export { useWheelbases };
