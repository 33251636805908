import { jsx as _jsx } from "react/jsx-runtime";
import { TracePageRenderRQ } from '@wheely/web-tracing';
import { useSelector } from 'react-redux';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { getPartner } from '../../modules/partner/store/selectors';
import { FetchingStatusStoreInstance } from '../../shared/store/fetching-status-store';
export const Tracer = () => {
    const user = useSelector(getPartner);
    const isFetching = useSyncExternalStore(FetchingStatusStoreInstance.subscribe, FetchingStatusStoreInstance.getSnapshot);
    return _jsx(TracePageRenderRQ, { isFetching: isFetching, commonAttributes: { 'user.id': user?.id } });
};
