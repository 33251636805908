import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import cn from 'classnames';
import { Loader } from './Loader';
import s from './List.css';
const List = ({ data, columns, getEntityId, onClickRow, isLoading = false, }) => {
    const handleClickRow = useCallback((event) => {
        if (!onClickRow) {
            return;
        }
        const id = event.currentTarget.dataset.entityId ?? '';
        const entity = data.find(item => getEntityId(item) === id);
        onClickRow(entity);
    }, [data, getEntityId, onClickRow]);
    return (_jsxs(_Fragment, { children: [_jsxs("table", { className: s.list, children: [_jsx("thead", { children: _jsx("tr", { children: columns.map((col, idx) => (_jsx("th", { style: col.style, children: col.renderHeader() }, idx))) }) }), _jsxs("tbody", { children: [isLoading && data.length > 0 && _jsx(Loader, {}), data.length > 0 &&
                                data.map((row, rowIdx) => {
                                    const id = getEntityId(row);
                                    return (_jsx("tr", { "data-entity-id": id, onClick: handleClickRow, className: s.row, children: columns.map((col, idx) => (_jsx("td", { className: cn(s.cell, { [s.clickable]: onClickRow }), children: col.renderCell(row, idx, rowIdx) }, idx))) }, id));
                                })] })] }), isLoading && data.length === 0 && _jsx(Loader, { isStatic: true })] }));
};
export { List };
