const getInitialPhoneCodeByLocale = (locale) => {
    switch (locale) {
        case 'ru':
            return '+7';
        case 'fr':
            return '+33';
        default:
            return '+44';
    }
};
export { getInitialPhoneCodeByLocale };
