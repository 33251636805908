import { isPlainObject } from './isPlainObject';
const isNullish = (test) => test === undefined || test === null;
const isNullishOrEmpty = (test) => isNullish(test) || (isPlainObject(test) && Object.keys(test).length === 0);
const skipNullishDeep = (obj, skipEmptyObject = true) => {
    if (!isPlainObject(obj)) {
        return obj;
    }
    const check = skipEmptyObject ? isNullishOrEmpty : isNullish;
    return Object.fromEntries(Object.entries(obj)
        .map(([key, value]) => {
        // skip only in plain object, ignore File, FormData and etc.
        if (isPlainObject(value)) {
            return [key, skipNullishDeep(value, skipEmptyObject)];
        }
        return [key, value];
    })
        .filter(([, value]) => !check(value)));
};
export { skipNullishDeep };
