import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { Tooltip } from '@wheely/ui-kit';
import { ListLayoutView } from '../../shared/view/ListLayout/ListLayout';
import { MobileListItem } from '../../shared/view/ListLayout/MobileListItem';
import { Icon } from '../../../src-common/view/components/Icon';
import { UpcomingBill } from './components/UpcomingBill';
import s from './styles.css';
const View = ({ upcomingBill, bills, fetchBills, timezone, isLoading = false }) => {
    const columns = useMemo(() => [
        {
            renderHeader: () => _jsx(_Fragment, {}),
            renderCell: bill => (_jsx(Tooltip, { content: bill.isPaid ? (_jsx(FormattedMessage, { id: 'QGgE/h', defaultMessage: 'Successful payment' })) : (_jsx(FormattedMessage, { id: '24QX5b', defaultMessage: 'Failed payment' })), children: _jsx("div", { className: s.iconWrapper, children: _jsx(Icon, { icon: bill.isPaid ? 'checkmark' : 'cross', className: bill.isPaid ? s.iconCheckmark : s.iconCross }) }) })),
            style: { width: '44px' },
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'eyTih+', defaultMessage: 'Number' })),
            renderCell: bill => bill.number,
            style: { width: '110px' },
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'R9QjGW', defaultMessage: 'Date' })),
            renderCell: bill => _jsx(FormattedDate, { value: bill.date, timeZone: timezone }),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'STXGoD', defaultMessage: 'Paid at' })),
            renderCell: bill => bill.paidAtDate ? _jsx(FormattedDate, { value: bill.paidAtDate, timeZone: timezone }) : '–',
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'fDE6wL', defaultMessage: 'Period' })),
            renderCell: bill => (_jsxs(_Fragment, { children: [_jsx(FormattedDate, { value: bill.periodStart, timeZone: timezone }), ' – ', _jsx(FormattedDate, { value: bill.periodEnd, timeZone: timezone })] })),
            style: { width: '184px' },
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'm186q+', defaultMessage: 'Amount' })),
            renderCell: bill => (_jsx(FormattedNumber, { value: bill.amount, style: "currency", currency: bill.currencyCode })),
        },
        {
            renderHeader: () => _jsx(_Fragment, {}),
            renderCell: bill => (_jsxs("div", { className: s.links, children: [_jsx("a", { className: s.link, href: bill.pdfUrl, target: "_blank", rel: "noreferrer", children: "PDF" }), _jsx("a", { className: s.link, href: bill.excelUrl, target: "_blank", rel: "noreferrer", children: "XLS" })] })),
            style: { width: '80px' },
        },
    ], [timezone]);
    return (_jsx("div", { className: s.root, children: _jsx(ListLayoutView, { title: _jsx(FormattedMessage, { id: 'Yhdu1X', defaultMessage: 'Bills' }), data: bills.items, before: upcomingBill ? (_jsx(UpcomingBill, { amount: upcomingBill.amount, currencyCode: upcomingBill.currencyCode, periodStart: upcomingBill.periodStart, periodEnd: upcomingBill.periodEnd, timezone: timezone })) : null, after: _jsx("div", { className: s.bottomPadding }), total: bills.total, limit: bills.limit, columns: columns, getEntityId: bill => String(bill.id), onFetchData: fetchBills, shouldBeEmptyWithoutFilters: false, isLoading: isLoading, renderItem: bill => (_jsx(MobileListItem, { title: _jsx(FormattedDate, { value: bill.date, timeZone: timezone }), note: _jsxs(_Fragment, { children: [_jsx(FormattedDate, { value: bill.periodStart, timeZone: timezone }), ' – ', _jsx(FormattedDate, { value: bill.periodEnd, timeZone: timezone })] }), left: _jsx("div", { className: s.mobileIconWrapper, children: _jsx(Icon, { icon: bill.isPaid ? 'checkmark' : 'cross', className: bill.isPaid ? s.iconCheckmark : s.iconCross }) }), right: _jsxs("div", { className: s.mobileRight, children: [_jsx(FormattedNumber, { value: bill.amount, style: "currency", currency: bill.currencyCode }), _jsx("a", { className: s.link, href: bill.pdfUrl, target: "_blank", rel: "noreferrer", children: "PDF" })] }) })) }) }));
};
export { View };
