import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissions } from '../../app/hooks/usePermissions';
import { loadJourney } from '../../modules/journey/actions/async/load-journey';
import { getJourney } from '../../modules/journey/store/selectors';
import { View } from './view';
const Details = ({ journeyId, onNavigateBack }) => {
    const dispatch = useDispatch();
    usePermissions('viewJourney');
    useEffect(() => {
        dispatch(loadJourney({ id: journeyId }));
    }, [dispatch, journeyId]);
    const journey = useSelector(getJourney);
    const handleClickBack = useCallback(async () => {
        onNavigateBack('/journeys');
    }, [onNavigateBack]);
    if (!journey) {
        return null;
    }
    return _jsx(View, { journey: journey, onClickBack: handleClickBack });
};
export { Details };
