import { EnumCardItemStatus } from '../types';
import { hasAllRequiredDocs, getUploadedRequiredDocTypes, getRequiredDocsTypes, } from '../../../shared/utils/hasAllRequiredDocs';
const getItem = ({ entities, docDefinitions, getTitle, getInternalLink, getItemAction, }) => {
    let hasRejectedDoc = false;
    const items = entities.map((entity) => {
        const uploadedDocTypes = Object.keys(entity.docUploads);
        const requiredDocTypes = getRequiredDocsTypes(docDefinitions);
        const hasAllDocs = hasAllRequiredDocs(entity.docUploads, docDefinitions);
        const uploadedRequiredDocTypes = getUploadedRequiredDocTypes(uploadedDocTypes, requiredDocTypes);
        return {
            title: getTitle(entity),
            internalLink: getInternalLink?.(entity),
            action: getItemAction?.(entity),
            ...(() => {
                if (!hasAllDocs) {
                    return {
                        status: EnumCardItemStatus.addDocument,
                        tipIntent: 'danger',
                    };
                }
                else if (uploadedRequiredDocTypes.some(docType => entity.docUploads[docType]?.status === 'rejected')) {
                    hasRejectedDoc = true;
                    return {
                        status: EnumCardItemStatus.documentsError,
                        tipIntent: 'danger',
                    };
                }
                else if (uploadedRequiredDocTypes.some(docType => entity.docUploads[docType]?.status === 'expired')) {
                    hasRejectedDoc = true;
                    return {
                        status: EnumCardItemStatus.documentsExpired,
                        tipIntent: 'danger',
                    };
                }
                else if (hasAllDocs &&
                    uploadedRequiredDocTypes.some(docType => entity.docUploads[docType]?.status === 'pending')) {
                    return {
                        status: EnumCardItemStatus.inReview,
                        tipIntent: 'info',
                    };
                }
                else if (uploadedRequiredDocTypes.every(docType => entity.docUploads[docType]?.status === 'approved') &&
                    entity.accessStatus !== 'active') {
                    return {
                        status: EnumCardItemStatus.waitingForApproval,
                        tipIntent: 'info',
                    };
                }
                else if (entity.accessStatus === 'active') {
                    return {
                        status: EnumCardItemStatus.approved,
                        tipIntent: 'success',
                    };
                }
                else if (entity.accessStatus === 'blocked') {
                    return {
                        status: EnumCardItemStatus.waitingForApproval,
                        tipIntent: 'danger',
                    };
                }
                return {
                    status: EnumCardItemStatus.approved,
                    tipIntent: 'success',
                };
            })(),
        };
    });
    let cardStatus = 'todo';
    if (items?.length) {
        if (items.every(item => item.tipIntent === 'success')) {
            cardStatus = 'done';
        }
        else if (items.some(item => item.tipIntent === 'info')) {
            cardStatus = 'review';
        }
        else if (hasRejectedDoc) {
            cardStatus = 'rejected';
        }
    }
    const pendingItems = items.filter(item => item.tipIntent !== 'success');
    return { items: pendingItems, cardStatus };
};
export { getItem };
