import { createSelector } from '@reduxjs/toolkit';
import { CountryCode } from '../../../shared/models/country-code';
import { namespace as partnerNamespace } from '../../partner/namespace';
import { namespace as userNamespace } from '../../user/namespace';
const countryOrder = [
    CountryCode.GreatBritain,
    CountryCode.France,
    CountryCode.ArabEmirates,
    CountryCode.Russia,
];
const getCountryCode = createSelector((state) => state.partner.data.cityId, (state) => state.user.data.cityId, (state) => state.common.cities, (cityId, userCityId, cities) => {
    const cityToSearch = cityId || userCityId;
    return cities.find(city => city.id === cityToSearch)?.countryCode ?? CountryCode.Unknown;
});
const getCities = createSelector((state) => state.common.cities, cities => [...cities].sort((city1, city2) => {
    const idx1 = countryOrder.indexOf(city1.countryCode);
    const idx2 = countryOrder.indexOf(city2.countryCode);
    if (idx1 > idx2) {
        return 1;
    }
    if (idx1 < idx2) {
        return -1;
    }
    return 0;
}));
const getCitiesForOnboarding = createSelector(getCities, cities => cities.filter(city => city.isEnabledForOnboarding));
const getCityName = (state) => state[partnerNamespace].data.cityName || state[userNamespace].data.cityName;
export { getCountryCode, getCities, getCityName, getCitiesForOnboarding };
