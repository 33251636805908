import { jsx as _jsx } from "react/jsx-runtime";
import { useIntl } from 'react-intl';
import { FormGroup } from '@wheely/ui-kit';
import { UserInputError } from '../UserInputError';
import { SelectInput } from '../../components/SelectInput';
import { isSubList } from '../../components/ListInput';
import s from '../styles.css';
const SelectField = (props) => {
    const { name, items, value, isDisabled, onChange, error, title, description, inputRef, placeholder, isClearable, } = props;
    const intl = useIntl();
    const hasNoItems = items.length === 0;
    const hasOnlyOneItem = items.length === 1 && !isSubList(items[0]) && items[0].value === value;
    return (_jsx("div", { "data-test-id": "form-select-field", children: _jsx(FormGroup, { title: title, error: error && _jsx(UserInputError, { children: error }), description: !error && _jsx("div", { "data-test-id": `form-select-field__note`, children: description }), formGroupProps: { className: s.formGroup }, children: _jsx(SelectInput, { ref: inputRef, value: value, isDisabled: isDisabled || hasNoItems || hasOnlyOneItem, onChange: onChange, placeholder: placeholder ??
                    intl.formatMessage({ id: "b2Wal/", defaultMessage: "Select item" }), items: items, name: name, hasError: !!error, isClearable: isClearable }) }) }));
};
export { SelectField };
