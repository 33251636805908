import { FsmState } from './fsm-types';
const fsmCfg = {
    [FsmState.welcome]: {
        on: {
            NEXT: {
                target: FsmState.driver,
                effects: ['SCROLL_UP'],
            },
        },
    },
    [FsmState.driver]: {
        on: {
            BACK: {
                target: FsmState.welcome,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.payTo,
                effects: ['PREPARE_DRIVER_FORM', 'SCROLL_UP'],
            },
            UPLOAD: {
                target: FsmState.driverUpload,
                effects: [],
            },
        },
    },
    [FsmState.payTo]: {
        on: {
            BACK: {
                target: FsmState.driver,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.partnerPayments,
                effects: ['SCROLL_UP'],
            },
            UPLOAD: {
                target: FsmState.payToUpload,
                effects: [],
            },
        },
    },
    [FsmState.partnerPayments]: {
        on: {
            BACK: {
                target: FsmState.payTo,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.doYouHaveCar,
                effects: ['SCROLL_UP'],
            },
        },
    },
    [FsmState.doYouHaveCar]: {
        on: {
            BACK: {
                target: FsmState.partnerPayments,
                effects: ['SCROLL_UP'],
            },
            YES: {
                target: FsmState.newCar,
                effects: ['PREPARE_CAR_FORM', 'SCROLL_UP'],
            },
            NO: {
                target: FsmState.toDo,
                effects: ['TRACK_CAR_SKIPPED_EVENT', 'SCROLL_UP'],
            },
        },
    },
    [FsmState.newCar]: {
        on: {
            BACK: {
                target: FsmState.doYouHaveCar,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.toDo,
                effects: ['LOAD_CAR', 'SCROLL_UP'],
            },
            UPLOAD: {
                target: FsmState.uploadNewCarDocs,
                effects: [],
            },
        },
    },
    [FsmState.uploadNewCarDocs]: {
        on: {
            BACK: {
                target: FsmState.newCar,
                effects: ['PREPARE_CAR_FORM'],
            },
        },
    },
    [FsmState.payToUpload]: {
        on: {
            BACK: {
                target: FsmState.payTo,
                effects: ['RELOAD_PARTNER'],
            },
        },
    },
    [FsmState.driverUpload]: {
        on: {
            BACK: {
                target: FsmState.driver,
                effects: ['PREPARE_DRIVER_FORM'],
            },
        },
    },
    [FsmState.toDo]: {
        onEntry: 'COMPLETE_ONBOARDING',
        on: {},
    },
    [FsmState.licensingAuthority]: {
        on: {
            BACK: {
                target: FsmState.welcome,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: ctx => {
                    if (ctx.state === 'failed') {
                        return FsmState.licensingAuthorityMissing;
                    }
                    return FsmState.vehicle;
                },
                effects: ['SCROLL_UP'],
            },
        },
    },
    [FsmState.vehicle]: {
        on: {
            BACK: {
                target: FsmState.licensingAuthority,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.driver,
                effects: ['SCROLL_UP'],
            },
        },
    },
    [FsmState.licensingAuthorityMissing]: {
        on: {
            BACK: {
                target: FsmState.licensingAuthority,
                effects: ['SCROLL_UP'],
            },
        },
    },
};
const fsmConfigPatchForUK = {
    [FsmState.welcome]: {
        on: {
            NEXT: {
                target: FsmState.licensingAuthority,
                effects: ['SCROLL_UP'],
            },
        },
    },
    [FsmState.driver]: {
        on: {
            BACK: {
                target: FsmState.vehicle,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.payTo,
                effects: ['PREPARE_DRIVER_FORM', 'SCROLL_UP'],
            },
            UPLOAD: {
                target: FsmState.driverUpload,
                effects: [],
            },
        },
    },
    [FsmState.partnerPayments]: {
        on: {
            BACK: {
                target: FsmState.payTo,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.toDo,
                effects: ['SCROLL_UP'],
            },
        },
    },
};
const fsmConfigPatchForFR = {
    [FsmState.welcome]: {
        on: {
            NEXT: {
                target: FsmState.vehicle,
                effects: ['SCROLL_UP'],
            },
        },
    },
    [FsmState.vehicle]: {
        on: {
            BACK: {
                target: FsmState.welcome,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.driver,
                effects: ['SCROLL_UP'],
            },
        },
    },
    [FsmState.driver]: {
        on: {
            BACK: {
                target: FsmState.vehicle,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.payTo,
                effects: ['PREPARE_DRIVER_FORM', 'SCROLL_UP'],
            },
            UPLOAD: {
                target: FsmState.driverUpload,
                effects: [],
            },
        },
    },
    [FsmState.partnerPayments]: {
        on: {
            BACK: {
                target: FsmState.payTo,
                effects: ['SCROLL_UP'],
            },
            NEXT: {
                target: FsmState.toDo,
                effects: ['SCROLL_UP'],
            },
        },
    },
};
export { fsmCfg, fsmConfigPatchForUK, fsmConfigPatchForFR };
