import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';
import { Section } from '../../../../shared/view/Section';
import { BottomButtons } from '../../../../shared/view/BottomButtons';
import { DocsList, } from '../../../../../src-common/view/components/DocsList';
import { Button } from '../../../../../src-common/view/adapters/Button';
import { CountryCode } from '../../../../shared/models/country-code';
import { getCountryCode } from '../../../../modules/common/store/selectors';
import { RUPartnerType } from '../../../../modules/partner/model/partnerType';
import { RuPaymentType } from './LocalizedForm/ru';
import { GbPaymentType } from './LocalizedForm/gb';
import { FrPaymentType } from './LocalizedForm/fr';
import s from './styles.css';
const PaymentTypeView = ({ isSubmitting, docs, onDocListItemClick, control }) => {
    const intl = useIntl();
    const countryCode = useSelector(getCountryCode);
    const form = useMemo(() => {
        switch (countryCode) {
            case CountryCode.Russia: {
                return _jsx(RuPaymentType, { control: control });
            }
            case CountryCode.France: {
                return _jsx(FrPaymentType, { control: control });
            }
            default:
                return _jsx(GbPaymentType, { control: control });
        }
    }, [countryCode, control]);
    const selectedPartnerType = useWatch({ control, name: 'partnerType' });
    return (_jsxs(_Fragment, { children: [_jsxs(Section, { children: [form, docs.length > 0 && (_jsx(DocsList, { title: intl.formatMessage({ id: "pytlFK", defaultMessage: "ADD DOCUMENTS" }), className: s.docs, onChange: onDocListItemClick, docs: docs, isStatusHidden: true }))] }), _jsx(BottomButtons, { children: _jsx(Button, { type: "submit", isPending: isSubmitting, isDisabled: selectedPartnerType === RUPartnerType.selfEmployed && countryCode === CountryCode.Russia, children: _jsx(FormattedMessage, { id: '6jg2Fp', defaultMessage: 'NEXT' }) }) })] }));
};
export { PaymentTypeView };
