import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useWatch } from 'react-hook-form';
import { GBPartnerType } from '../../../../../modules/partner/model/partnerType';
import { TextField } from '../../../../../../src-common/view/adapters/reactHookForm/TextField';
import { SelectField } from '../../../../../../src-common/view/adapters/reactHookForm/SelectField';
const GbPaymentType = ({ control }) => {
    const intl = useIntl();
    const partnerTypes = useMemo(() => [
        {
            title: intl.formatMessage({ id: "6Sy04Z", defaultMessage: "Pay myself" }),
            value: GBPartnerType.selfEmployed,
        },
        {
            title: intl.formatMessage({ id: "Ar+x4C", defaultMessage: "Pay my company" }),
            value: GBPartnerType.company,
        },
    ], [intl]);
    const selectedPartnerType = useWatch({ control, name: 'partnerType' });
    return (_jsxs(_Fragment, { children: [_jsx(SelectField, { control: control, name: "partnerType", title: intl.formatMessage({ id: "MafFrv", defaultMessage: "PAYOUT TYPE" }), items: partnerTypes }), selectedPartnerType === GBPartnerType.company && (_jsxs(_Fragment, { children: [_jsx(TextField, { shouldUnregister: true, name: "companyName", control: control, title: intl.formatMessage({ id: "w8qpaR", defaultMessage: "COMPANY NAME" }) }), _jsx(TextField, { shouldUnregister: true, name: "companyAddress", control: control, title: intl.formatMessage({ id: "jfwv8e", defaultMessage: "COMPANY ADDRESS" }) }), _jsx(TextField, { shouldUnregister: true, name: "companyRegNumber", control: control, title: intl.formatMessage({ id: "h4mKm7", defaultMessage: "COMPANY REGISTRATION NUMBER" }), placeholder: intl.formatMessage({ id: "IgDgSi", defaultMessage: "01234567" }) }), _jsx(TextField, { shouldUnregister: true, name: "vatRegNo", control: control, title: intl.formatMessage({ id: "9u8nc2", defaultMessage: "VAT REG NO" }), rules: {
                            pattern: {
                                value: /^(GB)?\d{9,12}$/,
                                message: intl.formatMessage({ id: "vHT3Ce", defaultMessage: "This is 9\u201312 digits, sometimes with \u2018GB\u2019 at the start." }),
                            },
                        }, description: intl.formatMessage({ id: "wswI6O", defaultMessage: "Optional. This is 9\u201312 digits, sometimes with \u2018GB\u2019 at the start." }) })] }))] }));
};
export { GbPaymentType };
