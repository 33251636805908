import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import cn from 'classnames';
import { Button, Select, IconGlobe } from '@wheely/ui-kit';
import { IntlContext } from '../../../../src/i18n';
import s from './style.css';
const LANGUAGE_ITEMS = [
    {
        value: 'en',
        title: 'English',
    },
    {
        value: 'ru',
        title: 'Русский',
    },
    {
        value: 'fr',
        title: 'Français',
    },
];
const LanguageSwitcher = ({ buttonProps: { className: buttonClassname, ...restButtonProps } = {}, ...restProps }) => {
    const { language, setLanguage } = useContext(IntlContext);
    const handleItemSelect = useCallback(item => {
        setLanguage(item.value);
    }, [setLanguage]);
    const selectedItem = LANGUAGE_ITEMS.find(({ value }) => value === language) || LANGUAGE_ITEMS[0];
    return (_jsx(Select, { selectedItem: selectedItem, items: LANGUAGE_ITEMS, onItemSelect: handleItemSelect, popoverProps: {
            minimal: true,
            targetTagName: 'div',
            wrapperTagName: 'div',
            targetClassName: s.popoverTarget,
        }, ...restProps, children: _jsx(Button, { minimal: true, icon: _jsx(IconGlobe, {}), text: selectedItem.title, className: cn(s.button, buttonClassname), ...restButtonProps }) }));
};
export { LanguageSwitcher };
