import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getRequiredDocsTypes, hasAllRequiredDocs, getUploadedRequiredDocTypes, } from '../../../shared/utils/hasAllRequiredDocs';
const createPartnerCards = (context, onNavigate, intl) => {
    const uploads = context.partner.docUploads;
    const definitions = context.partnerUploadDefinitions;
    const docsTotalAmount = definitions.length;
    if (docsTotalAmount === 0) {
        return [];
    }
    const requiredDocTypes = getRequiredDocsTypes(definitions);
    const uploadedDocTypes = Object.keys(uploads);
    const uploadedDocsAmount = uploadedDocTypes.length;
    const partnerHasAllRequiredDocs = hasAllRequiredDocs(uploads, definitions);
    let listOfRequiredDocsToUpload = definitions
        .filter(definition => !uploads[definition.docType])
        .map(doc => doc.title);
    if (listOfRequiredDocsToUpload.length > 2) {
        listOfRequiredDocsToUpload = listOfRequiredDocsToUpload.slice(0, 2);
        listOfRequiredDocsToUpload.push(intl.formatMessage({ id: "Wjf89K", defaultMessage: "other company documents" }));
    }
    const uploadedRequiredDocTypes = getUploadedRequiredDocTypes(uploadedDocTypes, requiredDocTypes);
    const cardStatus = (() => {
        if (partnerHasAllRequiredDocs &&
            uploadedRequiredDocTypes.every(docType => uploads[docType]?.status === 'approved')) {
            return 'done';
        }
        else if (uploadedRequiredDocTypes.some(docType => uploads[docType]?.status === 'rejected')) {
            return 'rejected';
        }
        else if (uploadedRequiredDocTypes.some(docType => uploads[docType]?.status === 'pending')) {
            return 'review';
        }
        else {
            return 'todo';
        }
    })();
    return [
        {
            action: () => onNavigate('/profile'),
            title: (() => {
                if (cardStatus === 'done') {
                    return intl.formatMessage({ id: "w2MeeJ", defaultMessage: "Legal information approved" });
                }
                if (cardStatus === 'review') {
                    return intl.formatMessage({ id: "y1AtC1", defaultMessage: "Legal information" });
                }
                return intl.formatMessage({ id: "tMm4Jh", defaultMessage: "Add legal information" });
            })(),
            cardStatus,
            desc: (_jsxs(_Fragment, { children: [cardStatus === 'review'
                        ? intl.formatMessage({ id: "7HrhYK", defaultMessage: "Documents are in review" })
                        : intl.formatList(listOfRequiredDocsToUpload, { type: 'conjunction' }) + '.', _jsx("br", {}), _jsx("br", {}), intl.formatMessage({ id: "iMaQow", defaultMessage: "Added {added} out of {total}" }, {
                        added: uploadedDocsAmount,
                        total: docsTotalAmount,
                    })] })),
            id: 'company',
            actionTitle: intl.formatMessage({ id: "lgP+Cj", defaultMessage: "add" }),
        },
    ];
};
export { createPartnerCards };
