import { createSlice } from '@reduxjs/toolkit';
import { namespace as billsNamespace } from '../namespace';
const initialState = {
    upcomingBill: null,
    bills: {
        items: [],
        total: 0,
        limit: 10,
        offset: 0,
    },
};
const billsSlice = createSlice({
    name: billsNamespace,
    initialState,
    reducers: {
        setBills: (state, action) => {
            state.bills = action.payload;
        },
        setUpcomingBill: (state, action) => {
            state.upcomingBill = action.payload;
        },
    },
});
export { billsSlice, billsNamespace };
