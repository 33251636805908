import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { UploadView } from './view';
const ConnectedUpload = ({ onUpdateForm, onSubmitForm, onClickSkip, onFinishUpload, selectUploadForm, selectUploadDefinition, }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const uploadDefinition = useSelector(selectUploadDefinition);
    const form = useSelector(selectUploadForm);
    const handleChangeValue = useCallback((_, { value, name }) => {
        const update = { [name]: value };
        if ('file' === name) {
            update.rejectReason = undefined;
            update.status = undefined;
        }
        dispatch(onUpdateForm(update));
    }, [dispatch, onUpdateForm]);
    const handleSubmit = useCallback(async () => {
        setIsUploading(true);
        const resultAction = await dispatch(onSubmitForm({
            textDefaultError: intl.formatMessage({ id: "nLx3K4", defaultMessage: "File upload failed" }),
        }));
        if (onSubmitForm.fulfilled.match(resultAction) && resultAction.payload.isSucceed) {
            await onFinishUpload();
        }
        setIsUploading(false);
    }, [dispatch, onSubmitForm, intl, onFinishUpload]);
    return (_jsx(UploadView, { onClickSkip: onClickSkip, onSubmit: handleSubmit, docDefinition: uploadDefinition, form: form, isUploading: isUploading, onChangeValue: handleChangeValue }));
};
export { ConnectedUpload };
