class FormMemoryStorage {
    constructor() {
        this.map = new Map();
    }
    getItem(key) {
        return this.map.get(key);
    }
    setItem(key, values) {
        this.map.set(key, values);
    }
    removeItem(key) {
        this.map.delete(key);
    }
}
const formMemoryStorage = new FormMemoryStorage();
export { formMemoryStorage };
