import { createSelector } from '@reduxjs/toolkit';
import { namespace } from '../namespace';
import { getMyDriver } from '../../driver/store/selectors';
import { checkIsOnBoardingCompleted, getPartnerCountryCode } from '../../partner/store/selectors';
import { CountryCode } from '../../../shared/models/country-code';
const ROLES = {
    DRIVER: 'driver',
    PARTNER: 'partner',
    PARTNER_ASSISTANT: 'partner_view',
    FLEET: 'fleet',
};
const getUserData = (state) => state[namespace].data;
const getRoles = (state) => state[namespace].data.roles;
const getIsUserWithPartnerRole = createSelector(getRoles, roles => roles.includes(ROLES.PARTNER));
const getIsUserWithDriverRole = createSelector(getRoles, roles => roles.includes(ROLES.DRIVER));
const getIsUserWithFleetRole = createSelector(getRoles, roles => roles.includes(ROLES.FLEET));
const getIsUserWithPartnerAssistantRole = createSelector(getRoles, roles => roles.includes(ROLES.PARTNER_ASSISTANT));
const selectIsSamePartner = createSelector(getUserData, getMyDriver, (user, ownDriver) => user.partnerId === ownDriver.partnerId);
const selectIsChauffeur = createSelector(getIsUserWithDriverRole, getIsUserWithPartnerRole, (isUserWithDriverRole, isUserWithPartnerRole) => isUserWithDriverRole && !isUserWithPartnerRole);
const selectIsFleet = createSelector(getIsUserWithDriverRole, getIsUserWithPartnerRole, getIsUserWithPartnerAssistantRole, checkIsOnBoardingCompleted, (isUserWithDriverRole, isUserWithPartnerRole, isUserWithPartnerAssistantRole, isOnboardingCompleted) => (isUserWithPartnerRole && !isUserWithDriverRole && isOnboardingCompleted) ||
    isUserWithPartnerAssistantRole);
const selectIsFleetAndChauffeur = createSelector(getIsUserWithDriverRole, getIsUserWithPartnerRole, selectIsSamePartner, getMyDriver, (isUserWithDriverRole, isUserWithPartnerRole, isSamePartner) => isUserWithDriverRole && isUserWithPartnerRole && !isSamePartner);
const selectIsIndividualChauffeur = createSelector(getIsUserWithDriverRole, getIsUserWithPartnerRole, selectIsSamePartner, checkIsOnBoardingCompleted, (isUserWithDriverRole, isUserWithPartnerRole, isSamePartner, isOnboardingCompleted) => (isUserWithDriverRole && isUserWithPartnerRole && isSamePartner) ||
    (isUserWithPartnerRole && !isOnboardingCompleted));
const selectIsIndividualChauffeurUK = createSelector(selectIsIndividualChauffeur, getPartnerCountryCode, (isIndividualChauffeur, countryCode) => isIndividualChauffeur && countryCode === CountryCode.GreatBritain);
const selectIsIndividualChauffeurRU = createSelector(getIsUserWithDriverRole, getIsUserWithPartnerRole, getIsUserWithFleetRole, checkIsOnBoardingCompleted, (isUserWithDriverRole, isUserWithPartnerRole, isUserWithFleetRole, isOnboardingCompleted) => (isUserWithPartnerRole && isUserWithDriverRole && isUserWithFleetRole) ||
    (isUserWithPartnerRole && !isOnboardingCompleted));
const selectIsNew = createSelector(getIsUserWithDriverRole, getIsUserWithPartnerRole, getIsUserWithFleetRole, getIsUserWithPartnerAssistantRole, (isUserWithDriverRole, isUserWithPartnerRole, isUserWithFleetRole, isUserWithPartnerAssistantRole) => !isUserWithDriverRole &&
    !isUserWithPartnerRole &&
    !isUserWithPartnerAssistantRole &&
    !isUserWithFleetRole);
export { ROLES, selectIsChauffeur, selectIsFleetAndChauffeur, selectIsFleet, selectIsIndividualChauffeur, selectIsIndividualChauffeurRU, selectIsIndividualChauffeurUK, selectIsNew, getRoles, getUserData, getIsUserWithPartnerRole, };
