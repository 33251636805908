const JourneyStatusEnum = {
    new: 'new',
    accepted: 'accepted',
    looking_for_drivers: 'looking_for_drivers',
    on_the_way: 'on_the_way',
    arrived: 'arrived',
    serving: 'serving',
    done: 'done',
    cancelled: 'cancelled',
    failed: 'failed',
    no_show: 'no_show',
    car_not_found: 'car_not_found',
};
const EventNameEnum = {
    [JourneyStatusEnum.looking_for_drivers]: JourneyStatusEnum.looking_for_drivers,
    [JourneyStatusEnum.on_the_way]: JourneyStatusEnum.on_the_way,
    [JourneyStatusEnum.arrived]: JourneyStatusEnum.arrived,
    [JourneyStatusEnum.serving]: JourneyStatusEnum.serving,
    [JourneyStatusEnum.done]: JourneyStatusEnum.done,
    [JourneyStatusEnum.cancelled]: JourneyStatusEnum.cancelled,
    [JourneyStatusEnum.failed]: JourneyStatusEnum.failed,
    [JourneyStatusEnum.car_not_found]: JourneyStatusEnum.car_not_found,
    extrastop: 'extrastop',
};
export { JourneyStatusEnum, EventNameEnum };
