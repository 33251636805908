import { isAxiosError } from 'axios';
import { createTransport } from '@wheely/web-auth-lib';
import { callCbWithPageSpanContext } from '@wheely/web-tracing';
import { v4 as uuid } from 'uuid';
import { userLanguage } from '../../services/userLanguage';
import { deleteCookie } from '../utils/cookie';
import { AUTH_COOKIE_KEY } from '../utils/session';
import { sessionManager } from '../../modules/auth/api';
import { SerializableError } from '../../../src-common/utils/SerializableError';
import { errorHandling } from '../../services/errorHandling';
import { FetchingStatusStoreInstance } from '../store/fetching-status-store';
class LoginRequiredError extends SerializableError('LoginRequiredError') {
}
class NotFoundError extends SerializableError('NotFoundError') {
}
const transport = createTransport({
    sessionManager,
});
const transportWithTraces = callCbWithPageSpanContext(transport);
const PATH_WITHOUT_AUTH = '/auth/oauth';
const create = (httpMethod, getHeaders = () => Promise.resolve({}), preparePayload = (x) => x) => async (path, payload, headers) => {
    const isPathWithoutAuth = path.includes(PATH_WITHOUT_AUTH);
    const requestId = uuid();
    try {
        const defaultHeaders = await getHeaders();
        FetchingStatusStoreInstance.addRequest(requestId);
        const result = await transportWithTraces({
            url: path,
            headers: {
                ...defaultHeaders,
                ...headers,
            },
            data: payload && preparePayload(payload),
            method: httpMethod,
        });
        FetchingStatusStoreInstance.removeRequest(requestId);
        return result.data;
    }
    catch (error) {
        FetchingStatusStoreInstance.removeRequest(requestId);
        if (isAxiosError(error)) {
            // eslint-disable-next-line no-console
            console.debug('Axios Error');
            // eslint-disable-next-line no-console
            console.debug('Axios Error information', error.toJSON());
            // eslint-disable-next-line no-console
            console.debug('Error message', error.message);
            if (error.code) {
                // eslint-disable-next-line no-console
                console.debug('Error code', error.code);
            }
            if (error.status) {
                // eslint-disable-next-line no-console
                console.debug('Error status', error.status);
            }
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // eslint-disable-next-line no-console
                console.debug('Error response status:', error.response.status);
                // eslint-disable-next-line no-console
                console.debug('Error response status text:', error.response.statusText);
                // eslint-disable-next-line no-console
                console.debug('Error response data:', JSON.stringify(error.response.data));
                if (error.response.status === 404) {
                    throw new NotFoundError(error.response.data?.errors?.entity_was_removed_or_not_found ?? 'Not found');
                }
                if (error.response.status === 429) {
                    throw new Error(error.response.data?.errors?.throttling?.[0] ??
                        'Too many requests. Please try again later');
                }
                if (error.response.status === 500) {
                    throw new Error('Something went wrong. Please try again later');
                }
                if (error.response.status === 401 && !isPathWithoutAuth) {
                    deleteCookie(AUTH_COOKIE_KEY);
                    throw new LoginRequiredError();
                }
                errorHandling.captureException(error);
                return error.response.data || {};
            }
            else {
                // The request was made but no response was received or
                // something happened in setting up the request that triggered an Error
                throw new Error(error.message);
            }
        }
        // eslint-disable-next-line no-console
        console.debug('Non-Axios Error');
        errorHandling.captureException(error);
        if (error instanceof Error) {
            throw error;
        }
    }
};
const getContentTypeJSONHeaders = () => Promise.resolve({
    'content-type': 'application/json; charset=UTF-8',
    'accept-language': userLanguage.getLanguage(),
});
const get = create('GET', getContentTypeJSONHeaders, (x) => JSON.stringify(x));
const post = create('POST', getContentTypeJSONHeaders, (x) => JSON.stringify(x));
const put = create('PUT', getContentTypeJSONHeaders, (x) => JSON.stringify(x));
const del = create('DELETE', getContentTypeJSONHeaders, (x) => JSON.stringify(x));
const patch = create('PATCH', getContentTypeJSONHeaders, (x) => JSON.stringify(x));
const postForm = create('POST');
export { get, post, del, put, postForm, patch, LoginRequiredError, NotFoundError };
