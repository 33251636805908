import { defineMessages } from 'react-intl';
import { JourneyStatusEnum } from '../../modules/journey/model';
const STATUSES = defineMessages({ [JourneyStatusEnum.new]: { id: "mk2cxg", defaultMessage: "New" }, [JourneyStatusEnum.accepted]: { id: "unPG1u", defaultMessage: "Reserved" }, [JourneyStatusEnum.on_the_way]: { id: "xHwCZt", defaultMessage: "On the way" }, [JourneyStatusEnum.arrived]: { id: "O8bqC7", defaultMessage: "Arrived" }, [JourneyStatusEnum.no_show]: { id: "z8K/5P", defaultMessage: "No show" }, [JourneyStatusEnum.serving]: { id: "uLDfZd", defaultMessage: "Passenger on board" }, [JourneyStatusEnum.done]: { id: "kFq+2R", defaultMessage: "Done" }, [JourneyStatusEnum.cancelled]: { id: "+VTXj5", defaultMessage: "Cancelled" }, [JourneyStatusEnum.failed]: { id: "qiM7R1", defaultMessage: "Failed" }, [JourneyStatusEnum.looking_for_drivers]: { id: "WzwGLS", defaultMessage: "Looking for chauffeur" }, [JourneyStatusEnum.car_not_found]: { id: "A0d3oI", defaultMessage: "Vehicle not found" } });
const renderStatus = (status, intl) => {
    if (status in STATUSES) {
        return intl.formatMessage(STATUSES[status]);
    }
    return '–';
};
export { renderStatus };
