import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PHONE_CODES } from '../const/phone-codes';
const usePhoneCodes = () => {
    const intl = useIntl();
    const phoneCodes = useMemo(() => PHONE_CODES.map(phoneCode => ({
        title: phoneCode.title,
        value: phoneCode.value,
        countryCode: phoneCode.countryCode,
        validationRules: {
            validate: (value) => {
                if (value.length === 0 || phoneCode.length === undefined) {
                    return undefined;
                }
                if (value.length === phoneCode.length) {
                    return undefined;
                }
                return intl.formatMessage({ id: "GHOOzU", defaultMessage: "It should have {threshold} characters" }, { threshold: phoneCode.length });
            },
        },
    })), [intl]);
    return phoneCodes;
};
export { usePhoneCodes };
