import { jsx as _jsx } from "react/jsx-runtime";
// Polyfill required for Safari 13 and below
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/ru';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import enDateFnsLocale from 'date-fns/locale/en-GB';
import frDateFnsLocale from 'date-fns/locale/fr';
import ruDateFnsLocale from 'date-fns/locale/ru';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../services/userLanguage';
import { errorHandling } from '../services/errorHandling';
import { getCountryCode } from '../modules/common/store/selectors';
import { CountryCode } from '../shared/models/country-code';
import messagesEn from './compiled-lang/en.json';
import messagesFr from './compiled-lang/fr.json';
import messagesRu from './compiled-lang/ru.json';
import { useUserLanguage } from './useUserLanguage';
const IntlContext = React.createContext({
    setLanguage: () => {
        errorHandling.captureMessage("Something went wrong with locale context. Looks like you're trying to switch locale before app has completely loaded");
    },
    availableLanguages: AVAILABLE_LANGUAGES,
    language: DEFAULT_LANGUAGE,
    region: CountryCode.Unknown,
    dateFnsLocale: enDateFnsLocale,
});
const getDateFnsLocaleByLanguage = (language) => {
    switch (language) {
        case 'fr':
            return frDateFnsLocale;
        case 'ru':
            return ruDateFnsLocale;
        default:
            return enDateFnsLocale;
    }
};
const getLocaleByLanguage = (language) => {
    if (language === 'en') {
        // 'en' is a shortcut for `en-US` locale.
        // Right now we support only GB
        return 'en-GB';
    }
    return language;
};
// TODO load messages dynamically
const messagesMap = {
    en: messagesEn,
    ru: messagesRu,
    fr: messagesFr,
};
const IntlProvider = ({ children }) => {
    const { language, setLanguage } = useUserLanguage();
    const region = useSelector(getCountryCode);
    const contextValue = useMemo(() => ({
        setLanguage,
        availableLanguages: AVAILABLE_LANGUAGES,
        language,
        region,
        dateFnsLocale: getDateFnsLocaleByLanguage(language),
    }), [setLanguage, language, region]);
    const locale = getLocaleByLanguage(language);
    return (_jsx(IntlContext.Provider, { value: contextValue, children: _jsx(ReactIntlProvider, { locale: locale, messages: messagesMap[language], defaultLocale: getLocaleByLanguage(DEFAULT_LANGUAGE), children: children }, locale) }));
};
export { IntlProvider, IntlContext };
