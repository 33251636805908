const setNativeValue = (element, value) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;
    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter?.call(element, value);
    }
    else {
        valueSetter?.call(element, value);
    }
};
const setInputElementValue = (inputElement, value) => {
    setNativeValue(inputElement, value);
    inputElement.dispatchEvent(new Event('input', { bubbles: true }));
};
export { setInputElementValue };
