import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import differenceInYears from 'date-fns/differenceInYears';
import { Checkbox } from '@wheely/ui-kit';
import { TextField } from '../../../src-common/view/adapters/reactHookForm/TextField';
import { PhoneField } from '../../../src-common/view/adapters/reactHookForm/PhoneField';
import { DateField } from '../../../src-common/view/adapters/reactHookForm/DateField';
import { SelectField } from '../../../src-common/view/adapters/reactHookForm/SelectField';
import { Section } from '../../shared/view/Section';
import { BottomButtons } from '../../shared/view/BottomButtons';
import { Button } from '../../../src-common/view/adapters/Button';
import { CountryCode } from '../../shared/models/country-code';
import { usePhoneCodes } from '../../shared/hooks/usePhoneCodes';
import s from './styles.css';
const CreateDriverView = ({ isSubmitting, control, countryCode, licensingAuthorityTypes, linkToLicensingAuthorities, linkToPrivacyPolicy, }) => {
    const intl = useIntl();
    const phoneCodes = usePhoneCodes();
    const [isContractActive, setIsContractActive] = useState(false);
    const handleChangeContractStatus = useCallback(event => {
        setIsContractActive(event?.currentTarget?.checked);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(Section, { children: [_jsx(TextField, { name: "firstName", title: intl.formatMessage({ id: "57XCIM", defaultMessage: "First name" }), control: control, isRequired: true }), _jsx(TextField, { name: "lastName", title: intl.formatMessage({ id: "bjvrE4", defaultMessage: "Last name" }), control: control, isRequired: true }), _jsx(PhoneField, { name: "phoneNumber", namePhoneCode: "phoneCode", title: intl.formatMessage({ id: "zjEEtB", defaultMessage: "Phone" }), control: control, isRequired: true, items: phoneCodes, description: countryCode === CountryCode.France &&
                            intl.formatMessage({ id: "V8we6R", defaultMessage: "Enter phone number without 0" }) }), _jsx(TextField, { name: "email", title: intl.formatMessage({ id: "ZjX73y", defaultMessage: "Email" }), control: control, isRequired: true }), _jsx(DateField, { name: "birthday", title: intl.formatMessage({ id: "cQB2EL", defaultMessage: "DATE OF BIRTH" }), control: control, isRequired: true, rules: {
                            validate: value => value && differenceInYears(new Date(), value) < 18
                                ? intl.formatMessage({ id: "bdjV3H", defaultMessage: "You need to be at least 18 years of age" })
                                : undefined,
                        } }), countryCode === CountryCode.GreatBritain && (_jsxs(_Fragment, { children: [_jsx(SelectField, { name: "licensingAuthority", title: intl.formatMessage({ id: "/Q8aMx", defaultMessage: "Licensing authority" }), description: intl.formatMessage({ id: "EUTZOe", defaultMessage: "If multiple authorities license you, choose only one. Learn more about license types <a>here</a>." }, {
                                    a: (text) => (_jsx("a", { href: linkToLicensingAuthorities, target: "_blank", rel: "noreferrer", children: text })),
                                }), control: control, items: licensingAuthorityTypes }), _jsx(TextField, { name: "licenseNumber", title: intl.formatMessage({ id: "pFGlVW", defaultMessage: "Private Hire Driver License Number" }), placeholder: "12345678", description: intl.formatMessage({ id: "NB/idy", defaultMessage: "Full PHD license number from your licensing authority." }), control: control, isRequired: true }), _jsx(TextField, { name: "ukNationalInsurance", title: intl.formatMessage({ id: "N/hPyL", defaultMessage: "NATIONAL INSURANCE NUMBER" }), placeholder: "AB123456C", isRequired: true, rules: {
                                    pattern: {
                                        value: /^\w{2}\d{6}\w$/i,
                                        message: intl.formatMessage({ id: "5rqrKE", defaultMessage: "Wrong national insurance number, ex. AB123456C." }),
                                    },
                                }, control: control })] })), countryCode === CountryCode.France && (_jsx(TextField, { name: "licenseNumber", title: intl.formatMessage({ id: "RdRdnV", defaultMessage: "VTC (PRIVATE HIRE) REGISTRATION NUMBER" }), placeholder: "AAAA00000000", description: intl.formatMessage({ id: "A7nLWH", defaultMessage: "Example: EVTC12345678." }), control: control, isRequired: true })), countryCode === CountryCode.Russia && (_jsxs("label", { className: s.contractCheckbox, children: [_jsx(Checkbox, { onChange: handleChangeContractStatus }), _jsx("div", { children: _jsx(FormattedMessage, { id: 'vFICsO', defaultMessage: '\u042F \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u044E, \u0447\u0442\u043E \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u044B\u0439 \u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044C \u043E\u0437\u043D\u0430\u043A\u043E\u043C\u043B\u0435\u043D \u0441 <a>\u041F\u043E\u043B\u0438\u0442\u0438\u043A\u043E\u0439 \u041A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438</a> \u0438 \u0441\u043E\u0433\u043B\u0430\u0441\u0435\u043D \u043D\u0430 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0443 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0433\u0430\u0440\u0430\u043D\u0442\u0438\u0440\u0443\u044E \u043D\u0430\u043B\u0438\u0447\u0438\u0435 \u0441 \u043D\u0438\u043C \u0422\u0440\u0443\u0434\u043E\u0432\u043E\u0433\u043E \u0434\u043E\u0433\u043E\u0432\u043E\u0440\u0430 \u0438 \u0441\u0440\u0430\u0437\u0443 \u0436\u0435 \u0443\u0432\u0435\u0434\u043E\u043C\u043B\u044E Wheely \u043F\u0440\u0438 \u0435\u0433\u043E \u0440\u0430\u0441\u0442\u043E\u0440\u0436\u0435\u043D\u0438\u0438', values: {
                                        a: (text) => (_jsx("a", { href: linkToPrivacyPolicy, target: "_blank", rel: "noreferrer", children: text })),
                                    } }) })] }))] }), _jsx(BottomButtons, { children: _jsx(Button, { type: "submit", isPending: isSubmitting, isDisabled: countryCode === 'RU' && !isContractActive, children: _jsx(FormattedMessage, { id: 'FLNCRh', defaultMessage: 'Create' }) }) })] }));
};
export { CreateDriverView };
