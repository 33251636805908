import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { prepareUploadMyDriverForm } from '../../modules/driver/actions/async/prepare-upload-driver-form';
import { prepareUploadPartnerForm } from '../../modules/partner/actions/async/preparePartnerUploadForm';
import { myDriverUploadSelectors } from '../../modules/driver/store/upload-slice';
import { partnerUploadSelectors } from '../../modules/partner/store/uploadSlice';
import { getMyDriver } from '../../modules/driver/store/selectors';
import { getPartner } from '../../modules/partner/store/selectors';
import { UploadMyDriver, UploadPartner } from '../../shared/view/Upload';
import { reloadPersonData } from '../../modules/partner/actions/async/reload-person-data';
import { partnerAPI } from '../../modules/partner/api';
import { usePermissionsContext } from '../../app/contexts/permissions';
import { IntlContext } from '../../i18n';
import { ProfileView } from './view';
const isDriverDoc = Symbol('isDriverDoc');
const mergeDocListItems = (forPartner, forDriver) => [
    ...forDriver.map(document => ({ ...document, [isDriverDoc]: true })),
    ...forPartner.map(document => ({ ...document, [isDriverDoc]: false })),
];
const Profile = ({ onNavigate, onNavigateBack }) => {
    const dispatch = useDispatch();
    const { user } = usePermissionsContext();
    const { region } = useContext(IntlContext);
    const [isDriverUploading, setIsDriverUploading] = useState(false);
    const [isLegalUploading, setIsLegalUploading] = useState(false);
    const handleClickEditPayment = useCallback(() => {
        onNavigate('/profile/payments');
    }, [onNavigate]);
    const handleClickEditDriver = useCallback(() => {
        onNavigate('/profile/chauffeur');
    }, [onNavigate]);
    const handleClickContactInformation = useCallback(() => {
        onNavigate('/profile/contact-information');
    }, [onNavigate]);
    const handleClickBack = useCallback(() => {
        onNavigateBack('/todo');
    }, [onNavigateBack]);
    const handleClickAgencyAgreementLink = useCallback(async () => {
        const url = await partnerAPI.getAgencyAgreementLink();
        window.open(url, '_blank');
    }, []);
    const handleFinishUpload = useCallback(() => {
        dispatch(reloadPersonData());
        setIsDriverUploading(false);
        setIsLegalUploading(false);
    }, [dispatch]);
    const handleSkipUpload = useCallback(() => {
        setIsDriverUploading(false);
        setIsLegalUploading(false);
    }, []);
    const driverDocs = useSelector(myDriverUploadSelectors.getDocsList);
    const partnerDocs = useSelector(partnerUploadSelectors.getDocsList);
    const mergedDocs = useMemo(() => user.isFleet
        ? mergeDocListItems(partnerDocs, [])
        : mergeDocListItems(partnerDocs, driverDocs), [driverDocs, partnerDocs, user.isFleet]);
    const handleUploadDoc = useCallback((_, doc) => {
        if (doc[isDriverDoc]) {
            dispatch(prepareUploadMyDriverForm({
                docType: doc.docType,
                onSuccess: () => setIsDriverUploading(true),
            }));
        }
        else {
            dispatch(prepareUploadPartnerForm({
                docType: doc.docType,
                onSuccess: () => setIsLegalUploading(true),
            }));
        }
    }, [dispatch]);
    const driver = useSelector(getMyDriver);
    const partner = useSelector(getPartner);
    return (_jsxs(_Fragment, { children: [_jsx(ProfileView, { user: user, region: region, driver: driver, docs: mergedDocs, onUploadDoc: handleUploadDoc, partner: partner, onClickEditPayment: handleClickEditPayment, onClickEditDriver: handleClickEditDriver, onClickEditContactInformation: handleClickContactInformation, onClickBack: handleClickBack, onClickAgencyAgreementLink: handleClickAgencyAgreementLink }), isDriverUploading && (_jsx(UploadMyDriver, { onClickSkip: handleSkipUpload, onFinishUpload: handleFinishUpload })), isLegalUploading && (_jsx(UploadPartner, { onClickSkip: handleSkipUpload, onFinishUpload: handleFinishUpload }))] }));
};
export { Profile };
