import { jsx as _jsx } from "react/jsx-runtime";
import { useDialog } from '@wheely/ui-kit';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { usePrevious } from '../../../../shared/hooks/usePrevious';
import { getIsAppInitialized } from '../../../../app/store/selectors';
import { getPartner } from '../../../../modules/partner/store/selectors';
import { CongratsView } from './view';
import s from './styles.css';
const useCongratsDialog = () => {
    const { openDialog } = useDialog();
    const intl = useIntl();
    const partner = useSelector(getPartner);
    const isAppInitialized = useSelector(getIsAppInitialized);
    const prevIsOnboardingCompleted = usePrevious(partner.onboarding.isCompleted);
    useEffect(() => {
        if (!isAppInitialized) {
            return;
        }
        if (prevIsOnboardingCompleted === undefined) {
            return;
        }
        if (prevIsOnboardingCompleted === partner.onboarding.isCompleted) {
            return;
        }
        openDialog(({ onClose }) => _jsx(CongratsView, { onContinueClick: onClose }), {
            title: intl.formatMessage({ id: "1VMq+P", defaultMessage: "Congratulations" }),
            className: s.dialog,
            bodyClassName: s.dialogBody,
            titleClassName: s.dialogTitle,
        });
    }, [partner, isAppInitialized, intl, openDialog, prevIsOnboardingCompleted]);
};
export { useCongratsDialog };
