import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import { renderEvent } from '../../../shared/utils/renderEvent';
import s from './styles.css';
const History = ({ journey }) => {
    const intl = useIntl();
    const titles = useMemo(() => (journey.events || []).map(event => [event.name, renderEvent(event.name, intl)]), [journey.events, intl]);
    const values = useMemo(() => (journey.events || []).reduce((eventValues, event) => {
        eventValues[event.name] = intl.formatDate(event.date, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: journey.timezone,
        });
        return eventValues;
    }, {}), [journey.events, journey.timezone, intl]);
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "w/dzZd", defaultMessage: "History" }), titles: titles, values: values, className: s.details }));
};
export { History };
