const getLocalizedCarMakeName = (make, intl) => {
    switch (make) {
        case 'Mercedes-Benz':
            return intl.formatMessage({ id: "Pg4cfc", defaultMessage: "Mercedes-Benz" });
        case 'Mercedes-Maybach':
            return intl.formatMessage({ id: "hxZvyj", defaultMessage: "Mercedes-Maybach" });
        case 'BMW':
            return intl.formatMessage({ id: "JGFgoE", defaultMessage: "BMW" });
        default:
            return make;
    }
};
export { getLocalizedCarMakeName };
