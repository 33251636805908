import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { createSelectors } from './selectors';
const createAsyncActions = (namespace, selectors, effects, actions) => {
    const trySetValue = createAsyncThunk(`${namespace}/try-set-fsm-value`, async (args, { dispatch, getState }) => {
        try {
            if (selectors.isBusy(getState())) {
                return { isSucceed: false, result: 'transition already in progress' };
            }
            batch(() => {
                dispatch(actions.setIsBusy(true));
                dispatch(actions.setContext(args.newContext || {}));
            });
            const context = selectors.getContext(getState());
            const config = selectors.getConfig(getState());
            const entryEffectKey = config[args.newValue].onEntry;
            if (entryEffectKey !== undefined) {
                const entryEffect = effects[entryEffectKey];
                await dispatch(entryEffect(context))?.unwrap?.();
            }
            dispatch(actions.setValue(args.newValue));
            const onUpdate = selectors.getOnUpdateHandler(getState());
            if (onUpdate) {
                onUpdate(args.newValue, context);
            }
            return { isSucceed: true };
        }
        finally {
            dispatch(actions.setIsBusy(false));
        }
    });
    const sendEvent = createAsyncThunk(`${namespace}/send-event`, async ({ event, newContext }, { dispatch, getState }) => {
        const transitions = selectors.getTransitions(getState());
        const transition = transitions[event];
        if (transition !== undefined) {
            const newValue = typeof transition.target === 'string'
                ? transition.target
                : transition.target(newContext || {});
            const transitionResult = await dispatch(trySetValue({ newValue, newContext })).unwrap();
            if (transitionResult.isSucceed) {
                const context = selectors.getContext(getState());
                transition.effects?.forEach(effectKey => {
                    const effect = effects[effectKey];
                    if (effect) {
                        dispatch(effect(context))?.unwrap?.();
                    }
                });
                return { isSucceed: true };
            }
            else {
                return transitionResult;
            }
        }
        return { isSucceed: false };
    });
    return {
        trySetValue,
        sendEvent,
    };
};
const createFsmSlice = ({ config, currentValue, effects, namespace, }) => {
    const getSliceState = (state) => state[namespace];
    const fsmSelectors = createSelectors(getSliceState);
    const fsmSlice = createSlice({
        name: namespace,
        initialState: {
            config,
            currentValue,
            isBusy: false,
            context: {},
            onUpdate: undefined,
            isInitialized: false,
            draft: {},
        },
        reducers: {
            setValue: (state, action) => {
                state.currentValue = action.payload; // Type 'TValue' is not assignable to type 'Draft<TValue>'
                if (!state.isInitialized) {
                    state.isInitialized = true;
                }
            },
            setIsBusy: (state, action) => {
                state.isBusy = action.payload;
            },
            setContext: (state, action) => {
                state.context = action.payload;
            },
            setDraft: (state, action) => {
                state.draft = {
                    ...state.draft,
                    ...action.payload,
                };
            },
            setOnUpdate: (state, action) => {
                state.onUpdate = action.payload;
            },
            patchTransitions: (state, action) => {
                Object.assign(state.config, action.payload);
            },
            removeTransition: (state, action) => {
                const { fsmValue, fsmEvent } = action.payload;
                const configDraft = state.config;
                if (configDraft[fsmValue].on[fsmEvent]) {
                    delete configDraft[fsmValue].on[fsmEvent];
                }
            },
        },
    });
    const fsmAsyncActions = createAsyncActions(namespace, fsmSelectors, effects, fsmSlice.actions);
    return { fsmSlice, fsmSelectors, fsmAsyncActions };
};
export { createFsmSlice };
