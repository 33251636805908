import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import { Icon } from '../../../../src-common/view/components/Icon';
import { TwoLineCell } from '../../../shared/view/TwoLineCell';
import s from './CurrentChauffeur.css';
const CurrentChauffeur = ({ chauffeur }) => {
    const intl = useIntl();
    const titles = useMemo(() => [
        [
            'chauffeur',
            _jsx(TwoLineCell, { line1: [chauffeur.firstName, chauffeur.lastName].join(' '), line2: chauffeur.rating ? (_jsx(FormattedNumber, { value: chauffeur.rating, maximumFractionDigits: 2, minimumFractionDigits: 2 })) : null }, "chauffeur"),
            ({ children }) => _jsx(Link, { to: `/chauffeurs/${chauffeur.id}`, children: children }),
        ],
    ], [chauffeur]);
    const values = {
        chauffeur: () => (_jsxs("div", { className: s.iconWrapper, children: [_jsx(Icon, { icon: 'chevron-to-end' }), ","] })),
    };
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "yVrSEB", defaultMessage: "Current chauffeur" }), titles: titles, values: values }));
};
export { CurrentChauffeur };
