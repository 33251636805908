import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setEarnings } from '../actions';
import { earningsAPI } from '../../api';
const loadEarnings = createAsyncThunk(`${namespace}/load-earnings`, async ({ periodName }, { dispatch }) => {
    const earnings = await earningsAPI.fetchEarnings({ periodName });
    dispatch(setEarnings(earnings));
    return earnings;
});
export { loadEarnings };
