import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setAllDrivers } from '../actions';
import { driverAPI } from '../../api';
const loadAllDrivers = createAsyncThunk(`${namespace}/load-all-drivers`, async (_, { dispatch }) => {
    const drivers = await driverAPI.fetchBriefDrivers();
    dispatch(setAllDrivers(drivers.items));
    return drivers;
});
export { loadAllDrivers };
