import { useMemo, useCallback, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { formLocalStorage } from './formLocalStorage';
import { formMemoryStorage } from './formMemoryStorage';
const dummyStorage = {
    clear: () => {
        /* noop */
    },
    getItem: () => null,
    setItem: () => {
        /* noop */
    },
    removeItem: () => {
        /* noop */
    },
};
const useFormStorage = ({ config: { storeId, type }, form, }) => {
    const values = useWatch({ control: form.control });
    const storage = useMemo(() => {
        switch (type) {
            case 'memory':
                return formMemoryStorage;
            case 'ls':
                return formLocalStorage;
            default:
                return dummyStorage;
        }
    }, [type]);
    useEffect(() => {
        const presavedValues = storage.getItem(storeId);
        if (presavedValues) {
            form.reset(presavedValues);
        }
    }, [form, storage, storeId]);
    useEffect(() => {
        storage.setItem(storeId, values);
    }, [storeId, values, storage]);
    const clearStorage = useCallback(() => {
        storage.removeItem(storeId);
    }, [storeId, storage]);
    return clearStorage;
};
export { useFormStorage };
