import * as t from 'io-ts';
const TestLink = t.type({
    type: t.string,
    url: t.string,
});
const LocalizedString = t.partial({
    en: t.string,
    ru: t.string,
    fr: t.string,
});
const DriverTest = t.type({
    id: t.string,
    title: LocalizedString,
    body_partner: LocalizedString,
    body_driver: LocalizedString,
    type: t.string,
    link: t.union([TestLink, t.null, t.undefined]),
    e_learning_module_link: t.union([t.string, t.null, t.undefined]),
    text_title: t.string,
    text_description: t.string,
});
const City = t.type({
    business_commission: t.union([t.number, t.undefined]),
    center: t.array(t.number),
    country_calling_code: t.string,
    country_code: t.string,
    country_name: t.union([t.string, t.null]),
    currency: t.string,
    driver_tests: t.union([t.undefined, t.array(DriverTest)]),
    email: t.string,
    exact_price: t.boolean,
    id: t.string,
    lang: t.string,
    localized_name: LocalizedString,
    name: t.string,
    names: t.union([t.undefined, t.record(t.string, t.array(t.string))]),
    phone: t.union([t.undefined, t.string]),
    radius: t.Int,
    status: t.string,
    timezone: t.string,
    trial_commission: t.union([t.undefined, t.number]),
    type: t.union([t.undefined, t.string]),
    url: t.union([t.undefined, t.string]),
    utc_offset: t.Int,
    onboarding_enabled: t.boolean,
});
export { City };
