import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { driverUploadSelectors, myDriverUploadSelectors } from '../../store/upload-slice';
import { updateUploadFormValue, updateMyDriverUploadFormValue } from '../actions';
let lastRequestId;
const createPrepareUploadDriverForm = (ownDriver) => createAsyncThunk(`${namespace}/prepare-upload-${ownDriver ? 'my' : ''}driver-form`, async ({ docType, onSuccess }, { getState, dispatch, requestId }) => {
    lastRequestId = requestId;
    if (lastRequestId !== requestId) {
        return { isReplaced: true };
    }
    const updateUploadFormValueActionCreator = ownDriver
        ? updateMyDriverUploadFormValue
        : updateUploadFormValue;
    dispatch(updateUploadFormValueActionCreator({ docType }));
    const values = (ownDriver ? myDriverUploadSelectors : driverUploadSelectors).getValuesForUploadForm(getState());
    dispatch(updateUploadFormValueActionCreator({
        expiresAt: values?.expiresAt,
        file: values?.file,
        status: values?.status,
        rejectReason: values?.rejectReason,
    }));
    onSuccess?.();
    return { isSucceed: true };
});
const prepareUploadDriverForm = createPrepareUploadDriverForm(false);
const prepareUploadMyDriverForm = createPrepareUploadDriverForm(true);
export { prepareUploadDriverForm, prepareUploadMyDriverForm };
