import differenceInDays from 'date-fns/differenceInDays';
import { prepareDate, prepareDateWithoutTime } from '../api/prepare-date';
const EXPIRATION_THRESHOLD_DAYS = 14;
const prepareExpiresSoon = (expiresAt) => {
    if (!expiresAt) {
        return false;
    }
    const daysToExpiration = differenceInDays(new Date(expiresAt), Date.now());
    return daysToExpiration > 0 && daysToExpiration < EXPIRATION_THRESHOLD_DAYS;
};
const prepareUploadedFile = (rawUpload) => ({
    docType: rawUpload.type,
    createdAt: prepareDate(rawUpload.created_at),
    expiresAt: prepareDateWithoutTime(rawUpload.expires_at),
    expiresSoon: prepareExpiresSoon(rawUpload.expires_at),
    updatedAt: prepareDate(rawUpload.updated_at),
    previewUrl: rawUpload.preview_url,
    downloadUrl: rawUpload.url,
    docFormat: rawUpload.format,
    status: rawUpload.status,
    rejectReason: rawUpload.reject_reason,
});
export { prepareUploadedFile };
