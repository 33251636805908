const getOnlineStatus = ({ online, busy }) => {
    if (busy) {
        return 'busy';
    }
    if (online) {
        return 'online';
    }
    return 'offline';
};
export { getOnlineStatus };
