import * as t from 'io-ts';
import * as tt from '../io-types/form-data';
import { postForm } from '../../../src/shared/api/fetch-based-http-methods';
import { DriverDetails, DriverDocType, DriverUploadDefinition, DriverUploadResult, NewDriverRequest, NewDriverResponse, TransferRequest, WorkloadInterval, WorkloadSummary, } from './w4d-drivers-types';
const w4dDriversRemoteAPI = ({ get, post, put, apiHost }) => ({
    sendTransferRequest: {
        path: ({ driverId }) => `${apiHost}/v6/drivers/${driverId}/driver_transfer_request`,
        response: t.type({
            driver_transfer_request: TransferRequest,
        }),
        pathParams: t.type({
            driverId: t.string,
        }),
        method: put,
    },
    readReferral: {
        path: `${apiHost}/v6/driver/referral_info`,
        response: t.type({
            referral_info: t.type({
                code: t.string,
                description: t.union([t.string, t.null]),
            }),
        }),
        method: get,
    },
    changeContractStatus: {
        path: ({ driverId }) => `${apiHost}/v6/drivers/${driverId}/partner_driver_contract`,
        pathParams: t.type({
            driverId: t.string,
        }),
        response: t.type({
            driver: DriverDetails,
        }),
        payload: t.type({
            status: t.keyof({ rejected: 1, approved: 1 }),
        }),
        method: put,
    },
    blockDriver: {
        path: ({ id }) => `${apiHost}/v6/drivers/${id}/block`,
        pathParams: t.type({
            id: t.string,
        }),
        response: t.union([
            t.type({
                driver: DriverDetails,
                errors: t.undefined,
            }),
            t.type({
                driver: t.undefined,
                errors: t.type({
                    reason: t.string,
                }),
            }),
        ]),
        payload: t.type({
            blocked_reason: t.string,
            blocked_reason_notes: t.string,
        }),
        method: put,
    },
    readMyDriver: {
        path: `${apiHost}/v6/driver`,
        response: t.type({
            driver: DriverDetails,
        }),
        method: get,
    },
    readDrivers: {
        path: `${apiHost}/v6/drivers`,
        pathParams: t.type({
            limit: t.number,
            skip: t.number,
            query: t.string,
        }),
        response: t.type({
            total_entries: t.Int,
            skip: t.Int,
            limit: t.Int,
            results: t.array(t.exact(DriverDetails)),
        }),
        method: get,
    },
    createDriver: {
        path: `${apiHost}/v6/drivers`,
        response: t.union([
            t.type({
                driver: NewDriverResponse,
            }),
            t.type({
                errors: t.partial({
                    email: t.array(t.string),
                    phone: t.array(t.string),
                    user_exists: t.array(t.string),
                }),
            }),
        ]),
        payload: NewDriverRequest,
        method: post,
    },
    updateDriver: {
        path: ({ id }) => `${apiHost}/v6/drivers/${id}`,
        pathParams: t.type({
            id: t.string,
        }),
        response: t.union([
            t.type({
                driver: DriverDetails,
                errors: t.undefined,
            }),
            t.type({
                driver: t.undefined,
                errors: t.record(t.string, t.array(t.string)),
            }),
        ]),
        payload: t.partial(NewDriverRequest.props),
        method: put,
    },
    readDriverDetails: {
        path: ({ id }) => `${apiHost}/v6/drivers/${id}`,
        pathParams: t.type({
            id: t.string,
        }),
        response: t.type({
            driver: DriverDetails,
        }),
        method: get,
    },
    uploadDriverDocument: {
        path: ({ driverId }) => `${apiHost}/v6/uploads/drivers/${driverId}`,
        pathParams: t.type({
            driverId: t.string,
        }),
        method: postForm,
        response: t.union([
            t.type({
                upload: DriverUploadResult,
            }),
            t.type({
                errors: t.type({
                    file: t.array(t.string),
                }),
            }),
        ]),
        payload: tt.formData(t.type({
            type: DriverDocType,
            expires_at: t.union([t.undefined, t.string]),
            upload_file: t.unknown, // binary
        })),
    },
    readDriverDocumentDefinitions: {
        path: `${apiHost}/v6/uploads/driver`,
        method: get,
        response: t.array(DriverUploadDefinition),
    },
    loadWorkload: {
        path: ({ driverId, from, to }) => `${apiHost}/v6/drivers/${driverId}/workload?from=${from}&to=${to}`,
        pathParams: t.type({
            driverId: t.string,
            from: t.string,
            to: t.string,
        }),
        method: get,
        response: t.type({
            intervals: t.array(WorkloadInterval),
            summary: WorkloadSummary,
        }),
    },
});
export { w4dDriversRemoteAPI };
