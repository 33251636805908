import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { BottomButtons } from '../../shared/view/BottomButtons';
import { getPartner } from '../../modules/partner/store/selectors';
import { Button } from '../../../src-common/view/adapters/Button';
import { useForm } from '../../shared/view/Form';
import { getCountryCode } from '../../modules/common/store/selectors';
import { PaymentsView } from '../Wizard/components/Payments/view';
const mapPartnerToForm = ({ paymentAccount, paymentSortCode, paymentBik, paymentRasAccount, paymentSwift, paymentIban, paymentBankName, paymentKorAccount, }) => ({
    accountNumber: paymentAccount,
    sortCode: paymentSortCode,
    bik: paymentBik,
    rasAccount: paymentRasAccount,
    swift: paymentSwift,
    iban: paymentIban,
    bankName: paymentBankName,
    corAccount: paymentKorAccount,
});
const PaymentsForm = ({ usePreSaveStore = true, textSubmitButton, onSubmit, }) => {
    const partner = useSelector(getPartner);
    const countryCode = useSelector(getCountryCode);
    const { handleSubmit, form } = useForm({
        preSaveStore: usePreSaveStore
            ? {
                storeId: `paymentInfoStepForm-${partner.id}`,
                type: 'memory',
            }
            : undefined,
        onSubmit,
        defaultValues: mapPartnerToForm(partner),
    });
    const { control, formState: { isSubmitting }, } = form;
    return (_jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit, children: [_jsx(PaymentsView, { control: control, partnerType: partner.partnerType, countryCode: countryCode }), _jsx(BottomButtons, { children: _jsx(Button, { type: "submit", isPending: isSubmitting, children: textSubmitButton || (_jsx(FormattedMessage, { id: '6jg2Fp', defaultMessage: 'NEXT' })) }) })] }) }));
};
export { PaymentsForm };
