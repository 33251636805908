const PeriodNames = {
    today: 'today',
    yesterday: 'yesterday',
    current_week: 'current_week',
    last_week: 'last_week',
    month: 'month',
    last_month: 'last_month',
};
const PeriodNameEnum = PeriodNames;
export { PeriodNameEnum };
