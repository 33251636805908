import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setCar } from '../actions';
import { carAPI } from '../../api';
const loadCarById = createAsyncThunk(`${namespace}/load-car-by-id`, async (id, { dispatch }) => {
    if (!id) {
        return undefined;
    }
    const car = await carAPI.fetchCarById(id);
    dispatch(setCar(car));
    return car;
});
export { loadCarById };
