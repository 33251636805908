import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useWatch } from 'react-hook-form';
import { RUPartnerType } from '../../../../../modules/partner/model/partnerType';
import { TextField } from '../../../../../../src-common/view/adapters/reactHookForm/TextField';
import { SelectField } from '../../../../../../src-common/view/adapters/reactHookForm/SelectField';
import { useExternalLinks } from '../../../../../app/hooks/useExternalLinks';
import s from '../styles.css';
const RuPaymentType = ({ control }) => {
    const intl = useIntl();
    const links = useExternalLinks();
    const partnerTypes = useMemo(() => [
        {
            title: intl.formatMessage({ id: "kgzbXX", defaultMessage: "Pay individual entrepreneur" }),
            value: RUPartnerType.ip,
        },
        {
            title: intl.formatMessage({ id: "qNnWaK", defaultMessage: "Pay my company" }),
            value: RUPartnerType.ooo,
        },
        {
            title: intl.formatMessage({ id: "6Sy04Z", defaultMessage: "Pay myself" }),
            value: RUPartnerType.selfEmployed,
        },
    ], [intl]);
    const selectedPartnerType = useWatch({ control, name: 'partnerType' });
    const isSelfEmployed = selectedPartnerType === RUPartnerType.selfEmployed;
    return (_jsxs(_Fragment, { children: [_jsx(SelectField, { control: control, name: "partnerType", title: intl.formatMessage({ id: "MafFrv", defaultMessage: "PAYOUT TYPE" }), items: partnerTypes, description: isSelfEmployed
                    ? intl.formatMessage({ id: "Gai39E", defaultMessage: "If you don\u2019t yet have an IE or OOO registration certificate." })
                    : undefined }), selectedPartnerType === RUPartnerType.ip && (_jsx("div", { className: s.fieldIp, children: _jsx(TextField, { shouldUnregister: true, name: "companyName", control: control, isDisabled: true }) })), selectedPartnerType === RUPartnerType.ooo && (_jsxs(_Fragment, { children: [_jsx(TextField, { shouldUnregister: true, name: "inn", title: intl.formatMessage({ id: "dxJSd0", defaultMessage: "COMPANY INN" }), placeholder: intl.formatMessage({ id: "kAtPKo", defaultMessage: "770000000" }), description: intl.formatMessage({ id: "/VIhvk", defaultMessage: "INN consits of 10 digits." }), control: control }), _jsx(TextField, { shouldUnregister: true, name: "companyName", title: intl.formatMessage({ id: "w8qpaR", defaultMessage: "COMPANY NAME" }), control: control }), _jsx(TextField, { shouldUnregister: true, name: "kpp", title: intl.formatMessage({ id: "lNdSmB", defaultMessage: "KPP" }), placeholder: intl.formatMessage({ id: "klcdsT", defaultMessage: "770000000" }), control: control }), _jsx(TextField, { shouldUnregister: true, name: "companyAddress", control: control, title: intl.formatMessage({ id: "jfwv8e", defaultMessage: "COMPANY ADDRESS" }) })] })), isSelfEmployed && (_jsxs("div", { className: s.description, children: [_jsx(FormattedMessage, { id: '+SwBTX', defaultMessage: 'To take bookings with Wheely you\u2019ll need to register as an IE (individual entrepreneur) or an OOO (limited liability company), or join a partner company.' }), _jsx("br", {}), _jsx("br", {}), _jsx(FormattedMessage, { id: 'gcdC66', defaultMessage: 'For more information please check the <a>\u2018Gosuslugi\u2019 website.</a>', values: {
                            a: (text) => (_jsx("a", { href: links.registerIPorOOO, target: "_blank", rel: "noreferrer", children: text })),
                        } })] }))] }));
};
export { RuPaymentType };
