import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DateField as OriginalField } from '../DateField';
const DateField = ({ description, name, rules, control, defaultValue, shouldUnregister, isRequired, ...inputProps }) => {
    const intl = useIntl();
    const { field: { ref, ...field }, fieldState: { error }, } = useController({
        name,
        control,
        defaultValue,
        rules: {
            required: isRequired
                ? {
                    value: true,
                    message: intl.formatMessage({ id: "Q4ipsb", defaultMessage: "Field is required." }),
                }
                : undefined,
            ...rules,
        },
        shouldUnregister,
    });
    const handleChange = (event, { value }) => {
        field.onChange(value);
    };
    return (_jsx(OriginalField, { ...field, ...inputProps, inputRef: ref, onChange: handleChange, value: field.value, error: error?.message, description: description }));
};
export { DateField };
