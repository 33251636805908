import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { partnerAPI } from '../../api';
import { updatePartner } from '../actions';
import { getPartner } from '../../store/selectors';
let lastRequestId;
const updateIsVehiclesHidden = createAsyncThunk(`${namespace}/update-vehicles-hidden`, async (formValues, { dispatch, requestId, getState }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const partner = getPartner(getState());
        const result = await partnerAPI.updateIsVehiclesHidden({
            ...partner,
            isVehiclesHidden: formValues.isVehiclesHidden,
        });
        await dispatch(updatePartner({
            isVehiclesHidden: formValues.isVehiclesHidden,
        }));
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { updateIsVehiclesHidden };
