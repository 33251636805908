import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Icon } from '../../../../src-common/view/components/Icon';
import s from './BlockInfo.css';
const BlockInfo = ({ blockInfo }) => {
    if (blockInfo === undefined) {
        return null;
    }
    return (_jsxs("div", { className: s.root, children: [_jsx(Icon, { icon: "lock", className: s.icon, size: '24px' }), _jsxs("div", { children: [_jsx(FormattedMessage, { id: 'Veo+8M', defaultMessage: 'Blocked' }), blockInfo.blockedAt && (_jsxs(_Fragment, { children: [': ', _jsx(FormattedDate, { value: blockInfo.blockedAt }), ' ', _jsx(FormattedTime, { value: blockInfo.blockedAt })] }))] }), _jsxs("div", { children: [_jsx(FormattedMessage, { id: 'MsJ5yw', defaultMessage: 'Reason' }), ': ', blockInfo.reason] }), blockInfo.notes && (_jsxs("div", { children: [_jsx(FormattedMessage, { id: 'aOdLtg', defaultMessage: 'Message' }), ': ', blockInfo.notes] }))] }));
};
export { BlockInfo };
