import { createSlice } from '@reduxjs/toolkit';
import { namespace as appNamespace } from '../namespace';
import { errorHandling } from '../../services/errorHandling';
import { LoginRequiredError, NotFoundError } from '../../shared/api/fetch-based-http-methods';
import { PartnerBlockedError } from '../../modules/partner/api';
import { InitialLoadError } from '../../modules/partner/actions/async/errors';
import { ValidationError } from '../../../src-common/api/src/validate';
import { ERROR } from './fatalErrors';
let handleError = () => undefined;
const stringify = (some) => {
    try {
        return JSON.stringify(some, null, 2);
    }
    catch (e) {
        return String(some);
    }
};
const initialState = {
    isAppInitialized: false,
    fatalError: undefined,
};
const callNextTick = (handler, ...args) => {
    setTimeout(() => handler(args));
};
const createNextTickHandler = (handler) => message => callNextTick(handler, message);
const appSlice = createSlice({
    name: appNamespace,
    initialState,
    reducers: {
        setErrorHandler: (state, action) => {
            handleError = createNextTickHandler(action.payload);
        },
        showError: (state, action) => {
            handleError(action.payload);
        },
        setIsAppInitialized: state => {
            state.isAppInitialized = true;
        },
    },
    extraReducers: builder => {
        builder.addMatcher((action) => action.error, (state, action) => {
            // action contains copy of error, so we can not use instanceOf
            if (LoginRequiredError.is(action.error)) {
                state.fatalError = ERROR.LOGIN_REQUIRED;
                return;
            }
            if (PartnerBlockedError.is(action.error)) {
                state.fatalError = ERROR.BLOCKED;
                return;
            }
            if (NotFoundError.is(action.error)) {
                state.fatalError = ERROR.NOT_FOUND;
                return;
            }
            errorHandling.captureException(action.error);
            if (InitialLoadError.is(action.error)) {
                state.fatalError = ERROR.INIT_LOAD_FAIL;
                return;
            }
            if (ValidationError.is(action.error)) {
                state.fatalError = ERROR.API_ERROR;
                return;
            }
            handleError(typeof action.error.message === 'string' ? action.error.message : stringify(action.error));
        });
    },
});
export { appSlice, appNamespace };
