import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../shared/view/Form';
import { updateOwnDriverInfo } from '../../../../modules/driver/actions/async/updateOwnDriver';
import { getMyDriver } from '../../../../modules/driver/store/selectors';
import { EditOwnDriverInfoView } from './view';
const EditOwnDriverInfo = ({ docs, onUploadDoc, onFinishSubmit, onGoBack, }) => {
    const dispatch = useDispatch();
    const driver = useSelector(getMyDriver);
    const postForm = useCallback(async (formValues) => {
        const { isSucceed, result = {} } = await dispatch(updateOwnDriverInfo(formValues)).unwrap();
        if (isSucceed && !result.validationErrors && !result.baseErrors) {
            onFinishSubmit();
        }
        return result;
    }, [dispatch, onFinishSubmit]);
    const { handleSubmit, form: { control, formState: { isSubmitting }, }, } = useForm({
        onSubmit: postForm,
        defaultValues: {
            licenseNumber: driver.licenseNumber,
            licensingAuthority: driver.licensingAuthority,
        },
    });
    return (_jsx(EditOwnDriverInfoView, { control: control, isSubmitting: isSubmitting, onSubmit: handleSubmit, docs: docs, onUploadDoc: onUploadDoc, onGoBack: onGoBack }));
};
export { EditOwnDriverInfo };
