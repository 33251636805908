import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ERROR } from '../../../app/store/fatalErrors';
import { signOut } from '../../../modules/auth/store/actions';
import { ErrorLayout } from './ErrorLayout';
import { AccountBlockedError } from './components/AccountBlockedError';
const ErrorBySlug = ({ slug }) => {
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        if (slug === ERROR.LOGIN_REQUIRED) {
            dispatch(signOut());
        }
    }, [slug, dispatch]);
    switch (slug) {
        case ERROR.INTERNAL_SERVER_ERROR:
            return (_jsx(ErrorLayout, { code: 500, title: _jsx(FormattedMessage, { id: 'ZsUgbi', defaultMessage: 'Internal server error' }), description: _jsx(FormattedMessage, { id: 'yCM2I2', defaultMessage: 'Something went wrong. please try again later.' }) }));
        case ERROR.BAD_GATEWAY:
            return (_jsx(ErrorLayout, { code: 502, title: _jsx(FormattedMessage, { id: 'Bl2mzQ', defaultMessage: 'Bad gateway' }), description: _jsx(FormattedMessage, { id: 'o4ivjh', defaultMessage: 'The server encountered a temporary error and could not complete your request. Please try again in 30 seconds.' }) }));
        case ERROR.NOT_FOUND:
            return (_jsx(ErrorLayout, { code: 404, title: _jsx(FormattedMessage, { id: 'HqlYmH', defaultMessage: 'Page not found' }), description: _jsx(FormattedMessage, { id: 's9F+tE', defaultMessage: 'Error 404. This page doesn\'t exist.' }), isLinkToIndexShown: true }));
        case ERROR.BLOCKED:
            return (_jsx(ErrorLayout, { title: _jsx(FormattedMessage, { id: 'TDrhF6', defaultMessage: 'Your account has been blocked' }), description: _jsx(AccountBlockedError, {}) }));
        case ERROR.LOGIN_REQUIRED:
            return null;
        default:
            return (_jsx(ErrorLayout, { title: _jsx(FormattedMessage, { id: 'D7Dner', defaultMessage: 'Error' }), description: _jsx(FormattedMessage, { id: 'yCM2I2', defaultMessage: 'Something went wrong. please try again later.' }) }));
    }
};
export { ErrorBySlug };
