import * as braze from '@braze/web-sdk';
import { newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone';
import { ClientHintsPlugin } from '@snowplow/browser-plugin-client-hints';
import { ENV } from '../env';
import { errorHandling } from './errorHandling';
const JSON_SCHEMA = 'iglu:com.snowplowanalytics.snowplow/web_page/jsonschema/1-0-0';
class EventTracking {
    constructor() {
        this.isInitialized = false;
    }
    get isEnabled() {
        return ENV.NODE_ENV === 'production';
    }
    init() {
        if (this.isInitialized) {
            return;
        }
        if (!this.isEnabled) {
            return;
        }
        newTracker('wheely_w4d', ENV.SNOWPLOW_COLLECTOR_URL ?? '', {
            appId: ENV.APP_ID,
            platform: 'web',
            withCredentials: false,
            discoverRootDomain: true,
            cookieName: 'x_wheely_sp_',
            encodeBase64: false,
            respectDoNotTrack: true,
            eventMethod: 'post',
            postPath: '',
            contexts: {
                webPage: true,
            },
            plugins: [TimezonePlugin(), ClientHintsPlugin(true)],
        });
        braze.initialize('__BRAZE_API_KEY__', {
            baseUrl: `${location.host}/api/v3`,
        });
        this.isInitialized = true;
    }
    setPartnerId(id) {
        if (!this.isEnabled) {
            return;
        }
        this.partnerId = id;
    }
    setDriverId(id) {
        if (!this.isEnabled) {
            return;
        }
        this.driverId = id;
        braze.changeUser(id);
    }
    trackSnowplow({ event, ...props }) {
        if (!this.isEnabled) {
            // eslint-disable-next-line no-console
            console.info('TRACK SNOWPLOW', event, props);
            return;
        }
        try {
            trackSelfDescribingEvent({
                event: {
                    schema: JSON_SCHEMA,
                    data: {
                        event,
                        event_group: 'Onboarding',
                        properties: {
                            ...props,
                            partner_id: this.partnerId,
                            driver_id: this.driverId,
                        },
                    },
                },
            });
        }
        catch (error) {
            errorHandling.captureException(error);
        }
    }
    trackBraze({ event, ...props }) {
        if (!this.isEnabled) {
            // eslint-disable-next-line no-console
            console.info('TRACK BRAZE', event, props);
            return;
        }
        if (!this.driverId) {
            return;
        }
        try {
            braze.logCustomEvent(event, {
                ...props,
                partner_id: this.partnerId,
                driver_id: this.driverId,
            });
        }
        catch (error) {
            errorHandling.captureException(error);
        }
    }
    track({ event, ...props }) {
        this.trackSnowplow({ event, ...props });
        this.trackBraze({ event, ...props });
    }
}
const eventTracking = new EventTracking();
export { EventTracking, eventTracking };
