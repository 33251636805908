import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@wheely/ui-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { BottomButtons } from '../../shared/view/BottomButtons';
import { StepLayout } from '../../shared/view/StepLayout';
import { DataField } from '../../shared/view/DataField';
import { Icon } from '../../../src-common/view/components/Icon';
import { VehicleSelector } from './components/VehicleSelector';
import s from './styles.css';
const EditCurrentVehicleView = ({ onSubmit, control, isSubmitting, onNavigateBack, vehicles, }) => {
    const intl = useIntl();
    return (_jsx(StepLayout, { hideProgress: true, title: intl.formatMessage({ id: "Gnx/o3", defaultMessage: "Current vehicle" }), onClickBack: onNavigateBack, children: _jsxs("main", { className: s.wrapper, children: [_jsx(Link, { to: "/vehicles", className: s.manageVehiclesLink, children: _jsx(DataField, { title: _jsx(FormattedMessage, { id: 'lplJAs', defaultMessage: 'Manage vehicles' }), children: _jsx("div", { className: s.iconWrapper, children: _jsx(Icon, { icon: 'chevron-to-end', className: s.linkIcon }) }) }) }), _jsxs("form", { onSubmit: onSubmit, className: s.form, children: [_jsx("div", { className: s.formFields, children: _jsx("div", { className: s.scrollableContainer, children: _jsx(VehicleSelector, { name: "carId", control: control, vehicles: vehicles }) }) }), _jsx(BottomButtons, { className: s.formButtons, children: _jsx(Button, { type: "submit", disabled: isSubmitting, className: s.mainButton, children: _jsx(FormattedMessage, { id: 'Q7Ha2N', defaultMessage: 'Change vehicle' }) }) })] })] }) }));
};
export { EditCurrentVehicleView };
