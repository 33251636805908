import { jsx as _jsx } from "react/jsx-runtime";
import { EnumCardItemStatus } from '../types';
import { CountryCode } from '../../../shared/models/country-code';
import { AccessStatusEnum } from '../../../shared/models/access-status';
import { assignTipTextForCardItems } from './assignTipTextForCardItems';
import { getItem } from './getItem';
import { createBookCarAppointmentCard } from './createBookCarAppointmentCard';
import { createGBBookCarAppointmentCard } from './createGBBookCarAppointmentCard';
const createCarsCards = (context, onNavigate, intl, user, links) => {
    const { items, cardStatus } = getItem({
        entities: context.cars,
        docDefinitions: context.carUploadDefinitions,
        getTitle: car => `${car.licensePlate} ${car.make}`,
        getItemAction: car => () => {
            if (['pending', 'rejected', 'expired_docs'].includes(car.accessStatus)) {
                onNavigate(`vehicles/${car.id}#docs`);
            }
            else {
                onNavigate(`vehicles/${car.id}`);
            }
        },
    });
    const itemsWithTips = assignTipTextForCardItems(intl, items);
    const result = [
        {
            id: 'cars',
            isBlocked: false,
            title: (() => {
                if (context.cars.length > 1) {
                    if (cardStatus === 'done') {
                        return intl.formatMessage({ id: "d+pK9s", defaultMessage: "Vehicle documents approved" });
                    }
                    if (cardStatus === 'review') {
                        return intl.formatMessage({ id: "QRhVLx", defaultMessage: "Vehicle documents" });
                    }
                    return intl.formatMessage({ id: "NCnwi4", defaultMessage: "Add vehicle documents" });
                }
                return intl.formatMessage({ id: "Z/C89n", defaultMessage: "Add vehicle" });
            })(),
            desc: intl.formatMessage({ id: "FqgSlN", defaultMessage: "Confirm <a>vehicle requirements.</a> The car must be inspected at the office." }, {
                a: (text) => (_jsx("a", { href: links.requirementsForCar, target: "_blank", rel: "noreferrer", children: text })),
            }),
            items: itemsWithTips,
            cardStatus,
            actionTitle: context.cars.length === 0 || user.isFleet
                ? intl.formatMessage({ id: "EwGCyS", defaultMessage: "Add vehicle" })
                : undefined,
            action: () => onNavigate(`/add-vehicle`),
        },
    ];
    if (context.partner.accessStatus === 'active') {
        if (context.partner.countryCode === CountryCode.GreatBritain &&
            context.myDriver &&
            context.myDriver.accessStatus !== 'pending' &&
            context.currentCar &&
            context.currentCar.accessStatus !== AccessStatusEnum.active) {
            result.push(createGBBookCarAppointmentCard(intl));
        }
        else if (items.some(i => i.status === EnumCardItemStatus.waitingForApproval)) {
            result.push(createBookCarAppointmentCard(intl, links));
        }
    }
    return result;
};
export { createCarsCards };
