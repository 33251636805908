import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import s from './styles.css';
const formatDate = ({ date, timezone, intl }) => intl.formatDate(date, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: timezone,
});
const mapToHeader = (journey, intl) => ({
    completedAt: journey.completedAt
        ? formatDate({ date: journey.completedAt, intl, timezone: journey.timezone })
        : null,
    type: journey.isASAP
        ? intl.formatMessage({ id: "hGcAWJ", defaultMessage: "ASAP" })
        : `${intl.formatMessage({ id: "9i7q+r", defaultMessage: "Reservation" })}, ${formatDate({ date: journey.pickupAt, intl, timezone: journey.timezone })}`,
});
const HeaderDetails = ({ journey }) => {
    const intl = useIntl();
    const titles = useMemo(() => [
        [
            'completedAt',
            intl.formatMessage({ id: "LFleTx", defaultMessage: "Completed at" }),
        ],
        [
            'type',
            intl.formatMessage({ id: "lWlj+k", defaultMessage: "Type" }),
        ],
    ], [intl]);
    const values = useMemo(() => mapToHeader(journey, intl), [journey, intl]);
    return _jsx(DescriptionList, { titles: titles, values: values, className: s.details });
};
export { HeaderDetails };
