import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { setUpcomingBill } from '../actions';
import { billAPI } from '../../api';
const loadUpcomingBill = createAsyncThunk(`${namespace}/load-upcoming-bill`, async (_, { dispatch }) => {
    const upcomingBill = await billAPI.fetchUpcomingBill();
    dispatch(setUpcomingBill(upcomingBill));
    return upcomingBill;
});
export { loadUpcomingBill };
