import { storage } from '../../../services/storage';
class FormLocalStorage {
    getItem(key) {
        const value = storage.getItem(key);
        return value ? JSON.parse(value) : null;
    }
    setItem(key, values) {
        storage.setItem(key, JSON.stringify(values));
    }
    removeItem(key) {
        storage.removeItem(key);
    }
}
const formLocalStorage = new FormLocalStorage();
export { formLocalStorage };
