import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { H5 } from '@wheely/ui-kit';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { DataField } from '../DataField';
import s from './styles.css';
const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
const DescriptionList = (props) => {
    const { values, itemsForSelect, title: mainTitle, titles, className, children, action } = props;
    const intl = useIntl();
    const renderValue = (key, value) => {
        if (value instanceof Date) {
            return intl.formatDate(value);
        }
        if (itemsForSelect && key in itemsForSelect) {
            const items = itemsForSelect[key];
            const foundTitle = items?.find(item => item.value === value)?.title;
            if (foundTitle) {
                return foundTitle;
            }
        }
        if (value === null) {
            return '-';
        }
        return String(value);
    };
    return (_jsxs("div", { className: cn(s.root, className), "data-test-id": "key-value-list", children: [mainTitle && (_jsxs(H5, { capitalize: true, className: s.title, children: [_jsx("div", { "data-test-id": "key-value-list__title", children: mainTitle }), action && _jsx("div", { "data-test-id": "key-value-list__action", children: action })] })), _jsx("div", { className: s.list, children: titles
                    .filter(([key]) => isNotEmpty(values[key]))
                    .map(([key, title, Wrapper = React.Fragment]) => (_jsx(Wrapper, { children: _jsx(DataField, { title: title, children: _jsx("span", { className: s.valueWrapper, children: typeof values[key] === 'function'
                                ? values[key]()
                                : renderValue(key, values[key]) }) }) }, key))) }), _jsx("div", { "data-test-id": "key-value-list__note", children: children })] }));
};
export { DescriptionList };
