import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { updateUploadFormValue } from '../actions';
import { getCarForm } from '../../store/selectors';
import { carUploadSelectors } from '../../store/upload-slice';
import { createCar } from './createCar';
let lastRequestId;
const prepareUploadCarForm = createAsyncThunk(`${namespace}/prepare-upload-car-form`, async ({ docType, onSuccess, vehicleFormValues }, { getState, dispatch, requestId }) => {
    lastRequestId = requestId;
    const carForm = getCarForm(getState());
    if (!carForm.id && vehicleFormValues) {
        const { isSucceed } = await dispatch(createCar({ formValues: vehicleFormValues })).unwrap();
        if (!isSucceed) {
            return { isSucceed: false };
        }
    }
    if (lastRequestId !== requestId) {
        return { isReplaced: true };
    }
    dispatch(updateUploadFormValue({ docType }));
    const values = carUploadSelectors.getValuesForUploadForm(getState());
    dispatch(updateUploadFormValue({
        expiresAt: values?.expiresAt,
        file: values?.file,
        status: values?.status,
        rejectReason: values?.rejectReason,
    }));
    onSuccess?.();
    return { isSucceed: true };
});
export { prepareUploadCarForm };
