import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H3, useDialog } from '@wheely/ui-kit';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SelectField } from '../../../../src-common/view/adapters/SelectField';
import { TextField } from '../../../../src-common/view/adapters/TextField';
import { Button } from '../../../../src-common/view/adapters/Button';
import s from './Blocking.css';
const Blocking = ({ onBlock }) => {
    const intl = useIntl();
    const options = useMemo(() => [
        {
            title: intl.formatMessage({ id: "SzHk1F", defaultMessage: "Temporarily blocked by the partner" }),
            value: 'Temporarily blocked by the partner',
        },
        {
            title: intl.formatMessage({ id: "uq/F0T", defaultMessage: "Fired by the partner" }),
            value: 'Fired by the partner',
        },
        {
            title: intl.formatMessage({ id: "2Ea+3e", defaultMessage: "Fraudster" }),
            value: 'Fraudster',
        },
        {
            title: intl.formatMessage({ id: "XvRFUa", defaultMessage: "Other" }),
            value: 'Other',
        },
    ], [intl]);
    const [values, setValues] = useState({ reason: '', message: '' });
    const handleChange = useCallback((_, { value, name }) => {
        setValues(current => ({ ...current, [name]: value }));
    }, []);
    const handleBlock = useCallback(() => {
        onBlock(values.reason, values.message);
    }, [onBlock, values.message, values.reason]);
    return (_jsxs("div", { className: s.root, children: [_jsx(SelectField, { title: _jsx(FormattedMessage, { id: 'ljHwP6', defaultMessage: 'Reason' }), items: options, value: values.reason, name: 'reason', onChange: handleChange }), _jsx(TextField, { title: _jsx(FormattedMessage, { id: 'ieD6Vm', defaultMessage: 'Message' }), value: values.message, name: 'message', onChange: handleChange, placeholder: _jsx(FormattedMessage, { id: 'VC90GT', defaultMessage: 'Block reason message' }) }), _jsx("div", { className: s.actions, children: _jsx(Button, { onClick: handleBlock, intent: 'danger', isDisabled: values.reason === '' || values.message === '', children: _jsx(FormattedMessage, { id: 'jkdv4T', defaultMessage: 'Block' }) }) })] }));
};
const useOpenBlockingModal = () => {
    const { openDialog, closeDialogs } = useDialog();
    const openBlocking = (params) => {
        openDialog(() => _jsx(Blocking, { onBlock: params.onBlock }), {
            title: (_jsx(H3, { children: _jsx(FormattedMessage, { id: 'Ob6B6s', defaultMessage: 'Block the chauffeur' }) })),
            bodyClassName: s.dialog,
        });
    };
    return { closeDialogs, openBlocking };
};
export { Blocking, useOpenBlockingModal };
