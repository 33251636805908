import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../namespace';
import { onboardingAPI } from '../api';
import { updatePartner } from '../../partner/actions/actions';
import { getPartnerType } from '../../partner/store/selectors';
import { loadPartnerDocDefinitions } from '../../partner/actions/async/loadDocDefinitions';
import { loadAllCars } from '../../car/actions/async/loadAllCars';
const completeOnboarding = createAsyncThunk(`${namespace}/complete-onboarding`, async (_, { dispatch, getState }) => {
    const partnerType = getPartnerType(getState());
    dispatch(loadPartnerDocDefinitions(partnerType));
    dispatch(loadAllCars());
    const onboarding = await onboardingAPI.completeOnboarding();
    await dispatch(updatePartner({ onboarding }));
});
export { completeOnboarding };
