import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useCallback } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ListLayoutView } from '../../shared/view/ListLayout/ListLayout';
import { MobileListItem } from '../../shared/view/ListLayout/MobileListItem';
import { getCity } from '../../modules/partner/store/selectors';
import { PeriodNameEnum } from '../../modules/earning/model';
import { formatDate } from '../../shared/utils/formatDate';
import { Feedback } from './components/Feedback';
import s from './styles.css';
const translatePeriodName = (intl, value) => {
    switch (value) {
        case 'yesterday':
            return intl.formatMessage({ id: "6dIxDP", defaultMessage: "Yesterday" });
        case 'last_month':
            return intl.formatMessage({ id: "y9ZbeM", defaultMessage: "Last month" });
        case 'month':
            return intl.formatMessage({ id: "AaULw5", defaultMessage: "Current month" });
        case 'current_week':
            return intl.formatMessage({ id: "xoUu9H", defaultMessage: "Current week" });
        case 'today':
            return intl.formatMessage({ id: "zWgbGg", defaultMessage: "Today" });
        case 'last_week':
            return intl.formatMessage({ id: "ynWMp2", defaultMessage: "Last week" });
    }
};
const getRowLink = ({ id, from, to }) => `/journeys?for=${id}&from=${from ? formatDate(from) : ''}&to=${to ? formatDate(to) : ''}`;
const View = ({ earnings, interval, fetchEarnings, isLoading }) => {
    const intl = useIntl();
    const history = useHistory();
    const currencyCode = useSelector(getCity)?.currencyCode;
    const columns = useMemo(() => [
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'fQ9BTa', defaultMessage: 'chauffeur' })),
            renderCell: earning => earning.driverName,
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: '1Xw6Vi', defaultMessage: 'rate' })),
            renderCell: earning => earning.acceptance !== null ? (_jsxs(_Fragment, { children: [_jsx(FormattedNumber, { value: earning.acceptance * 100, maximumFractionDigits: 2 }), "%"] })) : null,
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'lTurO7', defaultMessage: 'journeys' })),
            renderCell: earning => earning.count,
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'VwiuM3', defaultMessage: 'fare' })),
            renderCell: earning => (_jsx(FormattedNumber, { value: earning.gross, style: "currency", currency: currencyCode })),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'wykj+i', defaultMessage: 'commissions' })),
            renderCell: earning => (_jsx(FormattedNumber, { value: earning.commission, style: "currency", currency: currencyCode })),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: '01FUFE', defaultMessage: 'parking, toll road' })),
            renderCell: earning => (_jsx(FormattedNumber, { value: earning.parking, style: "currency", currency: currencyCode })),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'VtD+PD', defaultMessage: 'tips' })),
            renderCell: earning => (_jsx(FormattedNumber, { value: earning.tips, style: "currency", currency: currencyCode })),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: '81C4FK', defaultMessage: 'other' })),
            renderCell: earning => (_jsx(FormattedNumber, { value: earning.other, style: "currency", currency: currencyCode })),
        },
        {
            renderHeader: () => (_jsx(FormattedMessage, { id: 'FeuG+/', defaultMessage: 'payouts' })),
            renderCell: earning => (_jsx(FormattedNumber, { value: earning.payout, style: "currency", currency: currencyCode })),
        },
    ], [currencyCode]);
    const onClickRow = useCallback((earning) => earning && history.push(getRowLink({ id: earning.id, from: interval.from, to: interval.to })), [history, interval]);
    return (_jsx("div", { className: s.root, children: _jsx(ListLayoutView, { before: _jsx(Feedback, {}), title: _jsx(FormattedMessage, { id: '3H+PIM', defaultMessage: 'Overview' }), data: earnings, after: _jsx("div", { className: s.bottomPadding }), columns: columns, getEntityId: earning => String(earning.id), onFetchData: fetchEarnings, shouldBeEmptyWithoutFilters: false, onClickRow: onClickRow, renderItem: earning => (_jsx(Link, { to: getRowLink({ id: earning.id, from: interval.from, to: interval.to }), children: _jsx(MobileListItem, { title: earning.driverName, note: _jsx(FormattedMessage, { id: '8WWIK+', defaultMessage: '{journeys, plural, one {# journey} other {# journeys}}', values: { journeys: earning.count } }), right: _jsx(FormattedNumber, { value: earning.payout, style: "currency", currency: currencyCode }) }) })), filterConfig: [
                {
                    name: 'periodName',
                    type: 'select',
                    initialValue: PeriodNameEnum.last_week,
                    options: useMemo(() => Object.keys(PeriodNameEnum).map(value => ({
                        value,
                        title: translatePeriodName(intl, value),
                    })), [intl]),
                },
            ], isLoading: isLoading }) }));
};
export { View };
