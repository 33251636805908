import * as t from 'io-ts';
import { User } from './w4d-user-types';
const w4dUserRemoteAPI = ({ get, apiHost }) => ({
    readUser: {
        path: `${apiHost}/v6/user`,
        response: t.type({
            user: User,
        }),
        method: get,
    },
});
export { w4dUserRemoteAPI };
