import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DescriptionList } from '../../../shared/view/DescriptionList';
import { Icon } from '../../../../src-common/view/components/Icon';
import s from './OrdersInfo.css';
const OrdersInfo = ({ className, driver }) => {
    const intl = useIntl();
    const titles = useMemo(() => [
        [
            'orders',
            intl.formatMessage({ id: "ggrksM", defaultMessage: "See All Orders" }),
            ({ children }) => (_jsx(Link, { to: `/journeys?for=${driver.id}`, className: s.link, children: children })),
        ],
    ], [driver.id, intl]);
    const values = {
        orders: () => _jsx(Icon, { icon: 'chevron-to-end', className: s.linkIcon }),
    };
    return (_jsx(DescriptionList, { title: intl.formatMessage({ id: "FzsDOj", defaultMessage: "Orders" }), className: className, titles: titles, values: values }));
};
export { OrdersInfo };
