import * as t from 'io-ts';
import { licensingAuthorityTypesEnum } from '../../../src/shared/models/licensingAuthority';
import { WelcomeStepErrors, HelloStepErrors, PaymentInfoStepErrors, PayoutsStepErrors, Onboarding, } from './w4d-onboarding-types';
import { Partner } from './w4d-partner-types';
const w4dOnboardingRemoteAPI = ({ apiHost, put }) => ({
    updateWelcomeStep: {
        path: `${apiHost}/v6/onboarding/welcome`,
        response: t.partial({
            errors: WelcomeStepErrors,
            driver: t.type({
                id: t.string,
            }),
            partner: t.type({
                id: t.string,
                onboarding: t.union([Onboarding, t.undefined]),
            }),
        }),
        payload: t.type({
            city_id: t.string,
            first_name: t.string,
            middle_name: t.string,
            last_name: t.string,
            email: t.string,
            chauffeur_promo_code: t.string,
            source: t.union([t.null, t.string]),
        }),
        method: put,
    },
    updateHelloStep: {
        path: `${apiHost}/v6/onboarding/hello`,
        response: t.union([
            t.partial({
                errors: t.undefined,
                driver: t.partial({
                    id: t.string,
                }),
                partner: t.type({
                    id: t.string,
                    onboarding: t.union([Onboarding, t.undefined]),
                }),
            }),
            t.type({
                errors: HelloStepErrors,
                partner: t.undefined,
            }),
        ]),
        payload: t.partial({
            birthday: t.string,
            home_address: t.string,
            license_number: t.string,
            uk_national_insurance: t.string,
            personal_inn: t.string,
            license_authority: t.union([t.undefined, t.keyof(licensingAuthorityTypesEnum), t.null]),
        }),
        method: put,
    },
    updatePayoutsStep: {
        path: `${apiHost}/v6/onboarding/payouts`,
        response: t.union([
            t.type({
                errors: t.undefined,
                partner: Partner,
            }),
            t.type({
                errors: PayoutsStepErrors,
                partner: t.undefined,
            }),
        ]),
        payload: t.type({
            partner_type: t.string,
            company_name: t.union([t.undefined, t.string]),
            company_address: t.union([t.undefined, t.string]),
            company_reg_number: t.union([t.undefined, t.string]),
            vat_reg_no: t.union([t.undefined, t.string]),
            inn: t.union([t.undefined, t.string]),
            kpp: t.union([t.undefined, t.string]),
        }),
        method: put,
    },
    updatePaymentInfoStep: {
        path: `${apiHost}/v6/onboarding/payment_info`,
        response: t.union([
            t.partial({
                errors: t.undefined,
                partner: Partner,
            }),
            t.type({
                errors: PaymentInfoStepErrors,
                partner: t.undefined,
            }),
        ]),
        payload: t.partial({
            account_number: t.string,
            sort_code: t.string,
            bik: t.string,
            swift: t.string,
            iban: t.string,
            ras_account: t.string,
            kor_account: t.string,
            bank_name: t.string,
        }),
        method: put,
    },
    completeOnboarding: {
        path: `${apiHost}/v6/onboarding/complete`,
        response: t.type({
            partner: Partner,
        }),
        method: put,
    },
    updatePartnerType: {
        path: `${apiHost}/v6/onboarding/partner_type`,
        response: t.type({
            partner: Partner,
        }),
        payload: t.type({
            partner_type: t.string,
        }),
        method: put,
    },
});
export { w4dOnboardingRemoteAPI };
