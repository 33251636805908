import { createClient } from '../../../../src-common/api/src/create-client';
import { w4dJourneysRemoteAPI } from '../../../../src-common/api/definitions/w4d-journeys-endpoints';
import { w4dJourneyReportRemoteAPI } from '../../../../src-common/api/definitions/w4d-journey-report-endpoints';
import { get, post } from '../../../shared/api/fetch-based-http-methods';
import { ENV } from '../../../env';
const journeysClient = createClient(w4dJourneysRemoteAPI({ get, apiHost: ENV.API_URL }));
const reportsClient = createClient(w4dJourneyReportRemoteAPI({ post, apiHost: ENV.API_URL }));
const prepareChauffeur = (journey) => journey.service_provider
    ? {
        id: journey.service_provider.id,
        firstName: journey.service_provider.first_name,
        lastName: journey.service_provider.last_name,
    }
    : undefined;
const prepareVehicle = (journey) => journey.vehicle
    ? {
        id: journey.vehicle.id,
        make: journey.vehicle.make,
        model: journey.vehicle.model,
        licensePlate: journey.vehicle.license_plate,
    }
    : undefined;
const prepareEvents = (journey) => journey.events
    ? journey.events.map(event => ({
        date: new Date(event.ts),
        name: event.name,
    }))
    : undefined;
const prepareJourney = (journey) => ({
    id: journey.id,
    number: journey.number,
    status: journey.status,
    completedAt: journey.completed_at ? new Date(journey.completed_at) : undefined,
    pickupAt: journey.pickup_at ? new Date(journey.pickup_at) : undefined,
    service: journey.service.title,
    duration: journey.serving_time,
    chauffeur: prepareChauffeur(journey),
    vehicle: prepareVehicle(journey),
    price: journey.price,
    currencyCode: journey.service.fare.currency_code,
    breakdown: journey.breakdown,
    events: prepareEvents(journey),
    timezone: journey.service.timezone,
    isASAP: journey.pickup_at ? false : true,
    isTotalDefined: journey.price !== null && journey.price !== undefined,
});
const journeyAPI = {
    fetchJourneys: async ({ limit = 20, offset = 0, driverId, from, to, number, } = {}) => {
        const response = await journeysClient.readJourneys({
            pathParams: {
                limit: String(limit),
                offset: String(offset),
                driverId: driverId ?? '',
                from: from ?? '',
                to: to ?? '',
                number: number ?? '',
            },
        });
        const items = response.results.map(prepareJourney);
        return {
            items,
            total: response.total_entries,
            limit: response.limit,
            offset: response.skip,
        };
    },
    fetchJourney: async (id) => {
        const response = await journeysClient.readJourney({ pathParams: { id } });
        return prepareJourney(response.order);
    },
    requestJourneyReport: async ({ driverId = '', from = '', to = '', } = {}) => reportsClient.sendReport({ payload: { driver_id: driverId, from, to } }),
};
export { journeyAPI, prepareJourney };
