import { createAsyncThunk } from '@reduxjs/toolkit';
import { namespace } from '../../namespace';
import { driverAPI } from '../../api';
import { getMyDriver } from '../../store/selectors';
import { updateMyDriver } from '../actions';
let lastRequestId;
const updateOwnDriverInfo = createAsyncThunk(`${namespace}/submit-update-own-driver-info`, async (formValues, { dispatch, requestId, getState }) => {
    try {
        if (lastRequestId !== undefined) {
            return { isAlreadyExecuted: true };
        }
        lastRequestId = requestId;
        const result = {};
        const driver = getMyDriver(getState());
        const [, driverUpdateErrors] = await driverAPI.updateDriver(driver.id, {
            ...driver,
            ...formValues,
        });
        if (driverUpdateErrors) {
            const { licensingAuthority, licenseNumber, ...other } = driverUpdateErrors;
            if (licensingAuthority || licenseNumber) {
                result.validationErrors = {
                    licensingAuthority,
                    licenseNumber,
                };
            }
            result.baseErrors = Object.values(other).flat();
        }
        if (!driverUpdateErrors) {
            await dispatch(updateMyDriver({
                licenseNumber: formValues.licenseNumber,
                licensingAuthority: formValues.licensingAuthority,
            }));
        }
        return {
            isSucceed: true,
            result,
        };
    }
    finally {
        lastRequestId = undefined;
    }
});
export { updateOwnDriverInfo };
