const prepareErrors = (errors) => {
    if (!errors) {
        return undefined;
    }
    /*
      API returns something like this:
      errors: {
        payment_info: [
          “Invalid BIC and Bank account combination”,
          “Invalid BIC and Bank account combination”
        ]
      }
    */
    return [...new Set(Object.values(errors).flat())];
};
export { prepareErrors };
