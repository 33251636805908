import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { ENGINE_MESSAGES, ENGINES } from '../api/hardcoded-make-models';
export const useEngines = () => {
    const intl = useIntl();
    return useMemo(() => Object.values(ENGINES).map(engine => ({
        title: intl.formatMessage(ENGINE_MESSAGES[engine]),
        value: engine,
    })), [intl]);
};
